.preferential-zone {
    $this: &;

    &__about {
        display: flex;
        justify-content: space-between;

        @include respond-to(desktop) {
            flex-direction: column;
            gap: 70px;
        }

        @include respond-to(mobile) {
            gap: 50px;
        }

        &-name {
            max-width: 680px;

            @include respond-to(desktop) {
                max-width: 100%;
            }

            &:only-child {
                max-width: 100%;

                #{$this}__about-header {
                    max-width: 100%;
                }
            }
        }

        &-header {
            max-width: 640px;
            margin-block-end: 30px;

            @include respond-to(desktop) {
                max-width: 100%;
            }

            @include respond-to(tablet) {
                font-size: 2.5rem;
                line-height: 1;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                line-height: 1.2;
                margin-block-end: 20px;
            }
        }

        &-text {
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }

        &-presentation {
            position: relative;
            overflow: hidden;
            max-width: 563px;
            min-height: 338px;
            padding: 40px;
            border-radius: 8px;
            background-color: var(--c-light-bg);

            @include respond-to(desktop) {
                max-width: 100%;
            }

            @include respond-to(tablet) {
                min-height: 255px;
            }

            @include respond-to(mobile) {
                padding-block: 30px;
                padding-inline: 25px;
                min-height: 338px;
            }

            &.full-presentation-image {
                min-height: unset;
                padding: 0;
                min-width: 563px;
                background-color: var(--c-white);

                @include respond-to(tablet) {
                    min-width: 100%;
                }
            }
        }

        &-logo {
            position: absolute;
            inset-inline-end: 0;
            inset-block-end: 0;

            @include respond-to(tablet) {
                max-width: 317px;
            }

            @include respond-to(mobile) {
                max-width: 267px;
            }

            .full-presentation-image & {
                position: static;
                width: 100%;
                border-radius: 8px;

                @include respond-to(tablet) {
                    min-width: 100%;
                }
            }
        }

        &-presentation-header {
            font-size: 1.875rem;
            line-height: 1.2;
            margin-block-end: 40px;

            @include respond-to(tablet) {
                font-weight: 600;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                margin-block-end: 50px;
            }
        }

        &-link {
            position: relative;

            .full-presentation-image & {
                position: absolute;
                inset-inline-start: 16px;
                inset-block-start: 40px;
                max-width: calc(100% - 32px);
                line-height: 1.4;
                height: auto;
                white-space: normal;

                @include respond-to(mobile) {
                    inset-block-start: 12px;
                    width: calc(100% - 32px);
                }
            }
        }

        &_alt {
            gap: 140px;

            @include respond-to(tablet) {
                gap: 70px;
            }

            @include respond-to(mobile) {
                gap: 50px;
            }
        }
    }

    &__highlight {
        background-color: var(--c-light-bg);
        border-radius: 8px;
        color: var(--c-primary);
        display: flex;
        gap: 50px;
        padding-block: 80px;
        padding-inline: 50px;
        text-align: center;

        @include respond-to(tablet) {
            flex-wrap: wrap;
            gap: 66px 50px;
            padding-block: 50px;
            padding-inline: 25px;

            & .flex-item-equal {
                flex: 0 0 calc(50% - 25px);
            }
        }

        @include respond-to(mobile) {
            row-gap: 50px;
            padding-block: 40px;
            padding-inline: 20px;
            text-align: start;

            & .flex-item-equal {
                flex: 0 0 100%;
            }

            & .h2 {
                font-size: 2.1875rem;
                line-height: 1;
            }
        }

        &_alt {
            background-color: var(--c-true-transparent);
            padding: 0;
            text-align: start;
        }
    }

    &__platforms-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &_alt {
            gap: 140px;

            @include respond-to(tablet) {
                flex-direction: column;
                gap: 40px;
            }
        }
    }

    &__platforms-item {
        background-color: var(--c-light-blue);
        padding: 30px;
        border-radius: 8px;
        width: calc(50% - 10px);

        @include respond-to(tablet) {
            width: 100%;
        }

        @include respond-to(mobile) {
            padding: 20px;
            display: flex;
            flex-direction: column-reverse;
            gap: 40px;
        }

        &_alt {
            width: calc(33.3% - (280px / 3));
            background-color: var(--c-true-transparent);
            padding: 0;
            min-height: 472px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-to(tablet) {
                width: 100%;
                flex-direction: row-reverse;
                min-height: 179px;
                gap: 20px;
            }

            @include respond-to(mobile) {
                flex-direction: column-reverse;
                min-height: 285px;
            }
        }
    }

    &__platforms-image {
        float: right;
        width: 42%;
        margin-left: 20px; // не надо менять при rtl
        object-fit: cover;

        @include respond-to(tablet) {
            width: 40%;
        }

        @include respond-to(mobile) {
            float: none;
            margin-left: 0; // не надо менять при rtl
            width: 100%;
        }

        &_alt {
            width: 100%;
            float: none;
            margin-left: 0;
            margin-block-start: 55px;

            @include respond-to(tablet) {
                margin-block-start: 0;
                width: calc(50% - 10px);
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    &__platforms-text {
        & h4 {
            font-size: 1.5rem;
            line-height: 1.4;
            margin-block-end: 5px;

            @include respond-to(tablet) {
                font-size: 1.25rem;
                margin-block-end: 10px;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
                margin-block-end: 5px;
            }
        }

        &_alt {
            @include respond-to(tablet) {
                width: calc(50% - 10px);
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    &__platforms-square {
        color: var(--c-accent);
        font-size: 1rem;
        line-height: 1.4;
        margin-block-end: 40px;

        @include respond-to(mobile) {
            font-size: 0.875rem;
            margin-block-end: 20px;
        }
    }

    &__platforms-par {
        font-size: 1.125rem;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__points {
        &-wrapper {
            padding-block: 60px;
            padding-inline: 75px;
            background-color: var(--c-light-blue);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 40px;
            border-radius: 8px;

            @include respond-to(tablet) {
                grid-template-columns: 1fr 1fr;
                padding-block: 30px;
                padding-inline: 20px;
                grid-gap: 20px;
            }

            @include respond-to(mobile) {
                grid-template-columns: 1fr;
            }

            & p {
                position: relative;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.4;
                margin-inline-start: 22px;

                &::before {
                    position: absolute;
                    inset-block-start: -0.5em;
                    inset-inline-start: -22px;
                    color: var(--c-alt-base-color);
                    font-size: 2.5rem;
                    content: "•";
                }

                @include respond-to(tablet) {
                    font-size: 0.875rem;
                }
            }
        }
    }

    &__privileges {
        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            @include respond-to(tablet) {
                grid-template-columns: 1fr;
            }

            &:not(:last-child) {
                margin-block-end: 57px;

                @include respond-to(mobile) {
                    margin-block-end: 50px;
                }
            }
        }

        &-list {
            &:not(:last-child) {
                margin-block-end: 100px;

                @include respond-to(mobile) {
                    margin-block-end: 80px;
                }
            }
        }

        &-item {
            padding: 40px;
            background-color: var(--c-white);
            display: flex;
            flex-direction: column;
            border-radius: 8px;

            @include respond-to(mobile) {
                padding: 30px;
            }

            &-title {
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 140%;

                @include respond-to(tablet) {
                    font-size: 1rem;
                }

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                gap: 50px;

                &:not(:last-child) {
                    margin-block-end: 43px;

                    @include respond-to(tablet) {
                        margin-block-end: 54px;
                    }
                }
            }

            &-bid {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &-values {
                    display: flex;
                    gap: 30px 100px;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                &-value {
                    width: calc((100% - 100px) / 2);

                    @include respond-to(mobile) {
                        width: 100%;
                    }
                }

                &-subtitle {
                    color: var(--c-primary);
                }

                & h2 {
                    margin-block-end: 10px;

                    @include respond-to(mobile) {
                        font-size: 1.875rem;
                        margin-block-end: 11px;
                    }
                }

                & span {
                    font-weight: 500;
                    font-size: 1.5rem;
                    line-height: 1.4;

                    @include respond-to(tablet) {
                        font-size: 1rem;
                    }

                    @include respond-to(mobile) {
                        font-size: 0.875rem;
                    }
                }
            }
        }

        &-header {
            display: flex;
            align-items: baseline;

            @include respond-to(mobile) {
                flex-direction: column;
                gap: 10px;
                margin-block-end: 30px;
            }

            & h2 {
                min-width: 350px;
                max-width: 350px;

                @include respond-to(mobile) {
                    min-width: unset;
                    max-width: unset;
                    margin-block-end: 0;
                }
            }
        }

        &-accent {
            color: var(--c-alt-base-color);
            font-weight: 600;
        }

        &-row {
            display: flex;
            align-items: flex-end;

            &:not(:last-child) {
                margin-block-end: 30px;
            }

            @include respond-to(tablet) {
                flex-direction: column;
                align-items: flex-start;
                gap: 40px;
            }

            @include respond-to(mobile) {
                gap: 30px;
            }
        }

        &-name {
            min-width: 350px;
            max-width: 350px;
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 500;

            &_with-padding {
                padding-inline-end: 130px;

                @include respond-to(tablet) {
                    padding-inline-end: 0;
                }
            }

            @include respond-to(tablet) {
                min-width: unset;
                max-width: unset;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        &-subtitle {
            font-size: 0.875rem;
            line-height: 1.65;
            margin-block-start: 10px;
        }

        &-value {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
        }

        &-simple {
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 600;
        }

        &-legend {
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 500;

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        &-string {
            font-size: 1rem;
            line-height: 1.65;
            font-weight: 500;
            align-self: flex-end;

            &_smaller {
                font-size: 0.875rem;
                align-self: flex-start;
                margin-inline-start: 20px;
            }
        }

        &-grid {
            display: flex;
            flex-direction: column;
            max-width: 993px;

            #{$this}__privileges-row {
                padding-block-end: 80px;
                align-items: flex-start;
                grid-gap: 60px;

                @include respond-to(tablet) {
                    flex-flow: row wrap;
                    grid-gap: 30px;
                    padding-block-end: 0;
                }

                @include respond-to(mobile) {
                    grid-gap: 20px;
                }

                &:not(:last-child) {
                    margin-block-end: 0;

                    @include respond-to(tablet) {
                        padding-block-end: 70px;
                    }

                    @include respond-to(mobile) {
                        padding-block-end: 60px;
                    }
                }
            }

            #{$this}__privileges-counters-wrapper {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 30px 9px;

                @include respond-to(mobile) {
                    flex-direction: column;
                }
            }

            #{$this}__privileges-name {
                min-width: 213px;
                max-width: 213px;

                &:empty {
                    @include respond-to(tablet) {
                        display: none;
                    }
                }

                @include respond-to(tablet) {
                    min-width: 100%;
                    max-width: unset;
                }
            }

            #{$this}__privileges-string {
                min-width: 100%;
                font-weight: 400;

                @include respond-to(tablet) {
                    font-size: 0.875rem;
                    line-height: 1.4;
                }

                @include respond-to(mobile) {
                    margin-top: 10px;
                }
            }

            & .item-with-counter {
                align-self: flex-start;
                width: 233px;

                @include respond-to(tablet) {
                    width: 230px;
                }

                &__counter {
                    width: 100%;
                    font-weight: 400;
                }

                &__value {
                    width: 100%;
                }
            }
        }
    }

    &__fringe {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: stretch;
        border-radius: 8px;

        @include respond-to(tablet) {
            flex-wrap: nowrap;
            overflow-x: auto;
            scrollbar-width: none; // ff

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-item {
            padding: 20px;
            display: flex;
            background-color: var(--c-white);
            flex-direction: column;
            justify-content: space-between;
            min-height: 327px;
            border-radius: 8px;
            width: calc((100% - 60px) / 4);

            &:last-child {
                flex: 1;
                max-width: calc((100% - 20px) / 2);
            }

            @include respond-to(tablet) {
                min-width: 330px;
            }

            @include respond-to(mobile) {
                min-width: 252px;
                min-height: 240px;
            }
        }

        &-id {
            text-align: center;
            width: 67px;
            height: 67px;
            line-height: 67px;
            font-weight: 500;
            font-size: 1.875rem;
            align-self: end;
            background-color: var(--c-light-bg);
            border-radius: 50%;

            @include respond-to(mobile) {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 1.375rem;
            }
        }
    }

    &__services {
        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            @include respond-to(mobile) {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scrollbar-width: none; // ff

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-item {
            min-height: 243px;
            border-radius: 8px;
            padding: 20px;
            background-color: var(--c-light-bg);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-to(mobile) {
                min-width: 252px;
                min-height: 240px;
            }

            &-id {
                font-weight: 500;
                font-size: 1.875rem;
                background-color: var(--c-white);
                text-align: center;
                width: 67px;
                height: 67px;
                line-height: 67px;
                border-radius: 50%;
            }

            &-text {
                max-width: 340px;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 140%;

                @include respond-to(tablet) {
                    font-size: 1rem;
                }

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }
    }

    &__links-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to(mobile) {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            scrollbar-width: none; // ff

            &::-webkit-scrollbar {
                display: none;
            }
        }

        & .simple-link-block {
            background-color: var(--c-light-bg);

            &:hover {
                background-color: var(--c-light-bg);
            }

            @include respond-to(mobile) {
                min-width: 252px;
                max-height: unset;
                height: unset;
            }
        }

        &_alt {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;

            @include respond-to(tablet) {
                gap: 40px;
            }

            & .text-with-counter {
                width: calc(50% - 25px);

                &:not(:last-child) {
                    margin-block-end: 0;
                }

                @include respond-to(tablet) {
                    width: 100%;
                }
            }
        }
    }

    &__priority {
        &-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;

            @include respond-to(tablet) {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                scrollbar-width: none; // ff

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-item {
            position: relative;
            min-height: 320px;
            border-radius: 8px;
            padding: 20px;
            background-color: var(--c-light-bg);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            @include respond-to(tablet) {
                min-width: 330px;
                min-height: 240px;
            }

            @include respond-to(mobile) {
                min-width: 252px;
            }

            &-logo {
                width: 67px;
                height: 67px;

                @include respond-to(tablet) {
                    width: 50px;
                    height: 50px;
                }
            }

            &:nth-child(1),
            &:nth-child(7),
            &:nth-child(13) {
                grid-column: 1/3;
            }

            &:nth-child(6),
            &:nth-child(12),
            &:nth-child(18) {
                grid-column: 3/-1;
            }

            &-background {
                position: absolute;
                top: 0;
                right: 0;
            }

            & p {
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.4;

                @include respond-to(tablet) {
                    font-size: 1rem;
                }

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }
    }

    &__banner {
        color: var(--c-white);

        &-wrapper {
            display: flex;
            position: relative;
            padding: 58px 70px;
            border-radius: 8px;
            background-color: var(--c-primary);

            & h3 {
                max-width: 430px;
                margin-inline-end: 140px;
            }

            & a {
                align-self: end;
            }

            & img {
                position: absolute;
                inset-block-start: 65px;
                inset-inline-end: 85px;
            }
        }
    }

    &__contact {
        &:not(:last-child) {
            margin-block-end: 20px;
        }
    }

    &__list-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;

        @include respond-to(tablet) {
            flex-direction: column;
            gap: 20px;
        }

        @include respond-to(mobile) {
            gap: 40px;
        }
    }

    &__list-item {
        flex: 0 0 calc(50% - 30px);
    }

    @include section-indents;
}

.a11y-mode {
    .preferential-zone {
        &__privileges {
            &-header {
                display: block;

                & h2 {
                    max-width: unset;
                }
            }

            &-wrapper {
                grid-template-columns: 1fr;
            }

            &-item {
                width: 100%;
                padding: 0;
            }

            &-counters-wrapper {
                flex-direction: column;

                & .item-with-counter {
                    width: 100%;
                }
            }

            &-item-bid-values {
                flex-direction: column;
            }

            &-item-bid-value {
                width: 100%;
            }
        }

        &__about {
            flex-direction: column;
            gap: 40px;

            &-name {
                max-width: unset;
            }

            &-header {
                max-width: unset;
            }

            &-link {
                position: static;
            }
        }

        &__services {
            &-wrapper {
                grid-template-columns: 1fr;

                @include respond-to(mobile) {
                    flex-direction: column;
                }
            }

            &-item {
                min-height: unset;
                padding: 0;
            }

            &-item-text {
                max-width: unset;
            }
        }

        &__fringe {
            flex-flow: column wrap;

            &-item {
                flex-direction: column-reverse;
                min-width: 100%;
                min-height: unset;
                padding: 0;
            }

            &-id {
                align-self: flex-start;
            }
        }

        &__links {
            &-wrapper {
                display: flex;
                flex-direction: column;

                @include respond-to(tablet) {
                    display: flex;
                }

                &_alt {
                    & .text-with-counter {
                        width: 100%;
                    }
                }
            }
        }

        &__priority {
            &-wrapper {
                display: flex;
                flex-direction: column;
            }

            &-item {
                min-width: 100%;
                min-height: unset;
            }
        }

        &__list {
            &-item {
                flex: 0 0 100%;
            }
        }

        &__highlight {
            padding-inline: 0;

            & .flex-item-equal {
                flex: 0 0 100%;
            }
        }

        &__points {
            &-wrapper {
                padding: 0;
                grid-template-columns: 1fr;
            }
        }

        &__platforms {
            &-wrapper {
                flex-direction: column;
            }

            &-text_alt {
                width: 100%;
            }

            &-item {
                width: 100%;
            }

            &-item_alt {
                min-height: unset;
                width: 100%;
            }
        }
    }
}
