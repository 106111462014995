.btn {
    align-items: center;
    border: 1px solid var(--c-button-basic-color);
    border-radius: 27px;
    color: var(--c-button-basic-color);
    cursor: pointer;
    display: inline-flex;
    font-size: 0.875rem;
    justify-content: center;
    line-height: 1;
    font-weight: 500;
    height: 54px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 18px 28px;
    transition: opacity 0.3s, color 0.3s, background-color 0.3s, border-color 0.3s;
    white-space: nowrap;

    @include respond-to(mobile) {
        font-size: 0.75rem;
        line-height: 1.25;
    }

    &:hover {
        color: var(--c-button-hover-color);
        background-color: var(--c-white);
        border-color: var(--c-white);
    }

    &:active {
        color: var(--c-button-active-text-color);
        background-color: var(--c-button-active-background-color);
        border-color: var(--c-button-active-background-color);
        opacity: var(--c-button-active-opacity);
    }

    &_accent {
        border-color: var(--c-button-accent-color);
        color: var(--c-button-accent-color);
        line-height: 1.2;

        &:hover {
            background-color: var(--c-button-accent-color);
            color: var(--c-white);
            border-color: var(--c-button-accent-color);
        }
    }

    &_primary {
        border-color: var(--c-primary);
        color: var(--c-primary);
        line-height: 1.2;

        &:hover {
            background-color: var(--c-primary);
            border-color: var(--c-primary);
            color: var(--c-white);
        }

        &:active {
            background-color: var(--c-primary);
            border-color: var(--c-primary);
            color: var(--c-white);
            opacity: 0.5;
        }
    }

    &_background_accent {
        background-color: var(--c-button-accent-color);
        border-color: var(--c-button-accent-color);
        color: var(--c-white);

        &:hover {
            background-color: var(--c-button-accent-color);
            border-color: var(--c-button-accent-color);
            color: var(--c-white);
        }

        &:active {
            opacity: var(--c-button-accent-activeOpacity);
        }
    }

    &_wide {
        padding: 18px 60px;
    }

    &_mobile-full {
        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &_text-wrap {
        white-space: normal;
    }
}

.icon-btn {
    $this: &;

    align-items: center;
    background-color: var(--c-white-transparent-light);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 60px;
    padding: 0;
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--c-white-transparent-medium);
    }

    &:active {
        background-color: var(--c-white-transparent-dark);
    }

    &_plain {
        background-color: transparent !important;
    }

    &_dark {
        background-color: var(--c-dark-bg);

        &:hover {
            background-color: var(--c-primary);
        }
    }

    &_arrow {
        background-color: var(--c-arrow-dark-basic-color);

        &:hover {
            background-color: var(--c-arrow-dark-hover-color);
        }

        &:active {
            background-color: var(--c-arrow-dark-active-color);
        }

        // на светлом фоне
        &#{$this}_dark {
            background-color: var(--c-arrow-light-basic-color);

            &:hover {
                background-color: var(--c-arrow-light-hover-color);
            }

            &:active {
                background-color: var(--c-arrow-light-active-color);
            }
        }
    }

    &_disabled {
        background-color: var(--c-dark-bg);
    }

    &_footer {
        width: 42px;
        height: 42px;

        @include respond-to(tablet) {
            width: 50px;
            height: 50px;
        }

        @include respond-to(mobile) {
            width: 37px;
            height: 37px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_config {
        background-color: var(--c-dark-blue);
        height: 20px;
        width: 20px;
        border-radius: 4px;
        padding: 0;

        &:hover {
            background-color: var(--c-accent);
        }
    }

    &_smaller {
        width: 24px;
        height: 24px;

        & svg {
            width: 50%;
        }
    }

    &_reverse_color_light {
        background-color: var(--c-arrow-light-hover-color);

        &:hover {
            background-color: var(--c-arrow-light-basic-color);
        }
    }

    &_reverse_color_dark {
        background-color: var(--c-arrow-dark-hover-color);

        &:hover {
            background-color: var(--c-arrow-dark-basic-color);
        }
    }
}

.icon {
    align-items: center;
    background-color: #bfc7d4;
    border-radius: 50%;
    display: flex;
    height: 67px;
    justify-content: center;
    width: 67px;
}

.arrow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 37px;
    background-color: var(--c-white);
    border-radius: 9px;
    gap: 9px;
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 500;
    color: var(--c-primary);
    transition: background-color 0.3s;

    @include respond-to(mobile) {
        padding: 0;
        width: 40px;
        height: 36px;
    }

    &:hover {
        background: var(--c-light-bg);
    }

    &:active,
    &:visited {
        color: var(--c-primary);
    }

    &_reverse {
        flex-direction: row-reverse;

        & svg {
            transform: rotate(180deg);
        }
    }
}

.a11y-mode {
    .btn {
        color: var(--c-black);
        background: var(--c-white);
        border-color: var(--c-black);
        white-space: normal;
        height: auto;

        @include respond-to(mobile) {
            word-break: break-all;
        }
    }

    .icon-btn_arrow {
        display: none !important;
    }
}
