.footer-carousel {
    $this: &;

    position: relative;

    & .swiper {
        width: 100%;
        height: 100%;
    }

    &__button-prev,
    &__button-next {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 0;
        align-items: center;
        justify-content: center;
        background-color: var(--c-white-transparent-light);
        border-radius: 50%;
        cursor: pointer;
        height: 60px;
        width: 60px;
        padding: 0;
        margin: 0;
        transform: translateY(-50%);
        transition: background-color 0.3s;
        z-index: 2; // перекрыть градиент

        @include respond-to(tablet) {
            display: none !important;
        }
    }

    &__button-next {
        inset-inline: auto 0;
    }

    & .swiper-button-disabled {
        display: none;

        & + #{$this}__gradient-prev {
            display: none;
        }

        & + #{$this}__gradient-next {
            display: none;
        }
    }

    &__gradient-prev {
        position: absolute;
        inset-block: 0;
        inset-inline-start: 0;
        width: 200px;
        height: 165px;
        background: linear-gradient(90deg, var(--c-secondary) 50%, var(--c-true-transparent));
        z-index: 1; // перекрыть слайды

        @include respond-to(tablet) {
            display: none;
        }
    }

    &__gradient-next {
        position: absolute;
        inset-block: 0;
        inset-inline-end: 0;
        width: 200px;
        height: 165px;
        background: linear-gradient(270deg, var(--c-secondary) 50%, var(--c-true-transparent));
        z-index: 1; // перекрыть слайды

        @include respond-to(tablet) {
            display: none;
        }
    }

    & .swiper-slide {
        align-items: center;
        display: flex;
        word-break: keep-all;
        width: auto;
        height: 165px;
        position: relative;

        & img {
            height: 65px;
            margin-inline-end: 20px;
            max-width: 200px;
            object-fit: contain;

            @include respond-to(tablet) {
                max-width: 150px;
            }
        }

        & p {
            display: block;
            text-decoration: none;
            padding-block-start: 10px;
            color: var(--c-light-text);
            font-weight: 500;
        }

        & a {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
        }
    }

    &_light {
        border-block-end: none;

        & .icon-btn {
            background-color: var(--c-dark-bg);
        }

        #{$this}__gradient-prev {
            background: linear-gradient(90deg, var(--c-white) 50%, var(--c-true-transparent));
        }

        #{$this}__gradient-next {
            background: linear-gradient(270deg, var(--c-white) 50%, var(--c-true-transparent));
        }

        & .swiper-slide p {
            color: var(--c-primary);
            font-size: 0.875rem;
            line-height: 1.3;
        }
    }
}

.a11y-mode {
    .footer-carousel {
        height: auto;

        & .swiper {
            flex-direction: column;
        }

        & .swiper-slide {
            height: auto;

            @include respond-to(mobile) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }

            & a {
                inset-block-start: 3px;
                inset-inline-start: 3px;

                @include link-sizes-fix;
            }
        }

        & p {
            color: var(--c-black);
            width: 100%;
        }

        &__button-prev,
        &__button-next,
        &__gradient-prev,
        &__gradient-next {
            display: none !important;
        }
    }
}
