.card-event {
    background-color: var(--c-light-blue);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;

    &__img {
        height: 185px;

        img {
            border-radius: 8px 8px 0 0;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        @include respond-to(mobile) {
            height: 150px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 30px;
        color: var(--c-primary);

        @include respond-to(mobile) {
            padding-inline: 20px;
            padding-block-start: 20px;
            padding-block-end: 30px;
        }
    }

    &__title {
        margin-block-end: 50px;

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__text {
        margin-block-start: auto;
    }

    &__plate {
        background-color: var(--c-white);
        border-radius: 8px;
        color: var(--c-accent);
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.13em;
        line-height: 1;
        padding: 10px;
        position: absolute;
        text-transform: uppercase;
        inset-block-start: 15px;
        inset-inline-start: 15px;

        @include respond-to(mobile) {
            font-size: 0.625rem;
            padding: 6px;
            inset-block-start: 10px;
            inset-inline-start: 10px;
        }
    }

    &_gray {
        background-color: var(--c-light-bg);
    }
}

.a11y-mode {
    .card-event {
        outline: 1px solid var(--c-black);
        outline-offset: -3px;
    }
}
