.detailed-news {
    &__content {
        max-width: 913px;
        margin: 0 auto;
        padding-block-start: 50px;

        @include respond-to(tablet) {
            padding-block-start: 60px;
        }

        @include respond-to(mobile) {
            padding-block-start: 30px;
        }

        &-date {
            margin-block-end: 21px;
            color: var(--c-accent);
            font-size: 0.875rem;
            line-height: 1.2;
            letter-spacing: 0.13em;

            @include respond-to(tablet) {
                margin-block-end: 20px;
            }

            @include respond-to(mobile) {
                font-size: 0.75rem;
                line-height: 1.25;
            }
        }

        &-title {
            margin-block-end: 60px;

            @include respond-to(tablet) {
                margin-block-end: 50px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
            }
        }

        &-carousel {
            margin-block-end: 60px;
            height: 540px;

            @include respond-to(tablet) {
                margin-block-end: 50px;
                height: 370px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
                height: 230px;
            }
        }

        &-text {
            margin-block-end: 60px;

            @include respond-to(mobile) {
                margin-block-end: 40px;
            }

            & p:not(:last-child) {
                margin-block-end: 30px;

                @include respond-to(mobile) {
                    margin-block-end: 20px;
                }
            }

            & ul,
            ol {
                margin-block-start: 20px;
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-start: 10px;
                    margin-block-end: 10px;
                }
            }

            & ul li,
            ol li {
                list-style: none;
                margin: 0;
                padding-block: 0;
                padding-inline: 28px 0;
                position: relative;

                &:not(:last-child) {
                    margin-block-end: 20px;

                    @include respond-to(mobile) {
                        margin-block-end: 10px;
                    }
                }
            }

            & ul li {
                &::before {
                    content: "";
                    background-color: var(--c-alt-base-color);
                    border-radius: 50%;
                    display: block;
                    height: 8px;
                    inset-inline-start: 0;
                    position: absolute;
                    inset-block-start: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                }
            }

            & ol {
                counter-reset: myCounter;

                li {
                    &::before {
                        counter-increment: myCounter;
                        content: counter(myCounter);
                        position: absolute;
                        inset-inline-start: 0;
                        inset-block-start: 50%;
                        transform: translateY(-50%);
                        color: var(--c-alt-base-color);
                        font-weight: 500;
                    }
                }
            }
        }

        & .btn {
            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    @include section-indents;

    & .section-wrapper:first-child {
        @include respond-to(tablet) {
            margin-top: 20px;
        }

        @include respond-to(mobile) {
            margin-top: 10px;
        }
    }
}
