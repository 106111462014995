.contacts-with-map {
    $this: &;

    background-color: var(--c-light-bg);
    border-radius: 8px;
    display: flex;
    padding: 60px;

    @include respond-to(tablet) {
        flex-direction: column;
        padding: 50px;
    }

    @include respond-to(mobile) {
        padding-inline: 20px;
        padding-block-start: 30px;
        padding-block-end: 20px;
    }

    &__header {
        font-weight: 600;
        font-size: 2.5em;
        line-height: 1.2;

        @include respond-to(mobile) {
            font-size: 1.375em;
        }
    }

    &__left {
        width: 50%;

        h3 {
            margin-block-end: 30px;
        }

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__title {
        color: var(--c-deep-gray);
        font-size: 0.75em;
        font-weight: 500;
        letter-spacing: 0.13em;
        margin-block-end: 20px;
        text-transform: uppercase;
    }

    &__address {
        display: flex;
        margin-block-end: 60px;

        svg {
            margin-inline-end: 20px;

            @include respond-to(mobile) {
                min-width: 30px;
                width: 30px;
                height: 30px;
                margin-inline-end: 15px;
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            height: 80px;
            justify-content: space-between;
            padding-block-start: 8px;

            @include respond-to(mobile) {
                height: unset;
            }
        }
    }

    &__hours {
        display: flex;

        @include respond-to(mobile) {
            flex-direction: column;
            margin-block-start: 20px;
        }

        #{$this}__title {
            font-size: 1em;
            margin-block-end: 0;
            margin-inline-end: 30px;
            text-transform: unset;

            @include respond-to(tablet) {
                font-size: 0.875em;
            }

            @include respond-to(mobile) {
                margin-inline-end: unset;

                &:not(:last-child) {
                    margin-block-end: 5px;
                }
            }
        }
    }

    &__contacts {
        display: flex;

        @include respond-to(mobile) {
            flex-direction: column;

            > :not(:last-child) {
                margin-block-end: 45px;
            }
        }

        > div {
            flex: 1;

            h4 {
                margin-block-end: 16px;
            }

            a {
                color: var(--c-primary);
                display: block;
                margin-block-end: 10px;

                &:last-child {
                    margin-block-end: 0;
                }
            }
        }
    }

    &__right {
        margin-inline-start: 60px;
        width: 50%;

        @include respond-to(tablet) {
            width: 100%;
            margin-inline-start: unset;
            margin-block-start: 50px;
            min-height: 393px;
        }

        @include respond-to(mobile) {
            min-height: 612px;
        }
    }

    &_dark {
        background-color: var(--c-primary);
        color: var(--c-text-on-primary-bg);
    }
}

.a11y-mode {
    .contacts-with-map {
        &__left {
            width: 100%;
        }

        &__right {
            display: none;
        }

        &__contacts {
            flex-direction: column;
        }

        &__address {
            & svg {
                display: none;
            }

            & > div {
                height: auto;
                gap: 20px;
            }
        }

        &__hours {
            & .contacts-with-map__title {
                color: var(--c-a11y-accent-color);
            }
        }
    }
}
