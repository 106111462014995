.boiling-point-event {
    &__header {
        display: flex;
        gap: 36px;

        > div {
            width: 50%;

            p {
                font-size: 1.125rem;
                line-height: 1.65;
                margin-block-end: 30px;
            }
        }
    }

    &__header-date {
        color: var(--c-accent);
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.13em;
        margin-block-end: 20px;
        text-transform: uppercase;
    }

    &__header-image {
        width: 100%;
    }

    &__table {
        border-collapse: collapse;
        border-spacing: 0;

        tr {
            border-block-end: 1px solid var(--c-light-bg);

            td {
                font-size: 1.25rem;
                font-weight: 500;
                height: 184px;
                line-height: 1.4;
                padding: 50px 0;

                &:first-child {
                    color: var(--c-accent);
                    font-size: 1.5rem;
                    width: 24%;
                }

                &:nth-child(2) {
                    color: var(--c-deep-gray);
                }

                &:last-child {
                    padding-block: 0;
                    padding-inline: 18px 40px;
                    width: 50%;
                }
            }
        }
    }

    &__speakers {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        > div {
            width: calc((100% - 60px) / 4);
        }
    }

    &__partners {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;

        > div {
            display: flex;
            width: calc((100% - 50px) / 3);

            img {
                border-radius: 8px;
                width: 50%;
            }
        }
    }

    &__documents {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .card-document {
            width: calc((100% - 40px) / 3);
        }
    }
}
