@mixin list-with-small-bullets {
    & ul {
        & li {
            padding-inline-start: 36px;
            position: relative;

            &::before {
                content: "";
                width: 4px;
                height: 4px;
                position: absolute;
                inset-inline-start: 17px;
                inset-block-start: 15px;
                background-color: var(--c-white);
                border-radius: 50%;

                @include respond-to(tablet) {
                    inset-block-start: 10px;
                }

                @include respond-to(mobile) {
                    inset-block-start: 8px;
                }
            }
        }
    }
}
