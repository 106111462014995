.documents-tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include respond-to(tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to(mobile) {
        grid-template-columns: 1fr;
    }
}

.a11y-mode {
    .documents-tiles {
        grid-template-columns: 100%;
    }
}
