.a11y-header {
    display: none;
    width: 100%;
    max-height: 25vh;
    overflow-y: auto;
    border-block-end: 2px solid var(--c-black);

    a {
        color: var(--c-color);
    }

    &__wrapper {
        padding: 20px 40px;
        max-width: var(--content-size-max);
        margin: 0 auto;
    }

    &__main-level-menu {
        margin-block-end: 1rem;
        font-size: 1.25em;

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }

    &__main-level-menu--active {
        border-block-end: 2px solid var(--c-black);
    }

    &__sub-level-menu {
        margin-block-end: 1rem;

        ul {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }

    &__sub-level-menu--active {
        border-block-end: 2px solid var(--c-black);
    }

    &__additional-menu {
        margin-block-end: 1rem;
        display: flex;
        gap: 1rem;
    }

    &__lang {
        display: flex;
        gap: 1rem;

        ul {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }
}

.a11y-mode {
    .a11y-header {
        display: block;
    }
}
