.dp__menu {
    position: absolute;
    background: var(--c-light-blue);
    border-radius: $dp__border_radius;
    font-family: $dp__font_family;
    padding: 20px 10px 30px;
    user-select: none;
    margin: 0 auto;

    @include respond-to(tablet) {
        padding: 20px 40px 40px;
    }

    @include respond-to(mobile) {
        padding: 20px 20px 25px;
    }

    &:focus {
        outline: none;
    }
}

.dp__menu_index {
    z-index: 99999;
}
