.alt-tile {
    $this: &;

    display: flex;
    width: 100%;
    height: 100%;
    color: var(--c-white);
    position: relative;
    background-color: var(--c-light-bg);
    transition: background-color 0.3s;

    @include respond-to(tablet) {
        border-radius: 8px;
        overflow: hidden;
    }

    &:hover {
        background-color: var(--c-alt-base-color);

        & .btn {
            border-color: var(--c-white);
            color: var(--c-white);

            &:hover {
                color: var(--c-alt-base-color);
            }
        }
    }

    .tiles-with-image_separated & {
        border-radius: 8px;
        overflow: hidden;

        &:hover {
            background-color: var(--c-true-transparent);
        }
    }

    &.with-button {
        #{$this}__text-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            #{$this}__title {
                min-height: unset;
            }
        }

        @include respond-to(tablet) {
            display: none;
        }

        .tiles-with-image_separated & {
            display: none;
        }
    }

    &__image-container {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        display: none;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, var(--c-half-shadow), var(--c-half-shadow));
        }

        @include respond-to(tablet) {
            display: block;
        }

        .tiles-with-image_separated & {
            display: block;

            &::after {
                background: linear-gradient(0deg, var(--c-shadow-table-dark), var(--c-shadow-table-dark));
            }
        }
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__view {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        justify-content: space-between;
        padding-inline: 40px 30px;
        padding-block: 25px 40px;
        gap: 35px;

        @include respond-to(desktop) {
            padding-inline: 20px;
            padding-block: 20px;
        }

        @include respond-to(tablet) {
            z-index: 1; // перекрыть фоновую картинку
            gap: 16px;
        }

        .tiles-with-image_separated & {
            z-index: 1; // перекрыть фоновую картинку
            padding: 20px;
        }
    }

    &__icon {
        height: 67px;
        width: 67px;

        @include respond-to(desktop) {
            height: 50px;
            width: 50px;
        }
    }

    &__logo {
        @include respond-to(desktop) {
            max-height: 50px;
        }
    }

    &__text-content {
        flex: 1;

        @include respond-to(tablet) {
            flex: 0;
        }

        .tiles-with-image_separated & {
            flex: 0;
        }
    }

    &__title {
        font-size: 1.25em;
        min-height: 56px;

        @include respond-to(desktop) {
            font-size: 1em;
            min-height: 45px;
        }

        @include respond-to(tablet) {
            font-size: 1em;
            min-height: unset;
        }

        &:not(:last-child) {
            margin-block-end: 15px;

            @include respond-to(desktop) {
                margin-block-end: 10px;
            }
        }
    }

    &__subtitle {
        font-weight: 400;

        @include respond-to(desktop) {
            font-size: 0.875em;
        }
    }

    &__button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        & .btn {
            @include respond-to(desktop) {
                font-size: 0.75rem;
                line-height: 1.25;
            }
        }
    }

    &__link {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.a11y-mode {
    .alt-tile {
        color: var(--c-black) !important;

        &:hover {
            background-color: var(--c-white);

            & .btn:hover {
                border-color: var(--c-a11y-accent-color);
            }
        }

        &__icon,
        &__image {
            display: none;
        }

        &__image-container {
            &::after {
                content: none;
            }
        }

        &__link {
            inset-block-start: 3px;
            inset-inline-start: 3px;

            @include link-sizes-fix;
        }

        &__view {
            padding-inline: 0;
            padding-block: 0;
        }
    }
}
