.docs-dropdown {
    $this: &;

    &__docs {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-block: 50px;

        @include respond-to(tablet) {
            padding: 30px 40px;
        }

        @include respond-to(mobile) {
            padding: 20px;
        }
    }

    &__doc {
        align-items: end;
        display: flex;
        gap: 20px;

        div {
            align-items: end;
            display: flex;
            gap: 20px;
            flex: 1;
        }

        h4 {
            border-bottom: 2px solid;
            flex: 1;
            padding-bottom: 8px;
        }

        @include respond-to(mobile) {
            align-items: stretch;
            flex-direction: column;

            div {
                align-items: center;
                gap: 20px 10px;
            }

            h4 {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}
