.regional-legislation {
    &__title {
        margin-block-end: 65px;

        @include respond-to(tablet) {
            margin-block-end: 50px;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }
    }

    &__documents-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;

        @include respond-to(desktop) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond-to(mobile) {
            grid-template-columns: 1fr;
        }

        &_d-block {
            display: block;
        }
    }

    &__text {
        &:not(:last-child) {
            margin-block-end: 65px;

            @include respond-to(tablet) {
                margin-block-end: 50px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
            }
        }

        & p:not(:last-child) {
            margin-block-end: 20px;

            @include respond-to(mobile) {
                margin-block-end: 10px;
            }
        }
    }

    & .document-download_m {
        @include respond-to(mobile) {
            min-height: unset;
        }
    }

    & + .footer {
        & .footer__bottom-social {
            @include respond-to(mobile) {
                inset-block-start: 180px;
            }
        }
    }

    & .container {
        overflow: visible;
    }

    @include section-indents;
}

.a11y-mode {
    .regional-legislation {
        &__documents-wrapper {
            grid-template-columns: 1fr;
        }
    }
}
