.projects-registry-filters {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);

    @include respond-to(tablet) {
        flex-direction: column;
        gap: 30px;
    }

    & > li {
        width: calc((100% - 40px) / 3);

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__filter {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 20px;
        margin-block-end: 13px;
        flex: 1;

        @include respond-to(desktop) {
            flex-direction: column;
            margin-block-end: 0;
            gap: 10px;
            justify-content: flex-end;
        }

        @include respond-to(tablet) {
            margin-block-start: 10px;
            flex-direction: row;
            gap: 20px;
        }

        &:nth-of-type(3n) {
            justify-content: center;

            @include respond-to(desktop) {
                justify-content: flex-end;
            }
        }

        & .btn {
            width: calc(100% - 68px);

            @include respond-to(desktop) {
                width: 100%;
            }

            @include respond-to(tablet) {
                width: fit-content;
            }
        }
    }
}

.a11y-mode {
    .projects-registry-filters {
        flex-direction: column;

        &__filter {
            width: 100%;
        }

        &__buttons {
            justify-content: flex-start;
        }
    }
}
