.dp__month_year_row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    max-width: 367px;
    margin: 0 auto 20px;

    @include respond-to(tablet) {
        max-width: 532px;
        margin-block-end: 17px;
    }

    @include respond-to(mobile) {
        margin-block-end: 27px;
    }
}

.dp__month_year_col_nav {
    &:first-of-type {
        margin-inline-end: auto;
    }

    &:last-of-type {
        margin-inline-start: auto;
    }
}

.dp__inner_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: $dp__month_year_row_button_size;
    width: $dp__month_year_row_button_size;

    & svg {
        height: $dp__button_icon_height;
        width: $dp__button_icon_width;
    }
}

.dp__month_year_select {
    color: var(--c-accent);
    font-size: 1rem;
    line-height: 1.4;
    padding: 0 4px;
    pointer-events: none;
    font-weight: 500;
    text-transform: capitalize;

    @include respond-to(mobile) {
        font-size: 0.875rem;
    }
}
