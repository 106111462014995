.investors-feedback {
    & .btn {
        margin-block-start: 60px;

        @include respond-to(tablet) {
            margin-block-start: 40px;
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    @include section-indents;
}
