.list-stats-item {
    &__title {
        font-style: italic;
        font-size: 1.5rem;
        line-height: 1.4;
        margin-block-end: 40px;
        color: var(--c-alt-base-color);
        font-weight: 600;

        @include respond-to(mobile) {
            font-size: 0.875rem;
            margin-block-end: 20px;
        }
    }

    &__value {
        padding-inline-start: 42px;
        font-size: 1.5rem;
        line-height: 1.4;
        font-weight: 500;
        margin-block-end: 20px;
        position: relative;

        &::before {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0.5em;
            background-color: var(--c-alt-base-color);
            border-radius: 50%;

            @include respond-to(mobile) {
                width: 7px;
                height: 7px;
            }
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            margin-block-end: 10px;
            padding-inline-start: 17px;
        }
    }

    &__subtitle {
        padding-inline-start: 42px;
        font-size: 1.125rem;
        line-height: 1.4;

        @include respond-to(mobile) {
            font-size: 0.875rem;
            padding-inline-start: 17px;
        }
    }
}
