.headline-preview {
    $this: &;

    display: flex;
    gap: 20px;

    @include respond-to(tablet) {
        flex-direction: column;
    }

    &__image-container {
        display: flex;
        border-radius: 8px;
        width: 50%;
        overflow: hidden;
        position: relative;

        @include respond-to(desktop) {
            height: unset;
        }

        @include respond-to(tablet) {
            width: 100%;
            height: 300px;
            min-height: unset;
        }

        @include respond-to(mobile) {
            height: 216px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__label {
        position: absolute;
        inset-block-end: 30px;
        inset-inline-start: 30px;
        color: var(--c-white);

        @include respond-to(mobile) {
            inset-block-end: 20px;
            inset-inline-start: 20px;
        }
    }

    &__label-title {
        font-size: 40px;
        line-height: 1.23;
        margin-block-end: 10px;

        @include respond-to(tablet) {
            font-size: 30px;
            line-height: 1.2;
        }

        @include respond-to(mobile) {
            font-size: 16px;
            line-height: 1.4;
            margin-block-end: 5px;
        }
    }

    &__label-text {
        font-size: 20px;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 16px;
        }

        @include respond-to(mobile) {
            font-size: 14px;
        }
    }

    &__text-container {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        padding: 50px;
        width: 50%;

        & p:not(:last-child) {
            margin-block-end: 20px;
        }

        @include respond-to(desktop) {
            min-height: 428px;
            height: unset;
            padding: 40px;
        }

        @include respond-to(tablet) {
            width: 100%;
            min-height: unset;
            height: max-content;
        }

        @include respond-to(mobile) {
            padding: 30px;
        }
    }

    &__title {
        margin-block-end: 30px;

        @include respond-to(desktop) {
            font-size: 1.25rem;
            line-height: 1.4;
            margin-block-end: 20px;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__description {
        line-height: 1.7;

        @include respond-to(desktop) {
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &_alt {
        flex-direction: column-reverse;
        gap: 80px;

        @include respond-to(tablet) {
            gap: 60px;
        }

        @include respond-to(mobile) {
            gap: 30px;
        }

        #{$this}__image-container {
            border-radius: 0;
            height: 320px;
            gap: 20px;
            width: 100%;

            @include respond-to(desktop) {
                min-height: unset;
                height: 320px;
            }

            @include respond-to(tablet) {
                height: 300px;
            }

            @include respond-to(mobile) {
                height: 140px;
            }

            & img {
                width: calc((100% - 40px) / 3);

                @include respond-to(tablet) {
                    width: calc((100% - 20px) / 2);

                    &:nth-child(n + 3) {
                        display: none;
                    }
                }

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        #{$this}__text-container {
            background-color: transparent;
            border-radius: 0;
            height: auto;
            padding: 0;
            width: 100%;

            @include respond-to(desktop) {
                min-height: unset;
                padding: 0;
            }
        }

        #{$this}__title {
            font-size: 3.75rem;
            font-weight: 600;
            line-height: 1.1;
            width: 100%;

            @include respond-to(desktop) {
                font-size: 3.75rem;
                line-height: 1.1;
                margin-block-end: 30px;
            }

            @include respond-to(tablet) {
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 1.4;
                margin-block-end: 20px;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        #{$this}__description {
            @include respond-to(desktop) {
                line-height: 1.7;
            }

            @include respond-to(tablet) {
                line-height: 1.4;
            }
        }

        #{$this}__label {
            display: none;
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .headline-preview {
        &__image-container {
            display: none;
        }

        &__text-container {
            width: 100%;
            min-height: unset;
            padding: 0;

            & p {
                margin-block-start: 20px;
            }
        }
    }
}
