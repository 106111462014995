.asi-experts {
    &__text {
        & p {
            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }

        & img {
            display: inline-block;
            border-radius: 8px;
            margin-block-end: 20px;
            width: 100%;
        }
    }

    &__images {
        display: flex;
        flex-wrap: wrap;

        & img:only-child {
            width: 100%;
            margin-inline: auto;
        }
    }

    // alt
    &__title {
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 1.2;
        margin-block-end: 40px;

        @include respond-to(tablet) {
            font-weight: 600;
            font-size: 1.375rem;
        }

        @include respond-to(mobile) {
            margin-block-end: 32px;
        }
    }

    &__links {
        &:not(:last-child) {
            margin-block-end: 30px;

            @include respond-to(tablet) {
                margin-block-end: 20px;
            }
        }

        & a {
            display: block;

            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 30px;
                }
            }
        }
    }

    &__text-content {
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.7;

        @include respond-to(tablet) {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.4;
        }

        &:not(:last-child) {
            margin-block-end: 30px;

            @include respond-to(tablet) {
                margin-block-end: 20px;
            }
        }
    }

    &__image {
        margin-block-start: 50px;
        width: 100%;
        object-fit: cover;

        @include respond-to(tablet) {
            margin-block-start: 100px;
        }

        @include respond-to(mobile) {
            margin-block-start: 80px;
        }

        &:not(:last-child) {
            margin-block-end: 50px;

            @include respond-to(tablet) {
                margin-block-end: 40px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
            }
        }
    }

    &__image-in-picture {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    & ul,
    ol,
    p {
        &:not(:last-child) {
            margin-block-end: 20px;
        }
    }

    & ul li,
    ol li {
        list-style: none;
        margin: 0;
        padding-block: 0;
        padding-inline: 16px 0;
        position: relative;
    }

    & ul li {
        &::before {
            content: "·";
            display: block;
            inset-inline-start: 0;
            position: absolute;
        }
    }

    & ol {
        counter-reset: myCounter;

        li {
            &::before {
                counter-increment: myCounter;
                content: counter(myCounter);
                position: absolute;
                inset-inline-start: 0;
                inset-block-start: 0;
                color: var(--c-primary);
                font-weight: 500;
            }
        }
    }

    @include section-indents;
}
