.media {
    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 65px;

        @include respond-to(tablet) {
            margin-block-end: 57px;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }
    }

    &__news {
        & .btn {
            @include respond-to(mobile) {
                margin-block-start: 40px;
                width: 100%;
                text-align: center;
            }
        }
    }

    &__links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        @include respond-to(mobile) {
            margin-inline-end: -16px;
            overflow-x: auto;
            flex-wrap: nowrap;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &_alt {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @include respond-to(tablet) {
                grid-template-columns: 1fr;
            }

            @include respond-to(mobile) {
                margin-inline-end: 0;
            }
        }
    }

    &__link {
        width: calc((100% - 60px) / 4);

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            min-width: 252px;
        }

        &:last-child {
            @include respond-to(mobile) {
                margin-inline-end: 16px;
            }
        }

        &_alt {
            width: 100%;
            min-height: 110px;
            height: 100%;

            @include respond-to(mobile) {
                min-width: unset;
                min-height: 169px;
            }

            &:last-child {
                @include respond-to(mobile) {
                    margin-inline-end: 0;
                }
            }
        }
    }

    // alt version
    &__news-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
        }

        @include respond-to(mobile) {
            grid-auto-rows: unset;
        }
    }

    &__news-item {
        min-height: 226px;
        height: 100%;
        width: 100%;
    }

    &__info {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 20px;
        min-height: 283px;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        & > div {
            width: calc((100% - 20px) / 2);

            @include respond-to(tablet) {
                width: 100%;
            }
        }
    }

    &__contacts {
        .input__field {
            &:hover:not(:disabled) {
                background-color: var(--c-true-transparent);
            }

            &:focus,
            :focus-visible {
                background-color: var(--c-true-transparent);
            }
        }
    }

    @include section-indents;
}

.a11y-mode {
    .media {
        &__links {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        &__link {
            width: 100%;

            @include link-sizes-fix;
        }

        &__info {
            flex-direction: column;

            & > div {
                width: 100%;
            }
        }

        &__news-wrapper {
            display: block;
        }
    }
}
