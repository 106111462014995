.gallery {
    $this: &;

    &__view {
        display: flex;
        margin-block-end: 45px;

        @include respond-to(tablet) {
            flex-direction: column-reverse;
            margin-block-end: 20px;
        }

        &:last-child {
            margin-block-end: 0;
        }
    }

    &__photo {
        &-block {
            display: flex;
            position: relative;
            width: 65%;

            @include respond-to(tablet) {
                width: 100%;
            }

            & img {
                border-radius: 8px;
                width: 100%;
                object-fit: cover;
                user-select: none;

                @include respond-to(mobile) {
                    border-radius: unset;
                }
            }
        }

        &-text {
            inset-block-end: 30px;
            font-weight: 500;
            inset-inline-start: 30px;
            position: absolute;
            display: flex;
            gap: 26px;
            padding-inline: 20px;
            padding-block: 14px;
            align-items: center;
            background-color: var(--c-white);
            color: var(--c-primary);
            border-radius: 8px;
            width: fit-content;

            @include respond-to(tablet) {
                inset-inline-start: 12px;
                inset-block-end: 12px;
                padding-inline: 18px;
                padding-block: 12px;
                gap: 22px;
            }

            @include respond-to(mobile) {
                inset-inline-start: 10px;
                inset-block-end: 10px;
                padding-inline: 10px;
                padding-block: 8px;
                gap: 10px;
            }

            & img {
                height: 72px;
                width: 38px;

                @include respond-to(tablet) {
                    height: 60px;
                    width: 32px;
                }

                @include respond-to(mobile) {
                    height: 40px;
                    width: 22px;
                }
            }
        }

        &-title {
            font-size: 1.25em;
            line-height: 1.7;
            font-weight: 700;
            margin-block-end: 10px;

            @include respond-to(tablet) {
                font-size: 1em;
                line-height: 1.4;
                margin-block-end: 0;
            }

            @include respond-to(mobile) {
                font-weight: 400;
                font-size: 10px;
            }
        }

        &-subtitle {
            font-size: 16px;
            line-height: 1.6;

            @include respond-to(tablet) {
                font-weight: 500;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-weight: 400;
                font-size: 10px;
            }
        }

        &-description-block {
            position: absolute;
            inset-block-end: 30px;
            inset-inline-start: 30px;
            display: flex;
            gap: 26px;
            align-items: center;
            color: var(--c-primary);
            border-radius: 8px;
            width: fit-content;
            padding: 30px 40px;
            background-color: var(--c-light-blue);
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 500;

            &:empty {
                display: none;
            }

            @include respond-to(tablet) {
                inset-inline: 12px;
                inset-block-end: 12px;
                padding: 12px 18px;
                gap: 22px;
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                inset-inline: 10px;
                inset-block-end: 10px;
                padding: 8px 10px;
                gap: 10px;
                font-size: 0.875rem;
            }
        }
    }

    &__arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 0 16px;

        &-next {
            transform: rotate(180deg);
        }

        .icon-btn {
            width: 40px;
            height: 40px;
            padding: 10px;
        }
    }

    &__text {
        &-block {
            align-items: start;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding-inline-start: 30px;
            width: 35%;

            @include respond-to(tablet) {
                margin-block-end: 100px;
                padding-inline-start: 0;
                width: 100%;
            }

            @include respond-to(mobile) {
                align-items: stretch;
                margin-block-end: 60px;
            }

            & .btn {
                white-space: normal;
                height: fit-content;
            }
        }

        &-header {
            display: flex;
            font-size: 1.875rem;
            font-weight: 600;
            line-height: 1.2;
            margin-block-end: 30px;
            justify-content: space-between;
            width: 100%;

            img {
                height: 62px;
            }

            @include respond-to(tablet) {
                img {
                    display: none;
                }
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
            }
        }

        &-main {
            margin-block-end: 50px;

            & p:not(:last-child) {
                margin-block-end: 32px;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
                margin-block-end: 30px;
            }
        }
    }

    &__preview {
        display: flex;
        gap: 20px;
        height: 108px;
        overflow: auto;

        div,
        li {
            background-color: #fff;
            cursor: pointer;
            display: flex;
            flex: none;
            min-width: 170px;
            width: calc((100% - 100px) / 6);

            img {
                border-radius: 5px;
                opacity: 0.5;
                object-fit: cover;
                width: 100%;
                user-select: none;

                &.active {
                    border: 2px solid var(--c-accent);
                    opacity: 1;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }

        &::-webkit-scrollbar {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-dark-bg);
            border: 2px solid transparent;
            border-radius: 8px;
            background-clip: content-box;
        }

        &_glide {
            gap: 0;
            scrollbar-width: none; // firefox

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &_no-text-block {
        #{$this}__photo-block {
            width: 100%;
        }

        #{$this}__photo-text {
            padding: 0;
            background-color: var(--c-light-blue);
        }
    }

    &_municipality-detail {
        #{$this}__photo-text {
            @include respond-to(tablet) {
                display: none;
            }
        }

        #{$this}__preview {
            @include respond-to(mobile) {
                height: auto;
            }

            &::-webkit-scrollbar {
                @include respond-to(tablet) {
                    display: none;
                }
            }

            & div {
                @include respond-to(mobile) {
                    min-width: 138px;
                    max-height: 70px;
                }
            }
        }
    }

    &_alt {
        #{$this}__view {
            flex-direction: row-reverse;
            gap: 59px;

            @include respond-to(tablet) {
                flex-direction: column-reverse;
                gap: 100px;
            }

            @include respond-to(mobile) {
                gap: 60px;
            }
        }

        #{$this}__photo-block {
            width: 44.2%;

            @include respond-to(tablet) {
                width: 100%;
            }
        }

        #{$this}__text-block {
            padding-inline-start: 0;
            width: calc(55.8% - 59px);

            @include respond-to(tablet) {
                margin-block-end: 0;
                width: 100%;
            }
        }

        #{$this}__text-header {
            font-weight: 600;
            font-size: 2.5rem;
            line-height: 1.2;
            margin-block-end: 40px;

            @include respond-to(tablet) {
                font-size: 1.875rem;
                margin-block-end: 20px;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                line-height: 1.2;
            }
        }
    }

    &_perm-krai {
        @include respond-to(tablet) {
            & .glide__bullets {
                display: none;
            }
        }

        #{$this}__main-photo {
            @include respond-to(mobile) {
                border-radius: 8px;
            }
        }

        #{$this}__view {
            margin-block-end: 20px;
        }

        #{$this}__preview {
            @include respond-to(mobile) {
                overflow: visible;
            }
        }

        #{$this}__photo-block {
            height: 637px;

            @include respond-to(tablet) {
                height: 400px;
            }

            @include respond-to(mobile) {
                height: 230px;
            }
        }
    }

    .glide__bullets {
        margin-block-start: 0;
        display: block;
    }
}

.a11y-mode {
    .gallery {
        &__view {
            flex-direction: column;
        }

        &__text-block {
            padding-inline-start: 0;
        }
    }
}
