.spp {
    $this: &;

    &__section-title {
        display: block;
        margin-block-end: 65px;

        @include respond-to(tablet) {
            margin-block-end: 50px;
            font-size: 2.5rem;
            line-height: 1;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
            font-size: 1.375rem;
            line-height: 1.2;
        }
    }

    &__common {
        display: flex;
        gap: 20px;

        @include respond-to(tablet) {
            flex-direction: column;
        }
    }

    &__common-left {
        display: flex;
        border-radius: 8px;
        width: 50%;

        img {
            border-radius: 8px;
            width: 100%;
            object-fit: cover;
        }

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__common-right {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        padding: 50px;
        width: 50%;

        &:only-child {
            width: 100%;
        }

        @include respond-to(tablet) {
            width: 100%;
            padding: 40px;
        }

        @include respond-to(mobile) {
            padding: 30px;
        }
    }

    &__common-header {
        margin-block-end: 30px;
        width: 70%;

        @include respond-to(tablet) {
            margin-block-end: 20px;
            width: 100%;
        }
    }

    &__common-text {
        line-height: 1.7em;
    }

    &__table {
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-dark-bg);
            border: 2px solid transparent;
            border-radius: 8px;
            background-clip: content-box;
        }
    }

    &__first-tile {
        background-color: var(--c-light-bg);
        border-radius: 8px;
        display: flex;
        margin-block-end: 20px;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        &-left {
            align-items: center;
            display: flex;
            padding: 55px;
            width: 50%;

            @include respond-to(tablet) {
                width: 100%;
                padding-block: 34px 20px;
                padding-inline: 34px 108px;
            }

            @include respond-to(mobile) {
                padding-block: 20px;
                padding-inline: 20px 39px;
            }

            img {
                margin-inline-end: 40px;

                @include respond-to(tablet) {
                    width: 37px;
                    height: 69px;
                    margin-inline-end: 30px;
                }

                @include respond-to(mobile) {
                    width: 32px;
                    height: 60px;
                    margin-inline-end: 20px;
                }
            }

            & h5 {
                @include respond-to(mobile) {
                    font-size: 0.875rem;
                    line-height: 1.4;
                }
            }
        }

        &-right {
            align-items: center;
            display: flex;
            padding-block: 55px;
            padding-inline: 55px 85px;
            width: 50%;

            @include respond-to(tablet) {
                width: 100%;
                padding-block: 0 45px;
                padding-inline: 100px 35px;
            }

            @include respond-to(mobile) {
                padding-block: 0 20px;
                padding-inline: 20px 28px;
            }
        }

        &_column {
            flex-direction: column;
            justify-content: space-between;

            @include respond-to(tablet) {
                flex-direction: row;
                justify-content: flex-start;
            }

            #{$this}__first-tile-left {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }

            #{$this}__first-tile-right {
                width: 100%;
            }
        }
    }

    &__tiles {
        counter-reset: item-number;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(tablet) {
            flex-wrap: nowrap;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .tile {
            counter-increment: item-number;

            @include respond-to(tablet) {
                max-height: 273px;
                min-width: 330px;
            }

            @include respond-to(mobile) {
                max-height: 240px;
                min-width: 252px;
            }

            &::before {
                align-items: center;
                background-color: var(--c-dark-bg);
                border-radius: 50%;
                inset-block-end: 20px;
                color: var(--c-white);
                content: counter(item-number);
                display: flex;
                font-size: 1.875rem;
                font-weight: 500;
                height: 66px;
                justify-content: center;
                position: absolute;
                inset-inline-end: 20px;
                width: 66px;

                @include respond-to(desktop) {
                    width: 60px;
                    height: 60px;
                }

                @include respond-to(mobile) {
                    width: 50px;
                    height: 50px;
                    font-size: 1.375rem;
                    line-height: 1.5;
                    inset-inline-end: auto;
                    inset-inline-start: 20px;
                    inset-block-end: auto;
                    inset-block-start: 20px;
                }
            }

            &__view {
                flex-direction: column;

                @include respond-to(desktop) {
                    padding-block-end: 80px;
                }

                @include respond-to(mobile) {
                    justify-content: flex-start;
                    padding: 20px;
                    padding-block-start: 90px;
                }
            }

            &_half {
                .tile__text-content {
                    width: 65%;

                    @include respond-to(tablet) {
                        width: 100%;
                    }
                }
            }

            &_quarter {
                @include respond-to(desktop) {
                    &:last-child {
                        flex-grow: 1;
                    }
                }
            }
        }

        &_grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
            gap: 20px;

            @include respond-to(tablet) {
                display: none;
            }

            & .spp__first-tile {
                grid-area: 1 / 1 / 3 / 3;
                margin-block-end: 0;
            }
        }

        &_tablet-block {
            display: none;

            @include respond-to(tablet) {
                display: block;
            }
        }
    }

    &__rating {
        display: flex;
        min-height: 750px;
        position: relative;

        @include respond-to(tablet) {
            min-height: 1089px;
        }

        @include respond-to(mobile) {
            min-height: 668px;
        }

        & img {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 100%;
        }

        &_alt {
            min-height: unset;

            & img {
                width: 30%;
                inset-inline-end: 0;

                @include respond-to(tablet) {
                    width: 70%;
                }

                @include respond-to(mobile) {
                    width: 90%;
                }
            }
        }
    }

    &__rating-content {
        color: var(--c-white);
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-block: 100px;
        width: 50%;

        @include respond-to(tablet) {
            width: 100%;
        }

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        & h5 {
            @include respond-to(tablet) {
                font-size: 1.875rem;
                line-height: 1.2;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
                line-height: 1.4;
            }
        }

        & p {
            width: 70%;

            @include respond-to(tablet) {
                width: 60%;
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &_alt {
            width: 100%;
            flex-direction: row;

            @include respond-to(tablet) {
                flex-direction: column;
                gap: 136px;
            }

            @include respond-to(mobile) {
                gap: 100px;
            }
        }
    }

    &__rating-number {
        font-size: 8rem;
        line-height: 1;
        font-weight: 700;
        margin-block-start: auto;

        @include respond-to(tablet) {
            font-size: 6.25rem;
        }

        @include respond-to(mobile) {
            font-size: 1.875rem;
            margin-block-end: 5px;
        }

        &_alt {
            font-size: 3.75rem;

            @include respond-to(tablet) {
                font-size: 6.25rem;
            }

            @include respond-to(mobile) {
                font-size: 1.875rem;
            }
        }
    }

    &__rating-unit {
        font-size: 2.5rem;
        line-height: 1.2;

        @include respond-to(tablet) {
            font-size: 1.875rem;
        }

        @include respond-to(tablet) {
            font-size: 1rem;
        }
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 175px;
        margin-block-end: 65px;

        @include respond-to(tablet) {
            gap: 60px;
            margin-block-end: 50px;
        }

        @include respond-to(mobile) {
            gap: 0;
            margin-block-end: 40px;
        }

        #{$this}__section-title {
            font-size: 3.75rem;
            line-height: 1.1;
            margin-block-end: 0;

            @include respond-to(desktop) {
                font-size: 2.5rem;
                line-height: 1;
                margin-block-end: 0;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                line-height: 1.2;
                margin-block-end: 0;
            }
        }

        & .btn {
            @include respond-to(mobile) {
                display: none;
            }
        }
    }

    &__laws {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .card-with-text-and-btn {
            width: calc((100% - 40px) / 3);

            @include respond-to(tablet) {
                width: calc(50% - 10px);
                height: 317px;
            }

            @include respond-to(mobile) {
                width: 100%;
                height: 291px;
            }

            &_image-absolute {
                @include respond-to(tablet) {
                    height: auto;
                }
            }
        }

        .card-with-text-and-btn__content {
            @include respond-to(mobile) {
                inset-block: 20px;
                inset-inline: 20px;
            }
        }
    }

    &__stats {
        & h3 {
            @include respond-to(tablet) {
                font-size: 2.5rem;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
            }
        }
    }

    &__stats-header {
        display: flex;
        justify-content: space-between;

        @include respond-to(tablet) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 50px;
        }

        @include respond-to(mobile) {
            gap: 40px;
        }

        & > div:first-child {
            width: 47%;

            @include respond-to(tablet) {
                width: 100%;
            }
        }

        & .spp__stats-card {
            padding-block: 40px 50px;
            padding-inline: 60px 80px;
            width: 40%;

            @include respond-to(tablet) {
                padding-block: 40px 50px;
                padding-inline: 60px;
                width: 100%;
                flex-flow: row wrap;
                gap: 45px;
            }

            @include respond-to(mobile) {
                gap: 40px;
            }

            @include respond-to(mobile) {
                padding-block: 30px 35px;
                padding-inline: 30px;
            }
        }
    }

    & .stats-item {
        @include respond-to(tablet) {
            width: calc(50% - (45px / 2));

            & .stats-item__title {
                font-size: 1rem;
                line-height: 1.4;
            }

            & .stats-item__number {
                font-size: 2.5rem;
                line-height: 1;
            }
        }

        @include respond-to(mobile) {
            width: 100%;

            & .stats-item__title {
                font-size: 0.875rem;
            }

            & .stats-item__number {
                font-size: 1.875rem;
                line-height: 1;
            }
        }
    }

    &__stats-cards-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & > div {
            width: calc((100% - 20px) / 2);

            &:only-child {
                width: 100%;
            }

            @include respond-to(tablet) {
                width: 100%;

                &:not(:first-child) {
                    display: flex;
                    gap: 20px;

                    & .spp__stats-card {
                        width: calc(50% - 10px);
                    }
                }

                &:first-child {
                    order: 3;
                }
            }

            @include respond-to(mobile) {
                width: 100%;

                &:not(:first-child) {
                    flex-direction: column;

                    & .spp__stats-card {
                        width: 100%;
                    }
                }
            }
        }

        &_alt {
            & .spp__stats-card {
                width: calc(33.3% - (40px / 3));

                @include respond-to(tablet) {
                    width: calc(50% - 10px);
                }

                @include respond-to(mobile) {
                    width: 100%;
                }

                & .stats-item {
                    width: 90%;

                    @include respond-to(mobile) {
                        width: 100%;
                    }
                }
            }
        }

        &_bigger-gap {
            gap: 115px;

            & .list-stats-item {
                width: calc(50% - (115px / 2));
                flex-grow: 1;

                @include respond-to(tablet) {
                    width: 100%;
                }
            }

            @include respond-to(tablet) {
                gap: 50px;
            }

            & > div:first-child {
                @include respond-to(tablet) {
                    order: unset;
                }
            }

            & > div:not(:first-child) {
                display: block;
            }
        }
    }

    &__stats-card {
        background-color: var(--c-white);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        font-size: 1.125rem;
        line-height: 1.65em;
        padding: 40px 100px 40px 40px;

        @include respond-to(tablet) {
            padding-block: 40px;
            padding-inline: 40px 23px;
        }

        @include respond-to(mobile) {
            padding-block: 30px 35px;
            padding-inline: 30px 19px;
        }

        & p {
            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &__stats-card-head {
        color: var(--c-accent);
        font-size: 1.25rem;
        font-weight: 500;
        margin-block-end: 10px;

        span {
            font-size: 3.75rem;
            font-weight: 600;
            line-height: 1.1em;
        }
    }

    &__documents {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .card-document {
            width: calc((100% - 40px) / 3);

            @include respond-to(tablet) {
                width: calc(50% - 10px);
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &_d-block {
            display: block;
        }
    }

    &__text-block {
        width: 80%;
        margin-block-end: 72px;

        @include respond-to(tablet) {
            margin-block-end: 60px;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }
    }

    &__image-with-table {
        display: flex;
        gap: 20px;
    }

    &__image {
        display: block;
        border-radius: 8px;
        width: 33%;
        object-fit: cover;
        min-height: 100%;

        @include respond-to(tablet) {
            display: none;
        }
    }

    &__tabs {
        margin-block-end: 50px;

        @include respond-to(mobile) {
            flex-direction: row;
            margin-block-end: 20px;
        }
    }

    &__tab-btn {
        @include respond-to(mobile) {
            font-size: 0.75rem;
        }
    }

    @include section-indents;
}

.a11y-mode:not(.a11y-mode-font-size-standard),
.a11y-mode:not(.a11y-mode-letter-spacing-standard) {
    .spp {
        @include respond-to(tablet) {
            word-wrap: break-word;
        }
    }
}

.a11y-mode {
    .spp {
        &__rating {
            min-height: unset;

            & > picture {
                display: none;
            }

            &-content {
                width: 100%;
                gap: 40px;

                & p {
                    width: 100%;
                }
            }
        }

        &__laws {
            flex-direction: column;

            .card-with-text-and-btn {
                width: 100%;
            }
        }

        &__stats-header {
            flex-direction: column;

            & > div {
                width: 100% !important;
            }
        }

        &__stats-cards-container {
            flex-direction: column;

            & > div {
                width: 100%;
            }

            & > div:not(:first-child) {
                flex-direction: column;

                & .spp__stats-card {
                    width: 100%;
                }
            }
        }

        &__stats-card {
            gap: 40px;
            padding: 0;
        }

        & .stats-item {
            width: 100%;

            &__number {
                white-space: nowrap;
            }

            &__title {
                flex-wrap: wrap;
            }
        }

        &__documents {
            flex-direction: column;

            .card-document {
                width: 100%;
                padding: 0;
            }
        }

        &__tiles {
            @include respond-to(tablet) {
                flex-direction: column;
            }

            & .tile {
                &__text-content {
                    width: 100%;
                }

                &__view {
                    padding: 0 !important;
                }

                @include respond-to(tablet) {
                    min-width: 100%;
                    max-height: unset;
                }
            }

            &_grid {
                display: block;

                & .tile {
                    width: 100%;

                    &:not(:last-child) {
                        margin-block-end: 20px;
                    }
                }
            }
        }

        &__text-block {
            width: 100%;
        }

        &__flex {
            flex-direction: column;
        }

        &__table {
            display: none;
        }

        &__common-right {
            padding: 0;
        }

        &__first-tile {
            & > div {
                display: block;
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .spp {
        &__common-left {
            display: none;
        }

        &__common-right {
            width: 100%;
        }

        &__first-tile {
            flex-direction: column;
            gap: 20px;

            & > div {
                width: 100%;
                padding: 0;
            }
        }
    }
}
