.avatar {
    $this: &;

    align-items: center;
    color: var(--c-light-text);
    display: inline-flex;
    font-size: 0.875rem;
    line-height: 1.27rem;

    & img {
        border: 3px solid #fff;
        border-radius: 50%;
        display: block;
        height: 64px;
        margin-inline-end: 15px;
        object-fit: cover;
        width: 64px;
        flex-shrink: 0;
    }

    &__description {
        font-size: 0.75rem;
        font-style: italic;
    }

    &_dark {
        color: var(--c-primary);
    }

    &_bigger {
        & img {
            height: 75px;
            width: 75px;
        }
    }

    &_name-highlight {
        #{$this}__title {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.7em;
            margin-block-end: 5px;

            @include respond-to(mobile) {
                font-size: 14px;
                line-height: 1.4;
            }
        }
    }

    &_giant {
        @include respond-to(mobile) {
            align-items: flex-start;
        }

        & img {
            height: 143px;
            margin-inline-end: 50px;
            width: 143px;
            flex-shrink: 0;

            @include respond-to(tablet) {
                width: 127px;
                height: 127px;
                margin-inline-end: 32px;
            }

            @include respond-to(mobile) {
                width: 90px;
                height: 90px;
                margin-inline-end: 20px;
            }
        }

        #{$this}__title {
            font-size: 1.25rem;
            margin-block-end: 20px;
            color: var(--c-white);

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        #{$this}__description {
            font-size: 1rem;
            line-height: 1.4;
            color: var(--c-dark-bg);

            @include respond-to(mobile) {
                font-size: 0.75rem;
            }
        }
    }

    &_alt {
        & img {
            background-color: var(--c-white);
            width: 99px;
            height: 99px;
            border: none;

            @include respond-to(tablet) {
                width: 127px;
                height: 127px;
                margin-inline-end: 34px;
            }

            @include respond-to(mobile) {
                width: 90px;
                height: 90px;
                margin-inline-end: 20px;
            }
        }

        #{$this}__title {
            font-size: 1.25rem;
            line-height: 1.4;
            margin-block-end: 10px;
            color: var(--c-primary);

            @include respond-to(tablet) {
                margin-block-end: 20px;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
                margin-block-end: 10px;
            }
        }

        #{$this}__description {
            font-size: 1rem;
            line-height: 1.4;
            color: var(--c-primary);

            @include respond-to(mobile) {
                font-size: 0.75rem;
            }
        }
    }

    .modal-window .review__user-info & {
        & img {
            width: 150px;
            height: 150px;

            @include respond-to(tablet) {
                width: 98px;
                height: 98px;
            }
        }

        #{$this}__title {
            font-size: 1.875rem;
            line-height: 1.2;
            margin-block-end: 10px;

            @include respond-to(tablet) {
                font-size: 1.25rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
                margin-block-end: 5px;
            }
        }

        #{$this}__description {
            font-size: 1rem;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }
}
