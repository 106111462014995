.tile {
    $this: &;

    display: flex;
    height: 320px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    &:hover {
        & .btn_accent {
            background-color: var(--c-button-accent-color);
            color: var(--c-white);
        }
    }

    &:active {
        & .btn_accent {
            background-color: var(--c-button-active-background-color);
            border-color: var(--c-button-active-background-color);
            opacity: var(--c-button-active-opacity);
        }
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__view {
        align-items: start;
        background-color: var(--c-light-bg);
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        min-height: 100%;
        justify-content: space-between;
        padding: 20px 20px 40px;

        @include respond-to(mobile) {
            padding-block-end: 20px;
        }
    }

    &__count {
        width: 67px;
        height: 67px;
        min-height: 67px;
        border-radius: 50%;
        background-color: var(--c-dark-bg);
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to(mobile) {
            width: 50px;
            height: 50px;
            min-height: 50px;
        }
    }

    &__text-content {
        width: 100%;

        & a {
            color: var(--c-accent);
        }
    }

    &__title:not(:last-child) {
        margin-block-end: 15px;
    }

    &__icon {
        height: 67px;
        width: 67px;

        @include respond-to(mobile) {
            height: 50px;
            width: 50px;
        }
    }

    &__link {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
    }

    &__tcp-link {
        display: block;
        font-weight: 500;
        margin-block-end: 10px;
        width: fit-content;
    }

    &_main {
        color: var(--c-white);
        height: 660px;
        width: calc((100% - 20px) / 2);

        #{$this}__view {
            background-color: transparent;
            position: absolute;
            inset-block: 0;
            inset-inline: 0;
            padding: 40px;

            & h5 {
                max-width: 260px;
            }
        }

        #{$this}__text-content {
            flex: 1;
        }
    }

    &_full {
        width: 100%;
    }

    &_three-quarter {
        width: calc((100% - 10px) / 1.5);
    }

    &_half {
        width: calc((100% - 20px) / 2);

        #{$this}__text-content {
            width: 60%;
        }

        &.full-text-width {
            #{$this}__text-content {
                width: 100%;
            }
        }
    }

    &_third {
        width: calc((100% - 40px) / 3);
    }

    &_quarter {
        width: calc((100% - 60px) / 4);
    }

    &_half-tablet {
        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);

            #{$this}__text-content {
                width: 60%;
            }

            &.full-text-width {
                #{$this}__text-content {
                    width: 100%;
                }
            }
        }
    }

    &_third-tablet {
        @include respond-to(tablet) {
            width: calc((100% - 40px) / 3);
        }
    }

    &_full-mobile {
        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &_light {
        #{$this}__view {
            background-color: var(--c-white);
        }
    }

    &_transparent {
        border: 1px solid var(--c-white);

        @include respond-to(mobile) {
            border: none;
        }

        #{$this}__view {
            background-color: var(--c-true-transparent);
            color: var(--c-white);

            @include respond-to(mobile) {
                background-color: var(--c-white);
                color: var(--c-primary);
            }
        }
    }

    &_m {
        height: 279px;
    }

    &_s {
        height: 230px;
    }

    &_m-h-230 {
        min-height: 230px;
        height: unset;
    }

    &_m-h-270 {
        min-height: 270px;
        height: unset;
    }

    &_m-h-352 {
        min-height: 352px;
        height: unset;
    }

    &_m-h-355 {
        min-height: 355px;
        height: unset;
    }

    &_h-auto {
        height: auto;
    }

    &_center {
        text-align: center;

        #{$this}__view {
            justify-content: center;
        }
    }

    &_no-increment {
        counter-increment: none !important;

        &::before {
            content: none !important;
        }
    }

    &_alt {
        &::before {
            content: none !important;
        }

        #{$this}__view {
            flex-direction: column;
            justify-content: flex-start;
            gap: 50px;
        }
    }

    &_justify-end {
        #{$this}__view {
            justify-content: flex-end;
            gap: 40px;
        }

        #{$this}__text-content {
            flex-grow: 1;
        }
    }

    &_align-top {
        #{$this}__view {
            justify-content: start;
            padding-block-start: 120px;
        }
    }

    &_align-top-150 {
        #{$this}__view {
            justify-content: start;
            padding-block-start: 150px;

            @include respond-to(mobile) {
                padding-block-start: 20px;
                justify-content: end;
            }
        }
    }
}

.a11y-mode {
    .tile {
        height: auto;

        &_main,
        &__view,
        .btn {
            background: var(--c-white);
            color: var(--c-black) !important;
        }

        &__image {
            display: none;
        }

        &_three-quarter,
        &_half,
        &_third,
        &_quarter {
            width: 100%;
        }

        &__view {
            padding: 20px !important;
        }

        &::before {
            content: none !important;
        }

        &__link {
            inset-block-start: 3px;
            inset-inline-start: 3px;
            outline: 1px solid var(--c-black);
            outline-offset: -3px;

            @include link-sizes-fix;
        }
    }
}
