.presentation-block {
    $this: &;

    position: relative;
    background-color: var(--c-primary);
    color: var(--c-text-on-primary-bg);
    border-radius: 8px;
    min-height: 340px;
    padding: 40px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: var(--c-light-bg);
        color: var(--c-primary);

        #{$this}__image {
            opacity: 0.05;
        }

        #{$this}__icon {
            & svg > path {
                fill: var(--c-primary);
            }
        }

        & .btn {
            border-color: var(--c-primary);
            color: var(--c-primary);

            &:hover {
                background-color: var(--c-primary);
                color: var(--c-white);
            }
        }
    }

    @include respond-to(tablet) {
        min-height: 255px;
        width: 100%;
    }

    @include respond-to(mobile) {
        min-height: 338px;
        padding-block: 30px;
        padding-inline: 20px;
    }

    &__image {
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        width: 90%;
        transition: opacity 0.3s ease;

        @include respond-to(tablet) {
            width: 40%;
        }

        @include respond-to(mobile) {
            width: 70%;
        }
    }

    &__icon {
        margin-block-end: 30px;

        @include respond-to(tablet) {
            margin-block-end: 24px;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }

        & svg {
            @include respond-to(mobile) {
                width: 36px;
                height: 43px;
            }
        }

        & svg > path {
            transition: fill 0.3s ease;
        }
    }

    &__content {
        position: relative;
    }

    &__title {
        margin-block-end: 40px;

        @include respond-to(tablet) {
            width: 75%;
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &_alt {
        @include respond-to(mobile) {
            padding: 20px;
        }

        #{$this}__title {
            font-size: 1.875rem;
            line-height: 1.2;
            margin-block-end: 54px;

            @include respond-to(tablet) {
                margin-block-end: 40px;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                margin-block-end: 50px;
            }
        }
    }

    &_gray {
        background-color: var(--c-light-bg);
        color: var(--c-primary);

        & .btn {
            border-color: var(--c-primary);
            color: var(--c-primary);
        }
    }
}

.a11y-mode {
    .presentation-block {
        max-height: unset;
    }
}
