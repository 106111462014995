.investment-project-detail {
    $this: &;

    &__features {
        display: flex;
        gap: 20px;

        @include respond-to(mobile) {
            flex-wrap: wrap;
        }

        & > div {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: calc((100% - 20px) / 2);

            @include respond-to(mobile) {
                width: 100%;
            }

            .text-card {
                flex-grow: 1;
                padding-block: 30px 40px;
                padding-inline: 40px;

                @include respond-to(tablet) {
                    padding-block: 20px 30px;
                    padding-inline: 20px;
                }
            }
        }

        &_alt {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-auto-rows: auto;
            gap: 35px 90px;

            @include respond-to(tablet) {
                gap: 50px;
            }

            @include respond-to(mobile) {
                grid-template-columns: 1fr;
                grid-auto-rows: auto;
                gap: 30px;
            }

            & > div {
                width: 100%;
                flex-direction: row;
                min-height: 137px;
                gap: 30px;

                @include respond-to(tablet) {
                    flex-direction: column;
                    min-height: 146px;
                    gap: 20px;
                }

                @include respond-to(mobile) {
                    min-height: unset;
                    gap: 10px;
                }
            }
        }
    }

    &__card-title {
        color: var(--c-accent);
        font-size: 1rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 0.875rem;
        }
    }

    &__card-text {
        font-size: 1.5rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__text {
        max-width: 1147px;
        font-size: 1.25rem;

        @include respond-to(tablet) {
            max-width: unset;
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        & p {
            &:not(:last-child) {
                margin-block-end: 20px;
            }
        }

        & ul,
        ol {
            margin-block-start: 20px;
            margin-block-end: 20px;

            @include respond-to(mobile) {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }

        & ul li,
        ol li {
            list-style: none;
            margin: 0;
            padding-block: 0;
            padding-inline: 28px 0;
            position: relative;

            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }

        & ul li {
            &::before {
                content: "";
                background-color: var(--c-accent);
                border-radius: 50%;
                display: block;
                height: 8px;
                inset-inline-start: 0;
                position: absolute;
                inset-block-start: 50%;
                transform: translateY(-50%);
                width: 8px;
            }
        }

        & ol {
            counter-reset: myCounter;

            li {
                &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter);
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-start: 50%;
                    transform: translateY(-50%);
                    color: var(--c-accent);
                    font-weight: 500;
                }
            }
        }

        &_alt {
            font-size: 1rem;

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &__gallery {
        @include respond-to(tablet) {
            margin-inline: -30px;
        }

        @include respond-to(mobile) {
            margin-inline: -16px;
        }

        & .gallery__photo-block {
            max-height: 687px;

            @include respond-to(tablet) {
                max-height: 400px;
            }

            @include respond-to(mobile) {
                max-height: 230px;
            }
        }
    }

    &__description {
        &_alt {
            position: relative;
            padding-block: 100px;
            color: var(--c-white);

            @include respond-to(mobile) {
                padding-block: 80px;
            }
        }

        &-image {
            display: block;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
        }
    }

    &__drop-shadow {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background-color: var(--c-black);
        opacity: 0.5;
    }

    & .gallery__view {
        margin-block-end: 20px;
    }

    & .gallery__main-photo {
        height: 687px;

        @include respond-to(tablet) {
            height: 400px;
        }

        @include respond-to(mobile) {
            height: 230px;
        }

        & img {
            @include respond-to(mobile) {
                border-radius: unset;
            }
        }
    }

    @include section-indents;
}

.a11y-mode {
    .investment-project-detail {
        &__features {
            flex-direction: column;

            & > div {
                width: 100%;
            }

            &_alt {
                display: flex;
            }
        }
    }
}
