.promising-sectors {
    $this: &;

    &__text {
        font-size: 1.125rem;
        line-height: 165%;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 140%;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__description {
        display: flex;

        @include respond-to(tablet) {
            flex-direction: column;
        }
    }

    &__description-image {
        display: flex;
        flex: 0 0 40%;
        max-height: 620px;
        margin-inline-start: 105px;

        @include respond-to(tablet) {
            margin-block-start: 50px;
            margin-inline-start: 0;
            flex: unset;
            max-height: unset;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__tiles {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc((100% / 4) - 20px), 1fr));
        gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to(mobile) {
            grid-auto-flow: column;
            grid-template-columns: unset;
            width: 100vw;
            margin-inline-start: -16px;
            padding: 0 16px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 354px;
        padding: 40px;
        border-radius: 8px;
        background-color: var(--c-white);

        @include respond-to(tablet) {
            min-height: 271px;
        }

        @include respond-to(mobile) {
            width: 252px;
            min-height: 210px;
            padding: 30px;
        }

        a {
            color: var(--c-accent);
            text-decoration: underline;
            text-underline-offset: 0.5em;

            &:visited {
                color: var(--c-accent);
            }
        }
    }

    &__indicator {
        @include respond-to(tablet) {
            margin-block-end: 20px;
        }

        > * {
            display: inline-block;
        }

        &-value {
            @include respond-to(mobile) {
                font-size: 30px;
            }

            &:not(:only-child) {
                margin-inline-end: 10px;

                @include respond-to(tablet) {
                    margin-inline-end: 5px;
                }
            }
        }

        &-description {
            min-height: 70px;

            @include respond-to(mobile) {
                min-height: 60px;
                font-weight: 500;
            }
        }
    }

    &__sectors {
        display: flex;
        flex-direction: column;

        & > :not(:last-child) {
            margin-block-end: 40px;

            @include respond-to(tablet) {
                margin-block-end: 20px;
            }
        }

        &_alt {
            flex-flow: row wrap;
            gap: 20px;
        }
    }

    &__sector {
        &-card {
            display: flex;
            justify-content: space-between;
            gap: 124px;
            min-height: 378px;
            max-height: fit-content;
            padding: 40px;
            border-radius: 8px;
            background-color: var(--c-light-blue);
            color: var(--c-primary);
            position: relative;

            @include respond-to(tablet) {
                flex-direction: column;
                height: unset;
                gap: 51px;
            }

            @include respond-to(mobile) {
                padding: 20px;
            }

            &:not(:last-child) {
                margin-block-end: 40px;

                @include respond-to(tablet) {
                    margin-block-end: 20px;
                }
            }

            &_alt {
                width: calc((100% - 20px) / 2);
                min-height: 367px;
                color: var(--c-white);

                @include respond-to(tablet) {
                    width: 100%;
                    min-height: 343px;
                }

                @include respond-to(tablet) {
                    min-height: 415px;
                }

                &:not(:last-child) {
                    margin-block-end: 0;

                    @include respond-to(tablet) {
                        margin-block-end: 0;
                    }
                }

                #{$this}__sector-image {
                    position: absolute;
                    inset-block-start: 0;
                    inset-inline-start: 0;
                    width: 100%;
                    height: 100%;
                    max-width: unset;
                    max-height: unset;
                }

                #{$this}__sector-text-container {
                    width: 100%;
                    max-width: unset;
                    z-index: 2; // перекрыть картинку и тень
                }

                #{$this}__sector-buttons {
                    flex-wrap: wrap;

                    & .btn {
                        color: var(--c-white);
                        border-color: var(--c-white);

                        &:hover,
                        &:active {
                            color: var(--c-primary);
                            background-color: var(--c-white);
                        }
                    }
                }
            }
        }

        &-buttons {
            display: flex;
            gap: 15px;

            @include respond-to(mobile) {
                flex-direction: column;
                order: 2;
            }

            & .btn {
                width: fit-content;

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        &-image {
            object-fit: cover;
            min-height: 100%;
            border-radius: 8px;
            max-width: calc(59% - 124px);
            flex: 1;
            max-height: 298px;

            @include respond-to(tablet) {
                max-width: unset;
                min-height: unset;
            }

            @include respond-to(mobile) {
                min-height: 270px;
            }
        }

        &-text-container {
            max-width: 41%;
            min-height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @include respond-to(tablet) {
                max-width: 100%;
                gap: 58px;
            }
        }

        &-description {
            display: flex;
            flex-direction: column;
            margin-block-end: 20px;

            @include respond-to(tablet) {
                margin-block-end: 0;
            }
        }

        &-drop-shadow {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            border-radius: 8px;
            z-index: 1; // перекрыть картинку
        }
    }

    @include section-indents;
}

.a11y-mode {
    .promising-sectors {
        &__tiles {
            grid-template-columns: 1fr;
            gap: 40px;

            @include respond-to(mobile) {
                grid-auto-flow: row;
            }
        }

        &__tile {
            min-height: unset;
            gap: 20px;
            padding: 0;
            width: 100%;
        }

        &__indicator-description {
            min-height: unset;
        }

        &__indicator {
            margin-block-end: 0;
        }

        &__sectors {
            gap: 40px;
        }

        &__sector-card {
            padding: 0;
            margin-block-end: 0;
            min-height: unset;
            color: var(--c-primary);
        }

        &__sector-text-container {
            max-width: unset;
        }

        &__sector-drop-shadow {
            opacity: 0 !important;
        }

        &__sector-buttons {
            & .btn {
                color: var(--c-a11y-accent-color);
                border-color: var(--c-a11y-accent-color);
                background-color: var(--c-a11y-background-color);
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .promising-sectors {
        &__description-image {
            display: none;
        }
    }
}
