// этого размера нет в линейке
.mr-95 {
    margin-inline-end: 95px;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100% !important;
}

.fw-500 {
    font-weight: 500;
}

.big-text {
    font-size: 8rem;
    letter-spacing: -0.02em;
    line-height: 1em;
    font-weight: 700;
}

.c-fff {
    color: var(--c-white);
}

.c-contrast {
    color: var(--c-white);
}

.c-light-text {
    color: var(--c-light-text);
}

.c-accent {
    color: var(--c-accent);
}

.bg-primary {
    background: var(--c-primary) !important;
}

.bg-secondary {
    background: var(--c-bg-secondary);
}

.bg-light-blue {
    background-color: var(--c-light-blue);
}

.bg-white {
    background-color: var(--c-white);
}

.bg-light-grey {
    background-color: var(--c-light-bg);
}

.bg-base-color {
    background-color: var(--c-base-color);
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

.d-block {
    display: block !important;
}

.align-items-center {
    align-items: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-between {
    justify-content: space-between;
}

.flex-item-equal {
    flex: 1;
}

.width-content {
    width: fit-content;
}

.common-flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: stretch;

    &_mobile_scroll {
        @include respond-to(mobile) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            gap: 0;

            &::-webkit-scrollbar {
                display: none;
            }

            & .common-flex-item {
                min-width: 200px;
            }
        }
    }

    &_tablet_scroll {
        @include respond-to(tablet) {
            flex-wrap: nowrap;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.common-flex-item {
    display: block;
    width: calc(33.3% - 20px);

    @include respond-to(desktop) {
        width: calc(50% - 10px);
    }

    @include respond-to(mobile) {
        width: 100%;
    }

    &_half {
        width: calc(50% - (20px / 2));

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &_quarter {
        width: calc(25% - (60px / 4));

        @include respond-to(desktop) {
            width: calc(50% - 10px);
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.body-lock-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.rounded-edges {
    border-radius: 8px;
}

@media (max-width: 1459px) {
    .d-md-none {
        display: none !important;
    }
}

.d-tablet {
    display: none !important;

    @include respond-to(tablet) {
        display: block !important;
    }
}

.d-tablet-flex {
    display: none !important;

    @include respond-to(tablet) {
        display: flex !important;
    }
}

.d-tablet-grid {
    display: none !important;

    @include respond-to(tablet) {
        display: grid !important;
    }
}

.d-mobile {
    display: none !important;

    @include respond-to(mobile) {
        display: block !important;
    }
}

.d-tablet-none {
    @include respond-to(tablet) {
        display: none !important;
    }
}

.d-mobile-none {
    @include respond-to(mobile) {
        display: none !important;
    }
}

.relative-position {
    position: relative;
}

.float-right {
    float: right;
}

.styled-lists {
    & ul:not(.breadcrumbs__list, .tabs, .tab-panes),
    ol {
        &:not(:last-child) {
            margin-block-end: 20px;

            @include respond-to(mobile) {
                margin-block-end: 10px;
            }
        }

        &:not(:first-child) {
            margin-block-start: 20px;

            @include respond-to(mobile) {
                margin-block-start: 10px;
            }
        }
    }

    & ul:not(.breadcrumbs__list, .tabs, .tab-panes) li,
    ol li {
        list-style: none;
        margin: 0;
        padding-block: 0;
        padding-inline: 1.75rem 0;
        position: relative;

        &:not(:last-child) {
            margin-block-end: 20px;

            @include respond-to(mobile) {
                margin-block-end: 10px;
            }
        }
    }

    & ul:not(.breadcrumbs__list, .tabs, .tab-panes) li {
        &::before {
            content: "";
            background-color: var(--c-alt-base-color);
            border-radius: 50%;
            display: block;
            height: 8px;
            inset-inline-start: 0;
            position: absolute;
            inset-block-start: 0.5em;
            width: 8px;
        }
    }

    & ol {
        counter-reset: myCounter;

        li {
            &::before {
                counter-increment: myCounter;
                content: counter(myCounter);
                position: absolute;
                inset-inline-start: 0;
                inset-block-start: 0;
                color: var(--c-base-color);
                font-weight: 500;
            }
        }

        &.with-dot {
            li {
                &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter) ".";
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-start: 0;
                    color: var(--c-base-color);
                    font-weight: 500;
                }
            }
        }
    }

    &_bigger {
        & ul:not(.breadcrumbs__list, .tabs, .tab-panes),
        ol {
            font-weight: 500;
            font-size: 1.5em;

            @include respond-to(tablet) {
                font-size: 1.25em;
            }

            @include respond-to(mobile) {
                font-size: 1em;
            }
        }

        & ul:not(.breadcrumbs__list, .tabs, .tab-panes) li,
        ol li {
            &:not(:last-child) {
                margin-block-end: 30px;

                @include respond-to(tablet) {
                    margin-block-end: 20px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }
    }
}

.styled-text-links:not(.document-download) {
    @include styled-text-links;
}

.bg-light-blue:not(.breadcrumbs) + .bg-light-blue,
.bg-primary + .bg-primary,
.bg-secondary + .bg-secondary {
    // чтобы не было двойных отступов у блоков, идущих подряд
    // !important - чтобы не перписывалось стилями в модулях
    padding-block-start: 0 !important;
}

.styled-text {
    color: var(--c-alt-base-color);
}

.docs-container {
    overflow: visible;
}

.__jivoMobileButton {
    @include respond-to(mobile) {
        inset-block-end: 60px !important; // перекрываем встроенные стили виджета jivo
    }
}

jdiv[class^="button_"] {
    @include respond-to(mobile) {
        margin-block-end: 20px !important; // перекрываем встроенные стили виджета jivo
    }
}

.d-a11y-block,
.d-a11y-flex {
    display: none;
}

.a11y-mode {
    .d-a11y-block {
        display: block !important;
    }

    .d-a11y-flex {
        display: flex !important;
    }

    .d-a11y-none {
        display: none !important;
    }

    .common-flex-container {
        flex-direction: column;
    }

    .common-flex-item {
        width: 100%;
    }

    .c-contrast {
        color: var(--c-black);
    }
}

.a11y-mode.a11y-mode-images-hide {
    .d-a11y-images-hide-none {
        display: none;
    }
}

.page-wrap > .main__gallery {
    padding-block: 100px;

    @include respond-to(mobile) {
        padding-block: 80px;
    }
}
