.radio-filter {
    $this: &;

    &__title {
        margin-block-end: 20px;
    }

    &__options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(tablet) {
            margin-block-end: 15px;
        }

        @include respond-to(mobile) {
            margin-block-end: 10px;
        }
    }

    &_column {
        background-color: var(--c-true-transparent);

        #{$this}__options {
            display: block;

            & > label:not(:last-child) {
                margin-block-end: 15px;
            }
        }
    }
}
