.support-measures {
    $this: &;

    background-color: var(--c-primary);

    .container {
        overflow: visible;
    }

    &__steps-subtitle {
        font-size: 1.5rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 1.25rem;
        }
        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__icon {
        @include respond-to(mobile) {
            width: 30px;
            height: 30px;
        }
    }

    &__tiles-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & .tile:last-child {
            flex-grow: 1;
            max-width: calc((100% - 20px) / 2);
        }

        @include respond-to(tablet) {
            & .tile {
                width: calc(50% - 10px);
            }

            & .tile:last-child {
                max-width: 100%;
            }
        }

        @include respond-to(mobile) {
            flex-wrap: nowrap;
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            & .tile {
                min-width: 252px;
            }

            & .btn {
                width: 100%;
            }
        }
    }

    &__requirements-tiles {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        gap: 100px;
        margin-block-end: 70px;

        @include respond-to(desktop) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 30px;
            margin-block-end: 50px;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }
    }

    &__flex-item {
        width: calc(25% - (60px / 4));

        &:last-child,
        &:nth-last-child(2) {
            width: calc((75% - 25px) / 2);
        }

        @include respond-to(tablet) {
            min-width: 330px;

            &:last-child,
            &:nth-last-child(2) {
                min-width: 330px;
            }
        }

        @include respond-to(mobile) {
            min-width: 252px;

            &:last-child,
            &:nth-last-child(2) {
                min-width: 252px;
            }
        }
    }

    &__links-wrapper {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    &__link {
        width: calc(33.3% - (40px / 3));
        background-color: var(--c-light-bg);

        &:hover {
            background-color: var(--c-dark-bg);
        }

        @include respond-to(desktop) {
            width: calc(50% - (20px / 2));
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &__tile {
        border-radius: 8px;
        background-color: var(--c-light-bg);
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: var(--c-alt-base-color);

        @include respond-to(desktop) {
            padding: 20px;
        }

        & .btn {
            max-width: fit-content;

            @include respond-to(mobile) {
                max-width: 100%;
            }
        }

        &_white {
            background-color: var(--c-white);
            gap: 40px;
            padding-block: 30px;
            padding-inline: 0 30px;
            color: var(--c-primary);
        }

        &_primary {
            background-color: var(--c-primary);
            color: var(--c-white);
        }

        &_reverse {
            flex-direction: column-reverse;
            color: var(--c-primary);
        }

        &_order {
            background-color: var(--c-white);
            position: relative;
            color: var(--c-primary);

            &:last-child {
                #{$this}__tile-arrow {
                    display: none;
                }
            }
        }

        &_no-link {
            color: var(--c-primary);
        }

        &_transparent {
            background-color: var(--c-true-transparent);
            color: var(--c-text-on-primary-bg);
            padding: 0;
        }
    }

    &__tile-count {
        border-radius: 50%;
        background-color: var(--c-dark-bg);
        width: 50px;
        min-width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 500;
        color: var(--c-white);

        &_white {
            background-color: var(--c-white);
            color: var(--c-primary);
        }

        @include respond-to(mobile) {
            width: 35px;
            min-width: 35px;
            height: 35px;
            font-size: 0.875rem;
        }
    }

    &__tile-step {
        font-size: 1.875rem;
        line-height: 1.2;
        color: var(--c-alt-base-color);
    }

    &__tile-content {
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 500;
        flex-grow: 1;
        color: inherit;

        @include respond-to(desktop) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        & > p:not(:last-child) {
            margin-block-end: 30px;
        }

        & ul:not(.breadcrumbs__list) > li {
            &::before {
                width: 3px;
                height: 3px;
                inset-inline-start: 12px;
                inset-block-start: 0.5em;
            }

            &:not(:last-child) {
                margin-block-end: 0;
            }
        }

        &_small {
            font-weight: 400;
            font-size: 1rem;
        }

        &_medium {
            font-size: 1.125em;

            @include respond-to(desktop) {
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }

        &_big {
            font-weight: 500;
            font-size: 1.5rem;

            @include respond-to(desktop) {
                font-size: 1rem;
            }
        }
    }

    &__tile-arrow {
        position: absolute;
        inset-block-start: 50%;
        transform: translateX(-50%);
        inset-inline-end: -30px;
    }

    &__tile-value {
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1.2;

        @include respond-to(tablet) {
            font-size: 1.875rem;
        }

        @include respond-to(mobile) {
            font-size: 1.375rem;
        }

        &_smaller {
            font-size: 1.875rem;
        }

        &_accent {
            color: var(--c-alt-base-color);
        }
    }

    &__count-with-text {
        display: flex;
        gap: 30px;
        align-items: center;

        @include respond-to(tablet) {
            gap: 32px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }

        &:not(:last-child) {
            margin-block-end: 34px;

            @include respond-to(tablet) {
                margin-block-end: 23px;
            }

            @include respond-to(mobile) {
                margin-block-end: 9px;
            }
        }
    }

    &__list-wrapper {
        display: block;
        columns: 2;

        @include respond-to(tablet) {
            columns: 1;
        }
    }

    &__choose {
        background-color: var(--c-white);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 80px;
        padding-inline: 70px 80px;

        @include respond-to(tablet) {
            flex-direction: column;
            align-items: flex-start;
            gap: 60px;
            padding: 40px;
        }

        @include respond-to(mobile) {
            gap: 50px;
            padding: 30px;

            & .btn {
                width: 100%;
            }
        }

        &_alt {
            background-color: var(--c-primary);
            color: var(--c-text-on-primary-bg);
        }
    }

    &__text-block {
        max-width: 668px;

        @include respond-to(tablet) {
            max-width: unset;
        }

        & p {
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }
        }
    }

    &__text-with-icon {
        max-width: 883px;
        display: flex;
        gap: 50px;
        align-items: flex-start;

        @include respond-to(tablet) {
            max-width: unset;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__heading {
        max-width: 883px;

        @include respond-to(tablet) {
            max-width: unset;
        }
    }

    &__small-title {
        font-size: 1.5rem;
        line-height: 1.4;
        font-weight: 500;

        @include respond-to(tablet) {
            font-size: 1rem;
            font-weight: 400;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__big-subtitle {
        font-size: 2.5rem;
        line-height: 1.2;
        font-weight: 600;

        @include respond-to(tablet) {
            line-height: 1;
        }

        @include respond-to(mobile) {
            font-size: 1.375rem;
            line-height: 1.2;
        }

        &_white {
            color: var(--c-white);
        }
    }

    &__text {
        width: 70%;

        @include respond-to(tablet) {
            width: 100%;
        }

        &_medium {
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }

        &_large {
            font-size: 1.25rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &__columns {
        columns: 2;

        & li {
            break-inside: avoid;
        }

        @include respond-to(tablet) {
            columns: 1;
        }

        &_single {
            padding-inline-start: 80px;
            columns: 1;

            @include respond-to(tablet) {
                padding-inline-start: 0;
            }

            & li::before {
                background-color: var(--c-text-on-primary-bg) !important; // перекрыть основной стиль
            }
        }
    }

    &__bg-section {
        position: relative;
        padding-block: 100px;
        color: var(--c-text-on-primary-bg);

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        & img {
            position: absolute;
            inset-block-start: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-document {
        min-height: 339px;
    }

    .simple-link-block__text {
        max-width: 80%;
    }

    & .ul-default {
        & li {
            margin-block-end: 40px;
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                margin-block-end: 30px;
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
                font-size: 0.875rem;
            }

            &::before {
                inset-block-start: 1.2rem;
            }

            & ul li,
            ol li {
                padding-inline-start: 26px;
                font-size: 0.8rem;
                margin-block-end: 10px;
            }

            & ul li {
                &::before {
                    inset-inline-start: 12px;
                    width: 4px;
                    height: 4px;
                }
            }

            & ol li {
                &::before {
                    inset-inline-start: 12px;
                    font-size: 0.75rem;
                    background-color: var(--c-true-transparent);
                }
            }
        }
    }

    & p:not(:last-child) {
        margin-block-end: 20px;
    }

    & li > ul:not(.breadcrumbs__list) > li {
        &::before {
            width: 3px;
            height: 3px;
            inset-inline-start: 12px;
            inset-block-start: 0.5em;
        }

        &:not(:last-child) {
            margin-block-end: 0;
        }
    }

    &_detail {
        background-color: var(--c-white);
    }

    @include section-indents;
}

.support-measures-choose {
    background-color: var(--c-white);
    border-radius: 8px;

    &__content {
        padding-block-end: 55px;
        padding-inline: 50px;

        @include respond-to(tablet) {
            padding-block-end: 40px;
            padding-inline: 40px;
        }

        @include respond-to(mobile) {
            padding-block-end: 20px;
            padding-inline: 20px;
        }
    }

    &__progress {
        display: flex;
        gap: 5px;
        padding-block: 20px;
        padding-inline: 20px;

        @include respond-to(tablet) {
            gap: 10px;
        }

        @include respond-to(mobile) {
            gap: 5px;
            padding-block: 10px;
            padding-inline: 10px;
        }
    }

    &__line {
        flex: 1;
        height: 40px;

        &::before {
            background-color: var(--c-light-bg);
            border-radius: 8px;
            content: "";
            display: block;
            height: 5px;
        }

        &.active {
            &::before {
                background-color: var(--c-alt-base-color);
            }
        }

        &.enabled {
            cursor: pointer;

            &::before {
                background-color: var(--c-alt-base-color);
            }

            &:hover {
                &::before {
                    opacity: 0.7;
                }
            }
        }
    }

    &__step {
        display: none;
        justify-content: space-between;

        &.active {
            display: flex;
        }

        & > div {
            width: 42%;
        }

        @include respond-to(tablet) {
            flex-direction: column;
            gap: 100px;

            & > div {
                width: 100%;
            }
        }

        @include respond-to(mobile) {
            gap: 52px;
        }
    }

    &__filter {
        @include respond-to(mobile) {
            display: flex;
            flex-direction: column;
        }
    }

    &__filter-wrapper {
        margin-block-end: 40px;

        & .filter {
            padding: 0;
        }

        @include respond-to(tablet) {
            & .input__field {
                padding-block: 30px 29px;
            }

            & .select__choosen {
                padding-block: 30px;
            }
        }

        @include respond-to(mobile) {
            margin-block-end: 30px;

            & .input__field {
                padding-block: 18px 17px;
            }

            & .select__choosen {
                padding-block: 20px;
            }
        }
    }

    &__step-number {
        font-size: 0.875rem;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 0.13em;
        text-transform: uppercase;
        margin-block-end: 20px;

        @include respond-to(mobile) {
            font-size: 0.75rem;
        }
    }

    &__filter-title {
        margin-block-end: 40px;

        @include respond-to(mobile) {
            margin-block-end: 30px;
        }
    }

    &__filter-name {
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 500;

        @include respond-to(tablet) {
            font-weight: 600;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    &__filter-unit {
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 500;
        color: var(--c-alt-base-color);
        margin-inline-start: 20px;
        white-space: nowrap;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            font-weight: 400;
            margin-inline-start: 10px;
        }
    }

    &__answer-plate {
        align-content: start;
        background-color: var(--c-blue-bg);
        padding: 30px 40px;
        border-radius: 8px;
        min-height: 426px;
        display: flex;
        flex-wrap: wrap;
        gap: 30px 40px;

        @include respond-to(mobile) {
            padding: 20px;
        }
    }

    &__answer-item {
        width: calc((100% - 40px) / 2);
        display: none;

        &.active {
            display: block;
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &__answer-name {
        font-size: 0.875rem;
        line-height: 1.4;
        color: var(--c-deep-gray);
        margin-block-end: 10px;
    }

    &__answer-text {
        font-weight: 500;
    }

    & .btn.disabled {
        background-color: var(--c-deep-gray);
        border-color: var(--c-deep-gray);
        pointer-events: none;
        cursor: default;
    }
}

.support-measures-conclusion {
    $this: &;

    overflow: hidden;
    padding-block-end: 55px;
    padding-inline: 50px;

    @include respond-to(tablet) {
        padding-block-end: 40px;
        padding-inline: 40px;
    }

    @include respond-to(mobile) {
        padding-block-end: 20px;
        padding-inline: 20px;
    }

    &__list {
        display: flex;
        margin-inline-start: -20px;
        transform: translateX(0);
        transition: transform 0.8s ease-in-out;

        @include respond-to(tablet) {
            margin-inline: -40px;
            overflow: scroll;
            padding-inline: 40px;
            scrollbar-width: none;
            transform: translateX(0) !important;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include respond-to(mobile) {
            margin-inline: -20px;
            padding-inline: 20px;
        }
    }

    &__list-item {
        flex: 0 0 calc(33.33% - 20px);
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-inline-start: 20px;

        @include respond-to(tablet) {
            flex: 0 0 45%;
            margin-inline: 0 20px;

            &:last-child {
                margin-inline-end: 0;
            }
        }

        @include respond-to(mobile) {
            gap: 10px;
            flex: 0 0 70%;
            min-width: 100%;

            & .simple-link-block {
                height: 180px;

                &__text {
                    max-width: 70%;
                }
            }
        }
    }

    &__contact {
        background-color: var(--c-light-blue);
        padding: 20px;
        border-radius: 8px;
        min-height: 200px;
        transition: background-color 0.3s;

        &:hover {
            background-color: var(--c-light-bg);
        }

        @include respond-to(mobile) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__main-info {
        display: flex;
        gap: 28px;
    }

    &__contact-info {
        padding-inline-start: 148px;

        @include respond-to(tablet) {
            padding-inline-start: 106px;
        }

        @include respond-to(mobile) {
            padding-inline-start: 0;
        }
    }

    &__btn-wrapper {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
    }

    &__image-wrapper {
        display: flex;
        align-items: center;

        @include respond-to(tablet) {
            align-items: start;
        }
    }

    &__image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: cover;

        @include respond-to(tablet) {
            height: 78px;
            width: 78px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: start;

        @include respond-to(tablet) {
            & .btn {
                inset-inline-start: -98px;
                margin-block-start: auto;
                position: relative;
            }
        }
    }

    &__title {
        font-size: 0.875rem;
        line-height: 1.4;
        margin-block-end: 4px;
        color: var(--c-deep-gray);
    }

    &__name {
        font-size: 0.9375rem;
        line-height: 1.4;
        font-weight: 600;
        margin-block-end: 7px;
    }

    &__position {
        font-size: 0.75rem;
        line-height: 1.4;
        font-style: italic;
        margin-block-end: 18px;
    }

    &__phone {
        display: block;
        font-size: 0.875rem;
        line-height: 1.4;
        margin-block-end: 5px;
        color: var(--c-alt-base-color);
    }

    &__email {
        display: block;
        font-size: 0.875rem;
        line-height: 1.4;
        margin-block-end: 20px;
        color: var(--c-alt-base-color);
    }

    &__text {
        max-width: 782px;

        &_half {
            max-width: 513px;
        }
    }

    &__subtitle {
        color: var(--c-accent);
    }

    &__link {
        & .simple-link-block.with-subtitle {
            height: 100%;
        }
    }

    & button:not(.btn) {
        padding: 0;
    }

    &_mp2 {
        @include respond-to(mobile) {
            margin-inline: -20px;
            background: var(--c-white);
        }

        #{$this}__list {
            display: block;
        }

        #{$this}__list-item {
            flex-direction: row;
            gap: 0;

            @include respond-to(desktop) {
                flex-direction: column;
                gap: 10px;
            }

            @include respond-to(tablet) {
                margin-inline-end: 0;
            }

            @include respond-to(mobile) {
                min-width: 100%;
                max-width: 100%;
            }

            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(desktop) {
                    margin-block-end: 40px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 30px;
                }
            }

            &:nth-child(odd) {
                #{$this}__link {
                    & .simple-link-block {
                        background-color: var(--c-light-bg);

                        &:hover {
                            background-color: var(--c-light-bg);
                        }
                    }
                }

                #{$this}__contact {
                    background-color: var(--c-light-bg);

                    &:hover {
                        background-color: var(--c-light-bg);
                    }
                }
            }
        }

        #{$this}__link {
            flex-grow: 1;

            & .simple-link-block {
                display: flex;
                flex-direction: column;
                padding: 40px;
                gap: 20px;
                min-height: unset;

                &:hover {
                    background-color: var(--c-light-blue);
                }

                @include respond-to(desktop) {
                    padding: 30px;
                    min-height: unset;
                }

                @include respond-to(mobile) {
                    padding: 20px;
                    min-height: unset;
                }

                &__text {
                    max-width: unset;
                }

                &__subtitle {
                    margin-block-start: 0;
                }
            }

            &:not(:only-child) {
                border-radius: 8px 0 0 8px;

                @include respond-to(desktop) {
                    border-radius: 8px;
                }

                & .simple-link-block {
                    border-radius: 8px 0 0 8px;

                    @include respond-to(desktop) {
                        border-radius: 8px;
                    }
                }
            }
        }

        #{$this}__contact {
            min-width: 40.8%;
            max-width: 40.8%;
            border-radius: 0 8px 8px 0;
            padding: 40px;

            &:hover {
                background-color: var(--c-light-blue);
            }

            @include respond-to(desktop) {
                border-radius: 8px;
                min-width: 100%;
                max-width: 100%;
                padding: 30px;
            }

            @include respond-to(mobile) {
                padding: 20px;
            }
        }
    }

    & .tab-plate_alt {
        &:nth-last-child(2) {
            .tab-plate__point {
                &::after {
                    height: calc(100% + 30px);

                    @media screen and (max-width: 1237px) {
                        height: calc(100% + 20px);
                    }

                    @media screen and (max-width: 1047px) {
                        height: calc(100% + 40px);
                    }
                }
            }
        }
    }
}

.a11y-mode {
    .support-measures {
        background-color: var(--c-white);

        &__columns {
            columns: 1;
        }

        &__flex-item {
            width: 100%;
        }

        &__tile-arrow {
            display: none;
        }

        &__tile_transparent {
            color: var(--c-black);
        }

        &__tile-count {
            letter-spacing: normal;
        }

        &__choose {
            flex-direction: column;
            gap: 40px;
            align-items: flex-start;
            padding: 0;
        }

        &__text-block {
            max-width: 100%;
        }
    }

    .support-measures-choose {
        & .btn.disabled {
            border-color: var(--c-black);
        }

        &__answer-plate {
            border: 1px solid var(--c-black);
        }

        &__answer-name {
            color: var(--c-black);
        }

        &__step {
            &.active {
                display: flex;
                flex-direction: column;
                gap: 40px;
            }

            & > div {
                width: 100%;
            }
        }
    }

    .support-measures-conclusion {
        &__list {
            flex-direction: column;
        }

        &__list-item {
            flex-direction: column;
            min-width: 100%;
        }

        &__contact-info {
            padding-inline-start: 0;

            @include respond-to(tablet) {
                padding-inline-start: 0;
            }

            @include respond-to(mobile) {
                padding-inline-start: 0;
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .support-measures {
        &__bg-section {
            color: var(--c-black);
        }
    }
}
