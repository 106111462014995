.smi-item {
    $this: &;

    align-items: start;
    border-radius: 8px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: var(--c-white);
    min-height: 340px;
    position: relative;

    @include respond-to(mobile) {
        padding: 20px;
    }

    &__titles {
        z-index: 1; // для отображения поверх фонового слоя

        &-data {
            color: var(--c-accent);
            font-weight: 500;
            font-size: 0.875em;
            line-height: 1em;
            letter-spacing: 0.13em;
            text-transform: uppercase;

            @include respond-to(mobile) {
                font-size: 0.75em;
                line-height: 1em;
            }
        }

        &-name {
            font-weight: 500;
            font-size: 1.25em;
            line-height: 140%;
            margin-block-start: 20px;

            @include respond-to(mobile) {
                font-size: 1em;
            }
        }

        &-source {
            margin-block-start: 20px;
        }

        &-tag {
            margin-block-start: 20px;
        }
    }

    & .btn {
        z-index: 1; // для отображения поверх фонового слоя

        @include respond-to(mobile) {
            width: 100%;
            margin-block-start: 20px;
        }
    }

    &__bg {
        inset-block: 0;
        inset-inline: 0;
        position: absolute;

        &::after {
            background-color: rgb(0 0 0 / 40%);
            border-radius: 8px;
            content: "";
            display: block;
            inset-block: 0;
            inset-inline: 0;
            position: absolute;
        }

        & img {
            border-radius: 8px;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &_alt {
        #{$this}__titles-data,
        #{$this}__titles-name,
        #{$this}__titles-source,
        #{$this}__titles-tag {
            color: var(--c-white);
        }
    }
}

.a11y-mode {
    .smi-item {
        &__titles {
            width: 100%;
        }
    }
}
