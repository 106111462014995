.social-development {
    $this: &;

    &__indicators {
        display: grid;
        position: relative;
        background-color: var(--c-light-blue);
        grid-template-areas:
            "a a f"
            "b c f"
            "d e f";
        grid-template-columns: repeat(3, 1fr);
        column-gap: 60px;
        row-gap: 80px;

        > :nth-child(2) {
            grid-area: b;
        }

        > :nth-child(3) {
            grid-area: c;
        }

        > :nth-child(4) {
            grid-area: d;
        }

        > :nth-child(5) {
            grid-area: e;
        }

        > #{$this}__indicator_with-image {
            grid-area: f;
        }

        > #{$this}__indicators-header {
            grid-area: a;
        }

        @include respond-to(tablet) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                "a a"
                "b c"
                "d e"
                "f f";
            column-gap: 100px;
            row-gap: 40px;
        }

        @include respond-to(mobile) {
            grid-template-columns: 1fr;
            grid-template-areas: unset;

            > :nth-child(n) {
                grid-area: unset;
            }
        }
    }

    &__indicators-header {
        margin-block-end: 20px;

        @include respond-to(tablet) {
            margin-block-end: 30px;
        }

        @include respond-to(mobile) {
            margin-block-end: 10px;
        }

        &-title {
            display: inline;
            font-weight: 600;
            font-size: 40px;
            line-height: 120%;

            @include respond-to(tablet) {
                display: block;
            }

            @include respond-to(mobile) {
                font-size: 22px;
            }
        }
    }

    &__indicators-body {
        display: flex;
        flex-wrap: wrap;
        gap: 80px 60px;

        @include respond-to(tablet) {
            gap: 40px;
            justify-content: space-between;
        }
    }

    &__indicator {
        display: flex;
        flex-direction: column;
        position: relative;

        &_with-image {
            @include respond-to(tablet) {
                margin-block-start: 30px;
            }

            @include respond-to(mobile) {
                margin-block-start: 0;
            }

            & > :first-child {
                width: 100%;
                position: absolute;
                padding: 30px 24px;
                color: var(--c-white);

                @include respond-to(tablet) {
                    inset-block-end: 0;
                }

                @include respond-to(mobile) {
                    display: none;
                }
            }

            #{$this}__indicator-value {
                color: var(--c-white);
            }

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }
    }

    &__indicator-value {
        display: inline;
        opacity: 0;
        font-weight: 600;
        font-size: 60px;
        line-height: 110%;
        margin-inline-end: 4px;

        @include respond-to(tablet) {
            font-size: 40px;
            line-height: 100%;
        }

        @include respond-to(mobile) {
            font-weight: 700;
            font-size: 35px;
        }
    }

    &__text {
        width: 85%;

        > :not(:last-child) {
            margin-block-end: 16px;
        }

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__link {
        color: var(--c-accent);

        &:visited {
            color: var(--c-accent);
        }
    }

    &__link_underline {
        text-decoration: underline;
        text-underline-offset: 0.25em;
    }

    & .dropdowns__dropdown-content {
        display: flex;
        flex-direction: column;

        & > :not(:last-child) {
            margin-block-end: 16px;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .social-development {
        &__indicators {
            grid-template-columns: 1fr;
            grid-template-areas: unset;

            & > * {
                grid-area: unset;
            }
        }

        &__indicator-value {
            opacity: 1;
            color: var(--c-primary);
        }

        &__indicator_with-image > :first-child {
            padding: 0;
            position: static;
            color: var(--c-primary);
        }
    }
}
