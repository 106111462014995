.yandex-map {
    &__zoom {
        border-radius: 8px;
        box-shadow: 0 0 8px rgba(0 0 0 / 15%);
        color: var(--c-primary);
        display: flex;
        flex-direction: column;
        font-size: 30px;
    }

    &__zoom-plus,
    &__zoom-minus {
        align-items: center;
        background-color: var(--c-white);
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 36px;
        position: relative;
        width: 36px;
        padding: 0;
        border: none;

        &:hover {
            background-color: var(--c-light-blue);

            &::before {
                display: flex;
            }
        }

        &::before {
            align-items: center;
            border-radius: 18px;
            box-shadow: 0 4px 4px rgba(40 59 106 / 15%);
            display: none;
            background-color: var(--c-white);
            font-size: 13px;
            font-weight: 500;
            height: 36px;
            padding: 0 15px;
            position: absolute;
            inset-inline-end: 50px;
        }
    }

    &__zoom-plus {
        border-radius: 8px 8px 0 0;

        &::before {
            content: "Приблизить";
        }
    }

    &__zoom-minus {
        border-radius: 0 0 8px 8px;

        &::before {
            content: "Отдалить";
        }
    }
}
