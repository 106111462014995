.text-with-counter {
    $this: &;

    break-inside: avoid;

    &:not(:last-child) {
        margin-block-end: 60px;

        @include respond-to(tablet) {
            margin-block-end: 40px;
        }
    }

    &__header {
        display: flex;
        gap: 30px;
        align-items: center;
        margin-block-end: 40px;

        @include respond-to(tablet) {
            gap: 20px;
            margin-block-end: 30px;
        }
    }

    &__count {
        width: 67px;
        min-width: 67px;
        height: 67px;
        min-height: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--c-primary);
        border-radius: 50%;
        color: var(--c-white);
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: normal;

        @include respond-to(tablet) {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    &__heading {
        font-size: 1.5rem;
        line-height: 1.4;
        font-weight: 500;

        @include respond-to(tablet) {
            font-size: 1.25rem;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__text {
        font-size: 1.125rem;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &_desktop-smaller {
        #{$this}__heading {
            font-size: 1.25rem;

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }

        #{$this}__count {
            height: 44px;
            min-height: 44px;
            width: 44px;
            min-width: 44px;
            font-size: 1.125rem;
            line-height: 1;

            @include respond-to(tablet) {
                height: 67px;
                min-height: 67px;
                width: 67px;
                min-width: 67px;
                font-size: 1.875rem;
            }

            @include respond-to(mobile) {
                height: 50px;
                min-height: 50px;
                width: 50px;
                min-width: 50px;
                font-size: 1.375rem;
            }
        }
    }

    &_tablet-column {
        @include respond-to(tablet) {
            break-inside: avoid;
        }

        #{$this}__header {
            @include respond-to(tablet) {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }

            @include respond-to(mobile) {
                gap: 50px;
            }
        }
    }

    &_column {
        #{$this}__header {
            flex-direction: column;
            align-items: flex-start;
            gap: 50px;
            margin-block-end: 20px;

            @include respond-to(tablet) {
                gap: 40px;
            }

            @include respond-to(mobile) {
                gap: 30px;
            }
        }
    }

    &_vertical {
        #{$this}__header {
            margin-block-end: 30px;

            @include respond-to(tablet) {
                margin-block-end: 26px;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }
        }

        #{$this}__count {
            width: 50px;
            height: 50px;
            font-size: 1.25rem;
            line-height: 1.4;

            @include respond-to(mobile) {
                width: 35px;
                height: 35px;
                min-width: 35px;
                min-height: 35px;
                font-size: 1rem;
            }
        }

        #{$this}__text {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-weight: 400;
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &_light {
        #{$this}__count {
            background-color: var(--c-dark-bg);
            color: var(--c-white);
        }
    }

    &_white {
        #{$this}__count {
            background-color: var(--c-white);
            color: var(--c-primary);
        }
    }

    &_text-smaller {
        #{$this}__heading {
            font-size: 1.25rem;

            @include respond-to(tablet) {
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &_tablet-count-bigger {
        #{$this}__count {
            @include respond-to(tablet) {
                width: 67px;
                min-width: 67px;
                height: 67px;
                min-height: 67px;
                font-size: 1.875rem;
                line-height: 1.2;
                font-weight: 500;
            }

            @include respond-to(mobile) {
                width: 40px;
                min-width: 40px;
                height: 40px;
                min-height: 40px;
                font-size: 1.25rem;
                line-height: 1.4;
            }
        }
    }

    &_boiling-point {
        #{$this}__header {
            gap: 67px;

            @include respond-to(tablet) {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }

            &:last-child {
                @include respond-to(mobile) {
                    margin-block-end: 0;
                }
            }
        }

        #{$this}__count {
            width: 50px;
            height: 50px;
            font-size: 1.25rem;
        }

        #{$this}__heading {
            font-size: 1.25rem;

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &_investment-proposal {
        #{$this}__header {
            gap: 30px;
            align-items: flex-start;
            margin-block-end: 0;

            @include respond-to(mobile) {
                gap: 20px;
            }
        }

        #{$this}__count {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            font-size: 1.5rem;

            @include respond-to(mobile) {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                font-size: 0.875rem;
            }
        }

        #{$this}__heading {
            font-size: 1.25rem;

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }
    }

    &_pref-zone {
        #{$this}__header {
            align-items: flex-start;
            margin-block-end: 0;

            @include respond-to(mobile) {
                flex-direction: column;
            }
        }
    }

    &_with-icon {
        #{$this}__header {
            margin-block-end: 30px;

            @include respond-to(tablet) {
                margin-block-end: 0;
            }

            @include respond-to(mobile) {
                flex-direction: column;
                gap: 18px;
                margin-block-end: 24px;
                align-items: flex-start;
            }
        }

        #{$this}__count {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
        }

        #{$this}__heading {
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 500;

            @include respond-to(tablet) {
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }

        #{$this}__text {
            padding-inline-start: 80px;
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 500;

            @include respond-to(tablet) {
                font-size: 1rem;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
                padding-inline-start: 0;
            }

            & ul li {
                padding-inline-start: 16px;

                &::before {
                    width: 4px;
                    height: 4px;
                    background-color: var(--c-primary);
                    inset-block-start: 0.7em;

                    @include respond-to(tablet) {
                        inset-block-start: 0.6em;
                    }
                }
            }
        }
    }

    &_alt {
        #{$this}__header {
            align-items: flex-start;
            margin-block-end: 0;

            @include respond-to(tablet) {
                flex-direction: column;
            }
        }

        #{$this}__text-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 30px;
        }

        #{$this}__heading {
            @include respond-to(mobile) {
                font-size: 0.875rem;
            }

            &:not(:last-child) {
                margin-block-end: 10px;

                @include respond-to(mobile) {
                    margin-block-end: 5px;
                }
            }
        }

        #{$this}__subtitle {
            color: var(--c-accent);

            @include respond-to(tablet) {
                font-size: 0.875rem;
            }
        }

        #{$this}__count {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            font-size: 1rem;
            line-height: 1.4;
            background-color: var(--c-secondary);
        }
    }
}

.a11y-mode {
    .text-with-counter {
        &__count {
            color: var(--c-white);
            background: var(--c-black);
        }

        &__heading {
            width: 100%;
        }

        &__header {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
