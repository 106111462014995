.national-technology-initiative {
    $this: &;

    &__stages {
        display: grid;

        &_alt {
            gap: 100px;

            @include respond-to(mobile) {
                gap: 80px;
            }

            #{$this}__stage {
                display: block;

                & p:not(:last-child) {
                    margin-block-end: 30px;

                    @include respond-to(tablet) {
                        margin-block-end: 40px;
                    }

                    @include respond-to(mobile) {
                        margin-block-end: 30px;
                    }
                }

                & p,
                ul,
                ol {
                    font-size: 1.125rem;

                    @include respond-to(tablet) {
                        font-size: 1rem;
                    }

                    @include respond-to(mobile) {
                        font-size: 0.875rem;
                    }
                }
            }

            #{$this}__stage-title {
                font-size: 2.5rem;
                line-height: 1.1;

                @include respond-to(tablet) {
                    font-size: 1.875rem;
                    line-height: 1.2;
                }

                @include respond-to(mobile) {
                    font-size: 1rem;
                    line-height: 1.4;
                }
            }

            #{$this}__stage-subtitle {
                font-weight: 500;

                @include respond-to(mobile) {
                    font-size: 1rem;
                }
            }

            #{$this}__headline {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 50px;

                @include respond-to(tablet) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                }

                & h5 {
                    @include respond-to(mobile) {
                        font-size: 1.375rem;
                        font-weight: 600;
                        line-height: 1.2;
                    }
                }

                & p {
                    width: calc(50% - 10px);

                    @include respond-to(tablet) {
                        width: 100%;
                    }
                }

                &-with-btn {
                    @include respond-to(tablet) {
                        flex-direction: row;
                    }

                    @include respond-to(mobile) {
                        flex-direction: column;

                        & .btn {
                            width: 100%;
                        }
                    }
                }

                &_flex-start {
                    align-items: flex-start;
                    gap: 80px;

                    @include respond-to(tablet) {
                        gap: 20px;
                    }

                    @include respond-to(mobile) {
                        gap: 10px;
                    }

                    & h5 {
                        @include respond-to(mobile) {
                            font-size: 0.875rem;
                            font-weight: 500;
                            line-height: 1.4;
                        }
                    }
                }
            }

            #{$this}__links {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: 1fr;
                gap: 50px;

                @include respond-to(tablet) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px 95px;
                }

                @include respond-to(mobile) {
                    grid-template-columns: 1fr;
                    grid-auto-rows: unset;
                    gap: 40px;
                }
            }

            #{$this}__link-content {
                background-color: var(--c-true-transparent);
            }

            #{$this}__link-image {
                width: fit-content;
                height: 100px;
                margin-block-end: 10px;

                @include respond-to(tablet) {
                    margin-block-end: 20px;
                }

                & img {
                    width: 100%;
                }

                &:empty {
                    @include respond-to(mobile) {
                        height: 30px;
                        margin-block-end: 0;
                    }
                }
            }

            #{$this}__link-title {
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.4;
                margin-block-end: 10px;

                @include respond-to(tablet) {
                    font-size: 1rem;
                }
            }

            #{$this}__link-caption {
                font-size: 1rem;
                line-height: 1.4;

                @include respond-to(tablet) {
                    font-size: 0.875rem;
                }
            }

            #{$this}__list {
                @include respond-to(tablet) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include respond-to(mobile) {
                    grid-template-columns: 1fr;
                }
            }

            #{$this}__column-text {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                @include respond-to(mobile) {
                    flex-direction: column;
                    gap: 50px;
                }
            }

            #{$this}__column {
                break-inside: avoid;
                width: calc((100% - 20px) / 2);

                @include respond-to(mobile) {
                    width: 100%;
                }

                & h5 {
                    margin-block-end: 30px;

                    @include respond-to(tablet) {
                        margin-block-end: 20px;
                    }
                }

                & p {
                    font-size: 1.125rem;
                    line-height: 1.65;

                    @include respond-to(tablet) {
                        font-size: 1rem;
                        line-height: 1.4;
                    }

                    @include respond-to(mobile) {
                        font-size: 0.875rem;
                    }
                }
            }

            #{$this}__grid-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: auto;
                gap: 50px 235px;

                @include respond-to(desktop) {
                    column-gap: 100px;
                }

                @include respond-to(tablet) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 65px 100px;
                }

                @include respond-to(mobile) {
                    grid-template-columns: 1fr;
                    grid-auto-rows: unset;
                }
            }

            #{$this}__grid-item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 35px;

                @include respond-to(tablet) {
                    gap: 60px;
                }

                @include respond-to(mobile) {
                    gap: 50px;
                }
            }
        }
    }

    &__stage {
        display: grid;
        grid-template-areas:
            "a b b"
            "c b b"
            "c b b";
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;

        @include respond-to(tablet) {
            grid-template-areas:
                "a a a"
                "c b b"
                "c b b";
            column-gap: unset;
            grid-template-columns: 42px 1fr 1fr;
        }

        @include respond-to(mobile) {
            grid-template-columns: 100%;
            grid-template-areas: unset;
        }

        #{$this}__tile_stage {
            grid-area: a;

            @include respond-to(tablet) {
                width: 100%;
            }

            @include respond-to(mobile) {
                grid-area: unset;
            }
        }

        &-content {
            display: flex;
            grid-area: b;

            @include respond-to(tablet) {
                padding-inline-start: 60px;
                padding-block-start: 40px;
            }

            @include respond-to(mobile) {
                padding-inline-start: unset;
                padding-block-start: unset;
                grid-area: unset;
            }

            & .review {
                p:not(:last-child) {
                    margin-block-end: 20px;
                }
            }
        }

        &-line {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            width: 2px;
            background-color: var(--c-accent);
            margin-inline-start: 57px;
            grid-area: c;
            grid-row: 2/-1;

            @include respond-to(tablet) {
                margin-inline-start: 40px;
            }

            @include respond-to(mobile) {
                display: none;
                grid-area: unset;
            }
        }

        &-line-point {
            position: relative;
            width: 32px;
            height: 32px;
            inset-inline-start: -15px;

            &_top {
                inset-block-start: -17px;
            }

            &_bottom {
                inset-block-start: 17px;
            }
        }

        &:last-of-type {
            #{$this}__stage-line {
                display: none;
            }
        }

        &:not(:last-of-type) {
            #{$this}__stage-content {
                padding-block-end: 100px;

                @include respond-to(tablet) {
                    padding-block-end: 50px;
                }

                @include respond-to(mobile) {
                    padding-block-end: 80px;
                }
            }
        }

        &-title {
            font-weight: 600;
            font-size: 3.75rem;
            line-height: 1.1;

            @include respond-to(tablet) {
                font-size: 1.875rem;
                line-height: 1.2;
            }

            @include respond-to(mobile) {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.4;
                color: var(--c-alt-base-color);
            }
        }

        &-subtitle {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 1.25rem;
            }

            @include respond-to(mobile) {
                font-weight: 700;
                color: var(--c-alt-base-color);
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        gap: 20px;

        @include respond-to(tablet) {
            flex-direction: column;
            align-items: center;
        }

        &-img {
            max-width: calc((100% / 2) - 20px);
            height: 100%;
            object-fit: cover;
            border-radius: 8px;

            @include respond-to(tablet) {
                max-width: unset;
                width: 100%;
            }
        }

        &-description {
            display: flex;
            flex-direction: column;
            padding: 50px;
            border-radius: 8px;
            background-color: var(--c-light-blue);

            @include respond-to(tablet) {
                padding: 40px;
            }

            @include respond-to(mobile) {
                padding: 30px;
            }
        }
    }

    &__link {
        display: flex;
        color: inherit;
    }

    &__tile {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: var(--c-light-blue);
        border-radius: 8px;
        overflow: hidden;
        gap: 10px;

        @include respond-to(tablet) {
            font-size: 0.875rem;
            line-height: 1.4;

            & .ul-default {
                font-size: 0.875rem;
            }
        }

        & .btn {
            width: fit-content;
        }

        &-title {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 1rem;
            }

            &_mobile-l {
                @include respond-to(mobile) {
                    font-weight: 600;
                    font-size: 1.375rem;
                    line-height: 1.2;
                }
            }
        }

        &-logo {
            width: fit-content;
        }

        &_stage {
            padding: 50px;
            background-color: var(--c-light-bg);

            @include respond-to(tablet) {
                padding: 30px;
            }

            @include respond-to(mobile) {
                display: block;
                padding-inline: 0;
                padding-block-start: 0;
                padding-block-end: 0;
                margin-block-end: 38px;
                height: auto;

                & > #{$this}__stage-title {
                    display: inline;
                }

                & > #{$this}__stage-subtitle {
                    display: inline;

                    &::before {
                        content: "–";
                        margin-inline: 8px;
                    }
                }
            }
        }

        &_mobile-transparent {
            @include respond-to(mobile) {
                background-color: transparent;
            }
        }

        &_hover {
            transition: all 0.2s;

            &:hover {
                background-color: var(--c-alt-base-color);
                color: var(--c-white);
            }
        }

        &_space-between {
            justify-content: space-between;
            min-height: 220px;

            @include respond-to(tablet) {
                min-height: 188px;
            }
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: unset;
        }
    }

    &__project-title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 1rem;
        }
    }

    &__projects-title {
        font-size: 1.875rem;
        font-weight: 500;
        margin-block-end: 60px;
        line-height: 1.2;

        @include respond-to(tablet) {
            margin-block-end: 50px;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
            margin-block-end: 55px;
        }

        &_alt {
            @include respond-to(tablet) {
                font-weight: 600;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                margin-block-end: 65px;
            }
        }
    }

    &__projects-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to(mobile) {
            grid-template-columns: unset;
        }

        &_alt {
            gap: 80px 40px;

            @include respond-to(desktop) {
                gap: 60px 90px;
            }

            #{$this}__tile {
                flex-direction: row;
                gap: 40px;
                background-color: var(--c-true-transparent);

                @include respond-to(desktop) {
                    flex-direction: column-reverse;
                    justify-content: space-between;
                }

                @include respond-to(mobile) {
                    gap: 30px;
                }

                & img {
                    height: fit-content;
                    max-height: 80px;
                    width: 18%;

                    @include respond-to(desktop) {
                        width: fit-content;
                    }
                }
            }
        }
    }

    &__tiles-grid {
        display: grid;
        gap: 20px;

        @include respond-to(mobile) {
            gap: 60px;
        }

        &_columns {
            grid-template-columns: repeat(2, calc((100% - 20px) / 2));
            width: 100%;

            @include respond-to(tablet) {
                grid-template-columns: repeat(2, calc((100% - 10px) / 2));
                gap: 10px;
            }

            @include respond-to(mobile) {
                grid-template-columns: unset;
                gap: 20px;
            }
        }

        &_stretch-last {
            & > :last-child:nth-child(odd) {
                grid-column: span 2;

                @include respond-to(mobile) {
                    grid-column: unset;
                }
            }
        }
    }

    &__text-blocks {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include respond-to(tablet) {
            gap: 30px;
        }
    }

    &__team {
        display: flex;
        margin-inline-start: -20px;
        transform: translateX(0);
        transition: transform 0.8s ease-in-out;

        & > div {
            flex: 0 0 calc(25% - 20px);
            margin-inline-start: 20px;
        }

        @include respond-to(tablet) {
            gap: 20px;
            margin-inline: 0 -30px;
            overflow: scroll hidden;
            transform: translateX(0) !important;

            & .person-card {
                height: 377px;
            }

            & > div {
                flex: 0 0 40%;
                margin-inline-start: 0;

                &:last-child {
                    margin-inline-end: 30px;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include respond-to(mobile) {
            margin-inline: 0 -16px;

            & .person-card {
                height: 470px;
            }

            & > div {
                flex: 0 0 70%;

                &:last-child {
                    margin-inline-end: 16px;
                }
            }
        }

        &_alt {
            flex-wrap: wrap;
            gap: 20px;
            margin-inline-start: 0;
            transform: none;

            @include respond-to(tablet) {
                margin-inline-end: 0;
            }

            @include respond-to(mobile) {
                margin-inline: 0;
                flex-wrap: nowrap;
                overflow-x: scroll;
            }

            & > div {
                flex: 0 0 calc(50% - 10px);
                margin-inline-start: 0;

                @include respond-to(tablet) {
                    flex: 0 0 calc((100% - 40px) / 3);
                    flex-grow: 1;
                    max-width: calc(50% - 10px);
                }

                @include respond-to(mobile) {
                    flex: 0 0 252px;
                    max-width: unset;
                }

                &:last-child {
                    margin-inline-end: 0;
                }
            }

            & .person-card {
                height: unset;
            }
        }
    }

    & .carousel__headline {
        margin-block-end: 0;

        & h2 {
            width: auto;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .national-technology-initiative {
        &__tiles-grid_columns {
            display: flex;
            flex-direction: column;
        }

        &__stage-line {
            display: none;
        }

        &__stage {
            display: flex;
            flex-direction: column;
        }

        &__list {
            display: flex;
            flex-direction: column;
        }

        &__projects-content {
            display: flex;
            flex-direction: column;
        }

        &__info {
            flex-direction: column;

            &-description {
                width: 100%;
            }
        }

        &__team {
            flex-direction: column;
        }

        &__stages {
            display: block;

            &_alt {
                .national-technology-initiative__headline,
                .national-technology-initiative__headline-with-btn,
                .national-technology-initiative__column-text {
                    display: block;

                    & p {
                        width: 100%;
                    }

                    & h5 {
                        margin-block-end: 20px;
                    }
                }

                .national-technology-initiative__links,
                .national-technology-initiative__grid-content {
                    display: block;
                }

                .national-technology-initiative__column {
                    width: 100%;
                }
            }
        }

        &__tile {
            padding: 0 !important;

            &_space-between {
                height: auto;
            }
        }

        &__link-image {
            display: none;
        }

        &__link {
            display: block;
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .national-technology-initiative {
        &__info-description {
            padding: 0;
        }
    }
}
