.content-tabs {
    display: flex;
    gap: 20px;

    @include respond-to(tablet) {
        flex-direction: column;
    }

    @include respond-to(mobile) {
        margin-block-start: 80px;
    }

    &__tabs {
        width: calc(40% - 10px);

        // чтобы не перекрывалось хэдером при скролле
        padding-block-start: 86px;
        margin-block-start: -86px;

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__select {
        & .select.select_simple.open {
            & .select__choosen {
                background-color: var(--c-white);
            }
        }

        & .select__choosen {
            background-color: var(--c-light-bg);
            font-weight: 500;
            color: var(--c-primary);
            padding-block: 14px;
            padding-inline: 20px 30px;

            & span {
                font-size: 1.125rem;
                line-height: 1.4;

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }

        & .select__list {
            max-height: 293px;

            &::-webkit-scrollbar {
                background-color: var(--c-true-transparent);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--c-light-bg);
            }
        }

        & .select__item {
            align-items: center;

            &-checkbox-text {
                font-size: 1.125rem;
                line-height: 1.4;

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }

        & .select__icon {
            max-width: 52px;

            @include respond-to(mobile) {
                max-width: 36px;
            }

            & img {
                height: 70%;
                width: 70%;
                object-fit: contain;
            }
        }
    }

    &__select-label {
        margin-block-end: 14px;
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 500;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__content {
        width: calc(60% - 10px);
        height: fit-content;
        padding: 0 20px;
        border-radius: 8px;
        background-color: var(--c-white);

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__item-wrapper {
        display: none;

        &.active {
            display: block;
        }
    }
}

.a11y-mode {
    .content-tabs {
        flex-direction: column;

        &__content {
            width: 100%;
        }

        &__item-wrapper {
            display: block;
        }
    }
}
