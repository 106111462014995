@mixin styled-text-links {
    & a:not(.btn, .contacts__phone, .contacts__email, .investment-hr__link_underline) {
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1px;
        color: var(--c-alt-base-color); // потому что при базовом цвете #213160, цвет ссылок #4786E1

        &:visited {
            color: var(--c-alt-base-color);
        }

        .dropdown.active & {
            color: var(--c-alt-base-color);
        }
    }
}
