.simple-link-block {
    $this: &;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 136px;
    height: 100%;
    background-color: var(--c-light-blue);
    border-radius: 8px;
    position: relative;
    padding: 35px 30px;
    transition: background-color 0.3s;

    @include respond-to(mobile) {
        height: 89px;
        padding: 20px 29px;
    }

    &:hover {
        background-color: var(--c-light-bg);
    }

    &__text {
        max-width: 185px;
    }

    &__subtitle {
        font-size: 1em;
        line-height: 1.4;
        margin-block-start: 30px;

        @include respond-to(mobile) {
            font-size: 0.875em;
            margin-block-start: 20px;
        }
    }

    &__icon {
        margin-block-start: 6px;

        @include respond-to(tablet) {
            margin-block-start: 3px;
        }

        & svg {
            width: 8px;
            height: 16px;

            @include respond-to(mobile) {
                width: 6px;
                height: 12px;
            }
        }
    }

    &__link {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
    }

    &__btn-wrapper {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
    }

    &.with-subtitle {
        display: block;
        min-height: 310px;
    }

    &_bigger {
        min-height: 216px;
        padding: 30px;
        gap: 45px;

        @include respond-to(desktop) {
            padding: 35px 30px;
            gap: 10px;
            height: 100%;
        }

        @include respond-to(tablet) {
            min-height: 182px;
        }

        @include respond-to(mobile) {
            min-height: 195px;
        }

        #{$this}__text {
            font-size: 1.5em;
            max-width: unset;

            @include respond-to(desktop) {
                font-size: 1.25em;
            }

            @include respond-to(mobile) {
                font-size: 0.875em;
            }
        }

        #{$this}__icon {
            margin-block-start: 9px;
        }
    }

    &_plate {
        display: block;

        #{$this}__text {
            max-width: unset;
        }
    }
}

.a11y-mode {
    .simple-link-block {
        &__link {
            outline: 1px solid var(--c-black);
            outline-offset: -3px;
        }

        &__text {
            max-width: unset;
        }

        &__icon {
            display: none;
        }

        &__wrapper {
            display: block;
        }

        &.with-subtitle {
            min-height: unset;
            height: auto;
        }
    }
}
