.card-with-text-and-btn {
    $this: &;

    align-items: end;
    background-color: var(--c-primary);
    border-radius: 8px;
    color: var(--c-white);
    display: flex;
    height: 390px;
    justify-content: end;
    position: relative;

    &__content {
        align-items: start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        inset-block: 30px;
        inset-inline: 30px;
    }

    &__alt-link {
        position: absolute;
        width: 100%;
        height: 100%;
        inset-block-start: 0;
    }

    &__icon {
        width: 67px;
        height: 67px;

        @include respond-to(mobile) {
            width: 50px;
            height: 50px;
        }
    }

    &_light {
        height: unset;
        min-height: 250px;
        color: var(--c-primary);
        background-color: var(--c-light-bg);
        transition: background-color 0.3s;

        @include respond-to(tablet) {
            min-height: 195px;
        }

        &:hover {
            background-color: var(--c-dark-bg);
        }

        #{$this}__content {
            & div {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 140%;
                max-width: 90%;

                @include respond-to(tablet) {
                    font-size: 1rem;
                    max-width: 100%;
                }
            }
        }

        & .btn {
            @include respond-to(tablet) {
                width: 100%;
                font-size: 0.75rem;
                line-height: 1.25;
            }
        }
    }

    &_alt {
        min-height: 208px;
        height: 208px;
        padding: 20px;
        align-items: stretch;

        &:hover {
            background-color: var(--c-primary);

            & div {
                color: var(--c-white);
            }
        }

        #{$this}__content {
            position: static;
        }
    }

    &_horizontal {
        padding: 28px 30px;
        min-height: 110px;
        height: 100%;
        align-items: center;

        @include respond-to(mobile) {
            min-height: 169px;
        }

        #{$this}__content {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: static;
            width: 100%;

            @include respond-to(mobile) {
                flex-direction: column;
                gap: 36px;
                align-items: flex-start;
            }
        }

        & .btn {
            @include respond-to(tablet) {
                width: fit-content;
                font-size: 0.75rem;
                line-height: 1.25;
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    &_image-absolute {
        @include respond-to(tablet) {
            height: unset;
            padding: 30px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        #{$this}__content {
            @include respond-to(tablet) {
                position: static;
                height: 100%;
                gap: 30px;
            }
        }

        & img {
            @include respond-to(tablet) {
                position: absolute;
                inset-block-end: 0;
                inset-inline-end: 0;
            }
        }
    }
}

.a11y-mode {
    .card-with-text-and-btn {
        padding: 20px;
        height: auto;
        justify-content: flex-start;

        &__content {
            position: static;
            gap: 20px;
            width: 100%;

            & > div {
                width: 100%;
            }
        }

        &_alt {
            height: auto;
        }
    }
}
