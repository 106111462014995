.table-default {
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;

    @include respond-to(tablet) {
        min-width: 1380px;
    }

    thead {
        th {
            background-color: var(--c-base-color);
            color: var(--c-white);
            font-weight: 400;
            padding-block: 40px;
            padding-inline: 25px;
            text-align: start;

            &:first-child {
                border-start-start-radius: 8px;

                @include respond-to(tablet) {
                    position: sticky;
                    inset-inline-start: 0;
                    box-shadow: 4px 0 10px var(--c-shadow-table);
                    max-width: 214px;
                    width: 214px;
                }
            }

            &:last-child {
                border-start-end-radius: 8px;
            }
        }
    }

    tbody {
        tr {
            td {
                background-color: var(--c-light-blue);
                padding-block: 25px;
                padding-inline: 25px 45px;

                &:first-child {
                    @include respond-to(tablet) {
                        position: sticky;
                        inset-inline-start: 0;
                        box-shadow: 4px 0 10px var(--c-shadow-table);
                        max-width: 214px;
                        width: 214px;
                    }
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-end-start-radius: 8px;
                    }

                    &:last-child {
                        border-end-end-radius: 8px;
                    }
                }
            }
        }
    }

    &_first-col-light {
        tbody tr td:first-child {
            background-color: var(--c-light-bg);
        }
    }

    &_first-row-light {
        thead tr th {
            background-color: var(--c-light-bg);
            color: var(--c-primary);
        }
    }

    &_bg-white {
        tbody tr td {
            background-color: var(--c-white);
        }
    }

    &_bottom-left-accent {
        tbody tr:last-child td:first-child {
            background-color: var(--c-accent);
            color: #fff;
        }
    }

    &_top-border-radius {
        tbody tr:first-child {
            & td:first-child {
                border-start-start-radius: 8px;
            }

            & td:last-child {
                border-start-end-radius: 8px;
            }
        }
    }

    &_w-100 {
        @include respond-to(tablet) {
            min-width: 100%;
        }
    }

    &_smaller-padding {
        tbody tr td {
            @include respond-to(mobile) {
                padding: 12px;
            }
        }
    }
}
