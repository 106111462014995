.link-with-icon {
    $this: &;

    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: var(--c-light-blue);
    padding: 20px 37px;
    gap: 15px;
    transition: background-color 0.3s ease;

    @include respond-to(tablet) {
        padding: 20px;
    }

    @include respond-to(mobile) {
        padding: 18px 10px;
    }

    &:hover {
        background-color: var(--c-primary);

        #{$this}__icon {
            background-color: var(--c-light-bg);

            & svg > path {
                fill: var(--c-primary);
            }
        }

        #{$this}__text {
            color: var(--c-white);
        }
    }

    &__icon {
        width: 56px;
        min-width: 56px;
        height: 56px;
        min-height: 56px;
        border-radius: 50%;
        background-color: var(--c-dark-bg);
        transition: background-color 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to(tablet) {
            width: 41px;
            min-width: 41px;
            height: 41px;
            min-height: 41px;
        }

        @include respond-to(mobile) {
            width: 34px;
            min-width: 34px;
            height: 34px;
            min-height: 34px;
        }

        & svg {
            @include respond-to(tablet) {
                width: 23px;
                height: 23px;
            }

            @include respond-to(mobile) {
                width: 20px;
                height: 20px;
            }
        }

        & svg > path {
            transition: fill 0.3s ease;
        }
    }

    &__text {
        font-weight: 500;
        color: var(--c-primary);
        font-size: 1.25rem;
        line-height: 1.4;
        transition: color 0.3s ease;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }
}
