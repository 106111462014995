.news {
    $this: &;

    background-color: var(--c-white);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include respond-to(tablet) {
        flex-wrap: nowrap;
        margin-inline-end: -30px;
        overflow: scroll hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @include respond-to(mobile) {
        margin-inline-end: -16px;
    }

    &__card {
        height: 340px;

        @include respond-to(tablet) {
            flex: 0 0 55%;

            .municipalities-cards & {
                flex: 0 0 calc(50% - 25px);
                height: 270px;
            }
        }

        @include respond-to(mobile) {
            flex: 0 0 90%;

            .municipalities-cards & {
                flex: 0 0 calc(100% - 16px);
            }
        }

        &:hover {
            img {
                @include respond-to(desktop-only) {
                    transform: scale(1.1);
                }
            }
        }

        & > a {
            background-color: #f4f5f7;
            border-radius: 10px;
            color: var(--c-white);
            display: flex;
            height: 100%;
            padding: 10px;
            position: relative;
            overflow: hidden;

            & img {
                border-radius: 6px;
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: transform 0.44s ease-out;
            }
        }

        &_big {
            width: calc(43% - 40px / 3);
        }

        &_middle {
            width: calc(33% - 40px / 3);
        }

        &_small {
            width: calc(24% - 40px / 3);
        }

        &_mun2 {
            #{$this}__card-text {
                background: none;
                background-color: var(--c-light-bg);
                color: var(--c-primary);
                inset-block-start: auto;
                inset-block-end: 0;
                inset-inline: 0;
                padding: 30px;
                max-height: fit-content;
                border-radius: 0;
                width: calc(100% + 2px);
                min-height: 116px;
                justify-content: flex-start;

                @include respond-to(tablet) {
                    min-height: 104px;
                }

                @include respond-to(mobile) {
                    min-height: 104px;
                }
            }

            #{$this}__card-label {
                font-weight: 500;

                @include respond-to(mobile) {
                    font-size: 16px;
                }
            }

            & > a {
                padding: 0;
                border-radius: 8px;

                & img {
                    border-radius: 8px;
                }
            }
        }
    }

    &__card-image-container {
        overflow: hidden;
        border-radius: 6px;
        width: 100%;
        height: 100%;
    }

    &__card-text {
        background: linear-gradient(2.29deg, #0b1943 6.65%, rgb(0 0 0 / 3%) 94.15%);
        bottom: 10px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        left: 10px;
        padding: 0 30px 20px;
        position: absolute;
        inset-inline: 10px;
        inset-block: 10px;

        @include respond-to(mobile) {
            font-size: 0.75rem;
        }
    }

    &__card-date {
        font-weight: 500;
        margin-block-end: 10px;

        @include respond-to(mobile) {
            font-size: 0.75rem;
            line-height: 1.25;
        }
    }

    &__card-label {
        .municipalities-cards & {
            font-size: 20px;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 16px;
            }

            @include respond-to(mobile) {
                font-size: 14px;
            }
        }
    }

    &.municipalities-cards:not(:empty) {
        margin-block-start: 65px;

        @include respond-to(tablet) {
            flex-wrap: wrap;
        }

        &:last-child:not(:empty) {
            margin-block-end: 100px !important;

            @include respond-to(mobile) {
                margin-block-end: 80px !important;
            }
        }
    }

    &_alt-mob-view {
        flex-wrap: wrap;
        margin-inline-end: 0;
        overflow: hidden;

        #{$this}__card {
            @include respond-to(tablet) {
                flex-basis: calc((100% - 20px) / 2);
            }

            @include respond-to(mobile) {
                flex-basis: 100%;
            }

            &:nth-child(n + 7) {
                @include respond-to(tablet) {
                    display: none;
                }
            }
        }
    }
}

.a11y-mode {
    .news {
        flex-direction: column;
        margin-inline-end: 0;

        &__card-text {
            background: var(--c-white);
            color: var(--c-black);
            position: static;
            width: 100%;
        }

        &__card {
            width: 100%;
            height: auto;

            & > a {
                background: none;
                outline: 1px solid var(--c-black);
                outline-offset: -3px;

                @include link-sizes-fix;
            }
        }

        &__card-image-container {
            display: none;
        }
    }
}
