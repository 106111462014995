.search-results {
    background-color: var(--c-light-bg);
    padding-block-start: 60px;

    &__form {
        display: flex;
        gap: 14px;
        margin-block-end: 40px;

        @include respond-to(tablet) {
            margin-block-end: 30px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 10px;
        }
    }

    &__input-container {
        position: relative;
        flex: 1;
    }

    &__input {
        border: none;
        border-radius: 26.5px;
        color: var(--c-secondary);
        font-family: Montserrat, sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
        height: 53px;
        padding-inline: 23px;
        width: 100%;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: #bfc7d4;
            font-family: Montserrat, sans-serif;
            font-size: 1.25rem;
            font-weight: 500;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
            font-weight: 400;

            &::placeholder {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }

    &__input-clear-btn {
        align-items: center;
        background-color: var(--c-light-bg);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 45px;
        justify-content: center;
        position: absolute;
        inset-inline-end: 4px;
        inset-block-start: 4px;
        width: 45px;
    }

    &__info {
        padding-inline: 20px;

        @include respond-to(tablet) {
            padding-inline: 15px;
        }

        @include respond-to(mobile) {
            padding-inline-end: 0;
        }
    }

    &__info-counter {
        margin-block-end: 20px;
    }

    &__info-cats {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-block-end: 32px;

        @include respond-to(mobile) {
            flex-wrap: nowrap;
            gap: 20px;
            margin-block: 0 30px;
            margin-inline: 0 -15px;
            overflow: scroll hidden;
            padding-inline-end: 15px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-block-end: 40px;

        @include respond-to(tablet) {
            margin-block-end: 30px;
        }
    }

    &__item {
        border: 1px solid transparent;
        background-color: var(--c-white);
        border-radius: 8px;
        color: var(--c-primary);
        padding-block: 20px;
        padding-inline: 20px;

        &:hover {
            border-color: var(--c-accent);
        }

        @include respond-to(tablet) {
            padding-block: 20px;
            padding-inline: 15px;
        }
    }

    &__item-title {
        color: var(--c-accent);
        font-size: 1.25rem;
        margin-block-end: 20px;

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__item-text {
        margin-block-end: 20px;
    }

    &__item-footer {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__item-cat {
        align-items: center;
        background-color: var(--c-light-blue);
        border-radius: 17.5px;
        color: var(--c-primary);
        cursor: pointer;
        display: flex;
        height: 35px;
        padding-inline: 12px;
    }

    &__item-tag {
        color: var(--c-accent);
        cursor: pointer;
        font-weight: 500;
    }

    &__btn-container {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include respond-to(mobile) {
            align-items: stretch;
        }
    }
}

.a11y-mode {
    .search-results {
        &__input {
            border: 1px solid var(--c-black);
        }
    }
}
