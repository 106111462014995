[dir="rtl"] {
    .slider__prev,
    .slider__next,
    .carousel__prev,
    .carousel__next,
    .simple-link-block__icon,
    .footer-carousel__button-next,
    .footer-carousel__button-prev {
        svg {
            transform: rotateY(180deg);
        }
    }

    .header__nav-menu-btn {
        svg:not(.opened) {
            transform: rotateY(180deg);
        }
    }

    .tile_main {
        img {
            transform: rotateY(180deg);
        }
    }

    .footer-carousel__gradient-next {
        background: linear-gradient(90deg, var(--c-secondary) 50%, transparent);
    }

    .footer-carousel__gradient-prev {
        background: linear-gradient(270deg, var(--c-secondary) 50%, transparent);
    }

    .footer-carousel_light {
        & .footer-carousel__gradient-next {
            background: linear-gradient(90deg, var(--c-white) 50%, transparent);
        }

        & .footer-carousel__gradient-prev {
            background: linear-gradient(270deg, var(--c-white) 50%, transparent);
        }
    }

    .checkbox__field svg {
        transform: translate(50%, -50%);
    }

    input,
    textarea,
    .slider-tooltip,
    .range-slider__union-tooltip-value {
        direction: rtl;
        unicode-bidi: bidi-override;
    }

    .investment-hr__rich-text-block img,
    .promising-sectors-detail__description img {
        float: left;
    }

    .slider_number-controls {
        & .slider__controls ul {
            float: left;

            @include respond-to(mobile) {
                float: none;
            }
        }
    }

    .float-right {
        float: left;
    }

    .perm-krai__gallery {
        float: left;
    }

    .municipality-detail__float-image {
        float: left;
    }

    .main-indicators-card__front-image {
        transform: translateX(50%);
    }

    .swiper-button-prev,
    .swiper-button-next,
    .header-swiper-button-prev,
    .header-swiper-button-next {
        & svg {
            transform: rotate(180deg);
        }
    }

    .preferential-zone__platforms-text:not(:first-child) {
        & .preferential-zone__platforms-par {
            & ul {
                margin-inline-start: calc(42% + 20px);

                @include respond-to(tablet) {
                    margin-inline-start: calc(40% + 20px);
                }

                @include respond-to(mobile) {
                    margin-inline-start: 0;
                }
            }
        }
    }
}
