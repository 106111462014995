.investment-projects {
    &__registry {
        .section-headline {
            margin-block-end: 70px;

            @include respond-to(tablet) {
                margin-block-end: 50px;
            }
        }
    }

    &__download-block {
        margin-block-start: 10px;
        padding: 50px;
        color: var(--c-white);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(tablet) {
            padding: 40px;
            margin-block-start: 20px;
            flex-direction: column;
            gap: 60px;
            align-items: flex-start;
        }

        @include respond-to(mobile) {
            padding: 30px;
        }

        & .btn {
            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    &__download-text-title {
        margin-block-end: 10px;

        @include respond-to(tablet) {
            margin-block-end: 20px;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }

    &__download-text-subtitle {
        line-height: 1.7;
        font-weight: 400;

        @include respond-to(tablet) {
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .investment-projects {
        &__download-text {
            width: 100%;
        }
    }
}
