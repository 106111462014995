.datepicker-select {
    &__title {
        font-weight: 500;
        font-size: 1.125em;
        line-height: 1.4;
        margin-block-end: 20px;

        @include respond-to(tablet) {
            margin-block-end: 10px;
        }

        @include respond-to(mobile) {
            font-size: 0.875em;
        }
    }
}
