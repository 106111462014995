.smi-about-us {
    &__header {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 185px;
        margin-inline-start: auto;
        margin-block-end: 20px;
        cursor: pointer;
        padding: 0;
        border: none;
        color: var(--c-primary);

        & svg {
            margin-inline-start: 10px;
        }
    }

    &__news {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: 100%;
        }
    }

    @include section-indents;
}
