.rating-item {
    $this: &;

    display: flex;
    gap: 30px;
    align-items: stretch;
    padding: 25px 0;

    &:not(:last-child) {
        border-block-end: 1px solid var(--c-transparent-accent);
    }

    @include respond-to(tablet) {
        padding: 20px 0;
    }

    @include respond-to(mobile) {
        flex-direction: column;
        gap: 24px;
    }

    &__plates-wrapper {
        flex: 1 0 250px;
        max-width: 250px;

        @include respond-to(tablet) {
            flex: 1 0 150px;
            max-width: 150px;
        }

        @include respond-to(mobile) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__plate {
        background-color: var(--c-base-color);
        color: var(--c-white);
        display: flex;
        align-items: center;
        padding: 13px;
        border-radius: 8px;
        max-width: fit-content;

        @include respond-to(tablet) {
            padding: 10px;
        }

        &:not(:last-child) {
            margin-block-end: 10px;
        }

        & img {
            display: block;
            margin-inline-end: 17px;
        }

        & span {
            font-size: 1em;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 0.875em;
            }
        }
    }

    &__content {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__text {
        font-size: 1em;
        line-height: 1.4;
        margin-block-end: 10px;
        flex-grow: 1;

        @include respond-to(mobile) {
            font-size: 0.875em;
        }
    }

    &__link {
        display: block;
        max-width: fit-content;
        padding-block-end: 4px;
        border-block-end: 1px solid var(--c-alt-base-color);
        font-size: 0.75em;
        line-height: 1.4;
        font-style: italic;
        color: var(--c-alt-base-color);
    }

    &_alt {
        padding: 0;

        &:not(:last-child) {
            border-block-end: none;
            margin-block-end: 75px;

            @include respond-to(tablet) {
                border-block-end: 1px solid var(--c-white-border);
                margin-block-end: 25px;
                padding-block-end: 25px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
                padding-block-end: 30px;
            }
        }

        &:last-child {
            @include respond-to(tablet) {
                border-block-end: 1px solid var(--c-white-border);
                margin-block-end: 150px;
                padding-block-end: 25px;

                @include respond-to(mobile) {
                    margin-block-end: 110px;
                    padding-block-end: 30px;
                }
            }
        }

        #{$this}__text {
            color: var(--c-white);
        }

        #{$this}__plate {
            background-color: var(--c-true-transparent);
            display: flex;
            gap: 10px;
            align-items: baseline;
            padding: 0;

            @include respond-to(tablet) {
                display: block;
            }

            & span:first-child {
                font-size: 40px;

                @include respond-to(tablet) {
                    font-size: 30px;
                }

                @include respond-to(mobile) {
                    font-size: 16px;
                }
            }

            & span {
                font-size: 20px;

                @include respond-to(tablet) {
                    font-size: 30px;
                }

                @include respond-to(mobile) {
                    font-size: 16px;
                }
            }
        }

        #{$this}__link {
            @include respond-to(mobile) {
                border-block-end: 0;
            }
        }
    }
}

.a11y-mode {
    .rating-item_alt {
        & .rating-item__text,
        & .rating-item__plate {
            color: var(--c-a11y-accent-color);
        }
    }
}
