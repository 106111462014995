.media-content {
    $this: &;

    & > p:last-child {
        text-align: center;
    }

    &__tabs {
        display: flex;
        gap: 15px;

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 10px;
        }
    }

    &__tags {
        display: flex;
        align-items: center;
        gap: 22px;

        @include respond-to(mobile) {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        &-title {
            font-weight: 500;
            font-size: 1.25em;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-weight: 400;
                font-size: 0.875em;
            }
        }

        &-list {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            @include respond-to(mobile) {
                gap: 8px;
            }
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;

        &_photo,
        &_video {
            gap: 20px;
        }

        &_presentation {
            flex-direction: column;
            gap: 40px;

            @include respond-to(mobile) {
                gap: 50px;
            }

            #{$this}__item {
                width: 100%;

                @include respond-to(tablet) {
                    width: 100%;
                }
            }
        }
    }

    &__item {
        position: relative;
        width: calc((100% - 40px) / 3);

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
        }

        &-image {
            height: 250px;
            cursor: pointer;
            aspect-ratio: 1024 / 559;

            @include respond-to(tablet) {
                height: 200px;
            }

            & img {
                object-fit: cover;
                max-width: 100%;
                width: 100%;
                height: 100%;
            }
        }

        #{$this}__tags-list {
            position: absolute;
            inset-inline: 20px;
            inset-block-end: 20px;
        }

        &-card {
            min-height: 138px;
            padding: 25px 20px;
            background-color: var(--c-light-bg);
            cursor: pointer;
            flex: 1;
            color: var(--c-primary);

            @include respond-to(tablet) {
                min-height: 130px;
            }
        }

        &-title {
            margin-block-end: 20px;

            @include respond-to(tablet) {
                margin-block-end: 10px;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
                font-weight: 500;
                font-size: 16px;
            }
        }

        &-subtitle {
            @include respond-to(tablet) {
                font-size: 0.875em;
                line-height: 1.4;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 65%;
            gap: 70px;

            @include respond-to(tablet) {
                gap: 50px;
            }

            @include respond-to(mobile) {
                max-width: unset;
                width: 100%;
                gap: 30px;
            }
        }

        &-date {
            font-weight: 500;
            font-size: 0.875em;
            line-height: 1.2;
            letter-spacing: 0.13em;
            color: var(--c-accent);

            @include respond-to(mobile) {
                font-size: 0.75rem;
                line-height: 1.25;
            }
        }

        &_video {
            #{$this}__item-content {
                display: flex;
                flex-direction: column;
            }
        }

        &_presentation {
            #{$this}__item-content {
                display: flex;
                flex-direction: row;
                padding-block-end: 40px;
                border-block-end: 1px solid var(--c-transparent-accent);
                gap: 40px;
                min-height: 332px;
                border-radius: 0;
                overflow: visible;

                @include respond-to(tablet) {
                    gap: 20px;
                    min-height: 306px;
                }

                @include respond-to(mobile) {
                    padding-block-end: 50px;
                    flex-direction: column;
                }

                #{$this}__item-image {
                    height: auto;
                    max-height: 292px;
                    max-width: calc(35% - 40px);
                    border-radius: 8px;
                    overflow: hidden;
                    flex: 1;

                    @include respond-to(tablet) {
                        height: auto;
                        max-height: 160px;
                        max-width: calc(35% - 20px);
                    }

                    @include respond-to(mobile) {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    &__video-container {
        height: 250px;
        width: 100%;
        cursor: pointer;
        position: relative;

        @include respond-to(tablet) {
            height: 200px;
        }

        & button {
            width: 65px;
            height: 65px;

            & svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__video-control {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-color: var(--c-white);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
        display: flex;
        padding: 0;

        & svg {
            width: 18px;
            height: 18px;
        }

        @include respond-to(mobile) {
            width: 65px;
            height: 65px;

            & svg {
                width: 13px;
                height: 13px;
            }
        }
    }

    &__carousel {
        width: 914px;
        height: 600px;
        margin: auto;
        z-index: 2;
    }

    & .btn {
        width: fit-content;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &_mediacontent2 {
        #{$this}__items {
            @include respond-to(mobile) {
                gap: 40px;
            }
        }

        #{$this}__item {
            width: calc(50% - 10px);

            @include respond-to(mobile) {
                width: 100%;
            }

            &_presentation {
                width: 100%;

                #{$this}__item-content {
                    background-color: var(--c-true-transparent);
                    min-height: unset;
                    padding-block-end: 0;
                    border-block-end: none;
                }
            }

            &_video {
                #{$this}__video-container {
                    height: auto;
                    width: 48.5%;

                    @include respond-to(tablet) {
                        width: 100%;
                    }
                }

                #{$this}__item-card {
                    height: auto;
                    background-color: var(--c-light-blue);
                }

                #{$this}__item-image {
                    width: 100%;
                    height: 250px;
                    aspect-ratio: unset;
                }
            }
        }

        #{$this}__item-content {
            background-color: var(--c-light-blue);
            flex-direction: row;

            @include respond-to(tablet) {
                flex-direction: column;
            }
        }

        #{$this}__item-image {
            width: 51.5%;

            @include respond-to(tablet) {
                width: 100%;
            }
        }

        #{$this}__text-wrapper {
            padding: 20px;
            display: flex;
            flex-direction: column;

            @include respond-to(tablet) {
                height: 100%;
            }
        }

        #{$this}__item-title {
            color: var(--c-primary);
            font-size: 1rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 0.875rem;
            }
        }

        #{$this}__tags-list {
            padding-block-start: 12px;
            position: static;

            &:not(:only-child) {
                flex-grow: 1;
                align-items: flex-end;
            }

            @include respond-to(tablet) {
                flex-grow: 1;
                align-items: flex-end;
                padding-block-start: 46px;
            }
        }
    }

    @include section-indents;
}
