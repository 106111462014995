.form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    &__agreement {
        margin-block-start: 10px;

        & .checkbox__text {
            @include respond-to(mobile) {
                font-size: 0.75rem;
            }
        }

        & svg path {
            stroke: var(--c-alt-base-color);
        }
    }
}
