@mixin section-indents {
    & .section-wrapper {
        margin-block: 100px;

        @include respond-to(mobile) {
            margin-block: 80px;
        }

        &.bg-light-blue,
        &.bg-light-grey,
        &.bg-secondary {
            padding-block: 100px;
            margin-block: 0;

            @include respond-to(mobile) {
                padding-block: 80px;
                margin-block: 0;
            }
        }

        &.bg-primary:last-child {
            margin-block-end: 0;
        }

        &.bg-section {
            margin-block: 0;
        }
    }
}
