$placeholder-pseudo-els: (
    "::placeholder",
    "::-webkit-input-placeholder",
    "::-moz-placeholder",
    ":-ms-input-placeholder"
);

.input {
    $this: &;

    position: relative;

    &__field {
        border: 1px solid var(--c-alt-base-color);
        border-radius: 100px;
        color: var(--c-primary);
        padding: 15px 30px;
        background-color: transparent;
        transition: 0.3s color, 0.3s border-color;
        width: 100%;
        font-family: Montserrat, sans-serif;
        font-size: 1rem;
        line-height: 1.4;
        outline: none;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        @each $pseudo-el in $placeholder-pseudo-els {
            &#{$pseudo-el} {
                color: rgb(33 49 96 / 48%);
                opacity: 1;
                transition: 0.15s opacity, 0.3s color;
            }
        }

        &:hover:not(:disabled) {
            background-color: var(--c-white);
        }

        &:focus,
        :focus-visible {
            background-color: var(--c-white);

            @each $pseudo-el in $placeholder-pseudo-els {
                &#{$pseudo-el} {
                    opacity: 0;
                }
            }
        }

        .subscription_footer & {
            @each $pseudo-el in $placeholder-pseudo-els {
                &#{$pseudo-el} {
                    color: var(--c-white);
                    opacity: 0.4;
                    transition: 0.15s opacity, 0.3s color;
                }
            }

            &:hover:not(:disabled) {
                background-color: var(--c-true-transparent);
            }

            &:focus,
            :focus-visible {
                background-color: var(--c-true-transparent);

                @each $pseudo-el in $placeholder-pseudo-els {
                    &#{$pseudo-el} {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__error {
        display: none;
        pointer-events: none;
    }

    &.show-error {
        #{$this}__error {
            display: block;
        }

        #{$this}__field {
            border-color: var(--c-error);
        }
    }

    &_search {
        border-radius: 8px;
        display: flex;
        padding: 27px 38px 26px 40px;
        background-color: var(--c-light-blue);
        border: 1px solid var(--c-light-blue);

        @include respond-to(mobile) {
            padding: 19px;
        }

        @each $pseudo-el in $placeholder-pseudo-els {
            &#{$pseudo-el} {
                color: var(--c-dark-bg);
            }
        }

        &:hover,
        &:focus,
        &:active,
        &:focus-within {
            border-color: var(--c-accent);
            color: var(--c-dark-bg);
        }

        #{$this}__field {
            border: none;
            border-radius: unset;
            padding: 0;
            font-size: 1.125rem;
            font-weight: 500;
            background-color: var(--c-light-blue);

            &:hover:not(:disabled) {
                background-color: var(--c-light-blue);
            }

            &:focus,
            :focus-visible {
                background-color: var(--c-light-blue);
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &__search-btn {
        padding: 0;
        display: flex;

        & svg {
            height: 22px;
            width: 23px;

            @include respond-to(mobile) {
                height: 19px;
                width: 19px;
            }
        }
    }

    &_range {
        #{$this}__field {
            border: 2px solid var(--c-dark-blue);
            border-radius: 5px;
            padding: 15px 20px;
            background-color: var(--c-white);
            color: var(--c-input-range-text);
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.4;

            &:focus {
                color: var(--c-primary);
            }

            &:hover:not(:disabled),
            &:focus {
                border-color: var(--c-accent);
            }
        }
    }

    &_number {
        #{$this}__field {
            border: 1px solid var(--c-blue-bg);
            border-radius: 8px;
            padding: 18px 20px 17px;
            background-color: var(--c-blue-bg);
            color: var(--c-notification);
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.4;

            &:hover:not(:disabled),
            &:focus {
                background-color: var(--c-white);
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }
        }
    }
}

.a11y-mode {
    .input {
        @each $pseudo-el in $placeholder-pseudo-els {
            &#{$pseudo-el} {
                color: var(--c-black);
            }
        }

        &__field {
            color: var(--c-black);
            background: var(--c-white);
            border-color: var(--c-black);

            @each $pseudo-el in $placeholder-pseudo-els {
                &#{$pseudo-el} {
                    color: inherit;
                }
            }
        }

        &_search {
            border: 1px solid var(--c-black);
        }
    }
}
