.agency {
    $this: &;

    &__header {
        align-items: start;
        display: flex;
        justify-content: space-between;

        @include respond-to(tablet) {
            flex-direction: column;
        }
    }

    &__header-left {
        width: 45%;

        p {
            font-size: 1.125rem;
            line-height: 1.65;
            margin-block-end: 25px;

            &:last-child {
                margin-block-end: 0;
            }
        }

        &_single {
            margin-block-end: 65px;
            width: 65%;
        }

        @include respond-to(tablet) {
            margin-block-end: 60px;
            width: 100%;

            p {
                font-size: 1rem;
                line-height: 1.4;
            }
        }

        @include respond-to(mobile) {
            margin-block-end: 50px;

            p {
                font-size: 0.875rem;
            }
        }
    }

    &__header-right {
        width: 40%;

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__tiles {
        .tile {
            align-self: stretch;
            height: auto;
            min-height: 320px;

            &__view {
                flex-direction: column;
                padding-block-start: 126px;
            }

            &_main {
                .tile__view {
                    padding-block-start: 40px;
                }
            }
        }

        @include respond-to(tablet) {
            .tile_quarter {
                width: calc((100% - 20px) / 2);
            }
        }

        @include respond-to(mobile) {
            .tile {
                min-height: 240px;
                width: 100%;

                &__view {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    &__contacts {
        background-color: var(--c-primary);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding-block: 50px 60px;
        padding-inline: 70px 100px;
        position: relative;

        @include respond-to(mobile) {
            padding: 40px 20px;
        }

        & img {
            height: 100%;
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 0;

            @include respond-to(tablet) {
                inset-inline: 25% unset;
            }
        }

        &_alt {
            background-color: var(--c-light-bg);
        }
    }

    &__contacts-inner {
        color: var(--c-white);
        width: 43%;
        z-index: 1; // перекрыть картинку

        @include respond-to(tablet) {
            width: 100%;
        }

        & .h4 {
            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        &_alt {
            color: var(--c-primary);

            #{$this}__contacts-link {
                color: var(--c-primary);

                &:hover {
                    color: var(--c-accent);
                }
            }

            #{$this}__contacts-title {
                color: var(--c-accent);
            }
        }
    }

    &__contacts-links-wrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 40px;
        gap: 20px;

        @include respond-to(tablet) {
            margin-block-end: 48px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            margin-block-end: 0;
            gap: 0;

            & > div {
                margin-block-end: 30px;
            }
        }
    }

    &__contacts-title {
        color: var(--c-dark-bg);
        display: block;
        margin-block-end: 10px;
        font-style: italic;

        @include respond-to(mobile) {
            font-size: 0.75rem;
            margin-block-end: 5px;
        }
    }

    &__contacts-link {
        color: var(--c-white);
        white-space: nowrap;

        &:hover {
            color: var(--c-dark-blue);
        }
    }

    &__members {
        display: flex;
        margin-inline-start: -20px;
        transform: translateX(0);
        transition: transform 0.8s ease-in-out;

        &::-webkit-scrollbar {
            @include respond-to(tablet) {
                display: none;
            }
        }

        @include respond-to(tablet) {
            gap: 20px;
            margin-inline: 0 -30px;
            overflow: scroll hidden;
            transform: translateX(0) !important;
        }

        @include respond-to(mobile) {
            margin-inline: 0 -16px;
        }

        & > div {
            flex: 0 0 calc(25% - 20px);
            margin-inline-start: 20px;

            @include respond-to(tablet) {
                flex: 0 0 40%;
                margin-inline-start: 0;
            }

            @include respond-to(mobile) {
                flex: 0 0 70%;
            }

            &:last-child {
                @include respond-to(tablet) {
                    margin-inline-end: 30px;
                }

                @include respond-to(mobile) {
                    margin-inline-end: 16px;
                }
            }
        }

        &_alt {
            flex-wrap: wrap;
            gap: 20px;
            margin-inline-start: 0;
            transform: none;

            @include respond-to(tablet) {
                margin-inline-end: 0;
            }

            @include respond-to(mobile) {
                margin-inline: 0;
                flex-wrap: nowrap;
                overflow-x: scroll;
            }

            & > div {
                flex: 0 0 calc(50% - 10px);
                margin-inline-start: 0;

                @include respond-to(tablet) {
                    flex: 0 0 calc(33.3% - (40px / 3));
                }

                @include respond-to(mobile) {
                    flex: 0 0 252px;
                }
            }
        }
    }

    &__carousel-buttons {
        display: flex;
        justify-content: center;
        margin-block-end: 80px;
        margin-block-start: 20px;

        & .carousel-buttons {
            margin-inline-start: 0;
        }
    }

    &__team-wrapper {
        margin-block-start: 100px;

        @include respond-to(mobile) {
            margin-block-start: 80px;
        }
    }

    & .main-indicators-card {
        @include respond-to(mobile) {
            padding: 20px;
        }

        &__subtitle {
            @include respond-to(mobile) {
                font-size: 0.75rem;
                line-height: 1.2;
            }

            & ul li {
                @include respond-to(mobile) {
                    padding-inline-start: 10px;
                }

                &::before {
                    @include respond-to(mobile) {
                        inset-inline-start: 0;
                    }
                }
            }
        }
    }

    @include section-indents;

    @include anchor-fix;
}

.a11y-mode {
    .agency {
        &__header {
            flex-direction: column;
            gap: 40px;
        }

        &__header-left {
            width: 100%;
        }

        &__header-right {
            background: none;
            color: var(--c-black);
            width: 100%;

            & > img {
                display: none;
            }
        }

        &__tiles {
            .tile,
            .tiles__container {
                flex-direction: column;
                width: 100%;
            }
        }

        &__members {
            flex-direction: column;
            gap: 20px;
        }

        &__contacts-links-wrapper {
            flex-direction: column;
        }

        &__contacts-link {
            white-space: normal;
        }
    }
}
