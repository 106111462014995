.indicators {
    $this: &;

    color: var(--c-white);
    padding-block-start: 100px;
    position: relative;
    overflow: hidden;

    @include respond-to(mobile) {
        padding-block-start: 80px;
    }

    &_gradient_top {
        background: radial-gradient(
                circle at calc(50% - 730px) 250px,
                var(--c-half-transparent-blue) 50px,
                var(--c-true-transparent) 400px
            ),
            var(--c-secondary);

        & + .bg-primary {
            margin-block-start: 0 !important;
        }
    }

    &_gradient_bottom {
        background: radial-gradient(
                circle at calc(50% + 730px) 900px,
                var(--c-half-transparent-blue) 0,
                var(--c-true-transparent) 400px
            ),
            var(--c-secondary);
        padding-block-end: 100px;

        @include respond-to(mobile) {
            padding-block-end: 80px;
        }
    }

    &__carousel {
        margin-block-end: 70px;
        position: relative;
        z-index: 1;
    }

    &__content {
        position: relative;
        min-height: 700px;
    }

    &__headline {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-block-end: 40px;
        position: relative;
        z-index: 1;

        @include respond-to(tablet) {
            align-items: end;
            margin-block-end: 65px;
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }

        & h2 {
            width: 80%;

            @include respond-to(tablet) {
                font-size: 2.5rem;
                width: 50%;
            }

            @include respond-to(mobile) {
                font-size: 1.5rem;
                width: auto;
            }
        }

        & .btn {
            @include respond-to(mobile) {
                display: none;
            }
        }

        &_alt {
            margin-block-end: 50px;

            @include respond-to(tablet) {
                margin-block-end: 40px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
            }
        }
    }

    &__data {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        position: relative;
        z-index: 1;
        width: 50%;

        & > div {
            width: calc(50% - 10px);
            font-size: 8rem;
            font-weight: 700;
            line-height: 1;

            &:first-child {
                width: 100%;

                & h4 {
                    width: 50%;

                    @include respond-to(tablet) {
                        width: 100%;
                    }
                }
            }

            & h4 {
                margin-block-end: 30px;
            }
        }

        @include respond-to(tablet) {
            flex-flow: row nowrap;
            gap: 40px;
            overflow: scroll hidden;
            width: auto;

            > div {
                font-size: 3.125rem;
                flex: 0 0 36%;

                div {
                    margin-block-end: 10px;
                }

                h4 {
                    font-size: 1rem;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include respond-to(mobile) {
            gap: 42px;
            margin-inline-end: -16px;

            > div {
                flex: 0 0 66%;
                font-size: 2.1875rem;

                h4 {
                    font-size: 0.875rem;
                    font-weight: 400;
                }
            }
        }
    }

    &__bg-map {
        position: absolute;
        inset-inline: 40px;
        inset-block-start: 10%;
        display: flex;

        @include respond-to(tablet) {
            inset-block-start: -40px;
            inset-inline-start: -12%;
        }

        @include respond-to(mobile) {
            inset-inline-start: -109%;
            inset-block-start: -100px;
        }

        & img {
            width: 100%;
            object-fit: cover;

            @include respond-to(tablet) {
                width: auto;
            }
        }
    }

    &__bg-cities {
        position: absolute;
        inset-block-start: 10%;
        inset-inline-start: 50%;

        @include respond-to(tablet) {
            inset-inline-start: 0;
            inset-block-end: 0;
            position: relative;
            z-index: 1;
        }

        & img {
            max-height: 630px;

            @include respond-to(tablet) {
                height: 550px;
            }

            @include respond-to(tablet) {
                height: 350px;
            }
        }
    }

    &__detail-btn {
        display: none;
        margin-block-start: 50px;
        position: relative;
        z-index: 1;

        @include respond-to(mobile) {
            display: flex;
        }
    }

    &__slider {
        overflow: hidden;
        position: relative;
        z-index: 1; // перекрыть картинку ниже с абсолютным позиционированием
    }

    & .container {
        overflow: visible; // TODO: экстренное решение
    }
}

.a11y-mode {
    .indicators {
        background: var(--c-white);
        color: var(--c-black);
        padding-block-start: 0;
        margin-block-start: 100px;

        @include respond-to(mobile) {
            margin-block-start: 80px;
        }

        &__slider {
            .glide__bullets {
                display: none;
            }

            .glide__slides {
                width: 100% !important;
                flex-direction: column;
                gap: 20px;
            }
        }

        &__bg-cities,
        &__bg-map {
            display: none;
        }

        &__data {
            width: 100%;
            flex-direction: column;

            & > div {
                width: 100%;
            }
        }

        &__headline {
            & h2 {
                width: 100%;
            }
        }
    }

    .swiper-wrapper {
        flex-direction: column;
        gap: 20px;
        transform: none !important;
    }

    .swiper-slide {
        width: 100% !important;
    }

    .swiper-pagination {
        display: none;
    }
}
