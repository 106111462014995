.investment-offers {
    &__title {
        margin-block-end: 60px;

        @include respond-to(tablet) {
            margin-block-end: 40px;
        }
    }

    @include section-indents;
}
