.tiles-with-image {
    $this: &;

    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background-color: var(--c-light-bg);

    @include respond-to(tablet) {
        background-color: var(--c-white);
    }

    &__image-container {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%));
        }

        @include respond-to(tablet) {
            display: none;
        }
    }

    &__items-list {
        display: flex;
        flex-wrap: wrap;

        @include respond-to(tablet) {
            gap: 20px;
        }
    }

    &__item {
        z-index: 1; // чтобы перекрыть фоновую картинку
        height: 283px;
        border-block-end: 1px solid var(--c-dark-bg);
        width: calc(100% / 3);

        &:last-child {
            flex-grow: 1;
        }

        @include respond-to(desktop) {
            height: 250px;
        }

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
            min-height: 200px;
            height: unset;
            border: none;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &:not(:nth-child(3n)) {
            border-inline-end: 1px solid var(--c-dark-bg);

            @include respond-to(tablet) {
                border: none;
            }
        }

        &:empty {
            background-color: var(--c-white);
            border: 2px solid var(--c-white);
        }

        & .alt-tile {
            background-color: var(--c-true-transparent);
        }
    }

    &_separated {
        background-color: var(--c-true-transparent);

        #{$this}__image-container {
            display: none;
        }

        #{$this}__items-list {
            gap: 20px;
        }

        #{$this}__item {
            border: none;
            width: calc((100% - 40px) / 3);

            &:not(:nth-child(3n)) {
                border: none;
            }

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }
}

.a11y-mode {
    .tiles-with-image {
        &__image-container {
            display: none;
        }

        &__items-list {
            flex-direction: column;
        }

        &__item {
            width: 100%;
            height: auto;
            min-height: unset;
        }

        &_separated .alt-tile__image-container::after {
            background: var(--c-white);
        }
    }
}
