.carousel-item {
    $this: &;

    border-radius: 8px;
    overflow: hidden;
    color: var(--c-white);
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    position: relative;

    &:hover {
        #{$this}__front-image {
            display: none;
        }

        #{$this}__hover-image {
            display: block;
        }

        #{$this}__header {
            display: block;
        }

        #{$this}__footer-title {
            display: none;

            &_main {
                display: block;
            }
        }

        #{$this}__footer-subtitle {
            display: none;

            &_main {
                display: block;
            }
        }
    }

    &__image {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(50%);
    }

    &__front-image {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
    }

    &__hover-image {
        display: none;
        filter: brightness(50%);
        height: 100%;
        object-fit: cover;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
    }

    &__text-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        inset-block: 30px;
        inset-inline: 20px;
    }

    &__header {
        max-width: 57%;
        display: none;

        &-title {
            margin-block-end: 15px;
        }

        @include respond-to(mobile) {
            max-width: unset;
        }
    }

    &__footer {
        &-title {
            margin-block-end: 20px;
            display: block;

            &_main {
                display: none;
            }

            @include respond-to(tablet) {
                font-size: 1em;
            }
        }

        &-subtitle {
            display: block;

            &_main {
                display: none;
                min-height: 66px;
            }
        }
    }

    &.no-text-changing {
        #{$this}__header {
            display: block;
        }

        #{$this}__text-content {
            justify-content: space-between;
        }

        #{$this}__footer-title_main {
            display: block;
        }

        #{$this}__footer-subtitle_main {
            display: block;
            min-height: unset;
        }
    }

    .swiper-slide & {
        display: block;
        height: 572px;

        @include respond-to(tablet) {
            height: 500px;
        }

        @include respond-to(mobile) {
            height: 400px;
        }
    }
}
