.business-council {
    $this: &;

    &__about {
        & .tiles__container {
            width: 100%;
            align-items: unset;

            @include respond-to(mobile) {
                flex-wrap: nowrap;
                overflow: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        & .tile {
            min-height: 340px;
            height: unset;

            @include respond-to(tablet) {
                width: calc(50% - 10px);
                min-height: 350px;
                height: 350px;

                &:last-child {
                    flex-grow: 1;
                }
            }

            @include respond-to(mobile) {
                min-width: 252px;
                min-height: 350px;
                height: unset;
            }
        }

        & .tile__view {
            flex-direction: column;
            padding-block-start: 120px;
        }

        &_alt {
            #{$this}__about-header {
                flex-direction: column;
                gap: 60px;

                @include respond-to(tablet) {
                    gap: 30px;
                    margin-block-end: 90px;
                }

                @include respond-to(mobile) {
                    gap: 20px;
                    margin-block-end: 50px;
                }
            }

            #{$this}__about-title {
                width: 100%;
            }

            #{$this}__about-description {
                width: 100%;
            }
        }
    }

    &__about-header {
        display: flex;
        justify-content: space-between;
        margin-block-end: 80px;

        @include respond-to(desktop) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__about-title {
        width: 52%;

        @include respond-to(desktop) {
            width: 100%;
            margin-block-end: 30px;
            font-size: 2.5rem;
            line-height: 1;
        }

        @include respond-to(mobile) {
            margin-block-end: 20px;
            font-size: 1.375rem;
            line-height: 1.2;
        }
    }

    &__about-description {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.65;
        width: 41%;

        @include respond-to(desktop) {
            width: 100%;
        }

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(mobile) {
            gap: 10px;
        }
    }

    &__text-card {
        width: calc(50% - 10px);
        background-color: var(--c-light-blue);
        padding: 36px 40px;
        border-radius: 8px;
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 500;
        order: 2;

        @include respond-to(tablet) {
            font-size: 0.875rem;
            padding: 20px;
        }

        @include respond-to(mobile) {
            width: 100%;
            order: unset;
        }

        &-title {
            color: var(--c-alt-base-color);
            margin-block-end: 20px;
            font-size: 1rem;
            font-weight: 400;

            @include respond-to(tablet) {
                font-size: 0.875rem;
            }
        }

        &_gray {
            background-color: var(--c-light-bg);
            order: 1;

            @include respond-to(mobile) {
                order: unset;
            }
        }
    }

    // alt
    &__about-tasks {
        width: 80%;

        @include respond-to(tablet) {
            width: 100%;
            display: flex;
            column-gap: 20px;
            flex-flow: row wrap;
        }
    }

    &__about-task {
        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &:last-child {
            flex: 1;
        }

        &:not(:last-child) .text-with-counter__header {
            @include respond-to(tablet) {
                margin-block-end: 50px;
            }

            @include respond-to(mobile) {
                margin-block-end: 40px;
            }
        }

        &:last-child .text-with-counter__header {
            margin-block-end: 0;
        }

        & .text-with-counter__header {
            gap: 50px;

            @include respond-to(tablet) {
                gap: 30px;
            }

            @include respond-to(mobile) {
                gap: 20px;
            }
        }
    }

    &__activities {
        display: flex;
        flex-wrap: wrap;
        gap: 40px 20px;

        & > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: calc((100% - 60px) / 4);

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
                height: 412px;
            }

            @include respond-to(mobile) {
                min-width: 252px;
                width: 252px;
                height: 315px;
            }
        }

        h4 {
            font-weight: 700;
        }

        & .person-card__no-image svg {
            @include respond-to(mobile) {
                margin-block-start: 80px;
            }
        }
    }

    &__members {
        & .tiles__container {
            @include respond-to(tablet) {
                flex-wrap: nowrap;
                overflow: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        & .tile {
            height: 330px;
            min-height: unset;

            @include respond-to(tablet) {
                min-width: 330px;
            }

            @include respond-to(mobile) {
                min-width: 252px;
                height: 220px;
            }
        }

        & .contacts__avatar-info {
            max-width: unset;
        }
    }

    // alt
    &__members-list {
        display: flex;
        flex-flow: row wrap;
        column-gap: 150px;
    }

    &__member {
        width: calc((100% - 150px) / 2);

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    & .styled-lists ul:not(.tabs, .tab-panes) li::before {
        inset-block-start: 0.6rem;

        @include respond-to(mobile) {
            inset-block-start: 0.2rem;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .business-council {
        & .tile {
            min-height: fit-content;
            height: auto;
            width: 100%;
        }

        &__about-tasks {
            width: 100%;
            flex-direction: column;
        }

        &__about-header {
            flex-direction: column;
            gap: 20px;
        }

        &__about-title,
        &__about-description,
        &__about-task {
            width: 100%;
        }

        &__cards-wrapper {
            flex-direction: column;
        }

        &__text-card {
            order: unset;
            width: 100%;
            padding: 0;

            &_gray {
                padding-block: 36px;
            }
        }

        &__activities {
            flex-direction: column;
            margin-inline-start: 0;
            width: 100%;

            & > div {
                min-height: unset;
                height: auto;
                width: 100%;
            }
        }
    }
}
