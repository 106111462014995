.investment-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--c-light-bg);
    border-radius: 8px;
    padding: 30px;
    color: var(--c-primary);
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;

    @include respond-to(mobile) {
        padding: 20px;
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-block-end: 60px;

        & svg {
            margin-inline-end: 15px;
            width: 36px;
            height: 43px;

            @include respond-to(mobile) {
                width: 31px;
                height: 37px;
            }

            & path {
                transition: fill 0.3s;
            }
        }
    }

    &__label {
        @include respond-to(mobile) {
            font-size: 0.875em;
        }
    }

    &__information {
        position: relative;

        &-header {
            margin-block-end: 20px;
            color: var(--c-accent);
            font-weight: 400;
            font-size: 1em;
            line-height: 140%;
            transition: color 0.3s;

            @include respond-to(tablet) {
                margin-block-end: 10px;
            }

            @include respond-to(mobile) {
                font-size: 0.875em;
            }
        }

        &-text {
            font-weight: 500;
            font-size: 1.25em;
            line-height: 1.4;

            &.hidden {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @include respond-to(tablet) {
                font-size: 1em;
            }

            @include respond-to(mobile) {
                font-size: 0.875em;
            }
        }

        & .tooltip-hint {
            inset-block-start: 100%;
        }

        &:hover {
            .tooltip-hint {
                display: block;
            }
        }
    }

    &:hover {
        color: var(--c-white);
        background-color: var(--c-base-color);

        & .investment-document__icon path {
            fill: var(--c-white);
        }

        & .investment-document__information-header {
            color: var(--c-white);
        }
    }
}

.a11y-mode {
    .investment-document {
        padding: 0;

        &:hover {
            background-color: var(--c-a11y-background-color);

            & .investment-document__icon path {
                fill: var(--c-a11y-accent-color);
            }

            & .investment-document__information-header,
            & .investment-document__label,
            & .investment-document__information-text {
                color: var(--c-a11y-accent-color);
            }
        }

        &__icon {
            & svg path {
                fill: var(--c-black);
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .investment-document {
        &__icon {
            display: none;
        }
    }
}
