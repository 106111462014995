.indicator-tile {
    background-color: var(--c-white);
    border-radius: 8px;
    padding: 40px;
    color: var(--c-primary);
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-height: 280px;

    @include respond-to(tablet) {
        padding: 30px;
        gap: 76px;
        min-height: 246px;
    }

    @include respond-to(mobile) {
        padding: 20px;
        gap: 10px;
        min-height: unset;
    }

    &__header {
        display: flex;
        align-items: baseline;
        gap: 10px;
        flex-grow: 1;

        @include respond-to(mobile) {
            gap: 5px;
        }
    }

    &__value {
        font-size: 3.75rem;
        line-height: 1.1;
        font-weight: 600;
        white-space: nowrap;

        @include respond-to(tablet) {
            font-size: 2.5rem;
            line-height: 1;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            line-height: 1.4;
            font-weight: 500;
        }
    }

    &__value-text {
        font-size: 1.375rem;
        line-height: 1.4;
        font-weight: 500;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__unit {
        font-size: 1.25rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    &__text {
        font-size: 1.25rem;
        line-height: 1.4;
        min-height: 56px;
        font-weight: 500;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            font-weight: 400;
            min-height: unset;
        }
    }
}
