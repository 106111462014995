.investment-proposal-detail {
    $this: &;

    &__filter-values {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
            gap: 10px;
        }

        &_alt {
            @include respond-to(tablet) {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px 10px;
            }

            @include respond-to(mobile) {
                grid-template-columns: 1fr;
                gap: 10px;
            }

            #{$this}__filter-values-block {
                @include respond-to(tablet) {
                    font-size: 0.875rem;
                }

                & > span:first-of-type {
                    font-weight: 600;

                    @include respond-to(tablet) {
                        font-weight: revert;
                    }
                }
            }
        }
    }

    &__filter-values-block {
        background-color: var(--c-light-blue);
        padding: 28px 32px;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 140%;
        border-radius: 8px;

        @include respond-to(tablet) {
            font-size: 1rem;
            padding: 24px 16px;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        & > span:first-of-type {
            font-weight: 600;
        }
    }

    &__description {
        display: flex;

        @include respond-to(tablet) {
            flex-direction: column;
        }
    }

    &__description-title {
        margin-block-end: 30px;

        @include respond-to(tablet) {
            margin-block-end: 20px;
        }
    }

    &__description-image {
        width: 100%;
        max-width: calc((100% - 20px) / 2);
        height: 100%;
        border-radius: 8px;
        margin-inline-end: 20px;

        @include respond-to(tablet) {
            max-width: 100%;
            margin-block-end: 20px;
            margin-inline-end: 0;
        }
    }

    @mixin proposal-styled-lists {
        & ul,
        ol {
            margin-block-start: 20px;

            @include respond-to(mobile) {
                margin-block-start: 10px;
            }

            &:not(:last-child) {
                margin-block-start: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }

        & ul li,
        ol li {
            list-style: none;
            margin: 0;
            padding-block: 0;
            padding-inline: 26px 0;
            position: relative;

            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }

        & ul li {
            &::before {
                content: "";
                background-color: var(--c-primary);
                border-radius: 50%;
                display: block;
                width: 2px;
                height: 2px;
                inset-inline-start: 12px;
                position: absolute;
                inset-block-start: 50%;
                transform: translateY(-50%);
            }
        }

        & ol {
            counter-reset: myCounter;

            li {
                &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter) ")";
                    position: absolute;
                    inset-inline-start: 12px;
                    inset-block-start: 0;
                    color: var(--c-primary);
                    font-weight: 500;
                    font-size: 0.75rem;
                }
            }
        }
    }

    &__description-text {
        width: 100%;
        background-color: var(--c-light-blue);
        padding: 50px;
        border-radius: 8px;

        @include respond-to(tablet) {
            padding: 40px;
        }

        @include respond-to(mobile) {
            padding: 30px;
        }

        & span {
            line-height: 170%;
        }

        @include proposal-styled-lists;

        &_alt {
            background-color: var(--c-white);
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            @include respond-to(mobile) {
                flex-direction: column;
            }

            & > * {
                width: calc((100% - 30px) / 2);

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }
    }

    &__infoblock-title {
        display: flex;
        margin-block-end: 65px;

        span {
            width: 50%;
            margin-inline-start: 32px;
        }

        @include respond-to(tablet) {
            flex-direction: column;
            margin-block-end: 50px;

            h2 {
                max-width: 100%;
            }

            span {
                width: 100%;
                margin-inline-start: 0;
                margin-block-start: 30px;
            }
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;

            span {
                margin-block-start: 20px;
            }
        }
    }

    &__columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 40px;

        &:not(:last-child) {
            margin-block-end: 65px;

            @include respond-to(tablet) {
                margin-block-end: 50px;
            }

            @include respond-to(mobile) {
                margin-block-end: 40px;
            }
        }

        @include respond-to(mobile) {
            grid-template-columns: 1fr;
        }
    }

    &__column {
        display: grid;
        row-gap: 20px;
        height: fit-content;

        @include respond-to(tablet) {
            row-gap: 10px;
        }
    }

    &__card-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;

        @include respond-to(tablet) {
            font-size: 14px;
            line-height: 140%;
        }
    }

    &__proposal {
        &:not(:last-of-type) {
            margin-block-end: 100px;

            @include respond-to(tablet) {
                margin-block-end: 70px;
            }

            @include respond-to(mobile) {
                margin-block-end: 60px;
            }
        }

        &-option-title {
            color: var(--c-alt-base-color);
            margin-block-end: 20px;

            @include respond-to(mobile) {
                margin-block-end: 10px;
            }
        }

        &-title {
            margin-block-end: 65px;

            @include respond-to(tablet) {
                margin-block-end: 40px;
            }
        }

        &_alt {
            &:not(:last-of-type) {
                margin-block-end: 125px;

                @include respond-to(tablet) {
                    margin-block-end: 90px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 70px;
                }
            }

            #{$this}__proposal-option-title {
                @include respond-to(mobile) {
                    font-size: 1rem;
                }
            }
        }
    }

    &__proposal-blocks {
        display: flex;
        flex-direction: column;

        > :not(:last-child, .investment-proposal-detail__list-wrapper) {
            margin-block-end: 20px;
        }
    }

    &__indicators {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc(100% / 4 - 90px), 1fr));
        gap: 90px;
        padding: 80px 96px;
        background-color: var(--c-white);
        text-align: center;

        @include respond-to(tablet) {
            grid-template-columns: repeat(auto-fit, minmax(calc(100% / 2 - 130px), 1fr));
            gap: 130px;
            padding: 70px 88px;
        }

        @include respond-to(mobile) {
            grid-template-columns: 1fr;
            gap: 50px;
            padding: 40px 20px;
            text-align: start;

            h2 {
                font-weight: 700;
                font-size: 35px;
            }
        }

        &_alt {
            padding: 0;
            background-color: var(--c-true-transparent);
            text-align: start;
            gap: 50px 150px;

            & h4 {
                @include respond-to(tablet) {
                    max-width: 180px;
                }

                @include respond-to(mobile) {
                    max-width: unset;
                }
            }
        }
    }

    &__resources {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc((100% / 2) - 20px), 1fr));
        gap: 20px;

        &:not(:last-child) {
            margin-block-end: 65px;

            @include respond-to(tablet) {
                margin-block-end: 50px;
            }

            @include respond-to(mobile) {
                margin-block-end: 40px;
            }
        }

        &-title {
            color: var(--c-alt-base-color);
        }

        & > .text-card {
            padding: 36px 32px 36px 40px;
            height: 100%;
            justify-content: center;
        }

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
            gap: 10px;

            > .text-card {
                padding: 30px;
            }
        }

        @include respond-to(mobile) {
            grid-auto-flow: column;
            overflow: auto;
            width: 100vw;
            padding: 0 16px;
            margin-inline-start: -16px;

            & > .text-card {
                padding: 20px;
                min-width: 252px;
                min-height: 200px;
                justify-content: flex-start;
            }
        }

        &-block {
            &:not(:last-child) {
                margin-block-end: 100px;

                @include respond-to(mobile) {
                    margin-block-end: 80px;
                }
            }
        }

        &_alt {
            display: block;
            font-size: 1.25rem !important; // перекрыть стили ul-default
            font-weight: 500;
            line-height: 140%;

            @include respond-to(tablet) {
                font-size: 1rem !important;
            }

            & li:not(:last-child) {
                margin-block-end: 40px;

                @include respond-to(tablet) {
                    margin-block-end: 50px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 30px;
                }
            }
        }
    }

    &__resources-indicator {
        margin-block-start: 100px;
        color: var(--c-accent);

        &-value {
            font-weight: 700;
            font-size: 128px;
            line-height: 100%;
            margin-inline-end: 10px;

            @include respond-to(tablet) {
                font-size: 80px;
            }

            @include respond-to(mobile) {
                font-size: 40px;
            }
        }

        @include respond-to(mobile) {
            margin-block-start: auto;
        }
    }

    &__support {
        & > :not(:last-child) {
            margin-block-end: 20px;
        }

        &-title {
            margin-block-end: 30px;

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }
        }

        & > .text-card {
            padding: 40px;

            @include respond-to(mobile) {
                padding: unset;
                background-color: unset;
            }
        }

        // alt
        &-headline {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            flex-wrap: wrap;
        }

        &_alt {
            #{$this}__support-title {
                margin-block-end: 0;
            }

            & .btn {
                text-align: center;
            }
        }
    }

    &__support-links {
        margin-block-start: 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(mobile) {
            margin-block-start: 20px;
        }
    }

    &__form-wrapper {
        background-color: var(--c-light-blue);
        padding-inline: 80px 50px;
        padding-block: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;

        @include respond-to(tablet) {
            flex-direction: column;
            gap: 40px;
            padding-inline: 50px;
            padding-block: 58px 55px;
            align-items: flex-start;

            @include respond-to(mobile) {
                padding-inline: 20px;
                padding-block: 30px;
            }
        }

        &_alt {
            gap: 90px;
            padding: 0;
            background-color: var(--c-true-transparent);

            @include respond-to(tablet) {
                gap: 40px;
            }

            #{$this}__form-title {
                font-size: 2.5rem;
                font-weight: 600;

                @include respond-to(tablet) {
                    font-size: 1.875rem;
                }

                @include respond-to(mobile) {
                    font-size: 1.5rem;
                }
            }

            & .btn {
                white-space: nowrap;
            }
        }
    }

    &__form-title {
        font-weight: 500;
        font-size: 1.25rem;

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__unit {
        font-size: 2.5rem;
        line-height: 1.23;
        margin-inline-start: 5px;

        @include respond-to(tablet) {
            font-size: 1.25rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 1.375rem;
            line-height: 1.2;
            margin-inline-start: 7px;
        }
    }

    &__list-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        margin-block-end: 100px;

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
        }

        @include respond-to(mobile) {
            margin-block-end: 70px;
        }

        & .text-with-counter:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;

            @include respond-to(tablet) {
                grid-area: auto;
            }
        }

        & .text-with-counter:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2;

            @include respond-to(tablet) {
                grid-area: auto;
            }
        }

        & .text-with-counter:nth-child(3) {
            grid-area: 3 / 1 / 4 / 2;

            @include respond-to(tablet) {
                grid-area: auto;
            }
        }

        & .text-with-counter:nth-child(4) {
            grid-area: 1 / 2 / 2 / 3;

            @include respond-to(tablet) {
                grid-area: auto;
            }
        }

        & .text-with-counter:nth-child(5) {
            grid-area: 2 / 2 / 3 / 3;

            @include respond-to(tablet) {
                grid-area: auto;
            }
        }

        & .text-with-counter:nth-child(6) {
            grid-area: 3 / 2 / 4 / 3;

            @include respond-to(tablet) {
                grid-area: auto;
            }
        }
    }

    // alt
    & .headline-preview_alt {
        @include proposal-styled-lists;

        @include respond-to(tablet) {
            gap: 30px;
        }

        &__description {
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &__portfolio-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        gap: 70px 150px;

        @include respond-to(tablet) {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 100px;
        }

        @include respond-to(mobile) {
            grid-template-columns: 1fr;
        }
    }

    & .gallery__view {
        margin-block-end: 20px;
    }

    & .gallery__main-photo {
        height: 419px;

        @include respond-to(tablet) {
            height: 470px;
        }

        @include respond-to(mobile) {
            height: 208px;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .investment-proposal-detail {
        &__indicators {
            grid-template-columns: 1fr;
            text-align: start;
            padding: 0;
        }

        &__description-text_alt {
            & > * {
                width: 100%;
            }
        }

        &__form-wrapper {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &__columns {
            grid-template-columns: 1fr;
        }

        &__filter-values {
            grid-template-columns: 1fr;

            &-block {
                padding: 0;
            }
        }
    }
}
