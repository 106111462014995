.news-filters {
    $this: &;

    display: flex;
    flex-direction: column;
    width: 100%;

    &__list {
        display: flex;
        flex-direction: column;
        margin-block-end: 20px;
        gap: 20px;

        @include respond-to(tablet) {
            gap: 30px;
        }

        @include respond-to(mobile) {
            gap: 20px;
            margin-block-end: 10px;
        }
    }

    &__buttons {
        padding-block-start: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @include respond-to(desktop) {
            flex-wrap: wrap;
        }

        @include respond-to(tablet) {
            justify-content: flex-end;
            flex-wrap: nowrap;
        }

        @include respond-to(mobile) {
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            padding-block-start: 10px;
        }

        & .btn {
            @include respond-to(desktop) {
                width: 100%;
                justify-content: center;
            }

            @include respond-to(tablet) {
                width: fit-content;
            }

            @include respond-to(mobile) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &_newslist2 {
        flex-direction: row;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        #{$this}__list {
            flex: 1;
            flex-direction: row;
            margin-inline-end: 72px;

            & li {
                flex: 1;
            }

            @include respond-to(tablet) {
                flex-direction: column;
                margin-inline-end: 0;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }
        }

        #{$this}__buttons {
            @include respond-to(tablet) {
                justify-content: flex-start;
            }
        }
    }
}
