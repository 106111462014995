.subscription-form {
    &__form {
        & label {
            &.input {
                width: 331px;

                @include respond-to(tablet) {
                    flex: 1;
                }

                & .input__error {
                    position: absolute;
                    inset-inline-start: 17px;
                    inset-block-start: calc(100% + 7px);
                }

                .subscription_footer & {
                    width: 100%;
                }
            }

            &.checkbox {
                & .checkbox__error {
                    position: absolute;
                    inset-block-start: calc(100% + 6px);
                    inset-inline-start: -9px;
                }

                & .checkbox__field {
                    margin-block-start: 0;
                }
            }
        }

        & > .form__row:first-child {
            @include respond-to(tablet) {
                flex-wrap: wrap;
            }

            @include respond-to(mobile) {
                flex-direction: column;
            }

            & .input,
            & .btn {
                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }
    }

    &__success-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        & p {
            color: var(--c-button-basic-color);

            .media & {
                color: var(--c-base-color);
            }
        }
    }
}

#subscription-form {
    @include respond-to(tablet) {
        width: 100%;
    }
}

.a11y-mode {
    .subscription-form {
        & .form__row {
            align-items: flex-start;
        }

        & .input__error {
            position: static !important;

            & .input-error {
                margin-block-start: 5px;
                padding-inline: 0;

                &::before {
                    content: none;
                }
            }
        }
    }
}
