.investment-hr {
    $this: &;

    &__text {
        font-size: 1.125rem;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        &_lh-1 {
            line-height: 1;
        }

        &_tablet-s {
            @include respond-to(tablet) {
                font-size: 0.875rem;
            }
        }

        &_tablet-bold {
            @include respond-to(tablet) {
                font-weight: 500;
            }
        }
    }

    &__title {
        &_w-50 {
            max-width: 50%;
        }

        &_w-75 {
            max-width: 75%;
        }

        &_w-80 {
            max-width: 80%;
        }

        &_mobile-w-100 {
            @include respond-to(mobile) {
                max-width: unset;
            }
        }
    }

    &__image {
        border-radius: 8px;

        &_mobile_vw-100 {
            @include respond-to(mobile) {
                width: 100vw;
                margin-inline-start: -16px;
                border-radius: unset;
            }
        }
    }

    &__column-text {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        > * {
            break-inside: avoid;
            width: calc((100% - 32px) / 2);

            > img {
                border-radius: 8px;
                width: 100%;
            }
        }

        &_g-20 {
            gap: 20px;

            > * {
                width: calc((100% - 20px) / 2);
            }
        }

        &_g-40 {
            gap: 40px;

            > * {
                width: calc((100% - 40px) / 2);
            }
        }

        @include respond-to(tablet) {
            flex-flow: column nowrap;
            gap: 16px;

            > * {
                width: unset;

                > img {
                    margin-block-start: 40px;
                }
            }
        }

        @include respond-to(mobile) {
            > * {
                > img {
                    width: 100vw;
                    margin-inline-start: -16px;
                    border-radius: unset;
                }
            }
        }
    }

    &__rich-text-block {
        & img {
            border-radius: 8px;
            float: right;
            margin-block-end: 20px;
            margin-inline-start: 20px;
            width: 50%;
        }

        & a:not(:last-child) {
            display: block;
            margin-block-end: 20px;
        }

        & p:not(:last-child) {
            margin-block-end: 32px;
        }

        & ul {
            &:not(:last-child) {
                margin-block-end: 32px;
            }

            &:not(:first-child) {
                margin-block-start: 32px;
            }

            @include respond-to(tablet) {
                display: flex;
                flex-wrap: wrap;
                gap: 20px 60px;
            }

            @include respond-to(mobile) {
                flex-flow: column nowrap;
            }

            & li {
                @include respond-to(tablet) {
                    width: calc((100% - 60px) / 2);
                }

                @include respond-to(mobile) {
                    width: 100%;
                }

                &:not(:last-child) {
                    margin-block-end: 16px;

                    @include respond-to(tablet) {
                        margin-block-end: 0;
                    }
                }
            }
        }

        @include respond-to(tablet) {
            display: flex;
            flex-direction: column;

            img {
                display: flex;
                order: 2;
                float: unset;
                width: 100%;
                margin-inline-start: 0;
                margin-block-end: 0;
                margin-block-start: 60px;
            }
        }

        &_alt {
            img {
                float: left;
                margin-block-end: 77px;
                margin-inline-start: 0;
                margin-inline-end: 20px;
                width: 40%;
                max-height: 281px;
                object-fit: cover;

                @include respond-to(tablet) {
                    display: none;
                }
            }

            & ul {
                margin-block: 0 32px !important; // перекрыть стили styled-lists
                margin-inline-start: calc(50% + 20px);

                @include respond-to(tablet) {
                    margin-block: 0 80px !important; // перекрыть стили styled-lists
                    margin-inline-start: 0;
                }

                @include respond-to(mobile) {
                    margin-block: 0 60px !important; // перекрыть стили styled-lists
                }
            }

            & > p:first-of-type:nth-child(2) {
                margin-inline-start: calc(50% + 20px);

                @include respond-to(tablet) {
                    margin-inline-start: 0;
                }
            }

            #{$this}__column-text {
                @include respond-to(tablet) {
                    gap: 0;
                }
            }
        }
    }

    &__data {
        border-radius: 8px;
        overflow: hidden;

        &_alt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px 162px;
            flex-wrap: wrap;

            @include respond-to(desktop-only) {
                #{$this}__data-item:first-child {
                    order: 1;
                }

                #{$this}__data-item:nth-child(2) {
                    order: 3;
                }

                #{$this}__data-item:nth-child(3) {
                    order: 5;
                }

                #{$this}__data-item:nth-child(4) {
                    order: 7;
                }

                #{$this}__data-item:nth-child(5) {
                    order: 2;
                }

                #{$this}__data-item:nth-child(6) {
                    order: 4;
                }

                #{$this}__data-item:last-child {
                    order: 6;
                }
            }

            @include respond-to(tablet) {
                flex-direction: column;
                gap: 50px;
            }

            @include respond-to(mobile) {
                gap: 30px;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            gap: 40px;
            width: calc((100% - 162px) / 2);

            @include respond-to(tablet) {
                width: 100%;
            }

            @include respond-to(mobile) {
                gap: 25px;
            }
        }

        &-value {
            min-width: 135px;

            @include respond-to(tablet) {
                min-width: 105px;
            }

            @include respond-to(mobile) {
                min-width: 75px;
            }
        }

        &-name {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-weight: 500;
                font-size: 0.875rem;
            }
        }

        &-columns {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 5px;

            @include respond-to(tablet) {
                grid-template-columns: 1fr 4fr;
            }

            @include respond-to(mobile) {
                grid-template-columns: 1fr 2fr;
                column-gap: 0;
            }
        }

        &-card {
            display: flex;
            width: 100%;

            &_center {
                justify-content: center;
            }
        }

        &-text {
            padding: 24px 40px;

            @include respond-to(mobile) {
                padding: 24px 30px;
                width: fit-content;
            }
        }
    }

    &__structure {
        &-header {
            display: flex;
            justify-content: space-between;

            @include respond-to(tablet) {
                flex-direction: column;
            }

            & > :last-child {
                max-width: 40%;
                margin-inline-start: 32px;

                @include respond-to(tablet) {
                    max-width: unset;
                    margin-inline-start: unset;
                    margin-block-start: 30px;
                }

                @include respond-to(mobile) {
                    margin-block-start: 20px;
                }
            }

            &_alt {
                display: block;

                & h2 {
                    @include respond-to(tablet) {
                        max-width: 630px;
                    }

                    @include respond-to(mobile) {
                        max-width: unset;
                    }
                }

                & > :last-child {
                    max-width: unset;
                    margin-inline-start: 0;

                    @include respond-to(tablet) {
                        margin-block-start: unset;
                    }

                    @include respond-to(mobile) {
                        margin-block-start: unset;
                    }
                }
            }
        }
    }

    &__education {
        &:not(:last-of-type) {
            margin-block-end: 100px;

            @include respond-to(mobile) {
                margin-block-end: 80px;
            }
        }

        &-tiles {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            @include respond-to(tablet) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include respond-to(mobile) {
                width: 100vw;
                grid-template-columns: unset;
                grid-auto-flow: column;
                padding: 0 16px;
                margin-inline-start: -16px;
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-tile {
            min-height: 290px;
            padding: 40px;
            border-radius: 8px;
            background-color: var(--c-white);

            @include respond-to(mobile) {
                min-width: 252px;
                min-height: 211px;
                padding: 30px 20px;

                > h2 {
                    font-size: 1.875rem;
                }
            }
        }

        &-tile-indicator {
            font-weight: 600;
            font-size: 3.75rem;
            line-height: 1.1;

            @include respond-to(tablet) {
                font-size: 2.5rem;
                line-height: 1;
            }

            @include respond-to(mobile) {
                font-weight: 700;
                font-size: 1.875rem;
            }
        }

        &-institutes {
            display: flex;
            flex-wrap: wrap;
            gap: 72px;

            @include respond-to(tablet) {
                gap: 30px 20px;
            }

            @include respond-to(mobile) {
                flex-flow: column nowrap;
            }

            & > li {
                width: calc((100% - 72px) / 2);
                padding-inline: 0 !important; // перекрыть основной стиль

                @include respond-to(tablet) {
                    width: calc((100% - 20px) / 2);
                }

                @include respond-to(mobile) {
                    width: unset;
                }

                &::before {
                    content: none !important; // перекрыть основной стиль
                }
            }

            &_alt {
                gap: 60px 50px;

                @include respond-to(tablet) {
                    gap: 70px 20px;
                }

                @include respond-to(mobile) {
                    gap: 40px;
                }

                & .btn {
                    @include respond-to(mobile) {
                        width: 100%;
                    }
                }
            }
        }

        &-rankings {
            display: flex;
            flex-wrap: wrap;
            gap: 0 60px;

            & > li {
                width: calc((100% - 60px) / 2);
            }

            @include respond-to(tablet) {
                gap: 0 20px;

                & > li {
                    width: calc((100% - 20px) / 2);
                }
            }

            @include respond-to(mobile) {
                flex-flow: column nowrap;

                & > li {
                    width: unset;
                }
            }
        }

        &-university-name {
            & a {
                color: var(--c-primary);
            }
        }

        &-schools {
            & > :not(:last-child) {
                margin-block-end: 10px;
            }
        }

        &-schools-list {
            display: grid;
            column-gap: 60px;
            row-gap: 20px;
            list-style: decimal inside;
            grid-template-columns: repeat(2, 1fr);

            @include respond-to(tablet) {
                grid-template-columns: 1fr;
            }

            & > li {
                display: flex;
                flex-direction: column;
            }

            & .js--hidden-element {
                display: none;
            }
        }

        &-school-title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 140%;
            display: list-item;

            @include respond-to(mobile) {
                font-size: 0.875rem;
                font-weight: 400;
            }
        }

        &-school-text {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 150%;
        }

        &-tabs {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @include respond-to(tablet) {
                gap: 10px;
            }

            @include respond-to(mobile) {
                gap: 7px;
            }
        }

        &-tabs-content {
            padding-inline: 50px;
            padding-block: 60px 50px;
            border-radius: 8px;

            @include respond-to(mobile) {
                padding: 30px 20px;
            }

            & .btn {
                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        @include styled-text-links;
    }

    &__contacts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
            gap: 70px;
        }

        @include respond-to(mobile) {
            gap: 50px;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-to(mobile) {
                word-wrap: break-word;
            }
        }

        &-type {
            max-width: 60%;
            color: var(--c-deep-gray);

            @include respond-to(tablet) {
                max-width: unset;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
        }

        &-position {
            font-style: italic;

            @include respond-to(tablet) {
                max-width: 80%;
                margin-block-end: 10px;
            }

            @include respond-to(mobile) {
                max-width: unset;
            }
        }

        &-card {
            display: flex;
        }

        &-image {
            border-radius: 50%;
            border: 2px solid var(--c-dark-blue);
            max-width: 174px;
            max-height: 174px;
            margin-inline-end: 60px;

            @include respond-to(tablet) {
                max-width: 134px;
                max-height: 134px;
                margin-inline-end: 25px;
            }

            @include respond-to(mobile) {
                max-width: 71px;
                max-height: 71px;
                margin-inline-end: 10px;
            }
        }

        &_alt {
            display: flex;
            flex-direction: column;
            gap: 20px;

            #{$this}__contacts-wrapper {
                background: var(--c-light-blue);
                border-radius: 8px;
                min-height: 254px;
                padding: 50px 48px;
                flex-direction: row;
                gap: 100px;

                @include respond-to(tablet) {
                    padding: 30px 40px;
                    flex-direction: column;
                    gap: 30px;
                }

                @include respond-to(mobile) {
                    min-height: 300px;
                    padding: 30px 20px;
                    gap: 20px;
                }
            }

            #{$this}__contacts-type {
                width: 27%;
                color: var(--c-primary);

                @include respond-to(tablet) {
                    width: 100%;
                    color: var(--c-deep-gray);
                }
            }

            #{$this}__contacts-card {
                width: 50%;

                @include respond-to(tablet) {
                    width: 100%;
                }

                @include respond-to(mobile) {
                    flex-direction: column;
                }
            }

            #{$this}__contacts-image {
                border: 2px solid var(--c-white);
                max-width: 107px;
                max-height: 107px;
                margin-inline-end: 40px;

                @include respond-to(tablet) {
                    max-width: 134px;
                    max-height: 134px;
                    border-color: var(--c-dark-blue);
                    margin-inline-end: 25px;
                }

                @include respond-to(mobile) {
                    max-width: 71px;
                    max-height: 71px;
                    margin-inline-end: 0;
                    margin-block-end: 17px;
                }
            }

            #{$this}__contacts-info {
                gap: 25px;

                @include respond-to(tablet) {
                    gap: 20px;
                }
            }

            #{$this}__contacts-position {
                margin-block-end: 0;
                max-width: 80%;

                @include respond-to(mobile) {
                    max-width: unset;
                }
            }
        }
    }

    &__link {
        display: inline;
        color: var(--c-alt-base-color);

        &:visited {
            color: var(--c-alt-base-color);
        }

        &_underline {
            text-decoration: underline;
            text-underline-offset: 0.25em;
        }

        &_block {
            display: block;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .investment-hr {
        &__structure-header {
            flex-direction: column;
            gap: 20px;
        }

        &__text {
            max-width: unset;
            margin-inline-start: 0;
        }

        &__education-tiles {
            display: flex;
            flex-direction: column;
        }

        &__education-tile {
            min-height: auto;
            padding: 10px;
        }

        &__contacts-type {
            color: var(--c-black);
            max-width: unset;
        }

        &__contacts {
            display: flex;
            flex-direction: column;

            &-info {
                width: 100%;
            }
        }

        &__column-text {
            flex-direction: column;

            & > * {
                width: 100% !important;
            }
        }

        &__education-institutes > li {
            width: 100%;
        }

        &__education-rankings > li {
            width: 100%;
        }

        &__data-columns {
            grid-template-columns: 1fr;
        }

        &__data-card {
            padding-block: 24px;
        }

        &__data-text {
            padding: 0;
        }

        &__education-schools-list {
            display: block;
        }

        &__data-item {
            flex-direction: column;
            align-items: flex-start;
        }

        &__title_w-80 {
            max-width: unset;
        }

        &__rich-text-block ul li {
            width: 100%;
        }

        &__education-university-name {
            max-width: unset;
        }
    }
}
