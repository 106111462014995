@import "variables";
@import "components/Menu";
@import "components/Calendar";
@import "components/MonthYearInput";
@import "components/Input";

.dp__theme_light {
    --dp-background-color: var(--c-white);
    --dp-text-color: var(--c-primary);
    --dp-primary-color: var(--c-accent);
    --dp-secondary-color: var(--c-dark-bg);
}

.dp__main {
    font-family: $dp__font_family;
    user-select: none;
    box-sizing: border-box;
}

.dp__pointer {
    cursor: pointer;
}

.dp__action_row {
    display: none;
}
