.prev-next-bar {
    background: var(--c-light-blue);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
    margin: 0 -20px;

    @include respond-to(mobile) {
        margin-inline-start: -6px;
        margin-inline-end: -6px;
    }
}
