.media-tabs {
    $this: &;

    &__tabs {
        margin-block-end: 42px;
    }

    & .tab-btn {
        display: flex;
        align-items: center;
        gap: 25px;
        background-color: var(--c-light-blue);

        &.active,
        &:hover {
            background-color: var(--c-light-bg);

            #{$this}__tab-text {
                color: var(--c-primary);
            }
        }
    }

    &__tab-image {
        width: 56px;
        height: 56px;
    }

    &__tab-text {
        color: var(--c-primary);
    }

    & .checkbox {
        align-items: center;
    }

    &__gallery-wrapper {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        padding: 40px;

        @include respond-to(tablet) {
            padding: 30px;
            margin-inline: -30px;
        }

        @include respond-to(mobile) {
            padding: 16px;
            margin-inline: -16px;
        }
    }

    &__gallery-title {
        font-size: 1.5rem;
        line-height: 1.4;
        margin-block-end: 40px;

        @include respond-to(tablet) {
            font-size: 1.25rem;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__video-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & .media-content__item-content {
            width: calc(33.3% - (40px / 3));

            @include respond-to(tablet) {
                width: calc(50% - (20px / 2));
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    &__presentations-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
