.infrastructure-services {
    $this: &;

    & h2 {
        line-height: 1.1;

        @include respond-to(tablet) {
            font-size: 1.875rem;
            line-height: 1.2;
        }

        @include respond-to(mobile) {
            font-size: 1.375rem;
        }
    }

    & .section-headline {
        margin-block-end: 50px;

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(mobile) {
            overflow-x: auto;
            flex-wrap: nowrap;
            margin-inline-end: -16px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &_alt {
            @include respond-to(mobile) {
                flex-wrap: wrap;
                margin-inline-end: 0;
            }
        }
    }

    &__link {
        width: calc((100% - 40px) / 3);

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            min-width: 252px;
        }

        &:last-child {
            @include respond-to(mobile) {
                margin-inline-end: 20px;
            }
        }

        &_alt {
            @include respond-to(mobile) {
                min-width: 100%;

                &:last-child {
                    margin-inline-end: 0;
                }
            }
        }
    }

    &__urban-portal {
        width: calc((100% - 40px) / 3);
        padding: 30px;
        background-color: var(--c-primary);
        border-radius: 8px;
        color: var(--c-white);
        display: flex;
        position: relative;

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            min-width: 252px;
        }

        &-content {
            z-index: 1; // чтобы перекрыть картинку на бэкграунде
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }

        & h5 {
            font-size: 24px;
            line-height: 1.4;
            margin-block-end: 34px;

            @include respond-to(tablet) {
                font-size: 20px;
            }

            @include respond-to(mobile) {
                font-size: 14px;
                font-weight: 400;
            }
        }

        & .btn {
            @include respond-to(mobile) {
                width: 100%;
                text-align: center;
            }
        }

        & img {
            position: absolute;
            inset-inline-end: 0;
            inset-block-end: 0;
        }

        &:hover {
            background-color: var(--c-light-bg);
            color: var(--c-primary);

            img {
                opacity: 0.05;
            }

            .btn {
                border-color: var(--c-primary);
                color: var(--c-primary);

                &:hover {
                    background-color: var(--c-primary);
                    color: var(--c-white);
                }
            }
        }

        &_alt {
            @include respond-to(tablet) {
                width: 100%;
                padding: 40px;
            }

            @include respond-to(mobile) {
                padding: 20px;
            }

            & h5 {
                font-size: 20px;

                @include respond-to(tablet) {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 1.2;
                    margin-block-end: 40px;
                }

                @include respond-to(mobile) {
                    font-weight: 600;
                    font-size: 22px;
                    margin-block-end: 34px;
                }
            }

            &:hover {
                #{$this}__document-icon {
                    & svg > path {
                        fill: var(--c-primary);
                    }
                }
            }
        }
    }

    &__document-icon {
        width: 32px;
        height: 38px;
        margin-block-end: 20px;

        & svg > path {
            fill: var(--c-white);
        }

        @include respond-to(tablet) {
            width: 46px;
            height: 55px;
            margin-block-end: 24px;
        }

        @include respond-to(mobile) {
            width: 36px;
            height: 43px;
            margin-block-end: 22px;
        }
    }

    &__urban {
        &-header {
            display: flex;
            justify-content: space-between;
            margin-block-end: 109px;

            @include respond-to(desktop) {
                flex-direction: column;
                gap: 40px;
            }

            &-left {
                width: 48%;

                @include respond-to(desktop) {
                    width: 100%;
                }

                & h2 {
                    margin-block-end: 40px;

                    @include respond-to(tablet) {
                        margin-block-end: 20px;
                    }

                    @include respond-to(mobile) {
                        font-size: 1.375rem;
                    }
                }

                & h5 {
                    @include respond-to(tablet) {
                        font-size: 1rem;
                        line-height: 1.4;
                        font-weight: 400;
                    }
                }
            }
        }

        &-acts {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }

        &-load-btn {
            width: 100%;
            text-align: center;

            & > .btn {
                margin-block-start: 44px;

                @include respond-to(mobile) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    &__act {
        width: calc((100% - 40px) / 3);

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &.js--hidden-element {
            display: none;
        }

        &_tcp2 {
            width: calc(50% - 10px);
            position: relative;

            @include respond-to(mobile) {
                width: 100%;
            }

            & .card-with-text-and-btn {
                justify-content: flex-start;
            }
        }
    }

    @include section-indents;
}

.a11y-mode {
    .infrastructure-services {
        &__links {
            flex-direction: column;
            margin-inline-end: 0;
        }

        &__link {
            width: 100%;
        }

        &__urban-portal {
            width: 100%;
            min-width: unset;
            display: block;

            &-content {
                display: block;
            }

            img {
                display: none;
            }
        }

        .simple-link-block {
            height: auto !important;
            min-height: unset !important;
            display: block;
        }
    }
}
