.investment-offer {
    $this: &;

    position: relative;
    width: 100%;
    height: 395px;
    padding: 45px 40px;
    border: 1px solid var(--c-dark-bg);
    border-radius: 8px;
    background-color: var(--c-white);
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: var(--c-white);
    transition: background-color 0.33s ease;

    @include respond-to(desktop) {
        padding: 30px;
        height: 220px;
    }

    &__title {
        position: relative;
        z-index: 1;
        max-width: 300px;
        font-weight: 500;
        font-size: 1.5em;
        line-height: 140%;

        @include respond-to(desktop) {
            font-size: 1em;
        }
    }

    &__tag {
        position: relative;
        z-index: 1;
        margin-block-start: auto;
        font-weight: 500;
        font-size: 0.875em;
        line-height: 1em;
        letter-spacing: 0.13em;
        text-transform: uppercase;

        @include respond-to(desktop) {
            font-size: 0.75em;
            line-height: 1.25;
        }
    }

    & img {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        opacity: 1;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: opacity 0.33s ease;
    }

    &__icon {
        position: absolute;
        z-index: 1;
        inset-block-start: 54px;
        inset-inline-end: 40px;
        margin-inline-start: 4px;

        @include respond-to(desktop) {
            inset-block-start: 31px;
            inset-inline-end: 30px;
        }

        & svg {
            fill: var(--c-white);
            width: 14px;
            height: 24px;

            @include respond-to(desktop) {
                width: 7px;
                height: 12px;
            }
        }
    }

    &__drop-shadow {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background-color: var(--c-black);
    }

    &:hover {
        background-color: var(--c-primary);

        & #{$this}__drop-shadow {
            opacity: 0 !important; // перекрыть значение в инлайне
        }

        & img {
            opacity: 0;
        }
    }
}

.a11y-mode {
    .investment-offer {
        color: var(--c-black);
        border: 2px solid var(--c-black);
        gap: 20px;
        height: auto;

        &:hover {
            background-color: var(--c-white);
        }

        & > img {
            display: none;
        }

        &__drop-shadow {
            display: none;
        }

        &__title {
            max-width: 100%;
        }

        &__icon {
            & svg {
                fill: var(--black);
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .investment-offer {
        &__icon {
            display: none;
        }
    }
}
