.header {
    $this: &;

    position: relative;
    font-weight: 500;
    color: var(--c-white);
    height: 490px;

    &.with-slider {
        height: 757px;
    }

    &.mobile-smaller-text {
        #{$this}__text-content > h1 {
            @include respond-to(mobile) {
                font-size: 1.625rem;
            }
        }
    }

    &.on-main {
        position: absolute;
    }

    &.navbar-only {
        height: 86px;

        @include respond-to(desktop) {
            height: 70px;
        }

        &:not(.on-main) {
            #{$this}__nav {
                background: var(--c-secondary);
            }
        }
    }

    &.is_detail {
        & .breadcrumbs__item {
            &:last-child {
                @include respond-to(tablet) {
                    display: none;
                }
            }

            &:nth-last-child(2) svg {
                @include respond-to(tablet) {
                    display: none;
                }
            }
        }
    }

    &__nav {
        background: linear-gradient(180deg, rgb(7 10 56 / 80%), transparent);
        height: 86px;
        position: fixed;
        inset-inline: 0;
        inset-block-start: 0;
        width: 100%;
        z-index: 100;

        &_scrolled {
            background-color: var(--c-primary);
        }

        @include respond-to(desktop) {
            background: var(--c-primary);
            box-shadow: 0 0 2px rgba(255 255 255 / 20%);
            height: 70px;
        }
    }

    &__nav-inner {
        align-items: center;
        margin: 0 auto;
        max-width: var(--content-size-max);
        padding: 0 40px;
        display: flex;
        height: 100%;

        @include respond-to(tablet) {
            padding: 0 30px;
        }

        @include respond-to(mobile) {
            padding: 0 16px;
        }
    }

    &__nav-logo {
        color: var(--c-white);
        display: flex;
        font-size: 10px;
        font-weight: 600;
        line-height: 11px;
        align-items: center;

        img {
            margin-inline-end: 9px;
            width: 110px;
            height: 30px;

            @include respond-to(tablet) {
                width: 90px;
                height: 25px;
            }
        }
    }

    &__nav-logo-text {
        max-width: 100px;
    }

    &__nav-menu-btn {
        align-items: center;
        background-color: rgb(255 255 255 / 17%);
        border-radius: 8px;
        color: var(--c-white);
        cursor: pointer;
        display: none;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        margin-inline-start: 30px;
        padding-block: 0;
        padding-inline: 4px 10px;

        &:hover {
            background-color: rgb(255 255 255 / 30%);
        }

        @include respond-to(desktop) {
            display: flex;
        }

        @include respond-to(tablet) {
            margin-inline-start: 15px;
            padding-inline: 4px;
        }

        @include respond-to(mobile) {
            margin-inline-start: 10px;
        }

        span {
            margin-inline-start: 1px;

            @include respond-to(tablet) {
                display: none;
            }
        }

        & svg {
            width: 30px;
            height: 30px;

            &.opened {
                display: none;
            }

            &.closed {
                display: block;
            }
        }

        &_search {
            display: none;

            @include respond-to(desktop) {
                display: none;
            }

            @include respond-to(mobile) {
                display: flex;
                margin-inline-start: auto;
            }
        }
    }

    &__nav-menu {
        display: flex;
        list-style: none;
        height: 36px;
        margin-block: 0;
        margin-inline: 22px 0;
        padding: 0;

        @include respond-to(big-tablet-only) {
            overflow: scroll;
            scrollbar-width: none; // ff

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include respond-to(desktop) {
            align-content: start;
            align-items: start;
            background-color: var(--c-white);
            box-shadow: 0 1px 0 var(--c-dark-bg);
            display: none;
            flex-flow: column wrap;
            height: calc(100vh - 70px);
            min-height: 940px;
            margin: 0;
            padding-block-start: 45px;
            position: absolute;
            inset-block-start: 70px;
            inset-inline: 0;

            &::after {
                content: "";
                background-color: var(--c-white);
                position: absolute;
                inset-block-end: -70px;
                inset-inline: 0;
                width: 100%;
                height: 70px;
            }
        }

        @include respond-to(tablet) {
            flex-wrap: nowrap;
            margin-block-end: 40px;
            overflow-y: scroll;

            &::after {
                content: none;
            }
        }

        @include respond-to(mobile) {
            align-items: stretch;
            border-radius: 0;
            display: none;
            padding: 0;
            inset-block-start: 70px;
            min-height: unset;
        }

        &-item {
            display: flex;
            margin-inline-end: 40px;
            position: relative;

            @include respond-to(desktop) {
                flex-direction: column;
                justify-content: start;
                margin-block: 0 20px;
                margin-inline: 40px 0;
            }

            @include respond-to(mobile) {
                margin: 0;
            }

            &:hover {
                #{$this}__nav-menu-link {
                    &::after {
                        background-color: var(--c-alt-base-color);
                        inset-block-end: 0;
                        border-radius: 2px;
                        content: "";
                        display: block;
                        height: 4px;
                        position: absolute;
                        width: 100%;
                    }

                    &:not(:only-child)::before {
                        transform: rotate(180deg);

                        @include respond-to(big-desktop-only) {
                            transform: rotate(180deg) translateY(50%);
                        }
                    }
                }

                #{$this}__nav-submenu {
                    display: flex;
                }

                @include respond-to(desktop) {
                    #{$this}__nav-menu-link::after {
                        content: none;
                    }
                }

                @include respond-to(mobile) {
                    #{$this}__nav-menu-link:not(:only-child)::before {
                        transform: none;
                    }

                    #{$this}__nav-submenu {
                        display: none;
                    }
                }
            }

            &.active {
                @include respond-to(desktop-only) {
                    #{$this}__nav-menu-link:not(:only-child)::before {
                        transform: rotate(180deg);
                    }

                    #{$this}__nav-submenu {
                        display: flex;
                    }
                }

                @include respond-to(mobile) {
                    #{$this}__nav-menu-link:not(:only-child)::before {
                        transform: rotate(180deg);
                    }

                    #{$this}__nav-submenu {
                        display: flex;
                    }
                }
            }

            &:last-child {
                margin-inline-end: 0;

                @include respond-to(big-tablet-only) {
                    margin-inline-end: 40px;
                }

                @include respond-to(tablet) {
                    margin-block-end: 60px;
                }
            }
        }

        &-link {
            display: flex;

            @include respond-to(big-desktop-only) {
                align-items: center;
            }

            @include respond-to(desktop) {
                max-width: none;
            }

            @include respond-to(mobile) {
                align-items: center;
                display: flex;
                height: 54px;
                justify-content: space-between;
                padding: 0 20px;
                gap: 40px;
            }

            &:hover {
                @include respond-to(mobile) {
                    background-color: var(--c-light-bg);
                }
            }

            &:not(:only-child) {
                @include respond-to(mobile) {
                    border-block-end: 1px solid var(--c-dark-bg);
                    flex-direction: row-reverse;
                }

                &::before {
                    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjQiIGZpbGw9Im5vbmUiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0uMTUuMTRBLjQyOS40MjkgMCAwIDAgMCAuNDYxYzAgLjEyLjA1NC4yMzYuMTUuMzIxbDMuNDgzIDMuMDg1QS41NDguNTQ4IDAgMCAwIDMuOTk2IDRhLjU0OC41NDggMCAwIDAgLjM2Mi0uMTMzTDcuODQxLjc4MmMuMS0uMDg0LjE1Ny0uMi4xNTktLjMyM2EuNDI5LjQyOSAwIDAgMC0uMTUtLjMyNkEuNTUuNTUgMCAwIDAgNy40OCAwYS41NDUuNTQ1IDAgMCAwLS4zNjUuMTQxbC0zLjEyIDIuNzY0TC44NzcuMTRBLjU0OS41NDkgMCAwIDAgLjUxMy4wMDguNTQ5LjU0OSAwIDAgMCAuMTUxLjE0WiIvPjwvc3ZnPg==");
                    content: "";
                    height: 4px;
                    position: absolute;
                    transition: transform 0.2s ease-in-out;
                    inset-inline-end: -14px;
                    inset-block-start: 4px;
                    width: 8px;

                    @include respond-to(big-desktop-only) {
                        inset-block-start: 50%;
                        transform: translateY(-50%);
                    }

                    @include respond-to(desktop) {
                        content: none;
                    }

                    @include respond-to(mobile) {
                        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4IiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjMjEzMTYwIiBkPSJNLjg0IDEuMDI1YS42ODYuNjg2IDAgMCAwLS4yNC41MTNjMCAuMTkzLjA4Ni4zNzguMjQuNTE0bDUuNTcyIDQuOTM1YS44NzcuODc3IDAgMCAwIC41OC4yMTMuODc3Ljg3NyAwIDAgMCAuNTgtLjIxM2w1LjU3My00LjkzNWEuNjkuNjkgMCAwIDAgLjI1NS0uNTE3LjY4Ni42ODYgMCAwIDAtLjI0MS0uNTIzLjg3OS44NzkgMCAwIDAtLjU5MS0uMjEyLjg3My44NzMgMCAwIDAtLjU4NC4yMjdMNi45OTMgNS40NDggMiAxLjAyN2EuODc4Ljg3OCAwIDAgMC0uNTgtLjIxNC44NzguODc4IDAgMCAwLS41OC4yMTJaIi8+PC9zdmc+");
                        content: "";
                        height: 8px;
                        position: static;
                        width: 14px;
                        cursor: pointer;
                    }
                }
            }

            a {
                color: var(--c-white);
                font-size: 0.75rem;
                inline-size: min-content;
                line-height: 14px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.08em;
                word-break: keep-all;

                @include respond-to(big-desktop-only) {
                    white-space: nowrap;
                }

                @include respond-to(desktop) {
                    color: var(--c-secondary);
                    font-size: 0.875rem;
                    font-weight: 700;
                    inline-size: auto;
                    letter-spacing: 0.13em;
                }

                @include respond-to(mobile) {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
            }
        }
    }

    &__nav-submenu {
        background-color: var(--c-white);
        border-radius: 20px;
        box-shadow: 0 0 3px var(--c-dark-bg);
        display: none;
        flex-direction: column;
        list-style: none;
        margin: 20px 0 0;
        padding: 30px 0;
        position: absolute;
        inset-block-start: 100%;

        @include respond-to(desktop) {
            box-shadow: none;
            display: flex;
            margin: 0;
            padding: 0;
            position: static;
        }

        @include respond-to(mobile) {
            border-block-end: 1px solid var(--c-dark-bg);
            border-radius: 0;
            display: none;
            padding: 10px 0;
        }

        &::before {
            background-color: transparent;
            content: "";
            display: block;
            height: 20px;
            inset-block-end: 100%;
            inset-inline-start: 0;
            position: absolute;
            width: 100%;

            @include respond-to(desktop) {
                content: none;
            }
        }

        &::after {
            background-color: var(--c-white);
            content: "";
            display: block;
            height: 20px;
            position: absolute;
            inset-inline-start: 40px;
            inset-block-start: -10px;
            transform: rotate(45deg);
            width: 20px;

            @include respond-to(desktop) {
                content: none;
            }
        }

        &-item {
            @include respond-to(desktop) {
                margin-block-start: 20px;
            }

            @include respond-to(mobile) {
                margin-block-start: 0;
            }

            &:last-child {
                @include respond-to(desktop) {
                    margin-block-end: 40px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 0;
                }
            }

            a {
                color: var(--c-primary);
                display: block;
                padding: 10px 40px;
                text-decoration: none;
                white-space: nowrap;

                @include respond-to(desktop) {
                    color: var(--c-secondary);
                    display: block;
                    padding: 0;
                    white-space: normal;
                }

                @include respond-to(mobile) {
                    align-items: center;
                    display: flex;
                    height: 54px;
                    padding: 0 20px;
                }

                &:hover {
                    background-color: var(--c-light-bg);

                    @include respond-to(desktop) {
                        background-color: transparent;
                    }

                    @include respond-to(mobile) {
                        background-color: var(--c-light-bg);
                    }
                }

                &.active {
                    color: var(--c-alt-base-color);

                    @include respond-to(desktop) {
                        color: var(--c-alt-base-color);
                    }
                }
            }
        }
    }

    &__nav-menu-trigger:checked + &__nav-menu-btn {
        background-color: rgb(255 255 255 / 40%);
        position: relative;

        &::after {
            background-color: var(--c-white);
            inset-block-end: -32px;
            content: "";
            display: block;
            height: 14px;
            inset-inline: 0;
            margin: 0 auto;
            position: absolute;
            transform: rotate(45deg);
            width: 14px;
        }

        & svg.opened {
            display: block;
        }

        & svg.closed {
            display: none;
        }
    }

    &__nav-menu-trigger:checked ~ nav &__nav-menu {
        @include respond-to(desktop) {
            display: flex;
        }

        @include respond-to(tablet) {
            display: flex;
        }

        @include respond-to(mobile) {
            padding-block-end: 74px;
        }

        &-item {
            @include respond-to(desktop) {
                max-width: 40%;
            }

            @include respond-to(tablet) {
                max-width: unset;
            }
        }
    }

    &__nav-menu-search-input {
        display: none;
        position: relative;
        width: 35%;

        input {
            background-color: rgb(255 255 255 / 17%);
            border: none;
            border-radius: 20px;
            color: var(--c-light-bg);
            font-size: 0.875rem;
            font-weight: 500;
            height: 40px;
            letter-spacing: 0.02em;
            padding: 0 15px;
            width: 100%;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #bfc7d4;
                font-family: Montserrat, sans-serif;
                font-size: 0.875rem;
                font-weight: 500;
                letter-spacing: 0.02em;
            }
        }

        &::after {
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0U1RUFFRiIgZD0iTTcuNTc4IDE0LjIxN2E2Ljg0NCA2Ljg0NCAwIDAgMCA0LjIwOC0xLjQzOGw0LjUyNSA0LjQ2NmMuNzQ0LjQxOCAxLjU4Mi0uNDAyIDEuMTU3LTEuMTQybC00LjUyNS00LjQ2NmE2LjY2NiA2LjY2NiAwIDAgMCAxLjI3LTUuNjk5QTYuNzUzIDYuNzUzIDAgMCAwIDEwLjQ5NiAxLjRhNi45MDMgNi45MDMgMCAwIDAtNS45MTIuMDNBNi43NSA2Ljc1IDAgMCAwIC45MTYgNi4wMDdhNi42NjggNi42NjggMCAwIDAgMS4zMzEgNS42ODYgNi44NTYgNi44NTYgMCAwIDAgNS4zMyAyLjUyNFptMC0xMS44NTFhNS4yMiA1LjIyIDAgMCAxIDMuNjY2IDEuNDk5IDUuMDg0IDUuMDg0IDAgMCAxIDEuNTE5IDMuNjE4YzAgMS4zNTgtLjU0NyAyLjY2LTEuNTE5IDMuNjJBNS4yMiA1LjIyIDAgMCAxIDcuNTc4IDEyLjZhNS4yMiA1LjIyIDAgMCAxLTMuNjY3LTEuNDk5IDUuMDg0IDUuMDg0IDAgMCAxLTEuNTE5LTMuNjE5YzAtMS4zNTcuNTQ3LTIuNjU4IDEuNTItMy42MThhNS4yMiA1LjIyIDAgMCAxIDMuNjY2LTEuNVoiLz48L3N2Zz4=");
            content: "";
            display: block;
            height: 18px;
            margin: auto 0;
            position: absolute;
            inset-block: 0;
            inset-inline-end: 15px;
            width: 18px;
        }

        @include respond-to(desktop) {
            display: block;
        }

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__nav-dropdown {
        border-radius: 10px 10px 0 0;
        line-height: normal;
        margin-inline-start: 20px;
        position: relative;

        a,
        div {
            align-items: center;
            color: var(--c-white);
            display: flex;
            padding: 8px;
            text-decoration: none;

            svg {
                margin-inline-start: 4px;
                transition: transform 0.2s ease-in-out;
            }
        }

        ul {
            display: none;
            flex-direction: column;
            inset-inline-start: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            inset-block-start: 100%;
            width: 100%;

            li {
                a {
                    background-color: var(--c-white);

                    &:hover,
                    &:focus {
                        background-color: var(--c-light-bg);
                    }
                }

                &:last-child a {
                    border-radius: 0 0 10px 10px;
                }
            }
        }

        &:hover,
        &:focus,
        &.active {
            background-color: var(--c-white);

            a,
            div {
                color: var(--c-secondary);

                svg {
                    transform: rotate(180deg);

                    path {
                        fill: var(--c-secondary);
                    }
                }
            }

            ul {
                display: flex;
            }
        }

        &_inverted {
            border-radius: 0 0 10px 10px;

            a,
            div {
                color: var(--c-secondary);
            }

            ul {
                inset-block-end: 100%;
                flex-direction: column-reverse;
                inset-block-start: auto;

                li {
                    a {
                        background-color: var(--c-secondary);

                        &:hover,
                        &:focus,
                        &.active {
                            background-color: var(--c-dark-bg);
                            color: var(--c-secondary);
                        }
                    }

                    &:last-child a {
                        border-radius: 10px 10px 0 0;
                    }
                }
            }

            &:hover,
            &:focus,
            &.active {
                background-color: var(--c-secondary);

                a,
                div {
                    color: var(--c-white);

                    svg {
                        path {
                            fill: var(--c-white);
                        }
                    }
                }
            }
        }

        @include respond-to(desktop) {
            margin-inline-start: 10px;
        }
    }

    &__search-btn {
        margin-inline-start: 30px;

        @include respond-to(desktop) {
            display: none !important;
        }
    }

    &__enter-btn {
        margin-inline-start: 20px;

        @include respond-to(desktop) {
            background-color: transparent !important;
            margin-inline-start: 5px;
        }
    }

    &__search {
        background-color: var(--c-light-bg);
        box-shadow: 0 0 1px var(--c-primary);
        padding-block: 150px 100px;
        position: fixed;
        inset-inline: 0;
        inset-block-start: 0;
        z-index: 90;

        @include respond-to(desktop) {
            padding-block: 0;
            padding-inline: 15%;

            &:has(li) {
                padding-block: 135px 100px;
            }
        }

        @include respond-to(mobile) {
            padding-block: 90px 40px;
            padding-inline: 5%;
        }
    }

    &__search-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 774px;
    }

    &__search-input {
        border: none;
        border-radius: 33px;
        color: var(--c-secondary);
        font-size: 1.25rem;
        font-weight: 500;
        height: 66px;
        margin-block-end: 22px;
        padding: 0 23px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: #bfc7d4;
            font-family: Montserrat, sans-serif;
            font-size: 1.25rem;
            font-weight: 500;
        }

        @include respond-to(desktop) {
            display: none;
        }

        @include respond-to(mobile) {
            display: flex;
            font-size: 0.875rem;
            font-weight: 400;
            height: 40px;

            &::placeholder {
                font-size: 0.875rem;
                font-weight: 400;
            }
        }
    }

    &__search-results {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75rem;
        list-style: none;
        max-height: 40vh;
        margin: 0;
        overflow-y: scroll;
        padding: 0;

        li a {
            border-block-end: 1px solid #bfc7d4;
            color: var(--c-secondary);
            display: block;
            padding: 15px 26px;
            text-decoration: none;

            &:hover {
                background-color: #dfe4eb;
            }
        }

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-dark-bg);
            border: 2px solid transparent;
            border-radius: 8px;
            background-clip: content-box;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            font-weight: 400;

            &::-webkit-scrollbar {
                width: 10px;
            }

            li {
                margin: 0 0 10px !important;

                a {
                    border-block-end: none;
                    display: inline-block;
                    padding: 0;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }

        &_tablet {
            display: block;
            width: 200%;
            max-height: 400px;
            overflow-y: scroll;
            background-color: white;
            position: absolute;
            inset-block-start: 50px;
            padding: 30px;
            border-radius: 8px;

            & li:not(:last-child) {
                margin-block-end: 20px;

                & a {
                    font-size: 1rem;
                    line-height: 1.4;
                    color: var(--c-primary);
                }
            }

            &:empty {
                display: none;
            }
        }
    }

    &__banner {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__text-content {
        position: absolute;
        inset-block-end: 50px;
        inset-inline: 0;
        margin: 0 auto;
        max-width: var(--content-size-max);
        padding: 0 40px;
        max-height: 286px;

        @include respond-to(tablet) {
            padding: 0 30px;
            max-height: 222px;
        }

        @include respond-to(mobile) {
            padding: 0 16px;
            max-height: 240px;
        }
    }

    &__mobile-bottom {
        align-items: center;
        background-color: var(--c-white);
        border-block-start: 1px solid var(--c-dark-bg);
        inset-block-end: 0;
        display: none;
        height: 60px;
        inset-inline: 0;
        padding: 0 20px;
        position: fixed;
        z-index: 110;

        & button {
            padding: 0;
        }

        @include respond-to(mobile) {
            display: flex;
        }
    }

    &__mobile-phone {
        color: var(--c-secondary);
    }

    @include respond-to(mobile) {
        &__vision-btn {
            display: none !important;
        }

        &__lang-btn {
            display: none !important;
        }

        &__enter-btn {
            display: none !important;
        }
    }

    &_alt {
        #{$this}__text-content {
            inset-block-end: unset;
            inset-block-start: 110px;

            @include respond-to(tablet) {
                inset-block-start: 100px;
            }

            @include respond-to(mobile) {
                inset-block-start: 80px;
            }
        }

        #{$this}__nav {
            @include respond-to(desktop) {
                background: var(--c-secondary);
                box-shadow: none;
            }
        }

        #{$this}__nav-menu-item {
            z-index: 1; // перекрыть лого
        }

        #{$this}__nav-menu-logo {
            position: absolute;
            inset-inline-end: 0;
            inset-block-end: 0;
            display: none;

            @include respond-to(desktop) {
                display: block;
            }

            @include respond-to(tablet) {
                display: none;
            }

            & svg {
                display: block;
            }
        }

        #{$this}__nav-submenu {
            @include respond-to(desktop) {
                background-color: var(--c-true-transparent);
            }
        }
    }
}

.a11y-mode {
    .header {
        display: none;
    }
}
