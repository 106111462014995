.link-on-primary {
    $this: &;

    background-color: var(--c-primary);
    padding-block: 46px;
    padding-inline: 40px;
    border-radius: 8px;
    order: 4;
    display: inline-block;
    max-width: 284px;

    @include respond-to(tablet) {
        min-width: calc((100% - 116px) / 2);
        padding: 30px;
    }

    @include respond-to(mobile) {
        min-width: 100%;
    }

    &__title {
        color: var(--c-text-on-primary-bg);
        font-size: 1.25rem;
        line-height: 1.4;
        margin-block-end: 43px;

        @include respond-to(tablet) {
            font-size: 1rem;
            margin-block-end: 24px;
        }
    }

    &__subtitle {
        color: var(--c-text-on-primary-bg);
        margin-block-end: 66px;
        width: 80%;
        font-size: 1rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            margin-block-end: 46px;
        }

        @include respond-to(mobile) {
            width: 100%;
            font-size: 0.875rem;
        }
    }

    &_full {
        max-width: unset;

        @include respond-to(tablet) {
            min-width: unset;
        }

        #{$this}__title {
            margin-block-end: 30px;
            font-weight: 500;

            @include respond-to(tablet) {
                font-size: 1.25rem;
            }

            @include respond-to(mobile) {
                margin-block-end: 24px;
                font-size: 1rem;
            }
        }
    }

    &_light-blue {
        background-color: var(--c-light-blue);

        #{$this}__title,
        #{$this}__subtitle {
            color: var(--c-primary);
        }

        & .btn {
            border-color: var(--c-alt-base-color);
            color: var(--c-alt-base-color);

            &:hover {
                background-color: var(--c-alt-base-color);
                color: var(--c-white);
            }
        }
    }
}
