.slider {
    $this: &;

    background-color: var(--c-secondary);
    color: var(--c-white);
    height: 100%;
    overflow: hidden;
    position: relative;

    &__slides {
        display: flex;
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        transform: translateX(0);
        transition: transform 0.8s ease-in-out;

        li {
            flex: 0 0 100%;
            overflow: hidden;
            position: relative;
        }
    }

    &__slide-image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__slide-content {
        align-items: start;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: var(--content-size-max);
        padding: 0 40px;
        position: absolute;
        inset-inline: 0;
        inset-block-start: 160px;

        @include respond-to(tablet) {
            padding: 0 30px;
            inset-block-start: 215px;
        }

        @include respond-to(mobile) {
            padding: 0 16px;
            inset-block-start: 125px;
            inset-block-end: 114px;
        }

        & > div {
            &:first-child {
                width: 70%;

                @include respond-to(tablet) {
                    width: 60%;
                }

                @include respond-to(mobile) {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                & .h1 {
                    margin-block-end: 20px;

                    // чтобы не перекрывалось картинкой при таком размере
                    @media screen and (max-width: 1180px) {
                        font-size: 2.5rem;
                    }

                    @include respond-to(tablet) {
                        margin-block-end: 47px;
                    }

                    @include respond-to(mobile) {
                        font-size: 1.875rem;
                        margin-block-end: 20px;
                    }
                }

                & .h4 {
                    margin-block-end: 40px;

                    @include respond-to(tablet) {
                        font-size: 1rem;
                        margin-block-end: 80px;
                    }

                    @include respond-to(mobile) {
                        font-size: 1rem;
                        margin-block-end: 80px;
                    }
                }

                & .btn {
                    @include respond-to(mobile) {
                        margin-block-start: auto;
                    }
                }
            }
        }
    }

    &__slide-add-block {
        align-items: start;
        color: #fff;
        display: flex;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4em;

        @include respond-to(mobile) {
            display: none;
        }

        img {
            margin-inline-end: 20px;
            max-width: 100px;

            @include respond-to(tablet) {
                max-width: 66px;
            }
        }
    }

    &__slide-add-block-content {
        inline-size: min-content;

        @include respond-to(tablet) {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.4;
        }

        div {
            font-size: 128px;
            letter-spacing: -0.02em;
            line-height: 0.7em;
            margin-block-end: 15px;

            @include respond-to(tablet) {
                font-size: 50px;
                font-weight: 700;
            }
        }
    }

    &__controls {
        inset-block-end: 0;
        color: rgb(255 255 255 / 60%);
        inset-inline: 0;
        margin: 0 auto;
        max-width: var(--content-size-max);
        padding: 0 40px;
        position: absolute;

        > div {
            display: flex;
            justify-content: end;
        }

        ul {
            display: flex;
            height: 120px;
            list-style: none;
            margin: 14px 0 0;
            padding: 0;

            li {
                border-block-start: 2px solid rgb(255 255 255 / 30%);
                cursor: pointer;
                flex: 1;
                padding-block-start: 24px;
                padding-inline: 20px 60px;
                position: relative;

                &:first-child {
                    padding-inline-start: 0;
                    padding-inline-end: 80px;
                }

                &.active {
                    color: var(--c-white);
                    font-weight: 700;

                    &::before {
                        background-color: var(--c-main-slider-line-color);
                        border-radius: 2px;
                        content: "";
                        display: block;
                        height: 4px;
                        inset-inline-start: 0;
                        position: absolute;
                        inset-block-start: -3px;
                        width: 100%;
                    }
                }
            }
        }

        @include respond-to(tablet) {
            padding: 0 30px;

            > div {
                display: none;
            }

            ul {
                gap: 20px;
                height: 50px;

                li {
                    padding: 0 !important;

                    span {
                        display: none;
                    }
                }
            }
        }

        @include respond-to(mobile) {
            padding: 0 16px;
        }
    }

    &_number-controls {
        #{$this}__prev,
        #{$this}__next {
            display: none;
        }

        #{$this}__controls {
            & ul {
                height: 60px;
                width: fit-content;
                margin-block-end: 60px;
                gap: 50px;
                float: right;

                @include respond-to(mobile) {
                    width: 100%;
                    float: none;
                    gap: calc((100% - 160px) / 3);
                    margin-block-end: 40px;
                }
            }

            & ul li {
                border-block-start: none;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                @include respond-to(mobile) {
                    max-width: 40px;
                    height: 40px;
                }

                &.active {
                    background-color: var(--c-lighter-border);

                    &::before {
                        content: none;
                    }
                }
            }
        }

        #{$this}__control-text {
            display: none;
        }

        #{$this}__control-number {
            font-size: 1.875rem;
            line-height: 1.2;
            font-weight: 700;

            @include respond-to(tablet) {
                display: block;
            }

            @include respond-to(mobile) {
                font-size: 1.25rem;
            }
        }

        #{$this}__slide-content {
            @include respond-to(tablet) {
                flex-direction: column;
                gap: 88px;
            }

            & > div:first-child .btn {
                @include respond-to(mobile) {
                    margin-block-start: 0;
                }
            }

            & > div:first-child .h1 {
                @include respond-to(tablet) {
                    margin-block-end: 17px;
                }
            }
        }
    }
}

.a11y-mode {
    .slider {
        color: var(--c-black);
        background: var(--c-white);
        padding: 1rem;

        .btn {
            color: var(--c-black);
            border-color: var(--c-black);
        }

        &__slides {
            flex-direction: column;

            picture {
                display: none;
            }

            li {
                display: block;
                margin-block-end: 2rem;
            }
        }

        .h1 {
            font-size: 3em;
        }

        &__slide-add-block {
            display: none;
        }

        &__slide-content {
            position: static;
        }

        &__controls {
            display: none;
        }

        &__slide-content > div:first-child {
            width: 100%;
        }
    }
}
