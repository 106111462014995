.text-with-giant-counter {
    height: 100%;
    min-height: 262px;
    width: 100%;
    position: relative;
    display: flex;

    @include respond-to(tablet) {
        min-height: 200px;
    }

    @include respond-to(mobile) {
        min-height: 100px;
    }

    &__counter {
        position: absolute;
        font-size: 12.5rem;
        line-height: 1;
        font-weight: 700;
        color: var(--c-giant-counter);

        @include respond-to(mobile) {
            font-size: 6.25rem;
        }
    }

    &__text-content {
        height: fit-content;
        padding-inline-start: 50px;
        margin-block-start: 128px;

        @include respond-to(tablet) {
            min-height: unset;
            padding-inline-start: 30px;
            margin-block-start: 135px;
        }

        @include respond-to(mobile) {
            padding-inline-start: 24px;
            margin-block-start: 65px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.4;
        color: var(--c-accent);

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        &:not(:last-child) {
            margin-block-end: 20px;

            @include respond-to(tablet) {
                margin-block-end: 10px;
            }
        }
    }

    &__subtitle {
        line-height: 1.4;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }
}
