@use "sass:map";

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//    >> breakpoint-min(sm, (xs: 0, sm: 639px, md: 959px, lg: 1459px))
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map.get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

//    >> breakpoint-infix(xs, (xs: 0, sm: 639px, md: 959px, lg: 1459px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 639px, md: 959px, lg: 1459px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min + 1) {
            @content;
        }
    } @else {
        @content;
    }
}
