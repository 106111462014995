.carousel {
    $this: &;

    overflow: hidden;

    &__headline {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-block-end: 65px;

        h2 {
            width: 60%;
        }

        @include respond-to(tablet) {
            align-items: end;

            h2 {
                width: 80%;
                font-size: 2.5em;
            }

            .icon-btn {
                display: none !important;
                height: 44px;
                width: 44px;

                svg {
                    transform: scale(0.75);
                }
            }
        }

        @include respond-to(mobile) {
            align-items: start;
            flex-direction: column;

            h2 {
                font-size: 1.375em;
                margin-block-end: 30px;
            }
        }
    }

    &__list {
        counter-reset: item-number;
        display: flex;
        font-weight: 500;
        list-style: none;
        margin-block: 0;
        margin-inline: -20px 0;
        padding: 0;
        transform: translateX(0);
        transition: transform 0.8s ease-in-out;

        @include respond-to(tablet) {
            overflow: scroll hidden;
            transform: translateX(0) !important;
            padding-inline-end: 30px;
            scrollbar-width: none; // ff

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include respond-to(mobile) {
            padding-inline-end: 16px;
        }
    }

    &__item {
        background-color: var(--c-primary);
        border-radius: 8px;
        counter-increment: item-number;
        display: flex;
        flex: 0 0 calc(28% - 20px);
        flex-direction: column;
        height: 572px;
        justify-content: space-between;
        margin-inline-start: 20px;
        position: relative;

        &::before {
            content: counter(item-number);
            font-size: 2.4em;
            position: absolute;
            inset-block-start: 30px;
            inset-inline-start: 20px;
            z-index: 1;
        }

        &:last-child {
            margin-inline-end: 0;
        }

        @include respond-to(tablet) {
            flex: 0 0 calc((52% - 20px));
            height: 500px;

            &::before {
                font-size: 1.875em;
            }
        }

        @include respond-to(mobile) {
            flex: 0 0 calc((80% - 20px));
            height: 400px;
        }
    }

    @include respond-to(tablet) {
        margin-inline-end: -30px;
    }

    @include respond-to(mobile) {
        margin-inline-end: -16px;
    }

    &.light-background {
        color: var(--c-white);

        #{$this}__headline {
            @include respond-to(tablet) {
                margin-block-end: 40px;
            }

            & > h2 {
                color: var(--c-primary);

                @include respond-to(mobile) {
                    margin-block-end: 0;
                }
            }
        }

        #{$this}__item {
            &::before {
                display: none;
            }
        }

        & .icon-btn:active {
            opacity: 0.9;
        }
    }
}

.a11y-mode {
    .carousel {
        &__list {
            flex-direction: column;
            gap: 20px;
        }

        &__item {
            flex: unset;
        }
    }
}
