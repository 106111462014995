.text-with-point {
    $this: &;

    position: relative;

    &:not(:last-child) {
        padding-block-end: 60px;

        &::before {
            content: "";
            width: 1px;
            height: 100%;
            background-color: var(--c-alt-base-color);
            position: absolute;
            inset-block-start: 16px;

            @include respond-to(mobile) {
                inset-block-start: 14px;
            }
        }
    }

    &__point {
        width: 11px;
        max-width: 11px;
        height: 11px;
        max-height: 11px;
        border-radius: 50%;
        background-color: var(--c-alt-base-color);
    }

    &__title {
        font-size: 1.875rem;
        line-height: 1.2;
        color: var(--c-alt-base-color);
        padding-inline-start: 63px;
        margin-block-end: 20px;
        max-width: 563px;

        @include respond-to(tablet) {
            font-size: 1.25rem;
            line-height: 1.4;
            margin-block-end: 10px;
            padding-inline-start: 86px;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
            padding-inline-start: 28px;
        }

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: var(--c-alt-base-color);
            position: absolute;
            inset-block-start: 0.5em;
            inset-inline-start: -4.5px;
            border-radius: 50%;
            box-shadow: 0 0 0 10px var(--c-transparent-blue);

            @include respond-to(mobile) {
                width: 7px;
                height: 7px;
                box-shadow: 0 0 0 7px var(--c-transparent-blue);
                inset-inline-start: -3.5px;
            }
        }
    }

    &__subtitle {
        font-size: 1rem;
        line-height: 1.4;
        padding-inline-start: 63px;
        max-width: 563px;

        @include respond-to(tablet) {
            padding-inline-start: 86px;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
            padding-inline-start: 28px;
        }
    }
}
