:root {
    --slider-handle-width: 24px;
    --slider-handle-height: 24px;
    --slider-tooltip-bg: #aecbf3; // цвет тултипов в бегунках
    --slider-tooltip-distance: 3px;
    --slider-tooltip-radius: 50px;
    --slider-tooltip-px: 10px;
    --slider-tooltip-py: 2px;
    --slider-tooltip-arrow-size: 12px;
    --slider-tooltip-font-size: 14px;
    --slider-tooltip-line-height: 1.4em;
    --slider-tooltip-font-weight: 600;
}

.range-slider {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__title {
        margin-block-end: 58px;
    }

    &__content {
        display: flex;
        flex-direction: column;

        @include respond-to(tablet) {
            flex-direction: row;
            align-items: flex-end;
            gap: 50px;
            margin-block-end: 20px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 36px;
            margin-block-end: 0;
        }
    }

    &__slider {
        position: relative;

        @include respond-to(tablet) {
            width: 48%;
            margin-block-end: 10px;
        }

        @include respond-to(mobile) {
            width: 100%;
            margin-block-end: 0;
        }
    }

    &__values {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        margin-block-start: 20px;

        @include respond-to(tablet) {
            margin-block-start: 0;
            width: 52%;
        }

        @include respond-to(mobile) {
            width: 100%;
            margin-block-end: 20px;
        }
    }

    &__val {
        @include respond-to(mobile) {
            flex: 1;
        }
    }

    &__union-tooltip {
        visibility: hidden;
        position: absolute;
        display: block;
        font-size: var(--slider-tooltip-font-size);
        line-height: var(--slider-tooltip-line-height);
        font-weight: var(--slider-tooltip-font-weight);
        white-space: nowrap;
        text-align: center;
        color: var(--c-white);
        border-radius: var(--slider-tooltip-radius);
        border: 1px solid var(--slider-tooltip-bg);
        background: var(--slider-tooltip-bg);
        user-select: none;
        transform: translateX(-50%);
        left: 50%;
        bottom: 24px;
        z-index: 2;

        &-wrapper {
            padding: var(--slider-tooltip-py) var(--slider-tooltip-px);
            position: relative;
        }
    }

    & .slider-base {
        background-color: var(--c-alt-base-color);
    }

    & .slider-horizontal {
        height: 5px;
    }

    & .slider-target {
        margin: 0 auto;
        max-width: calc(100% - 24px);
    }

    & .slider-connects {
        background: var(--slider-tooltip-bg);
    }

    & .slider-connect {
        background: var(--c-alt-base-color);
    }

    & .slider-origin {
        position: absolute;
    }

    & .slider-horizontal .slider-handle {
        background-color: var(--c-alt-base-color);
        box-shadow: none;
        border-radius: 50%;
    }

    & .slider-touch-area {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            margin-block-end: 3px;
            inset-block-end: 100%;
            left: 50%; // не надо перестраивать при rtl
            transform: translateX(-50%) rotate(180deg);
            border-inline-start: 10px solid var(--c-true-transparent);
            border-inline-end: 10px solid var(--c-true-transparent);
            border-block-end: 12.7px solid var(--slider-tooltip-bg);
            z-index: -1;
        }
    }

    & .slider-horizontal .slider-tooltip-top {
        bottom: 33px;
    }

    & .slider-horizontal .slider-handle-lower .slider-tooltip-top {
        left: 0%;
        transform: translateX(-3%);
    }

    & .slider-horizontal .slider-handle-upper .slider-tooltip-top {
        left: 100%;
        transform: translateX(-97%);
    }

    & .slider-tooltip {
        visibility: visible;
        pointer-events: none;

        &::before {
            display: none;
        }
    }
}

.a11y-mode {
    --slider-tooltip-bg: var(--c-white);
    --slider-tooltip-color: var(--c-black);

    .range-slider {
        &__union-tooltip {
            border: 2px solid var(--c-black);
            color: var(--c-black);
        }

        & .slider-tooltip {
            border: 2px solid var(--c-black);
            color: var(--c-black);
        }

        & .slider-connects {
            outline: 1px solid var(--c-black);
        }

        &__content {
            flex-direction: column;
            align-items: flex-start;
        }

        &__slider {
            width: 100%;
        }

        &__values {
            width: 100%;
        }
    }

    &.a11y-mode-letter-spacing-big,
    &.a11y-mode-font-size-big {
        .range-slider {
            &__union-tooltip {
                @include respond-to(tablet) {
                    display: none;
                }
            }

            & .slider-tooltip {
                @include respond-to(tablet) {
                    display: none;
                }
            }
        }
    }
}
