.card-01 {
    $this: &;

    display: flex;
    min-height: 360px;
    position: relative;
    background-color: var(--c-light-bg);
    border-radius: 8px;
    color: var(--c-white);

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        position: relative;
        padding: 40px;
        background: linear-gradient(0deg, rgb(0 0 0 / 20%), rgba(0 0 0 / 20%)),
            linear-gradient(90deg, rgb(11 13 41 / 70%) 22.16%, transparent 72.57%);
        border-radius: 8px;
        z-index: 1;

        @include respond-to(mobile) {
            flex-direction: column;
            justify-content: end;
            padding: 20px;
        }

        .btn {
            @include respond-to(mobile) {
                width: 100%;
            }
        }

        @include respond-to(mobile) {
            padding: 20px;
        }

        ul {
            list-style: disc;
            margin-inline-start: 20px;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        margin-inline-end: 40px;

        @include respond-to(desktop-only) {
            margin-block-end: auto;
        }

        @include respond-to(mobile) {
            margin-inline-end: 0;
            margin-block-end: 30px;
        }
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.4em;
        margin-block-end: 15px;

        @include respond-to(mobile) {
            font-size: 1rem;
            margin-block-end: 10px;
        }
    }

    &__bg {
        display: flex;
        height: 100%;
        inset-inline-start: 0;
        position: absolute;
        inset-block-start: 0;
        width: 100%;

        img {
            border-radius: 8px;
            object-fit: cover;
            width: 100%;
        }
    }

    &:hover {
        .btn {
            background-color: var(--c-white);
            border-color: var(--c-white);
            color: var(--c-secondary);
        }
    }

    &_alt {
        min-height: 378px;

        @include respond-to(tablet) {
            min-height: 360px;
        }

        #{$this}__content {
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 20px;
        }

        #{$this}__title {
            font-size: 1.875rem;
            line-height: 1.2;
            margin-block-end: 20px;

            @include respond-to(tablet) {
                font-size: 1.25rem;
                line-height: 1.4;
                margin-block-end: 15px;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
                margin-block-end: 10px;
            }
        }

        #{$this}__subtitle {
            max-width: 486px;
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                max-width: 382px;
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                max-width: 100%;
                font-size: 0.875rem;
            }
        }
    }
}

.a11y-mode {
    .card-01 {
        background: var(--c-white);
        color: var(--c-black);
        min-height: unset;
        border: 1px solid var(--c-black);

        &__content {
            background: var(--c-white);
            color: var(--c-black);
        }

        &__subtitle {
            width: 100%;

            & ul {
                margin-inline-start: 0;
            }
        }

        &__left {
            width: 100%;
        }
    }
}
