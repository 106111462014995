.image-slider {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    &__button-prev {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 20px;
        transform: translateY(-50%);
        display: none;
    }

    &__button-next {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 20px;
        transform: translateY(-50%);
        display: none;
    }

    &__images {
        display: flex;
        height: 100%;
        scroll-behavior: smooth;
        transition: all 1s;
    }

    &__item {
        flex: 1 0 100%;
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
