.tiles {
    $this: &;

    align-items: start;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &__container {
        align-items: start;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        width: calc((100% - 20px) / 2);

        .tile_half {
            .tile__text-content {
                width: 100%;
            }
        }

        .tile:first-child .tile__image {
            height: auto;
            object-fit: none;
            position: absolute;
            top: 0;
            left: 0;
            right: unset;
            width: auto;
        }

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__container-lower {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
    }

    .tile {
        .tile__image {
            height: auto;
            object-fit: none;
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
        }

        &_main {
            .tile__image {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        @include respond-to(tablet) {
            &_main {
                display: none;
            }
        }
    }

    &_stretch-last {
        .tile:last-of-type {
            flex-grow: 1;
        }
    }

    &_scroll-tablet {
        @include respond-to(tablet) {
            width: 100vw;
            margin-inline-start: -30px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            #{$this}__container {
                width: fit-content !important;
                flex-wrap: nowrap;
                padding: 0 30px;
            }

            .tile {
                min-width: 330px;
                height: 280px;
            }
        }
    }

    &_scroll-mobile {
        @include respond-to(mobile) {
            width: 100vw;
            margin-inline-start: -16px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            #{$this}__container {
                width: fit-content !important;
                flex-wrap: nowrap;
                padding: 0 16px;
            }

            .tile {
                min-width: 252px;
                height: 233px;
            }
        }
    }

    &_increment {
        counter-reset: item-number;

        .tile {
            counter-increment: item-number;

            &::before {
                align-items: center;
                background-color: var(--c-dark-bg);
                border-radius: 50%;
                color: var(--c-white);
                content: counter(item-number);
                display: flex;
                font-size: 1.875em;
                font-weight: 500;
                width: 66px;
                height: 66px;
                justify-content: center;
                inset-inline-start: 20px;
                position: absolute;
                inset-block-start: 20px;

                @include respond-to(mobile) {
                    font-weight: 600;
                    font-size: 1.375em;
                    line-height: 120%;
                    width: 50px;
                    height: 50px;
                }
            }

            &_light {
                &::before {
                    color: var(--c-primary);
                    background-color: var(--c-light-bg);
                }
            }

            &_main {
                color: var(--c-primary);
                counter-increment: none;

                &::before {
                    content: none;
                }

                .tile__view {
                    flex-direction: column;
                }
            }

            &_alt {
                & .tile__count {
                    &::before {
                        color: var(--c-white);
                        content: counter(item-number);
                        font-size: 1.875em;
                        line-height: 1.2;
                        font-weight: 500;
                    }
                }
            }

            &_transparent {
                & .tile__count {
                    &::before {
                        color: var(--c-primary);

                        @include respond-to(mobile) {
                            color: var(--c-white);
                        }
                    }
                }
            }
        }

        &_s {
            .tile {
                &::before {
                    width: 50px;
                    height: 50px;
                    font-size: 1.25em;
                    line-height: 140%;
                }
            }
        }

        &_bottom {
            .tile {
                &::before {
                    inset-block: auto 20px;
                    inset-inline: auto 20px;
                }

                .tile__view {
                    flex-direction: column;
                }

                &_main {
                    .tile__view {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    &_alt {
        #{$this}__container {
            @include respond-to(tablet) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-rows: 1fr;
                grid-gap: 20px;
            }

            @include respond-to(mobile) {
                grid-template-columns: 1fr;
            }
        }

        & .tile {
            &:first-child .tile__image {
                left: unset;
                right: 0;
            }

            @include respond-to(tablet) {
                min-height: 200px;
                height: 100%;
                width: 100%;
            }

            & .tile__view {
                @include respond-to(tablet) {
                    padding: 20px;
                }
            }

            & .tile__image {
                inset-inline-end: 0;
                inset-block-start: unset;

                @include respond-to(tablet) {
                    display: none;
                }
            }

            & .tile__icon {
                @include respond-to(tablet) {
                    width: 50px;
                    height: 50px;
                }
            }

            & .tile__text-content {
                @include respond-to(tablet) {
                    width: 100%;
                }

                p {
                    @include respond-to(tablet) {
                        font-size: 0.875rem;
                    }
                }
            }

            & .tile__tile:not(:last-child) {
                @include respond-to(tablet) {
                    margin-block-end: 10px;
                }
            }
        }
    }

    &_transparent {
        .tile {
            &::before {
                inset-inline-start: 0;
                inset-block-start: 0;
            }

            &__view {
                background-color: transparent;
                padding-inline-start: 0;
                padding-block-end: 20px;
            }
        }
    }

    &_font_smaller {
        & .tile__title {
            font-size: 1rem;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }
}

.a11y-mode {
    .tiles {
        width: 100%;
        margin-inline-start: 0;

        &__container {
            display: flex !important;
            flex-direction: column;
            width: 100% !important;
            padding: 0;
        }
    }
}
