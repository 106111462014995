.infinite-carousel {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    &__button-prev {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 20px;
        transform: translateY(-50%);
        display: none;

        @include respond-to(mobile) {
            inset-inline-start: 10px;
        }
    }

    &__button-next {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 20px;
        transform: translateY(-50%);
        display: none;

        @include respond-to(mobile) {
            inset-inline-end: 10px;
        }
    }

    &__button-prev,
    &__button-next {
        &:hover {
            @include respond-to(tablet) {
                background-color: var(--c-dark-bg);
            }
        }

        @include respond-to(tablet) {
            height: 35px;
            width: 35px;
            background-color: var(--c-light-bg);
        }

        @include respond-to(mobile) {
            height: 29px;
            width: 29px;
        }

        & svg {
            fill: var(--c-white);
            width: 24px;
            height: 16px;

            @include respond-to(tablet) {
                fill: var(--c-primary);
                width: 15px;
                height: 9px;
            }
        }
    }

    &__images {
        display: flex;
        height: 100%;
        scroll-behavior: smooth;
        position: relative;
        inset-block-start: 0;
        width: 10000px;

        &.shifting {
            transition: left 1s;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
