.projects-registry {
    &__filters {
        margin-block-end: 65px;

        @include respond-to(mobile) {
            margin-block-end: 80px;
        }
    }

    &__list {
        min-height: 80px;
        text-align: center;

        &-table {
            @include respond-to(desktop) {
                overflow-x: auto;
                position: relative;

                &::-webkit-scrollbar {
                    margin-block-start: 5px;
                    height: 8px;
                    background-color: var(--c-light-blue);
                    border-radius: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--c-light-bg);
                    border-radius: 8px;
                    padding-inline-start: 5px;
                }
            }
        }
    }

    &__total {
        background-color: var(--c-light-bg);
        border-radius: 8px;
        padding: 40px;
        display: flex;
        gap: 60px;

        @include respond-to(tablet) {
            gap: 100px;
        }

        @include respond-to(mobile) {
            gap: 15px;
            flex-direction: column;
        }

        &-text {
            font-weight: 500;
            font-size: 1.25em;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 1em;
            }

            @include respond-to(mobile) {
                font-size: 0.875em;
            }
        }
    }
}

.a11y-mode {
    .projects-registry {
        &__list-content {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            margin-block-end: 20px;
            max-height: 80vh;
            overflow-y: scroll;
            padding: 5px;
        }

        &__list-item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            text-align: start;
            color: var(--c-black);
            outline: 1px solid var(--c-black);
            padding: 20px;
            border-radius: 8px;

            &-param {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &-name {
                    font-weight: 500;
                }
            }
        }

        &__total {
            flex-direction: column;
            gap: 20px;
        }
    }
}
