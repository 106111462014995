.municipality-detail {
    $this: &;

    &__block {
        padding: 0 40px;

        &_with-background {
            padding: 100px 40px;
            background-color: var(--c-light-blue);
        }
    }

    &__title {
        min-width: 513px;

        @include respond-to(mobile) {
            min-width: unset;
            font-size: 22px;
            line-height: 1.2;
        }
    }

    &__description {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        padding-block: 60px;
        padding-inline: 60px;
        display: flex;
        gap: 10px;

        @include respond-to(desktop) {
            padding-block: 40px;
            padding-inline: 40px;
            flex-direction: column;
        }

        @include respond-to(mobile) {
            background-color: var(--c-white);
            padding: 0;
        }

        &_alt {
            background-color: var(--c-true-transparent);
            border-radius: 0;
            padding: 0;
            display: block;
        }
    }

    &__description-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 16px;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 14px;
        }
    }

    &__square {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        #{$this}__param-tile {
            width: calc(50% - 10px);

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &_auto {
            gap: 200px;

            @include respond-to(tablet) {
                gap: 20px;
            }

            @include respond-to(mobile) {
                gap: 54px;
            }

            #{$this}__param-tile {
                width: auto;

                @include respond-to(tablet) {
                    width: calc(50% - 10px);
                }

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }
    }

    &__param {
        &:not(:last-of-type) {
            margin-block-end: 100px;
        }
    }

    &__param-title {
        margin-block-end: 65px;

        @include respond-to(tablet) {
            margin-block-end: 50px;
        }

        & > h3 {
            @include respond-to(tablet) {
                font-size: 20px;
            }

            @include respond-to(mobile) {
                font-size: 16px;
            }
        }

        & > span {
            font-size: 18px;
            line-height: 1.65;

            @include respond-to(tablet) {
                font-size: 16px;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 14px;
            }
        }

        &_w-smaller {
            width: 100%;
            max-width: 1030px;
        }
    }

    &__param-tiles {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc((100% / 3) - 20px), 1fr));
        gap: 20px;

        @include respond-to(desktop) {
            display: flex;
            flex-wrap: wrap;
        }

        &_bigger-gap {
            gap: 100px;

            @include respond-to(tablet) {
                gap: 70px 20px;
            }

            @include respond-to(mobile) {
                gap: 50px;
            }
        }
    }

    &__param-tile {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: fit-content;
        padding: 40px;
        background-color: var(--c-white);

        @include respond-to(desktop) {
            width: calc(33.3% - (40px / 3));
        }

        @include respond-to(tablet) {
            width: calc(50% - 10px);
            padding: 30px;
            min-height: unset;
            order: 2;
        }

        @include respond-to(mobile) {
            width: 100%;
            padding: 20px;
        }

        a {
            color: var(--c-accent);
            text-decoration: underline;
            text-underline-offset: 0.5em;

            &:visited {
                color: var(--c-accent);
            }
        }

        &_list {
            justify-content: start;
            min-height: 348px;

            @include respond-to(desktop) {
                width: calc(50% - 10px);
                min-height: unset;
            }

            @include respond-to(mobile) {
                width: 100%;
            }

            ul {
                padding-inline-start: 1.5em;
                list-style-position: inside;

                & li:not(:last-of-type) {
                    margin-block-end: 16px;
                }

                & li::before {
                    content: "\2022";
                    color: var(--c-accent);
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-inline-start: -1em;
                    font-size: 1.5em;
                }
            }
        }

        &.with-indicator {
            @include respond-to(tablet) {
                order: 1;
            }
        }

        &_gray {
            background-color: var(--c-light-bg);
            color: var(--c-primary);
        }

        &_blue {
            background-color: var(--c-light-blue);
        }

        &_small {
            min-height: unset;
        }

        &_no-padding {
            padding: 0;
        }

        &_transparent {
            background-color: var(--c-true-transparent);
            color: var(--c-white);
            border: 1px solid var(--c-white);

            @include respond-to(mobile) {
                border: none;
                padding: 0;
            }
        }
    }

    &__param-indicator {
        > * {
            display: inline;
        }

        > :last-child {
            margin-inline-start: 10px;
        }

        & h2 {
            line-height: 1;

            @include respond-to(tablet) {
                font-size: 50px;
            }

            @include respond-to(mobile) {
                font-size: 35px;
            }
        }

        & span {
            font-size: 20px;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-size: 14px;
            }
        }

        &_primary {
            color: var(--c-primary);
        }
    }

    &__links {
        display: flex;
        gap: 20px;

        @include respond-to(tablet) {
            flex-wrap: wrap;
        }

        & .simple-link-block {
            width: calc(33.3% - (40px / 3));
            min-height: 216px;

            @include respond-to(tablet) {
                width: calc(50% - 10px);
                min-height: 154px;
            }

            @include respond-to(mobile) {
                width: 100%;
                min-height: 106px;
            }
        }

        &_alt {
            @include respond-to(tablet) {
                gap: 10px;
            }

            @include respond-to(mobile) {
                gap: 5px;
            }

            & .link-with-icon {
                flex-grow: 1;

                @include respond-to(tablet) {
                    flex-grow: 0;
                    order: 3;
                }

                @include respond-to(mobile) {
                    flex-grow: 1;
                    order: 1;
                }

                &:first-child {
                    @include respond-to(tablet) {
                        order: 1;
                    }

                    @include respond-to(mobile) {
                        order: 1;
                    }
                }

                &:last-child {
                    @include respond-to(tablet) {
                        order: 2;
                    }

                    @include respond-to(mobile) {
                        order: 1;
                    }
                }
            }
        }
    }

    &__demography {
        position: relative;
        padding-block: 100px;
        margin-block: 0;

        @include respond-to(mobile) {
            padding-block: 80px;
        }
    }

    &__bg-image {
        position: absolute;
        inset-block-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__geography-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        grid-template-rows: repeat(4, auto);
        row-gap: 70px;

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;
        }

        @include respond-to(mobile) {
            row-gap: 40px;
        }

        #{$this}__param-tile {
            @include respond-to(desktop) {
                width: 100%;
            }
        }
    }

    &__geography-image {
        width: 100%;
        grid-area: 1 / 2 / 4 / 3;

        @include respond-to(tablet) {
            grid-area: 2 / 1 / 3 / 2;
        }

        @include respond-to(mobile) {
            grid-area: 2 / 1 / 3 / 2;
        }
    }

    &__geography-title {
        font-size: 1.875rem;

        @include respond-to(tablet) {
            font-size: 1.25rem;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__geography-subtitle {
        font-size: 1.125rem;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    .gallery__view {
        margin-block-end: 20px;
    }

    .gallery__main-photo {
        height: 750px;

        @include respond-to(tablet) {
            height: 400px;
        }

        @include respond-to(mobile) {
            height: 208px;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .municipality-detail {
        &__param-tile_transparent {
            background-color: var(--c-primary);
        }

        &__param-tiles {
            display: flex;
            flex-direction: column;
        }

        &__param-tile {
            width: 100%;
        }

        &__links {
            & .simple-link-block {
                width: 100%;
                min-height: unset;
            }
        }
    }
}
