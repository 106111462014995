.video-container {
    $this: &;

    height: 500px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    @include respond-to(tablet) {
        margin-inline-start: -30px;
        margin-inline-end: -30px;
        height: 386px;
        border-radius: 0;
    }

    @include respond-to(mobile) {
        margin-inline-start: -16px;
        margin-inline-end: -16px;
        height: 235px;
    }

    &.playing {
        #{$this}__control {
            display: none;
        }

        #{$this}__play-button {
            & svg {
                &.play {
                    display: none;
                }

                &.pause {
                    display: block;
                }
            }
        }
    }

    & iframe,
    & video {
        width: 100%;
        height: 100%;
        background-color: var(--c-black);
    }

    & video[poster] {
        height: 100%;
        width: 100%;
    }

    &__control {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-color: var(--c-white);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
        display: flex;
        padding: 0;

        & svg {
            width: 18px;
            height: 18px;
        }

        @include respond-to(mobile) {
            width: 65px;
            height: 65px;

            & svg {
                width: 13px;
                height: 13px;
            }
        }

        &_smaller {
            width: 65px;
            height: 65px;

            & svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__control-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        inset-inline: 15px;
        inset-block-end: 15px;
        gap: 10px;
        height: 40px;
        cursor: default;
    }

    &__play {
        position: relative;

        &-button {
            background-color: var(--c-secondary);
            padding: 13px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 69px;
            height: 40px;
            transition: background-color 0.3s;

            &:hover {
                background-color: var(--c-primary);

                & + #{$this}__play-tooltip {
                    opacity: 1;
                }
            }

            & svg {
                &.play {
                    display: block;
                }

                &.pause {
                    display: none;
                }
            }
        }

        &-tooltip {
            font-weight: 600;
            font-size: 0.625rem;
            line-height: 1.2;
            color: var(--c-white);
            background-color: var(--c-primary);
            padding: 6px 12px;
            border-radius: 5px;
            opacity: 0;
            transition: opacity 0.3s;
            white-space: nowrap;
            width: fit-content;
            position: absolute;
            inset-block-end: calc(100% + 12px);
            inset-inline-start: 50%;
            transform: translateX(-50%);

            &::before {
                content: "";
                position: absolute;
                margin-block-end: 3px;
                inset-block-end: -10px;
                inset-inline-start: 50%;
                transform: translateX(-50%) rotate(180deg);
                border-inline-start: 10px solid var(--c-true-transparent);
                border-inline-end: 10px solid var(--c-true-transparent);
                border-block-end: 10px solid var(--c-primary);
            }
        }
    }

    &__controls-wrapper {
        background-color: var(--c-secondary);
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 14px;
        border-radius: 4px;
        flex: 1;
        height: 40px;

        & button {
            padding: 0;
            width: 12px;
            height: 12px;
        }
    }

    &__timeline {
        width: 90%;
        cursor: pointer;
        position: relative;
        flex: 1;
        height: 4px;

        &:focus::-webkit-slider-runnable-track {
            background: transparent;
        }

        &-progress-bar {
            appearance: none;
            pointer-events: none;
            position: absolute;
            inset-block-start: 0;
            width: 100%;
            height: 4px;
            border-radius: 5px;
            background-color: var(--c-primary);

            &::-webkit-progress-bar {
                background-color: var(--c-primary);
                border-radius: 5px;
            }

            &::-webkit-progress-value {
                height: 5px;
                background: var(--c-white);
                border-radius: 5px;
            }

            &::-moz-progress-bar {
                border-color: var(--c-white);
                background: var(--c-white);
                border-radius: 5px;
            }

            &::-ms-fill {
                background: var(--c-white);
                border-radius: 5px;
            }
        }

        &-input {
            position: absolute;
            inset-block-start: 0;
            width: 100%;
            cursor: pointer;
            margin: 0;
            height: 4px;
            border-radius: 5px;
            background-color: var(--c-true-transparent);

            &:hover {
                & + #{$this}__timeline-tooltip {
                    opacity: 1;
                }
            }

            &::-webkit-slider-thumb {
                opacity: 0;
            }

            &::-moz-range-thumb {
                opacity: 0;
            }

            &::-ms-thumb {
                opacity: 0;
            }

            &::-webkit-slider-runnable-track {
                background-color: var(--c-true-transparent);
                border-color: var(--c-true-transparent);
                height: 4px;
            }

            &::-moz-range-track {
                border-color: 1px solid var(--c-primary);
            }
        }

        &-tooltip {
            font-weight: 600;
            font-size: 0.5rem;
            line-height: 1.25;
            border-radius: 4px;
            background-color: var(--c-white);
            width: fit-content;
            position: absolute;
            inset-block-end: calc(100% + 7px);
            transform: translateX(-50%);
            opacity: 0;
            padding: 2px 5px;
            transition: opacity 0.3s;

            &::before {
                content: "";
                position: absolute;
                margin-block-end: 3px;
                inset-block-end: -7px;
                inset-inline-start: 50%;
                transform: translateX(-50%) rotate(180deg);
                border-inline-start: 4px solid var(--c-true-transparent);
                border-inline-end: 4px solid var(--c-true-transparent);
                border-block-end: 4px solid var(--c-white);
            }
        }
    }

    &__volume {
        position: relative;
        display: flex;

        &.opened {
            #{$this}__volume-input {
                opacity: 1;
            }
        }

        &-input {
            background-color: var(--c-primary);
            border-radius: 2px;
            position: absolute;
            width: 57px;
            padding: 10px 5px;
            inset-block-end: calc(100% + 10px);
            inset-inline-start: 50%;
            transform: translate(-50%, -50%) rotate(270deg);
            opacity: 0;
            transition: opacity 0.3s;

            &::-webkit-slider-thumb {
                appearance: none;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: var(--c-white);
                cursor: pointer;
                margin-block-start: -2px;
            }

            &::-moz-range-thumb {
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: var(--c-white);
                cursor: pointer;
                margin-block-start: -2px;
                border: 1px solid var(--c-white);
            }

            &::-ms-thumb {
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: var(--c-white);
                cursor: pointer;
                margin-block-start: -2px;
            }

            &::-webkit-slider-runnable-track {
                width: 100%;
                height: 2px;
                cursor: pointer;
                background: var(--c-white);
                border-radius: 5px;
            }

            &::-moz-range-track {
                width: 100%;
                height: 2px;
                cursor: pointer;
                background: var(--c-white);
                border-radius: 5px;
            }
        }
    }

    &__fullscreen {
        position: relative;
        display: flex;

        &-button {
            &:hover {
                & + #{$this}__fullscreen-tooltip {
                    opacity: 1;
                }
            }
        }

        &-tooltip {
            font-weight: 600;
            font-size: 0.4375rem;
            line-height: 1.3;
            background-color: var(--c-primary);
            border-radius: 4px;
            padding: 5px 8px;
            color: var(--c-white);
            white-space: nowrap;
            width: fit-content;
            position: absolute;
            inset-block-end: calc(100% + 9px);
            inset-inline-end: -14px;
            opacity: 0;
            transition: opacity 0.3s;
        }
    }
}
