.page-wrap {
    background-color: var(--c-white);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;

    @include respond-to(tablet) {
        --content-size-max: 1023px;
        --content-padding-inline-size: 30px;
    }

    @include respond-to(mobile) {
        --content-size-max: 639px;
        --content-padding-inline-size: 16px;
    }
}

.container {
    margin: 0 auto;
    max-width: var(--content-size-max);
    overflow: hidden;
    padding: 0 var(--content-padding-inline-size);
    position: relative;
}

.section-wrapper {
    margin-block-end: 100px;

    @include respond-to(mobile) {
        margin-block-end: 80px;
    }
}

.section-title {
    margin-block-end: 65px;

    @include respond-to(tablet) {
        margin-block-end: 50px;
    }

    @include respond-to(mobile) {
        margin-block-end: 40px;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, var(--grid-column-width));
    gap: var(--grid-gap);

    @include respond-to(tablet) {
        grid-template-columns: repeat(6, 1fr);
    }

    @include respond-to(mobile) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.section-headline {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-block-end: 65px;

    // & > :first-child {
    //     width: 70%;
    // }

    @include respond-to(tablet) {
        & > :first-child {
            width: 60%;
        }
    }

    @include respond-to(mobile) {
        margin-block-end: 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        & > :first-child {
            width: 100%;
        }
    }
}

.map {
    & section {
        height: calc(calc(var(--vh, 1vh) * 100) - 86px);

        @include respond-to(desktop) {
            height: calc(calc(var(--vh, 1vh) * 100) - 70px);
        }

        @include respond-to(mobile) {
            height: calc(calc(var(--vh, 1vh) * 100) - 130px);
        }
    }
}

.a11y-mode {
    word-wrap: break-word;

    .map {
        flex: 1;
        display: flex;
        padding-block-end: 16px;

        & section {
            height: auto;
            width: 100%;
        }
    }

    .section-wrapper.bg-light-blue {
        padding-block: 0 !important;
        margin-block: 100px !important;

        @include respond-to(mobile) {
            padding-block: 0 !important;
            margin-block: 80px !important;
        }
    }

    .section-headline > :first-child {
        width: 100%;
    }

    .container {
        width: 50%;

        @media screen and (max-width: 1300px) {
            width: 100%;
        }
    }
}

.header.navbar-only + main {
    & > .section-wrapper {
        &:first-of-type {
            margin-block-start: 115px;

            @include respond-to(tablet) {
                margin-block-start: 105px;
            }

            @include respond-to(mobile) {
                margin-block-start: 85px;
            }
        }

        &.bg-light-blue:first-of-type,
        &.ratings__background:first-of-type {
            padding-block-start: 115px;
            margin-block: 0;

            @include respond-to(tablet) {
                padding-block-start: 105px;
                margin-block: 0;
            }

            @include respond-to(mobile) {
                padding-block-start: 85px;
                margin-block: 0;
            }
        }
    }
}
