.drop-file {
    $this: #{&};

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--c-primary);
    border-radius: 5px;
    padding: 9px 10px;
    width: max-content;
    cursor: pointer;

    @at-root .show-error & {
        background-color: var(--c-error);
    }

    &__icon {
        margin-inline-end: 10px;
        flex: none;
    }

    &__label {
        color: var(--c-white);
    }

    &__input {
        display: none;
    }

    &_active {
        background: #000;
        border-color: #000;
    }

    &_is-loading {
        background: #000;
        border-color: #000;

        &:hover {
            border-color: #000;
        }

        #{$this}__label {
            color: #000;
        }

        #{$this}__icon {
            animation: rotate 2s linear infinite;
        }
    }

    &_error {
        background-color: var(--c-blue-bg);

        #{$this}__icon {
            background-color: var(--c-white);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #{$this}__label {
            font-size: 0.75rem;
            line-height: 1.4;
            color: var(--c-notification);
        }
    }

    &_inner {
        border: none transparent;
        background: transparent;
        padding: 0;
        height: auto;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

.drop-uploaded {
    border-radius: 4px;
    padding: 10px 9px;
    background-color: var(--c-blue-bg);
    max-width: max-content;

    &:not(:last-child) {
        margin-block-end: 10px;
    }

    &__icon {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--c-white);
    }

    &__delete {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    &__item {
        display: flex;
        gap: 10px;
    }

    &__item-info {
        display: flex;
        column-gap: 10px;
        overflow: hidden;
    }

    &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.75rem;
        line-height: 1.4;
    }

    &__name_nowrap {
        white-space: nowrap;
        text-overflow: unset;
        overflow: visible;
    }
}

.drop-area {
    &__note {
        display: block;
        margin-block-start: 10px;
        font-size: 0.625rem;
        line-height: 1.4;
        color: var(--c-notification);
    }
}

.a11y-mode {
    .drop-area {
        &__note {
            color: var(--c-a11y-accent);
        }
    }
}
