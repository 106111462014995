.subscription {
    $this: &;

    background-color: var(--c-light-blue);
    border-radius: 8px;
    padding: 30px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-to(tablet) {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        padding: 50px 40px;
    }

    @include respond-to(mobile) {
        padding: 40px 20px;
        align-items: stretch;
    }

    &_footer {
        background-color: var(--c-true-transparent);
        padding: 0;
        flex-direction: column;
        align-items: stretch;
        gap: 30px;
        width: 340px;

        @include respond-to(mobile) {
            width: 100%;
            max-width: 340px;
        }

        #{$this}__text {
            font-size: 0.875em;
            line-height: 1.4;
            color: var(--c-white);
        }

        & .form__row {
            flex-direction: column;
            align-items: stretch;
        }

        & .checkbox__text {
            font-size: 0.75em;
            line-height: 1.4;
            color: var(--c-white);
            opacity: 0.4;
        }

        & .input__field {
            color: var(--c-white);
        }
    }

    &_alt {
        background-color: var(--c-light-bg);
        padding: 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 59px;

        @include respond-to(tablet) {
            padding: 50px 40px;
            align-items: stretch;
            gap: 30px;
        }

        @include respond-to(mobile) {
            padding: 40px 20px;
        }

        #{$this}__text {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;
            width: 55%;

            @include respond-to(tablet) {
                width: 100%;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        & .form__row {
            flex-wrap: wrap;
        }
    }
}

.a11y-mode {
    .subscription {
        &__text {
            color: var(--c-black);
        }

        & .checkbox__text {
            color: var(--c-black);
            opacity: 1;
        }

        & .checkbox__field {
            border-color: var(--c-black);
        }

        & .input__field {
            color: var(--c-black);
            border-color: var(--c-black);
        }

        &_footer {
            width: 100%;
        }
    }
}
