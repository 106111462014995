.badged-text {
    $this: &;

    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 6px;
    padding-block-end: 1px;
    white-space: nowrap;

    &__name {
        color: var(--c-deep-gray);
        font-size: 1.25em;
        font-weight: 500;

        @include respond-to(mobile) {
            font-size: 1em;
        }
    }

    &__value {
        align-items: center;
        background-color: var(--c-white);
        box-shadow: 0 1px 1px rgba(0 0 0 / 15%);
        border-radius: 12px;
        color: var(--c-primary);
        display: flex;
        font-size: 0.8125em;
        font-weight: 600;
        height: 24px;
        padding-inline: 6px;
    }

    &_active {
        #{$this}__name {
            color: var(--c-accent);
        }
    }
}

.a11y-mode {
    .badged-text {
        &__name {
            color: var(--c-black);
        }
    }
}
