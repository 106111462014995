.my-swiper-slider {
    $this: &;

    &__buttons {
        width: fit-content;
        display: flex;
        gap: 20px;
        margin-inline: auto;
        margin-block-start: 44px;
    }

    & .swiper-button-next,
    & .swiper-button-prev {
        position: static;
        width: 24px;
        height: 24px;

        &::after {
            content: none;
        }
    }

    & .swiper-pagination {
        position: static;
        margin-inline: auto;
        margin-block-start: 30px;
    }

    & .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        transform: none;
    }

    // на темном фоне
    & .swiper-pagination-bullet {
        --swiper-pagination-bullet-inactive-opacity: 1;
        background-color: var(--c-slider-bullet-dark-basic-color);

        &:hover,
        &:focus,
        &-active {
            background-color: var(--c-white);
        }
    }

    // на светлом фоне
    &.for-investors__reviews-carousel {
        & .swiper-pagination-bullet {
            --swiper-pagination-bullet-inactive-opacity: 1;
            background-color: var(--c-slider-bullet-light-basic-color);

            &:hover,
            &:focus,
            &-active {
                background-color: var(--c-slider-bullet-light-alt-color);
            }
        }

        & .swiper-slide {
            height: 520px;

            @include respond-to(tablet) {
                height: 466px;
            }

            @include respond-to(mobile) {
                height: 400px;
            }
        }

        & .review {
            height: 100%;
        }
    }

    &_with-thumbs {
        margin-block-end: 20px;

        #{$this}__buttons {
            display: none;
        }
    }

    &_success-stories {
        position: relative;

        #{$this}__buttons {
            position: absolute;
            inset-block-start: -130px;
            inset-inline-end: 0;
            margin-block-start: 0;
            margin-inline: 0;
            height: 60px;

            @include respond-to(desktop) {
                display: none;
            }

            & button {
                width: 60px;
                height: 60px;
                margin-block-start: 0;
            }
        }
    }
}

.a11y-mode {
    .my-swiper-slider {
        &__buttons {
            display: none;
        }
    }
}
