.block-with-text-cards {
    $this: &;

    &__groups-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;

        @include respond-to(mobile) {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        &:not(:last-child) {
            margin-block-end: 65px;
        }
    }

    &__group-container {
        display: grid;
        row-gap: 20px;
        height: fit-content;

        @include respond-to(mobile) {
            row-gap: 10px;
        }
    }

    &__main-card {
        min-height: 186px;

        @include respond-to(tablet) {
            min-height: 170px;
        }

        @include respond-to(mobile) {
            min-height: unset;
        }

        &-caption {
            color: var(--c-accent);

            @include respond-to(tablet) {
                font-size: 0.875rem;
                line-height: 1.4;
            }
        }

        &-title {
            font-size: 1.25rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 0.875rem;
            }
        }
    }

    &_alt {
        padding-block: 100px;
        color: var(--c-white);
        background-color: var(--c-dark-bg);
        position: relative;

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        #{$this}__image {
            display: block;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
        }

        #{$this}__drop-shadow {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background-color: var(--c-black);
            opacity: 0.5;
        }

        #{$this}__main-card {
            color: var(--c-primary);
        }
    }

    &_proposal {
        #{$this}__main-card {
            min-height: unset;
            padding: 40px;

            @include respond-to(tablet) {
                padding: 20px;
            }
        }

        #{$this}__main-card-title {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 0.875rem;
            }
        }

        & .text-card {
            min-height: unset;
        }
    }
}

.a11y-mode {
    .block-with-text-cards {
        &__drop-shadow {
            display: none;
        }

        &__group-container {
            display: block;
            max-width: 100%;
        }

        &__groups-container {
            display: block;
            max-width: 100%;
        }

        &_alt {
            color: var(--c-a11y-accent-color);
        }
    }
}
