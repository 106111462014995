.investment-rules-set {
    .btn {
        height: auto;
        padding: 20px 40px;

        @include respond-to(tablet) {
            padding-block: 10px;
        }
    }

    &__doc {
        background-color: var(--c-light-bg);
        border-radius: 8px;
        color: var(--c-primary);
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 25px 50px;

        h4 {
            margin-inline-start: 20px;
        }

        @include respond-to(tablet) {
            padding: 20px 40px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            padding: 20px;

            h4 {
                margin-inline-start: 10px;
            }
        }
    }
}
