.breadcrumbs {
    $this: &;

    position: absolute;
    inset-block-start: 106px;
    inset-inline: 0;

    @include respond-to(tablet) {
        inset-block-start: 90px;
    }

    @include respond-to(mobile) {
        inset-block-start: 80px;
    }

    &__list {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: var(--content-size-max);
        padding-inline: 40px;

        @include respond-to(tablet) {
            padding-inline: 30px;
            width: 100%;
            max-width: unset;
            margin: 0;
        }

        @include respond-to(mobile) {
            padding-inline: 16px;
        }
    }

    &__item {
        white-space: nowrap;

        &:last-child {
            max-width: calc(100vw - 2 * var(--content-padding-inline-size));
            width: fit-content;

            & span {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &__item-text {
        display: flex;
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 500;
        color: var(--c-white);
        white-space: normal;

        &:only-child {
            opacity: 0.6;
        }
    }

    &__item-link {
        display: flex;
        color: var(--c-white);
        gap: 10px;
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 500;
        transition: opacity 0.3s;

        @include respond-to(mobile) {
            font-size: 0.625rem;
        }

        & svg {
            width: 5px;
            height: 8px;
            flex-shrink: 0;
            margin-block-start: 6px;

            @include respond-to(tablet) {
                margin-block-start: 5px;
            }

            @include respond-to(mobile) {
                margin-block-start: 4px;
            }
        }
    }

    &_alt {
        @include respond-to(tablet) {
            inset-block-start: 106px;
        }

        @include respond-to(mobile) {
            inset-block-start: 106px;
        }

        #{$this}__list {
            @include respond-to(tablet) {
                width: max-content;
                margin: 0;
            }
        }

        #{$this}__item-link,
        #{$this}__item-text {
            color: var(--c-primary);

            & svg {
                path {
                    fill: var(--c-primary);
                }
            }
        }
    }

    &_banner_alt {
        inset-block-start: unset;
        inset-block-end: 40px;

        @include respond-to(mobile) {
            inset-block-end: 20px;
        }

        #{$this}__list {
            flex-wrap: wrap;
        }
    }
}
