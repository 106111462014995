@mixin color-settings {
    &.bg-primary-2D0C8A {
        --c-base-color: #2d0c8a; // ссылки, цвет шапки в таблицах
        --c-alt-base-color: #6a4ac7; // карточки при наведении, дропдауны, большие числа; бегунки, чекбоксы в фильтрах, табы
        --c-main-slider-line-color: #6a4ac7; // цвет полосок слайдера на главной
        --c-list-bullet-color: #2d0c8a; // цвет буллитов в списках
        --slider-tooltip-bg: #2d0c8a; // цвет тултипов в бегунках
        --c-map-point-color: #887baf; // цвет точки на карте / альт. странице рейтингов
        --c-map-point-outline-color: rgb(136 123 175 / 20%); // цвет обводки точки на карте / альт. странице рейтингов
        --c-alt-link-color: #d8d0f0; // цвета ссылок в шапках дропдаунов

        // кнопки-стрелки на темном фоне
        --c-arrow-dark-basic-color: rgb(136 123 175 / 30%);
        --c-arrow-dark-hover-color: #887baf;
        --c-arrow-dark-active-color: #564880;

        // кнопки-стрелки на светлом фоне
        --c-arrow-light-basic-color: #beb1e2;
        --c-arrow-light-hover-color: #2d0c8a;
        --c-arrow-light-active-color: rgb(45 12 138 / 90%);

        // буллиты в слайдере на темном фоне
        --c-slider-bullet-dark-basic-color: #887baf;

        // буллиты в слайдере на светлом фоне
        --c-slider-bullet-light-basic-color: #beb1e2;
        --c-slider-bullet-light-alt-color: #2d0c8a;

        // базовые кнопки: .btn
        --c-button-basic-color: #887baf;
        --c-button-hover-color: #2d0c8a;
        --c-button-active-text-color: #b8a7e7;
        --c-button-active-background-color: #564880;

        // акцентные кнопки: .btn.btn_accent и .btn.btn_background_accent
        --c-button-accent-color: #2d0c8a;
        --c-button-accent-activeOpacity: 0.5;
    }

    &.bg-primary-8C201B {
        --c-base-color: #8c201b; // ссылки, цвет шапки в таблицах
        --c-alt-base-color: #8c201b; // карточки при наведении, дропдауны, большие числа; бегунки, чекбоксы в фильтрах, табы
        --c-main-slider-line-color: #8c201b; // цвет полосок слайдера на главной
        --c-list-bullet-color: #8c201b; // цвет буллитов в списках
        --slider-tooltip-bg: #8c201b; // цвет тултипов в бегунках
        --c-map-point-color: #9f2f2a; // цвет точки на карте / альт. странице рейтингов
        --c-map-point-outline-color: rgb(159 47 42 / 20%); // цвет обводки точки на карте / альт. странице рейтингов
        --c-alt-link-color: #eacecd; // цвета ссылок в шапках дропдаунов

        // кнопки-стрелки на темном фоне
        --c-arrow-dark-basic-color: rgb(140 32 27 / 50%);
        --c-arrow-dark-hover-color: #721612;
        --c-arrow-dark-active-color: #4e1613;

        // кнопки-стрелки на светлом фоне
        --c-arrow-light-basic-color: #c68b88;
        --c-arrow-light-hover-color: #8c201b;
        --c-arrow-light-active-color: rgb(140 32 27 / 80%);

        // буллиты в слайдере на темном фоне
        --c-slider-bullet-dark-basic-color: #4a1529;

        // буллиты в слайдере на светлом фоне
        --c-slider-bullet-light-basic-color: #c68b88;
        --c-slider-bullet-light-alt-colorr: #8c201b;

        // базовые кнопки: .btn
        --c-button-basic-color: #c68b88;
        --c-button-hover-color: #8c201b;
        --c-button-active-text-color: #f2b5b2;
        --c-button-active-background-color: #8c201b;

        // акцентные кнопки: .btn.btn_accent и .btn.btn_background_accent
        --c-button-accent-color: #8c201b;
        --c-button-accent-activeOpacity: 0.5;
    }

    &.bg-primary-084707 {
        --c-base-color: #084707; // ссылки, цвет шапки в таблицах
        --c-alt-base-color: #084707; // карточки при наведении, дропдауны, большие числа; бегунки, чекбоксы в фильтрах, табы
        --c-main-slider-line-color: #084707; // цвет полосок слайдера на главной
        --c-list-bullet-color: #084707; // цвет буллитов в списках
        --slider-tooltip-bg: #084707; // цвет тултипов в бегунках
        --c-map-point-color: #2b932a; // цвет точки на карте / альт. странице рейтингов
        --c-map-point-outline-color: rgb(8 71 7 / 20%); // цвет обводки точки на карте / альт. странице рейтингов
        --c-alt-link-color: #d3e5d2; // цвета ссылок в шапках дропдаунов

        // кнопки-стрелки на темном фоне
        --c-arrow-dark-basic-color: rgb(8 71 7 / 50%);
        --c-arrow-dark-hover-color: #084707;
        --c-arrow-dark-active-color: #185a17;

        // кнопки-стрелки на светлом фоне
        --c-arrow-light-basic-color: #679367;
        --c-arrow-light-hover-color: #084707;
        --c-arrow-light-active-color: rgb(8 71 7 / 80%);

        // буллиты в слайдере на темном фоне
        --c-slider-bullet-dark-basic-color: #084707;

        // буллиты в слайдере на светлом фоне
        --c-slider-bullet-light-basic-color: #8fc18f;
        --c-slider-bullet-light-alt-color: #084707;

        // базовые кнопки: .btn
        --c-button-basic-color: #7baa7b;
        --c-button-hover-color: #084707;
        --c-button-active-text-color: #74bc72;
        --c-button-active-background-color: #084707;

        // акцентные кнопки: .btn.btn_accent и .btn.btn_background_accent
        --c-button-accent-color: #084707;
        --c-button-accent-activeOpacity: 0.5;
    }

    &.bg-primary-B47C10 {
        --c-base-color: #b47c10; // ссылки, цвет шапки в таблицах
        --c-alt-base-color: #b47c10; // карточки при наведении, дропдауны, большие числа; бегунки, чекбоксы в фильтрах, табы
        --c-main-slider-line-color: #b47c10; // цвет полосок слайдера на главной
        --c-list-bullet-color: #b47c10; // цвет буллитов в списках
        --slider-tooltip-bg: #b47c10; // цвет тултипов в бегунках
        --c-map-point-color: #b47c10; // цвет точки на карте / альт. странице рейтингов
        --c-map-point-outline-color: rgb(180 124 16 / 20%); // цвет обводки точки на карте / альт. странице рейтингов
        --c-alt-link-color: #f2e2c4; // цвета ссылок в шапках дропдаунов

        // кнопки-стрелки на темном фоне
        --c-arrow-dark-basic-color: rgb(180 124 16 / 50%);
        --c-arrow-dark-hover-color: #b47c10;
        --c-arrow-dark-active-color: #cf9d3c;

        // кнопки-стрелки на светлом фоне
        --c-arrow-light-basic-color: #d6af65;
        --c-arrow-light-hover-color: #b47c10;
        --c-arrow-light-active-color: rgb(180 124 16 / 80%);

        // буллиты в слайдере на темном фоне
        --c-slider-bullet-dark-basic-color: #b47c10;

        // буллиты в слайдере на светлом фоне
        --c-slider-bullet-light-basic-color: #e4cfa5;
        --c-slider-bullet-light-alt-color: #b47c10;

        // базовые кнопки: .btn
        --c-button-basic-color: #b47c10;
        --c-button-hover-color: #b47c10;
        --c-button-active-text-color: #efc87e;
        --c-button-active-background-color: #b47c10;

        // акцентные кнопки: .btn.btn_accent и .btn.btn_background_accent
        --c-button-accent-color: #b47c10;
        --c-button-accent-activeOpacity: 0.5;
    }
}
