.footer {
    $this: &;

    background-color: var(--c-secondary);

    @include respond-to(mobile) {
        padding-block-end: 90px;
    }

    &__main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-block: 55px 45px;

        @media screen and (max-width: 1280px) {
            gap: 50px;
        }

        @include respond-to(tablet) {
            grid-template-columns: 1fr 1fr;
            padding-block: 30px 70px;
            gap: 0;
        }

        @media screen and (max-width: 730px) {
            grid-template-columns: 1fr;
            padding-block: 30px 60px;
        }
    }

    &__col {
        &:not(:last-child) {
            margin-inline-end: 167px;

            @media screen and (max-width: 1280px) {
                margin-inline-end: 0;
            }

            @include respond-to(tablet) {
                margin-inline-end: 0;
            }

            @media screen and (max-width: 730px) {
                margin-block-end: 60px;
                display: flex;
                align-items: flex-end;
            }

            @include respond-to(mobile) {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
        }

        &:first-child {
            margin-inline-end: 187px;

            @media screen and (max-width: 1280px) {
                margin-inline-end: 0;
            }

            @include respond-to(tablet) {
                margin-inline-end: 90px;
                grid-area: 1 / 1 / 3 / 2;
            }

            @media screen and (max-width: 730px) {
                margin-block-end: 0;
                display: block;
            }
        }

        &:last-child {
            width: 100%;

            @include respond-to(tablet) {
                display: flex;
                align-items: flex-end;
            }
        }

        & .avatar {
            @include respond-to(tablet) {
                margin-block-end: 166px;
            }

            @media screen and (max-width: 730px) {
                margin-block-end: 60px;
            }

            &__title {
                font-size: 1rem;
                margin-block-end: 5px;
                color: var(--c-text-on-primary-bg);
                font-weight: 600;

                @include respond-to(tablet) {
                    font-size: 0.875rem;
                }
            }

            &__description {
                color: var(--c-text-on-primary-bg);
                font-size: 0.875rem;
                font-style: normal;
                opacity: 0.4;

                @include respond-to(tablet) {
                    font-size: 0.75rem;
                }
            }

            & img {
                width: 100px;
                height: 100px;

                @include respond-to(tablet) {
                    width: 55px;
                    height: 55px;
                }
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        gap: 14px;
        margin-block-end: 36px;

        @include respond-to(tablet) {
            gap: 9px;
            margin-block-end: 124px;
        }

        @media screen and (max-width: 730px) {
            gap: 6px;
            margin-block-end: 40px;
        }
    }

    &__logo-image {
        width: 187px;
        min-width: 187px;
        height: 51px;

        @include respond-to(tablet) {
            width: 139px;
            min-width: 139px;
            height: 38px;
        }

        @include respond-to(mobile) {
            width: 90px;
            min-width: 90px;
            height: 25px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__logo-text {
        color: var(--c-white);
        font-weight: 500;
        font-size: 1em;
        line-height: 1.1;
        min-width: 125px;

        @include respond-to(tablet) {
            font-size: 0.75em;
            line-height: 1.2;
        }

        @include respond-to(mobile) {
            font-size: 0.5em;
            line-height: 1;
        }
    }

    &__contacts {
        margin-block-end: 35px;

        @media screen and (max-width: 730px) {
            margin-block-end: 0;
        }

        & span,
        a {
            color: var(--c-white);
            display: block;
            max-width: 236px;

            &:not(:last-child) {
                margin-block-end: 20px;
            }

            @include respond-to(tablet) {
                opacity: 1;
            }
        }
    }

    &__socials {
        display: flex;
        gap: 9px;

        & a {
            @include respond-to(tablet) {
                opacity: 1;
            }
        }
    }

    &__bottom {
        display: flex;
        padding-block: 32px;
        color: var(--c-white);
        opacity: 0.4;

        @include respond-to(tablet) {
            align-items: end;
            border-block-start: none;
            padding-block-end: 33px;
        }

        @include respond-to(mobile) {
            padding-block-end: 0;
            padding-block-start: 50px;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }

    &__first {
        display: flex;
        gap: 38px;

        @media screen and (max-width: 1280px) {
            gap: 20px;
        }

        @include respond-to(tablet) {
            gap: 45px;
        }

        @include respond-to(mobile) {
            flex-direction: column-reverse;
            gap: 40px;
            width: 100%;
        }
    }

    &__rights {
        display: flex;
        gap: 15px;
        max-width: 275px;

        @include respond-to(tablet) {
            width: 311px;
            max-width: 311px;
            gap: 10px;
        }

        & img {
            width: 51px;
            height: 63px;

            @include respond-to(tablet) {
                width: 55px;
                height: 66px;
            }

            @include respond-to(mobile) {
                width: 51px;
                height: 62px;
            }
        }

        & span {
            font-size: 0.75em;
            line-height: 1.5;

            @include respond-to(tablet) {
                font-size: 0.875em;
                line-height: 1.55;
            }
        }
    }

    &__personal {
        margin-inline-end: 40px;
        display: block;
        font-size: 0.875em;
        line-height: 1.4;
        max-width: 185px;

        & a {
            color: var(--c-white);

            &:not(:last-child) {
                @include respond-to(tablet) {
                    display: block;
                    margin-block-end: 10px;
                }
            }
        }

        @media screen and (max-width: 1280px) {
            margin-inline-end: 20px;
        }

        @include respond-to(tablet) {
            max-width: 244px;
            margin-inline-end: 0;
        }

        @include respond-to(mobile) {
            max-width: 100%;
        }
    }

    &__links {
        margin-inline-end: 178px;

        @media screen and (max-width: 1280px) {
            margin-inline-end: 20px;
        }

        @include respond-to(tablet) {
            display: none;
        }

        @include respond-to(mobile) {
            display: block;
            margin-inline-end: 0;
            margin-block-end: 10px;
        }

        & a {
            display: block;
            font-size: 0.875em;
            line-height: 1.4;
            color: var(--c-white);
            white-space: nowrap;

            @media screen and (max-width: 1280px) {
                white-space: normal;
            }

            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }
    }

    &__support {
        font-size: 0.875em;
        line-height: 1.4;
        color: var(--c-white);
        max-width: 340px;

        @include respond-to(tablet) {
            display: none;
            opacity: 0.4;
            margin-block-end: 29px;
        }

        @include respond-to(mobile) {
            display: block;
            opacity: 1;
            margin-block-end: 50px;
        }

        & a {
            color: var(--c-alt-base-color);

            @include respond-to(tablet) {
                opacity: 1;
                color: var(--c-white);
            }
        }
    }

    &__corruption {
        @include respond-to(tablet) {
            font-size: 0.875em;
            color: var(--c-white);
            opacity: 0.4;
        }
    }

    & .input__field {
        border-color: var(--c-alt-base-color);
    }
}

.a11y-mode {
    .footer {
        color: var(--c-black);
        background: var(--c-white);

        &__main {
            grid-template-columns: 100%;
            gap: 20px;
        }

        .c-light-text {
            color: var(--c-black);
        }

        & a {
            color: var(--c-black);
        }

        &__col {
            margin-inline-end: 0;
            grid-area: unset;

            @include respond-to(tablet) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                margin-inline-end: 0;
            }
        }

        &__logo {
            width: 100%;

            @include respond-to(tablet) {
                margin-block-end: 0;
            }
        }

        &__logo-text {
            color: var(--c-black);
            font-size: 1rem;
        }

        &__contacts {
            width: 100%;

            & span,
            a {
                color: var(--c-black);
                max-width: unset;
            }
        }

        &__socials {
            & .icon-btn {
                background-color: var(--c-black);
            }

            & svg {
                fill: var(--c-black);
            }

            & svg path:nth-child(2) {
                fill: var(--c-black);
            }
        }

        &__bottom {
            color: var(--c-black);
            opacity: 1;
            flex-direction: column;
            gap: 20px;

            @include respond-to(tablet) {
                align-items: flex-start;
            }
        }

        &__first {
            flex-direction: column;
            gap: 20px;
        }

        &__personal {
            max-width: unset;

            & a {
                color: var(--c-black);
            }
        }

        &__links {
            width: 100%;

            & a {
                color: var(--c-black);
            }
        }

        &__support {
            color: var(--c-black);
            opacity: 1;
            max-width: 100%;

            @include respond-to(mobile) {
                margin-block-end: 0;
            }

            & a {
                color: var(--c-black);
            }
        }

        &__corruption {
            opacity: 1;
        }

        &__rights {
            max-width: unset;

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        & .avatar {
            color: var(--c-black);

            @include respond-to(tablet) {
                margin-block-end: 0;
            }
        }
    }
}

.a11y-mode-theme-whiteOnBlack {
    .footer {
        &__socials {
            & svg path:not(:nth-child(2)) {
                fill: var(--c-white);
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .footer__logo-image,
    .footer__socials,
    .footer__avatar-link {
        display: none !important;
    }
}
