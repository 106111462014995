.filter {
    background-color: var(--c-light-blue);
    border-radius: 8px;
    padding: 28px 34px;

    @include respond-to(tablet) {
        padding: 30px;
    }

    @include respond-to(mobile) {
        padding: 20px;
    }

    &__title {
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.4;

        @include respond-to(mobile) {
            font-size: 0.875rem;
            line-height: 1.4;
        }
    }
}

.a11y-mode {
    .filter:not(.radio-filter) {
        outline: 1px solid var(--c-black);
    }
}
