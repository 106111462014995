.dropdowns {
    $this: &;

    display: flex;

    &__image {
        border-radius: 8px;
        height: 100%;
        margin-inline-end: 20px;
        object-fit: cover;
    }

    &__items {
        display: flex;
        flex-direction: column;
        width: 100%;

        .dropdown:not(:last-child) {
            margin-block-end: 10px;
        }
    }

    &__dropdown-content {
        line-height: 1.625;
    }

    &__rtl {
        flex-direction: row-reverse;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        #{$this}__image {
            margin-inline-start: 20px;
            margin-inline-end: 0;

            @include respond-to(tablet) {
                margin-inline-start: 0;
                margin-block-end: 70px;
                max-height: 400px;
            }

            @include respond-to(mobile) {
                margin-block-end: 40px;
                max-height: 200px;
            }
        }
    }

    &_no-image-tablet {
        @include respond-to(tablet) {
            #{$this}__image {
                display: none;
            }
        }
    }
}
