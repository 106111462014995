ul.ul-default {
    font-size: 1em;

    li {
        list-style: none;
        margin: 0;
        padding-block: 0;
        padding-inline: 28px 0;
        position: relative;

        &::before {
            background-color: var(--c-alt-base-color);
            border-radius: 50%;
            content: "";
            display: block;
            height: 8px;
            inset-inline-start: 0;
            position: absolute;
            inset-block-start: 0.5em;
            width: 8px;
        }
    }
}
