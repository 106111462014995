.boiling-point {
    $this: &;

    &__header {
        display: flex;
        font-size: 1.125rem;
        line-height: 1.65;

        @include respond-to(tablet) {
            flex-direction: column;
            font-size: 1rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        & > div {
            width: 50%;

            @include respond-to(tablet) {
                width: unset;
            }

            &:first-child {
                margin-inline-end: 120px;

                @include respond-to(tablet) {
                    margin-inline-end: unset;
                    margin-block-end: 30px;
                }
            }
        }

        &-title {
            font-weight: 600;
            font-size: 2.5rem;
            line-height: 1.2;

            @include respond-to(mobile) {
                font-size: 1.375rem;
            }
        }

        &_alt {
            gap: 65px;

            @include respond-to(tablet) {
                flex-direction: column;
                gap: 60px;
            }

            @include respond-to(mobile) {
                gap: 50px;
            }

            & > div {
                width: auto;
            }

            & > div:first-child {
                margin-inline-end: 0;
            }
        }
    }

    &__header-image {
        max-width: 37.5%;

        @include respond-to(tablet) {
            max-width: 100%;
        }
    }

    &__tasks {
        @include respond-to(tablet) {
            display: flex;
            flex-wrap: wrap;
            gap: 60px;
        }

        @include respond-to(mobile) {
            flex-wrap: nowrap;
            overflow-y: scroll;
            scrollbar-width: none; // ff

            &::-webkit-scrollbar {
                display: none;
            }
        }

        & > div {
            @include respond-to(tablet) {
                width: calc(50% - (60px / 2));
            }

            @include respond-to(mobile) {
                min-width: 212px;
            }
        }

        & .text-with-counter:not(:last-child) {
            @include respond-to(mobile) {
                margin-block-end: 0;
            }
        }
    }

    &__tiles {
        & .tile {
            min-height: 225px;
        }

        & .tiles__container {
            align-items: stretch;
        }

        &_tasks {
            & .tile__title {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.4;

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }

        &_directions {
            & .tile {
                @include respond-to(tablet) {
                    min-height: unset;
                    height: unset;
                }

                &__view {
                    @include respond-to(tablet) {
                        padding-block-start: 90px;
                    }
                }

                &::before {
                    @include respond-to(mobile) {
                        width: 35px;
                        height: 35px;
                        font-weight: 500;
                        font-size: 0.875rem;
                        line-height: 1.4;
                    }
                }
            }
        }
    }

    &__bullet-blocks {
        & .ul-default {
            display: flex;
            flex-wrap: wrap;
            font-size: 1.125rem;
            line-height: 1.65;
            gap: 40px;

            & li {
                width: calc((100% - 40px) / 2);
            }

            @include respond-to(tablet) {
                flex-direction: column;
                font-size: 1rem;
                line-height: 1.4;

                & li {
                    width: 80%;
                }
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;

                & li {
                    width: 100%;
                }
            }
        }
    }

    &__block-background {
        position: relative;
        padding-block: 100px;
        color: var(--c-text-on-primary-bg);

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        #{$this}__header {
            display: block;
        }
    }

    &__block-background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        inset-block-start: 0;
    }

    &__list {
        display: flex;
        justify-content: space-between;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        .ul-default {
            & > :not(:last-child) {
                margin-block-end: 20px;
            }

            @include respond-to(tablet) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-block-end: 40px;
                font-size: 1rem;

                & li {
                    width: calc((100% - 20px) / 2);
                }
            }

            @include respond-to(mobile) {
                flex-direction: column;
                font-size: 0.875rem;

                & li {
                    width: unset;
                }
            }
        }

        &-image {
            width: 50%;

            @include respond-to(tablet) {
                width: 100%;
            }
        }
    }

    &__columns {
        columns: 3;
        column-gap: 30px;

        @include respond-to(tablet) {
            columns: 2;
            column-gap: 0;
        }
    }

    &__image-wrapper {
        background-color: var(--c-light-bg);
        border-radius: 8px;
        padding-block: 100px;
        display: flex;
        justify-content: center;

        @include respond-to(tablet) {
            padding-block: 70px 50px;
        }

        @include respond-to(mobile) {
            padding-block: 30px 20px;
        }
    }

    &__galleries {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        @include respond-to(mobile) {
            gap: 50px;
        }
    }

    &__galleries-item {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        padding: 30px 30px 40px;
        width: calc((100% - 20px) / 2);

        @include respond-to(tablet) {
            width: 100%;
        }

        @include respond-to(mobile) {
            background-color: transparent;
            padding: 0;
        }

        &_no-padding {
            padding: 0;
            position: relative;
        }
    }

    &__galleries-item-text {
        position: absolute;
        inset-block-end: 20px;
        inset-inline-start: 20px;
        background-color: var(--c-light-bg);
        padding: 20px;
        border-radius: 8px;

        @include respond-to(mobile) {
            position: static;
            background-color: var(--c-true-transparent);
            padding: 0;
        }
    }

    &__galleries-item-carousel {
        margin-block-end: 30px;
        overflow: hidden;
        position: relative;
        user-select: none;

        @include respond-to(mobile) {
            margin-block-end: 20px;
        }

        & ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            transform: translateX(0);

            & li {
                display: flex;
                flex: 0 0 100%;
                height: 330px;

                @include respond-to(mobile) {
                    height: 200px;
                }

                & img {
                    border-radius: 8px;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .carousel__prev,
        .carousel__next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 43px;
            height: 43px;
            background-color: var(--c-light-bg);
            border-radius: 50%;
            cursor: pointer;
            margin: auto 0;
            position: absolute;
            inset-block: 0;

            @include respond-to(mobile) {
                width: 29px;
                height: 29px;
            }

            & svg {
                @include respond-to(mobile) {
                    width: 13px;
                }
            }
        }

        & .carousel__prev {
            inset-inline-start: 15px;
        }

        & .carousel__next {
            inset-inline-end: 15px;
        }

        &_no-margin {
            margin-block-end: 0;

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }

            & ul li {
                height: 378px;

                @include respond-to(tablet) {
                    height: 360px;
                }

                @include respond-to(mobile) {
                    height: 220px;
                }
            }
        }
    }

    &__galleries-item-title {
        margin-block-end: 10px;
    }

    @mixin scroll-mobile {
        @include respond-to(mobile) {
            flex-wrap: nowrap;
            width: 100vw;
            margin-inline-start: -16px;
            overflow: auto;
            padding: 0 16px;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__team {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include scroll-mobile;

        & > div {
            width: calc((100% - 60px) / 4);

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
                height: 412px;
            }

            @include respond-to(mobile) {
                min-width: 252px;
                width: 252px;
                height: 315px;
            }
        }

        & .person-card__no-image svg {
            @include respond-to(mobile) {
                margin-block-start: 80px;
            }
        }

        &_alt {
            @include respond-to(mobile) {
                flex-wrap: nowrap;
                overflow-y: scroll;
                scrollbar-width: none; // ff

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            & > div {
                width: calc(50% - 10px);

                @include respond-to(tablet) {
                    width: calc(33.3% - (40px / 3));
                    height: auto;
                }

                @include respond-to(mobile) {
                    width: 252px;
                    height: auto;
                }
            }
        }
    }

    &__contacts-header {
        width: 50%;
        color: var(--c-white);

        @include respond-to(tablet) {
            width: unset;
        }
    }

    &__indicators-wrapper {
        display: flex;
        gap: 30px;

        @include respond-to(tablet) {
            flex-direction: column;
            gap: 0;
        }
    }

    &__indicators {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include scroll-mobile;

        &_alt {
            gap: 50px;
        }
    }

    &__indicators-tile {
        background-color: var(--c-white);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 40px;
        width: calc((100% - 60px) / 4);
        font-weight: 500;

        &:last-child {
            flex: 1;
            max-width: calc((100% - 20px) / 2);
        }

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            min-width: 252px;
            width: 252px;
            padding: 30px 20px;
            font-weight: 400;
        }

        & h2 {
            @include respond-to(mobile) {
                font-size: 1.875rem;
            }
        }

        & a {
            color: var(--c-alt-base-color);
            text-decoration: underline;
            text-underline-offset: 0.5em;

            &:visited {
                color: var(--c-accent);
            }
        }

        &_alt {
            padding: 0;
            background-color: var(--c-true-transparent);
            flex-direction: row;
            gap: 20px;
            align-items: center;
            width: calc(50% - 25px);

            #{$this}__indicator {
                margin-block-end: 0;
            }

            @include respond-to(tablet) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &__indicator {
        margin-block-end: 80px;

        & > * {
            display: inline;
        }

        & > :not(:first-child) {
            margin-inline-start: 10px;
        }

        &_nowrap {
            white-space: nowrap;
            min-width: 200px;
        }
    }

    &__events {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include scroll-mobile;

        & .card-event {
            width: calc((100% - 40px) / 3);

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
            }

            @include respond-to(mobile) {
                min-width: 252px;
                width: 252px;
                min-height: 449px;
            }
        }
    }

    & .section-wrapper.bg-light-blue:last-of-type {
        margin-block-end: 0;
    }

    @include section-indents;
}

.a11y-mode {
    .boiling-point {
        &__header {
            flex-direction: column;
            gap: 20px;

            & > div {
                width: 100%;
                margin-inline-end: 0;
            }
        }

        &__team {
            flex-direction: column;
            margin-inline-start: 0;
            width: 100%;

            & > div {
                min-height: unset;
                height: auto;
                width: 100%;
            }
        }

        &__contacts-header {
            width: 100%;
        }

        &__indicators {
            flex-direction: column;
        }

        &__indicators-tile {
            width: 100%;
            padding: 0;
        }

        &__indicator {
            margin-block-end: 40px;
        }

        &__events {
            flex-direction: column;
        }

        &__tasks {
            flex-direction: column;

            & .text-with-counter {
                width: 100%;
            }
        }

        &__block-background {
            color: var(--c-a11y-accent-color);
        }

        & .tile {
            min-height: unset;
        }

        & .card-event {
            width: 100%;
        }

        & .ul-default {
            flex-direction: column;

            & li {
                width: 100%;
            }
        }
    }
}
