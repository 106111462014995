.modal-window {
    $this: &;

    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--c-overlay-bg);
    backdrop-filter: blur(10px);
    z-index: 101; // перекрыть хэдер

    &:not(.modal-window_equal-adaptive) {
        @include respond-to(tablet) {
            height: calc(100vh - 70px);
            inset-block-start: 70px;
            backdrop-filter: none;
            align-items: center;
        }

        @include respond-to(mobile) {
            z-index: 111; // перекрыть нижнюю панель
        }
    }

    &_gallery,
    &_video {
        background-color: var(--c-overlay-dark-bg);
        backdrop-filter: none;

        #{$this}__close svg path {
            fill: var(--c-white);
        }
    }

    &__inner {
        max-width: 726px;
        padding-block: 40px;
        padding-inline: 63px;
        border-radius: 17px;
        box-shadow: 0 0 30px var(--c-light-bg);
        max-height: calc(100vh - 32px);
        overflow-y: scroll;
        background-color: var(--c-white);
        position: relative;
        scrollbar-width: none; // firefox

        &:not(.modal-review) {
            @include respond-to(tablet) {
                box-shadow: none;
                max-height: unset;
                max-width: unset;
                width: 100%;
                height: 100%;
                border-radius: unset;
                padding-inline: 30px;
                padding-block: 70px 50px;
                overflow-y: auto;
            }

            @include respond-to(mobile) {
                padding-inline: 16px;
                padding-block: 60px;
            }
        }

        &::-webkit-scrollbar {
            height: 100%;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-dark-bg);
            border: 6px solid transparent;
            border-radius: 8px;
            background-clip: content-box;
        }

        &.modal-review {
            padding-block: 80px 100px;
            padding-inline: 80px 116px;
            background-color: var(--c-light-bg);
            position: relative;
            max-width: 1146px;

            @include respond-to(tablet) {
                max-width: calc(100% - 60px);
                max-height: calc(100% - 170px);
                padding-block: 40px;
                padding-inline: 40px;
            }

            @include respond-to(mobile) {
                max-width: calc(100% - 32px);
                max-height: calc(100% - 107px);
                padding-block: 20px;
                padding-inline: 20px;
            }
        }

        &.modal-gallery {
            max-width: 914px;
            max-height: 600px;
            height: fit-content;
            padding: 0;
            box-shadow: none;
            border-radius: unset;
            position: relative;
            scrollbar-width: none; // ff

            @include respond-to(tablet) {
                max-height: 400px;
                max-width: calc(100% - 60px);
            }

            @include respond-to(mobile) {
                max-height: 260px;
                max-width: calc(100% - 32px);
            }

            &::-webkit-scrollbar {
                display: none;
            }

            & img {
                width: 100%;
                height: 600px;
                object-fit: contain;

                @include respond-to(tablet) {
                    height: 400px;
                }

                @include respond-to(mobile) {
                    height: 260px;
                }
            }

            #{$this}__button-prev,
            #{$this}__button-next {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 60px;
                background-color: var(--c-light-bg);
                border-radius: 50%;
                cursor: pointer;
                position: fixed;
                transform: translateY(-50%);
                z-index: 2; // чтобы перекрыть картинку в галерее

                @include respond-to(tablet) {
                    width: 35px;
                    height: 35px;
                    position: absolute;
                }

                @include respond-to(mobile) {
                    width: 29px;
                    height: 29px;
                }

                & svg {
                    @include respond-to(tablet) {
                        width: 16px;
                    }

                    @include respond-to(mobile) {
                        width: 13px;
                    }
                }
            }

            #{$this}__button-prev {
                inset-inline-start: 40px;
                inset-block-start: 50%;

                @include respond-to(tablet) {
                    inset-inline-start: 20px;
                }

                @include respond-to(mobile) {
                    inset-inline-start: 10px;
                }
            }

            #{$this}__button-next {
                inset-inline-end: 40px;
                inset-block-start: 50%;

                @include respond-to(tablet) {
                    inset-inline-end: 20px;
                }

                @include respond-to(mobile) {
                    inset-inline-end: 10px;
                }
            }
        }

        &.modal-video {
            width: 914px;
            max-width: unset;
            max-height: 600px;
            height: fit-content;
            padding: 0;
            box-shadow: none;
            border-radius: unset;
            position: relative;
            scrollbar-width: none; // ff

            @include respond-to(tablet) {
                max-height: 400px;
                max-width: calc(100% - 60px);
            }

            @include respond-to(mobile) {
                max-height: 260px;
                max-width: calc(100% - 32px);
            }

            &::-webkit-scrollbar {
                display: none;
            }

            & .video-container {
                margin-inline: 0;
                border-radius: 0;
                height: 600px;
                width: 100%;

                @include respond-to(tablet) {
                    height: 400px;
                }

                @include respond-to(mobile) {
                    height: 260px;
                }
            }
        }
    }

    &__close {
        width: 46px;
        height: 46px;
        position: absolute;
        inset-block-start: 17px;
        inset-inline-end: 17px;
        cursor: pointer;
        z-index: 1; // перекрыть кавычки
        padding: 0;

        @include respond-to(tablet) {
            inset-block-start: 8px;
            inset-inline-end: 8px;
        }

        @include respond-to(mobile) {
            width: 30px;
            height: 30px;
            inset-block-start: 5px;
            inset-inline-end: 5px;
        }
    }
}

.modal-fade-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.a11y-mode {
    .modal-window {
        &__close {
            & svg path {
                fill: var(--c-black);
            }
        }

        &__inner {
            max-width: 100%;
        }
    }
}
