.dp__input_wrap {
    position: relative;
    box-sizing: unset;
    border: 1px solid transparent;
    border-radius: $dp__border_radius;
    overflow: hidden;
    background-color: var(--c-light-blue);
    cursor: pointer;

    &:hover {
        --dp-border-color-hover: var(--c-accent);
        border-color: var(--dp-border-color-hover);
        outline: none;
    }
}

.dp__input {
    padding: 26px 40px 27px;
    font-family: $dp__font_family;
    outline: none;
    background-color: var(--c-light-blue);
    color: var(--dp-text-color);
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: 500;
    width: 100%;

    &::placeholder {
        color: var(--dp-text-color);
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 500;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    @include respond-to(mobile) {
        font-size: 0.875rem;
        padding: 19px;
    }
}

.dp__input_icon {
    padding: 8px 7px;
    width: 45px;
    height: 45px;
    position: absolute;
    inset-inline-end: 30px;
    inset-block-start: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    @include respond-to(mobile) {
        padding: 6px 5px;
        width: 33px;
        height: 33px;
    }

    & svg {
        width: 32px;
        height: 29px;

        @include respond-to(mobile) {
            width: 23px;
            height: 21px;
        }
    }
}

.a11y-mode {
    .dp__input_wrap {
        border: 1px solid var(--c-a11y-accent-color);
    }
}
