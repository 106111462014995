.technological-connection-portals {
    $this: &;

    &__filters {
        margin-block-end: 80px;

        @include respond-to(tablet) {
            margin-block-end: 85px;
        }

        @include respond-to(mobile) {
            margin-block-end: 80px;
        }
    }

    &__list {
        min-height: 268px;

        @include respond-to(tablet) {
            min-height: 190px;
        }

        & p {
            text-align: center;
        }

        &_tcp2 {
            min-height: unset;

            @include respond-to(tablet) {
                min-height: unset;
            }
        }
    }

    &__list-content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: stretch;
    }

    &__portal {
        width: calc((100% - 60px) / 4);
        height: auto;
        min-height: 228px;

        @include respond-to(tablet) {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        & .tile__title {
            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        & .tile__icon {
            width: 50px;
            height: 50px;
        }

        & .tile__view {
            gap: 20px;
        }

        &_tcp2 {
            width: calc(50% - 10px);

            @include respond-to(mobile) {
                width: 100%;
            }
        }
    }

    &_tcp2 {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        #{$this}__filters {
            width: calc(33.3% - 10px);

            @include respond-to(tablet) {
                width: 100%;
                margin-block-end: 50px;
            }
        }

        #{$this}__list-wrapper {
            width: calc(66.6% - 10px);

            @include respond-to(tablet) {
                width: 100%;
            }
        }

        #{$this}__contact-wrapper {
            width: 100%;
        }
    }
}

.a11y-mode {
    .technological-connection-portals {
        flex-direction: column;

        &__list-content {
            flex-direction: column;
        }

        &__list-wrapper {
            width: 100%;
        }

        &__act {
            width: 100%;
        }

        &__portal {
            width: 100%;
            min-height: unset;
        }
    }
}
