.tabs {
    display: flex;
    align-items: center;
    gap: 0.9375rem;
    max-width: fit-content;

    @include respond-to(mobile) {
        flex-direction: column;
        gap: 10px;
        max-width: 100%;
    }

    &_alt {
        @include respond-to(mobile) {
            flex-direction: row;
            gap: 7px;
            max-width: 100%;
        }
    }
}

.tab {
    &-btn {
        padding: 1.25rem;
        border-radius: 0.5rem;
        color: var(--c-primary);
        background-color: var(--c-light-bg);
        transition: color 0.3s, background-color 0.3s;
        cursor: pointer;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
            padding: 18px;
        }

        @include respond-to(mobile) {
            padding: 10px;
            width: 100%;
            text-align: center;
        }

        &:hover {
            color: var(--c-white);
            background-color: var(--c-alt-base-color);
        }

        &.active {
            color: var(--c-white);
            background-color: var(--c-alt-base-color);
        }

        &_center {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_alt_mobile {
            @include respond-to(mobile) {
                padding: 10px;
                text-align: center;
                width: fit-content;
            }
        }

        &_alt {
            font-size: 1rem;
            padding: 7px 10px;
            border-radius: 4px;
            color: var(--c-white);
            background-color: var(--c-deep-gray);
            opacity: 0.5;
            transition: opacity 0.3s;

            @include respond-to(mobile) {
                font-size: 0.875rem;
                padding-inline: 12px;
            }

            &:hover,
            &.active {
                opacity: 1;
                background-color: var(--c-deep-gray);
            }
        }
    }

    &-pane {
        display: none;

        &.active {
            display: block;
        }

        &_reset {
            border: unset;
            border-radius: unset;
            overflow: unset;
        }
    }
}

.a11y-mode {
    .tab-btn {
        border: 1px solid var(--c-black);
        width: 100%;
    }
}
