.block-with-hidden-text {
    padding: 50px;
    border-radius: 8px;
    background-color: var(--c-light-blue);

    @include respond-to(tablet) {
        padding: 40px;
    }

    @include respond-to(mobile) {
        padding: 30px;
    }

    &__heading {
        margin-block-end: 50px;
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 500;

        @include respond-to(tablet) {
            margin-block-end: 20px;
            font-size: 1.25rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 1rem;
        }
    }

    &__content {
        display: none;
    }
}
