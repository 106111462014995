.content-item {
    display: flex;
    padding-block: 30px;
    padding-inline: 10px;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    @include respond-to(tablet) {
        flex-direction: column;
        gap: 30px;
        align-items: flex-start;
    }

    @include respond-to(mobile) {
        gap: 40px;
        align-items: stretch;
        padding-block: 20px;
        padding-inline: 0;
    }

    &:not(:last-child) {
        border-block-end: 1px solid var(--c-transparent-accent);
    }

    &__text {
        max-width: 57%;

        @include respond-to(tablet) {
            max-width: unset;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.4;
        margin-block-end: 10px;

        @include respond-to(tablet) {
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            margin-block-end: 20px;
        }
    }

    &__subtitle {
        font-size: 1rem;
        line-height: 1.4;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }
}

.a11y-mode {
    .content-item {
        flex-direction: column;
        align-items: flex-start;

        &__text {
            max-width: 100%;
        }
    }
}
