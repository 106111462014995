.preloader {
    position: fixed;
    inset-inline-start: 0;
    inset-block-start: 0;
    height: 100vh;
    width: 100vw;
    border-radius: 8px;
    background-color: var(--c-overlay-bg);
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
        width: 330px;
        height: 90px;
    }
}
