.document-download {
    $this: &;

    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    color: var(--c-primary);
    background-color: var(--c-light-bg);
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    @include respond-to(mobile) {
        justify-content: space-between;
        padding: 20px;
    }

    &:visited {
        color: var(--c-primary);
    }

    &:hover {
        color: var(--c-white);
        background-color: var(--c-base-color);

        #{$this}__header {
            > svg {
                path {
                    fill: var(--c-white);
                }
            }
        }

        #{$this}__date {
            color: var(--c-white);
        }

        .tooltip-hint {
            display: block;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-block-end: 36px;

        & > :last-child {
            margin-inline-start: 16px;
        }

        @include respond-to(mobile) {
            > svg {
                width: 31px;
                height: 37px;
            }
        }
    }

    &__name {
        &.hidden {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include respond-to(mobile) {
            width: 90%;
        }
    }

    &__date {
        display: flex;
        color: var(--c-accent);
        margin-block-end: 20px;
    }

    &_alt {
        display: flex;
        flex-direction: row;
        background-color: var(--c-trueTransparent);
        padding: 0;

        &:hover {
            color: var(--c-alt-base-color);
            background-color: var(--c-trueTransparent);

            #{$this}__icon {
                & > svg {
                    path {
                        fill: var(--c-alt-base-color);
                    }
                }
            }
        }

        &:not(:last-child) {
            margin-block-end: 30px;
        }

        #{$this}__icon {
            margin-inline-end: 20px;

            & svg {
                @include respond-to(mobile) {
                    width: 21px;
                    height: 25px;
                }
            }

            & path {
                transition: fill 0.3s ease;
            }
        }

        #{$this}__name {
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 500;

            @include respond-to(tablet) {
                font-size: 1.125rem;
                line-height: 1.65;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
                line-height: 1.4;
            }
        }
    }
}

.a11y-mode {
    .document-download {
        border: 1px solid var(--c-black);

        &__icon {
            & svg path {
                fill: var(--c-black);
            }
        }

        &_alt {
            padding: 20px;
        }

        &__header {
            & svg path {
                fill: var(--c-a11y-accent-color);
            }
        }
    }
}
