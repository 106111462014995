.thumbs-slider {
    position: relative;

    &__buttons {
        & .thumbs-swiper-button-prev,
        & .thumbs-swiper-button-next {
            width: 36px;
            height: 36px;
            position: absolute;
            inset-block-start: 50%;
            transform: translateY(-50%);
            z-index: 1;
            background-color: var(--c-alt-base-color);

            &::after {
                content: none;
            }

            &.swiper-button-disabled {
                background-color: var(--c-deep-gray);
            }
        }

        & .thumbs-swiper-button-prev {
            inset-inline-start: -10px;
        }

        & .thumbs-swiper-button-next {
            inset-inline-end: -10px;
        }
    }

    & img {
        max-height: 108px;
        min-height: 108px;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;

        @include respond-to(tablet) {
            max-height: 86px;
            min-height: 86px;
        }

        @include respond-to(mobile) {
            max-height: 70px;
            min-height: 70px;
        }
    }

    & .swiper-slide {
        cursor: pointer;
    }

    & .swiper-slide:not(.swiper-slide-thumb-active) {
        opacity: 0.5;
    }
}

.a11y-mode {
    .thumbs-slider {
        display: none;
    }
}
