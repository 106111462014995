.technological-connection-portals-filters {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);

    @include respond-to(tablet) {
        flex-direction: column;
        gap: 40px;
    }

    @include respond-to(mobile) {
        gap: 30px;
    }

    & > li {
        width: calc((100% - 40px) / 3);

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 20px;
        margin-block-end: 13px;
        flex: 1;

        @include respond-to(tablet) {
            margin-block-end: 0;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 10px;
        }

        &:nth-of-type(3n) {
            justify-content: center;
        }

        & .btn {
            @include respond-to(mobile) {
                width: 100%;
                text-align: center;
            }
        }
    }

    &_tcp2 {
        flex-direction: column;
        gap: 30px;

        & > li {
            width: 100%;
        }

        & .btn {
            width: 100%;

            @include respond-to(tablet) {
                width: fit-content;
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        & .select__choosen {
            background-color: var(--c-light-bg);
        }
    }
}

.a11y-mode {
    .technological-connection-portals-filters {
        flex-direction: column;

        & > li {
            width: fit-content;
            max-width: 100%;
        }
    }
}
