@use "sass:map";

// Margin and Padding

@each $breakpoint in map.keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }
                .#{$abbrev}t#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-block-start: $length !important;
                }
                .#{$abbrev}r#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-inline-end: $length !important;
                }
                .#{$abbrev}b#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-block-end: $length !important;
                }
                .#{$abbrev}l#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-inline-start: $length !important;
                }
            }
        }

        // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
        @each $size, $length in $spacers {
            @if $size != 0 {
                .m#{$infix}-n#{$size} {
                    margin: -$length !important;
                }
                .mt#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-block-start: -$length !important;
                }
                .mr#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-inline-end: -$length !important;
                }
                .mb#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-block-end: -$length !important;
                }
                .ml#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-inline-start: -$length !important;
                }
            }
        }

        // Some special margin utils
        .m#{$infix}-auto {
            margin: auto !important;
        }
        .mt#{$infix}-auto,
        .my#{$infix}-auto {
            margin-block-start: auto !important;
        }
        .mr#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-inline-end: auto; // убрала !important из-за конфликта с сайтами 24
        }
        .mb#{$infix}-auto,
        .my#{$infix}-auto {
            margin-block-end: auto !important;
        }
        .ml#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-inline-start: auto; // убрала !important из-за конфликта с сайтами 24
        }
    }
}
