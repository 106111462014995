.preferential-zones {
    $this: &;

    &__description {
        display: flex;

        @include respond-to(tablet) {
            flex-direction: column;

            > :not(:last-child) {
                margin-block-end: 20px;
            }
        }

        &-title {
            @include respond-to(tablet) {
                font-size: 2.5rem;
                line-height: 1;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                line-height: 1.2;
            }
        }

        &-subtitle {
            font-size: 1.125rem;
            line-height: 1.7;

            @include respond-to(mobile) {
                font-size: 0.875rem;
                line-height: 1.4;
            }
        }

        &-tiles {
            .tiles__container {
                align-items: stretch;
            }

            .tile {
                @include respond-to(mobile) {
                    min-height: unset;
                }

                &__view {
                    padding-inline-end: 60px;
                    padding-block-start: 90px;

                    @include respond-to(tablet) {
                        padding-inline-end: 20px;
                    }
                }

                &__text-content {
                    @include respond-to(tablet) {
                        width: unset;
                    }

                    & > h4 {
                        font-size: 1.25rem;

                        @include respond-to(mobile) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }

    &__highlight {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3 - 100px), 1fr));
        gap: 50px;
        padding: 80px 50px;
        border-radius: 8px;
        color: var(--c-primary);
        background-color: var(--c-light-bg);
        text-align: center;
        transition: all 0.2s;

        &:hover {
            background-color: var(--c-primary);
            color: var(--c-light-bg);
        }

        @include respond-to(tablet) {
            grid-template-columns: repeat(2, 1fr);
            padding: 50px 25px;
        }

        @include respond-to(mobile) {
            grid-template-columns: 100%;
            padding: 40px 20px;
        }

        &-indicator {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond-to(mobile) {
                align-items: flex-start;
                text-align: left;
            }
        }

        &-value {
            font-weight: 600;
            font-size: 3.75rem;
            line-height: 1.1;

            @include respond-to(tablet) {
                font-size: 2.5rem;
                line-height: 1;
            }

            @include respond-to(mobile) {
                font-weight: 700;
                font-size: 2.1875rem;
            }
        }

        &-units {
            font-weight: 600;
            font-size: 2.5rem;
            line-height: 1.1;

            @include respond-to(tablet) {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.4;
            }
        }

        &-subtitle {
            font-size: 1.125rem;
            line-height: 1.65;
            margin-block-end: 80px;

            @include respond-to(tablet) {
                margin-block-end: 60px;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
                line-height: 1.4;
                margin-block-end: 40px;
            }
        }

        &_alt {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 80px 60px;
            background-color: var(--c-transparent);

            @include respond-to(tablet) {
                gap: 50px 70px;
            }

            @include respond-to(mobile) {
                gap: 50px;
            }

            &:hover {
                background-color: var(--c-transparent);
                color: var(--c-primary);
            }

            #{$this}__highlight-indicator {
                width: calc(33.3% - (120px / 3));
                text-align: start;
                align-items: flex-start;

                @include respond-to(tablet) {
                    width: calc(50% - 35px);
                }

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & .card-01 {
            width: calc(50% - 10px);

            @include respond-to(tablet) {
                width: 100%;
            }

            &__content {
                flex-direction: column;
                gap: 20px;
                align-items: flex-start;

                @include respond-to(mobile) {
                    gap: 0;
                }

                & .btn {
                    align-self: flex-end;
                }
            }

            &__left {
                margin-inline-end: 0;
            }
        }

        &_alt {
            .card-01 {
                width: 100%;
            }
        }
    }

    & .support-measures__link {
        &:hover {
            background-color: var(--c-light-bg);
        }
    }

    & .section-wrapper {
        margin-block: 100px;

        @include respond-to(mobile) {
            margin-block: 80px;
        }

        &.bg-light-blue {
            padding-block: 100px;
            margin-block: 0;

            @include respond-to(mobile) {
                padding-block: 80px;
                margin-block: 0;
            }
        }
    }
}

.a11y-mode {
    .preferential-zones {
        &__description {
            flex-direction: column;
        }

        &__highlight {
            display: flex;
            flex-direction: column;
            padding: 0;

            & h4 {
                width: 100%;
            }
        }

        &__cards {
            flex-direction: column;

            .card-01 {
                width: 100%;
            }
        }
    }
}
