.perm-krai {
    $this: &;

    &__text {
        font-size: 1.125rem;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
        }
    }

    &__image {
        border-radius: 8px;
    }

    &__column-text {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.125rem;
        line-height: 1.65;
        gap: 32px;

        & > * {
            break-inside: avoid;
            width: calc((100% - 32px) / 2);

            & img {
                border-radius: 8px;
                width: 100%;
            }
        }

        @include respond-to(tablet) {
            flex-direction: column;
            font-size: 1rem;
            gap: 20px;

            & > * {
                width: 100%;
            }
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }

        &_g-20 {
            gap: 20px;

            & > * {
                width: calc((100% - 20px) / 2);

                @include respond-to(tablet) {
                    width: 100%;
                }
            }
        }

        &_g-40 {
            gap: 40px;

            & > * {
                width: calc((100% - 40px) / 2);

                @include respond-to(tablet) {
                    width: 100%;
                }
            }
        }
    }

    &__data {
        border-radius: 8px;
        overflow: hidden;

        &-columns {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 5px;

            &_t-table {
                @include respond-to(tablet) {
                    min-width: 720px;
                }
            }

            &_m-tiles {
                @include respond-to(mobile) {
                    gap: 0;
                    grid-template-columns: 1fr;

                    & > :nth-child(2n + 1) {
                        border-start-start-radius: 8px;
                        border-start-end-radius: 8px;
                        padding-block-end: 10px;
                    }

                    & > :nth-child(2n) {
                        background-color: var(--c-white);
                        border-end-start-radius: 8px;
                        border-end-end-radius: 8px;
                        margin-block-end: 10px;
                        padding-block-start: 0;

                        & h4 {
                            font-size: 0.75rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        &-card {
            padding: 24px 40px;

            @include respond-to(mobile) {
                padding: 20px;
            }

            &_header {
                @include respond-to(tablet) {
                    min-width: 720px;
                }
            }
        }
    }

    &__description {
        display: flex;
        gap: 20px;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        &-image {
            width: calc((100% - 20px) / 2);

            @include respond-to(tablet) {
                width: 100%;
            }

            & img {
                border-radius: 8px;
                height: 100%;
                object-fit: cover;
                width: 100%;

                @include respond-to(tablet) {
                    height: 400px;
                }

                @include respond-to(mobile) {
                    height: 216px;
                }
            }
        }

        &-text {
            background-color: var(--c-light-blue);
            padding: 50px;
            border-radius: 8px;
            width: calc((100% - 20px) / 2);

            & > span {
                line-height: 1.7;
            }

            @include respond-to(tablet) {
                padding: 40px;
                width: 100%;
            }

            @include respond-to(mobile) {
                padding: 30px;
            }
        }
    }

    &__geography-tiles {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(tablet) {
            flex-wrap: nowrap;
            margin-inline-end: -30px;
            overflow: scroll hidden;
            margin-block-end: 40px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }

        & .tile__view {
            padding-block-start: 100px;
            flex-direction: column;
        }

        & .tile__image {
            min-width: 100%;
            height: 100% !important;
            object-fit: cover !important;
        }

        & .tile {
            height: auto;

            @include respond-to(tablet) {
                flex: 0 0 45%;
            }

            @include respond-to(mobile) {
                flex: 0 0 70%;
            }

            &:last-child {
                @include respond-to(tablet) {
                    display: none;
                }
            }

            &:nth-last-child(2) {
                @include respond-to(tablet) {
                    margin-inline-end: 30px;
                }
            }
        }
    }

    &__geography-image {
        display: none;

        @include respond-to(tablet) {
            display: flex;
        }

        @include respond-to(mobile) {
            height: 216px;
        }

        & img {
            border-radius: 8px;
            height: 340px;
            object-fit: cover;
            width: 100%;

            @include respond-to(mobile) {
                height: auto;
            }
        }
    }

    &__select {
        margin-block-end: 50px;

        @include respond-to(mobile) {
            margin-block-end: 40px;
        }

        & .select__choosen {
            background-color: var(--c-light-bg);
            font-size: 1.125rem;
            line-height: 1.4;
            font-weight: 500;
            height: 80px;
            color: var(--c-primary);
            padding-inline: 20px 30px;

            @include respond-to(mobile) {
                height: 60px;
            }

            & span {
                font-size: 1.125rem;

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }

        & .select__list {
            max-height: 293px;

            &::-webkit-scrollbar {
                background-color: var(--c-true-transparent);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--c-light-bg);
            }
        }

        & .select__item {
            align-items: center;
            height: 60px;
        }

        &.select__icon {
            & img {
                height: 100%;
            }
        }
    }

    &__transport {
        overflow: visible;

        &-tabs {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-block-end: 65px;

            & li {
                flex-grow: 1;

                &:last-child,
                &:nth-last-child(2) {
                    flex-grow: 0;
                }
            }

            & .tab-btn {
                min-width: max-content;
                width: 100%;
            }
        }

        &-tab-header {
            align-items: center;
            display: flex;
            margin-block-end: 34px;

            & > img {
                margin-inline-end: 14px;

                @include respond-to(mobile) {
                    height: 40px;
                    width: 40px;
                }
            }

            & h5 {
                @include respond-to(mobile) {
                    display: none;
                }
            }
        }
    }

    &__culture {
        &:not(:last-of-type) {
            margin-block-end: 100px;
        }

        & .gallery {
            &__view {
                margin-block-end: 20px;
            }

            &__preview {
                @include respond-to(tablet) {
                    height: 98px;
                }

                @include respond-to(mobile) {
                    height: 82px;

                    & div {
                        min-width: 140px;
                    }
                }
            }

            &__photo-block {
                height: 690px;

                @include respond-to(tablet) {
                    height: 400px;
                }

                @include respond-to(mobile) {
                    height: 230px;
                }
            }

            &__photo-text {
                @include respond-to(tablet) {
                    display: none;
                }
            }

            &__photo-description-block {
                max-width: 584px;
            }
        }
    }

    &__events-heading {
        position: relative;

        & .btn {
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 100%;

            // нестандартный размер, потому что кнопка не влезает в стандартный
            @media screen and (max-width: 1320px) {
                position: static;
                margin-block-end: 20px;
            }
        }

        &_alt {
            display: flex;
            gap: 84px;
            align-items: flex-end;

            @include respond-to(tablet) {
                flex-direction: column;
                gap: 40px;
                align-items: flex-start;
            }

            & .btn {
                position: static;
            }
        }
    }

    &__events-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;

        @include respond-to(tablet) {
            grid-template-columns: 1fr;
        }

        & .perm-krai__events-card:first-child {
            grid-area: 1 / 1 / 3 / 2;
        }
    }

    &__events-header {
        align-items: flex-end;
        display: flex;

        @include respond-to(tablet) {
            margin-inline-end: 30px;
        }
    }

    &__events-card {
        background-color: var(--c-white);
        border-radius: 8px;
        margin-inline-start: 20px;
        font-weight: 400;
        flex: 0 0 calc(47% - 20px);
        padding-block: 30px 50px;
        padding-inline: 30px;

        img {
            height: 210px;
            object-fit: cover;
            width: 100%;
        }

        @include respond-to(tablet) {
            flex: 0 0 65%;
            padding-block: 20px 30px;
            padding-inline: 20px;
        }

        @include respond-to(mobile) {
            flex: 0 0 80%;
        }

        &_alt {
            background-color: var(--c-light-bg);
            margin-inline-start: 0;
        }
    }

    &__events-text {
        max-width: 62%;

        @include respond-to(tablet) {
            max-width: unset;
        }
    }

    &__events-carousel {
        .carousel-buttons {
            @include respond-to(tablet) {
                display: none !important;
            }
        }
    }

    &__sports {
        &-tiles {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            @include respond-to(tablet) {
                display: flex;
                margin-inline-end: -30px;
                overflow: scroll hidden;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @include respond-to(mobile) {
                margin-inline-end: -16px;
            }
        }

        &-tile {
            min-height: 476px;
            padding-block: 30px 60px;
            padding-inline: 30px;
            background-color: var(--c-light-blue);
            border-radius: 8px;

            @include respond-to(tablet) {
                flex: 0 0 65%;
                padding-block: 20px 50px;
                padding-inline: 20px;

                &:last-child {
                    margin-right: 30px;
                }
            }

            @include respond-to(mobile) {
                flex: 0 0 80%;

                &:last-child {
                    margin-right: 16px;
                }
            }

            &_alt {
                @include respond-to(tablet) {
                    min-height: 465px;
                }

                @include respond-to(mobile) {
                    min-height: 410px;
                }
            }
        }

        &-tile-image {
            height: 300px;
            object-fit: cover;
            width: 100%;
        }

        &-tile-text {
            max-width: 70%;

            &_alt {
                white-space: normal;

                @include respond-to(mobile) {
                    max-width: unset;
                }
            }
        }

        &-results {
            > :not(:last-child) {
                margin-block-end: 5px;
            }

            @include respond-to(tablet) {
                height: 800px;
                overflow: scroll;
            }

            @include respond-to(mobile) {
                height: 600px;
            }
        }

        &-result {
            display: flex;
            align-items: center;
        }

        &-result-icon {
            width: 20px;
            height: 24px;
            margin-inline-end: 20px;
            align-self: flex-start;
        }

        &-accessibility {
            position: relative;
            padding: 60px;
            border-radius: 8px;
            color: var(--c-white);
            background-color: var(--c-primary);

            @include respond-to(tablet) {
                padding: 40px;
            }

            @include respond-to(mobile) {
                padding-inline: 30px;
            }
        }

        &-accessibility-bg {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
        }
    }

    &__education {
        &:not(:last-of-type) {
            margin-block-end: 100px;
        }

        &-rankings {
            display: flex;
            flex-wrap: wrap;
            gap: 20px 60px;

            & li {
                width: calc((100% - 60px) / 2);
            }

            @include respond-to(mobile) {
                li {
                    width: 100%;
                }
            }
        }

        &-university-name {
            max-width: 60%;
        }
    }

    &__ranking-list,
    &__ranking {
        & ul li {
            padding-inline-start: 26px;
            position: relative;

            &::before {
                content: "";
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: var(--c-primary);
                position: absolute;
                inset-inline-start: 12px;
                inset-block-start: 0.5em;
            }
        }
    }

    &__territories {
        &-bg {
            margin-block-start: 0 !important; // перекрыть стили section-wrapper
            background: radial-gradient(
                    circle at calc(50% - 730px) 250px,
                    rgba(77 146 245 / 40%) 50px,
                    transparent 400px
                ),
                radial-gradient(
                    circle at calc(50% + 730px) calc(100% - 150px),
                    rgba(77 146 245 / 40%) 0,
                    transparent 400px
                ),
                var(--c-secondary);

            @include respond-to(tablet) {
                background: radial-gradient(circle at 0 250px, rgba(77 146 245 / 40%) 50px, transparent 400px),
                    radial-gradient(circle at 100% calc(100% - 150px), rgba(77 146 245 / 40%) 0, transparent 400px),
                    var(--c-secondary);
            }

            @include respond-to(mobile) {
                background: radial-gradient(circle at 0 50px, rgba(77 146 245 / 40%) 50px, transparent 400px),
                    radial-gradient(circle at 100% 100%, rgba(77 146 245 / 40%) 0, transparent 400px),
                    var(--c-secondary);
            }

            &:last-child {
                margin-block-end: 0 !important; // перекрыть стили section-wrapper
            }
        }

        &-wrapper {
            display: flex;
            color: var(--c-white);
            align-items: center;
            padding-block: 100px;

            @include respond-to(tablet) {
                overflow: hidden visible;
            }

            @include respond-to(mobile) {
                padding-block: 80px;
            }

            &_alt {
                padding-block: 0;
                gap: 84px;
                color: var(--c-primary);

                @include respond-to(tablet) {
                    overflow: visible;
                    flex-direction: column;
                    gap: 56px;
                }

                @include respond-to(mobile) {
                    padding-block: 0;
                    gap: 40px;
                }
            }
        }

        &-text {
            width: 50%;
            z-index: 1; // перекрыть картинку

            @include respond-to(tablet) {
                min-height: 700px;
                position: relative;
                width: 100%;
            }

            @include respond-to(mobile) {
                min-height: 500px;
            }

            & h2 {
                margin-block-end: 40px;

                @include respond-to(tablet) {
                    margin-block-end: 30px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 20px;
                }
            }

            & p {
                margin-block-end: 100px;

                @include respond-to(tablet) {
                    margin-block-end: 66px;
                    width: 70%;
                }

                @include respond-to(mobile) {
                    margin-block-end: 40px;
                    width: 100%;
                }
            }

            &_alt {
                @include respond-to(tablet) {
                    min-height: unset;
                }

                & p {
                    @include respond-to(tablet) {
                        width: 100%;
                    }
                }
            }
        }

        &-data {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 120px;

            @include respond-to(tablet) {
                grid-template-columns: 1fr;
                row-gap: 60px;
            }

            @include respond-to(mobile) {
                row-gap: 30px;
            }

            &_alt {
                row-gap: 94px;
                column-gap: 45px;

                @include respond-to(tablet) {
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 85px;
                }

                @include respond-to(mobile) {
                    grid-template-columns: 1fr 1fr;
                    column-gap: 20px;
                    row-gap: 40px;
                }

                & > div:first-child {
                    grid-area: 1 / 1 / 2 / 3;

                    @include respond-to(tablet) {
                        grid-area: 1 / 1 / 2 / 2;
                    }
                }
            }
        }

        &-image {
            max-width: 50%;

            @include respond-to(tablet) {
                height: 67%;
                inset-block-start: 25%;
                inset-inline-end: -10%;
                max-width: unset;
                position: absolute;
            }
        }
    }

    &__more-links {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        // не влезает при стандартных размерах
        @media screen and (max-width: 1123px) {
            flex-wrap: nowrap;
            overflow: scroll hidden;
            scrollbar-width: none; // ff

            &::-webkit-scrollbar {
                display: none;
            }
        }

        & .simple-link-block {
            flex: 0 0 calc((100% - 60px) / 4);
            height: unset;
            min-height: 230px;

            @media screen and (max-width: 1123px) {
                flex: 0 0 45%;
            }

            @include respond-to(mobile) {
                flex: 0 0 70%;
            }
        }

        &_alt {
            align-items: stretch;

            #{$this}__more-link {
                @include respond-to(tablet) {
                    flex: 0 0 330px;
                }

                @include respond-to(mobile) {
                    flex: 0 0 252px;
                    min-height: 320px;
                }

                &:last-child {
                    @include respond-to(tablet) {
                        margin-inline-end: 30px;
                    }

                    @include respond-to(mobile) {
                        margin-inline-end: 16px;
                    }
                }
            }
        }
    }

    &__more-link {
        flex: 0 0 calc((100% - 60px) / 4);
        min-height: 100%;
    }

    &__anchors {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    &__tiles-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & .text-with-counter {
            width: calc((100% - 120px) / 3);
            max-width: calc(((100% - 120px) / 3) * 2);
            flex-grow: 1;

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
                max-width: unset;
                flex-grow: 1;
            }

            @include respond-to(mobile) {
                width: 100%;
            }

            &:not(:last-child) {
                margin-block-end: 0;
            }
        }

        & .indicator-tile {
            width: calc((100% - 40px) / 3);

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &_big-gap {
            gap: 60px;

            @include respond-to(tablet) {
                gap: 20px;
            }

            @include respond-to(tablet) {
                gap: 40px;
            }
        }
    }

    &__gallery-wrapper {
        @include respond-to(tablet) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        #{$this}__gallery {
            @include respond-to(tablet) {
                order: 2;
            }
        }
    }

    &__gallery {
        float: right;
        width: calc((100% - 10px) / 2);
        margin-inline-start: 20px;
        margin-block-end: 20px;

        @include respond-to(tablet) {
            width: 100%;
            margin-inline-start: 0;
            margin-block-end: 0;
        }

        & .glide__track {
            max-height: 108px;

            @include respond-to(tablet) {
                margin-inline: 0;
                clip-path: none;
                max-height: 82px;
            }

            @include respond-to(mobile) {
                max-height: 70px;
            }
        }

        & .gallery__preview li {
            min-width: 214px;
            max-height: 108px;

            @include respond-to(tablet) {
                min-width: 172px;
                max-height: 82px;
            }

            @include respond-to(mobile) {
                min-width: 138px;
                max-height: 70px;
            }
        }
    }

    & .glide__track {
        overflow: hidden;

        @include respond-to(tablet) {
            margin-inline: 0;
            clip-path: none;
        }
    }

    & p:not(:last-child) {
        margin-block-end: 20px;
    }

    @include section-indents;

    @include anchor-fix;
}

.a11y-mode {
    .perm-krai {
        &__more-links {
            flex-direction: column;
        }

        & .simple-link-block {
            align-self: center;

            @include link-sizes-fix;
        }

        &__data-columns {
            display: block;
            min-width: unset;
        }

        &__data-card {
            padding-block: 24px;
            padding-inline: 0;

            &_header {
                padding-inline: 40px;
                min-width: unset;
            }
        }

        &__transport-list {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        &__events-heading {
            margin-block-end: 40px;

            & .btn {
                position: static;
                margin-block-end: 0;
            }
        }

        &__events-text {
            max-width: unset;
        }

        &__column-text {
            & > * {
                width: 100%;
            }
        }

        &__sports-tiles {
            display: grid;
            grid-template-columns: 1fr;
        }

        &__sports-tile-text {
            max-width: unset;
            max-height: unset;
        }

        &__sports-results {
            height: auto;
            overflow: hidden;
        }

        &__education-rankings {
            flex-direction: column;

            & li {
                width: 100%;
            }
        }

        &__territories-text {
            width: 100%;

            & p {
                width: 100%;
            }
        }

        &__territories-data {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        &__geography-tiles {
            flex-direction: column;
            margin-block-end: 0;
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .perm-krai {
        &__column-text {
            margin-block-end: 0 !important; // перекрыть спэйсинги
        }

        &__sports-tile {
            min-height: fit-content;
        }

        &__territories-bg {
            background: var(--c-black);
        }

        &__geography-image {
            display: none;
        }
    }
}
