.for-investors {
    &__tiles {
        & .btn {
            height: unset;
            white-space: unset;

            @include respond-to(mobile) {
                text-align: center;
                margin-block-start: 40px;
            }
        }
    }

    &__success-stories {
        & .carousel__list {
            @include respond-to(desktop) {
                overflow: scroll hidden;
                transform: translateX(0) !important;
                padding-inline-end: 0;
                scrollbar-width: none; // ff

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @include respond-to(tablet) {
                padding-inline-end: 30px;
            }

            @include respond-to(mobile) {
                padding-inline-end: 16px;
            }
        }

        & .carousel__item {
            @include respond-to(desktop) {
                flex: 0 0 40%;
            }

            @include respond-to(tablet) {
                flex: 0 0 calc((52% - 20px));
                height: 500px;

                &::before {
                    font-size: 1.875rem;
                }
            }

            @include respond-to(mobile) {
                flex: 0 0 calc((80% - 20px));
                height: 400px;
            }
        }

        .icon-btn {
            @include respond-to(desktop) {
                display: none !important;
            }
        }
    }

    &__infographics {
        &-tabs {
            margin-block-end: 50px;

            @include respond-to(mobile) {
                margin-block-end: 30px;
            }

            & li {
                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        &-content {
            max-height: 658px;
            border-radius: 0.5rem;
            overflow: hidden;

            @include respond-to(tablet) {
                max-height: 1963px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &_alt {
                max-height: unset;
            }
        }

        &-item {
            max-height: 658px;

            @include respond-to(tablet) {
                max-height: 1963px;
            }

            @include respond-to(mobile) {
                max-height: 1359px;
            }

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.5rem;
                overflow: hidden;
            }

            &:not(:last-child) {
                margin-block-end: 40px;
            }
        }
    }

    &__headline {
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-block-end: 60px;

        @include respond-to(tablet) {
            align-items: center;
            margin-block-end: 40px;
        }

        @include respond-to(mobile) {
            margin-block-end: 20px;
        }

        & .btn {
            min-width: max-content;
        }
    }

    &__reviews {
        & h2 {
            max-width: 685px;
            margin-block-end: 0;
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @include respond-to(tablet) {
                flex-wrap: nowrap;
                margin-inline-end: -30px;
                overflow-x: scroll;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @include respond-to(mobile) {
                margin-inline-end: -16px;
            }
        }

        & > .btn {
            margin-block-start: 40px;

            @include respond-to(tablet) {
                display: none;
            }

            @include respond-to(mobile) {
                text-align: center;
                display: block;
            }
        }
    }

    &__review {
        max-width: calc((100% - 20px) / 2);

        @include respond-to(tablet) {
            max-width: unset;
            flex: 0 0 76%;
        }

        @include respond-to(mobile) {
            flex: 0 0 83%;
        }

        &:last-child {
            @include respond-to(tablet) {
                margin-inline-end: 30px;
            }

            @include respond-to(mobile) {
                margin-inline-end: 16px;
            }
        }
    }

    &__reviews-carousel {
        overflow: hidden;

        @include respond-to(tablet) {
            overflow: visible;
        }

        & ul {
            display: flex;
            margin-inline-start: -20px;
            transition: transform 0.8s ease-in-out;

            @include respond-to(tablet) {
                margin-inline-start: 30px;
                overflow: scroll hidden;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @include respond-to(mobile) {
                margin-inline-start: 16px;
            }
        }

        & li {
            margin-inline-start: 20px;
            height: 520px;

            & .review {
                height: 100%;
            }

            & .review__user-info {
                margin-block-end: 20px;
            }

            & .review__review-text {
                -webkit-line-clamp: 9;

                @include respond-to(tablet) {
                    -webkit-line-clamp: 6;
                }

                @include respond-to(mobile) {
                    -webkit-line-clamp: 5;
                }
            }

            @include respond-to(tablet) {
                height: 466px;
                margin-inline-start: 0;

                &:last-child {
                    margin-inline-end: 30px;
                }
            }

            @include respond-to(mobile) {
                height: 400px;

                &:last-child {
                    margin-inline-end: 16px;
                }
            }
        }

        & .swiper-slide {
            & .review__review-text {
                -webkit-line-clamp: 9;

                @include respond-to(tablet) {
                    -webkit-line-clamp: 6;
                }

                @include respond-to(mobile) {
                    -webkit-line-clamp: 5;
                }
            }
        }
    }

    & .tile__view,
    & .tile__link {
        z-index: 1;
    }

    @include section-indents;

    @include anchor-fix;

    .glide__slide--clone {
        filter: brightness(0.5);
    }
}

.a11y-mode {
    .for-investors {
        &__headline {
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
        }

        &__reviews-content {
            flex-direction: column;
        }

        &__review {
            max-width: unset;
            width: 100%;

            & > a {
                @include link-sizes-fix;
            }
        }

        &__reviews-carousel {
            & .swiper-slide {
                height: auto;
            }
        }
    }
}
