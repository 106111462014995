.request-form {
    color: var(--c-primary);

    &__title {
        font-size: 1.5em;
        line-height: 1.4;
        font-weight: 500;
        margin-block-end: 10px;

        @include respond-to(tablet) {
            font-weight: 600;
            font-size: 1.875em;
            line-height: 1.2;
            margin-block-end: 15px;
            text-align: center;
        }

        @include respond-to(mobile) {
            font-size: 1.375em;
            margin-block-end: 10px;
        }
    }

    &__subtitle {
        font-size: 0.875em;
        line-height: 1.4;
        margin-block-end: 30px;

        @include respond-to(tablet) {
            font-weight: 500;
            font-size: 1em;
            line-height: 1.4;
            margin-block-end: 40px;
            text-align: center;
        }

        @include respond-to(mobile) {
            font-weight: 400;
            font-size: 0.875em;
            margin-block-end: 30px;
        }
    }

    &__body {
        width: 600px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding-block-end: 30px;
        border-block-end: 1px solid var(--c-border);
        margin-block-end: 30px;

        @include respond-to(tablet) {
            width: 100%;
        }

        @include respond-to(mobile) {
            gap: 10px;
            padding-block-end: 20px;
            margin-block-end: 20px;
        }

        .landing-block & {
            width: 100%;
        }
    }

    &__input {
        flex: 0 0 100%;

        &_half {
            flex: 0 0 calc(50% - 10px);

            @include respond-to(mobile) {
                flex: 0 0 100%;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-to(mobile) {
            flex-direction: column;
            align-items: stretch;
            gap: 40px;

            & .checkbox__field {
                margin-block-start: 2px;
            }
        }
    }

    &__success-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 35px;

        @include respond-to(mobile) {
            align-items: stretch;
            text-align: center;
        }

        & .btn {
            margin-inline-start: 0;
        }
    }

    &__success-icon {
        margin-block-end: 20px;
        max-height: 40px;

        @include respond-to(mobile) {
            max-height: 30px;

            & svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    &__success-text {
        text-align: center;
        font-size: 1.5em;
        line-height: 1.4;
        margin-block-end: 40px;

        @include respond-to(mobile) {
            font-size: 1.375em;
            line-height: 1.2;
            margin-block-end: 50px;
        }
    }

    & .input__field {
        font-size: 0.875em;
        line-height: 1.4;
        padding: 18px 20px 17px;
        border-radius: 8px;
        background-color: var(--c-blue-bg);
        border: 1px solid var(--c-blue-bg);
        transition: 0.3s background-color, 0.3s border-color;

        &:hover:not(:disabled) {
            background-color: var(--c-white);
            border: 1px solid var(--c-transparent-border);
        }

        &:focus,
        :focus-visible {
            background-color: var(--c-white);
            border: 1px solid var(--c-transparent-border);
        }

        &_textarea {
            display: block;
            resize: none;
            height: 115px;
        }

        &.show-error {
            background-color: var(--c-error-bg);
            border: 1px solid var(--c-error-bg);

            & + .input__error {
                display: block;
                font-size: 0.625em;
                line-height: 1.4;
                margin-block-start: 4px;
                color: var(--c-error);
            }
        }
    }

    & .input__error {
        &_file {
            display: block;
            width: 100%;
            font-size: 0.625em;
            line-height: 1.4;
            color: var(--c-error);
        }
    }

    & .input__label {
        display: block;
    }

    & .checkbox__text {
        font-size: 0.75em;
        line-height: 1.4;
        color: var(--c-notifiaction);
    }

    & .checkbox__link {
        font-size: 0.75rem;
        line-height: 1.4;
        color: var(--c-alt-base-color);
    }

    & .btn {
        font-weight: 500 !important; // перекрыть стили на лендинге
    }
}

.a11y-mode {
    .request-form {
        & .input__field {
            border-color: var(--c-black);
        }

        &__footer {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }

        &__body {
            width: 100%;
        }
    }
}
