.a11y-control {
    padding: 20px;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
    background: var(--c-white);
    color: var(--c-black);
    display: none;
    border-block-end: 3px solid var(--c-black);
    max-height: 25vh;
    overflow-y: auto;

    &__title {
        font-size: 1em;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__button {
        min-width: 3rem;
        height: auto;
        min-height: 3em;
        border: 2px solid var(--c-black);
        font-weight: bold;
        color: var(--c-black);
    }

    &__button-text {
        font-size: 1rem;
    }

    &__button-text--text-small {
        font-size: 1rem;
    }

    &__button-text--text-medium {
        font-size: 1.5rem;
    }

    &__button-text--text-big {
        font-size: 2rem;
    }

    &__button--black-on-white {
        color: black;
        background-color: white;
    }

    &__button--white-on-black {
        color: white;
        background-color: black;
    }

    &__button--darkblue-on-blue {
        color: #063462;
        background-color: #9dd1ff;
    }

    &__button--brown-on-beige {
        color: #4d4b43;
        background-color: #f7f3d6;
    }

    &__button--green-on-darkbrown {
        color: #a9e44d;
        background-color: #3b2716;
    }

    input {
        appearance: none;
    }
}

.a11y-mode {
    .a11y-control {
        display: flex;
    }

    &.a11y-mode-font-size-standard {
        font-size: 1em;
    }

    &.a11y-mode-font-size-medium {
        font-size: 1.5em;
    }

    &.a11y-mode-font-size-big {
        font-size: 2em;
        word-wrap: break-word;
    }

    &.a11y-mode-font-serif {
        body,
        button {
            font-family: "Times New Roman", serif !important;
        }
    }

    &.a11y-mode-letter-spacing-medium {
        body,
        .a11y-control__button {
            letter-spacing: 0.5em;
        }
    }

    &.a11y-mode-letter-spacing-big {
        body,
        .a11y-control__button {
            letter-spacing: 0.7em;
            word-wrap: break-word;
        }
    }

    &.a11y-mode-images-hide {
        img,
        picture {
            display: none !important;
        }
    }

    button:focus,
    a:focus,
    input:not([type="checkbox"]):focus {
        outline: 3px solid var(--c-black) !important;
        border-radius: 0;
        border: none;
    }
}
