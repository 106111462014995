.municipalities-filters {
    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include respond-to(tablet) {
            gap: 30px;
        }

        @include respond-to(mobile) {
            gap: 20px;
        }
    }

    &__filter {
        width: calc(33.3% - (40px / 3));

        @include respond-to(tablet) {
            width: 100%;
        }

        & .select__label {
            font-size: 18px;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-size: 14px;
            }
        }
    }

    &__buttons {
        width: calc(33.3% - (40px / 3));
        display: flex;
        gap: 20px;
        align-items: flex-end;
        justify-content: flex-start;

        @include respond-to(tablet) {
            width: 100%;
            justify-content: flex-start;
            margin-block-start: 14px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 10px;
            margin-block-start: 10px;
        }

        & .btn {
            width: 100%;
        }
    }
}
