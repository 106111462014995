.header-slider {
    $this: &;

    color: var(--c-white);
    position: relative;
    height: 100%;

    &__slide-content {
        align-items: start;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: var(--content-size-max);
        padding: 0 40px;
        position: absolute;
        inset-inline: 0;
        inset-block-start: 160px;
        gap: 20px;

        @include respond-to(tablet) {
            padding: 0 30px;
            inset-block-start: 215px;
        }

        @include respond-to(mobile) {
            padding: 0 16px;
            inset-block-start: 125px;
            inset-block-end: 114px;
        }
    }

    &__text {
        width: 70%;
        display: flex;
        flex-direction: column;

        @include respond-to(tablet) {
            width: 64.7%;
        }

        @include respond-to(mobile) {
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        font-size: 5rem;
        font-weight: 700;
        color: var(--c-white);
        margin-block-end: 19px;

        // чтобы не перекрывалось картинкой при таком размере
        @media screen and (max-width: 1180px) {
            font-size: 2.5rem;
        }

        @include respond-to(tablet) {
            margin-block-end: 47px;
        }

        @include respond-to(mobile) {
            font-size: 1.875rem;
            margin-block-end: 20px;
        }
    }

    &__subtitle {
        font-weight: 500;
        color: var(--c-white);
        font-size: 1.25rem;
        line-height: 1.4;
        margin-block-end: 38px;

        @include respond-to(tablet) {
            font-size: 1rem;
            margin-block-end: 80px;
        }
    }

    &__slide-add-block {
        display: flex;
        align-items: start;
        color: var(--c-white);
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.4;

        @include respond-to(mobile) {
            position: absolute;
            inset-block-end: 74px;
        }

        & img {
            max-width: 100px;
            margin-inline-end: 20px;
            margin-block-start: 20px;

            @include respond-to(desktop) {
                max-width: 66px;
                margin-block-start: 0;
                margin-inline-end: 10px;
            }
        }
    }

    &__value {
        font-size: 8rem;
        line-height: 1;

        @include respond-to(desktop) {
            font-size: 3.125rem;
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: flex-end;

        @include respond-to(mobile) {
            flex-grow: 1;
        }
    }

    &__pagination {
        width: var(--content-size-max);
        padding: 0 40px;
        position: absolute;
        inset-block-end: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1; // перекрываем слайд

        @include respond-to(desktop) {
            width: 100%;
            padding: 0 30px;
        }

        @include respond-to(mobile) {
            padding: 0 16px;
        }

        & .swiper-pagination {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-block-start: 1px solid var(--c-white-transparent-medium);

            @include respond-to(tablet) {
                border-block-start: none;
                gap: 18px;
            }

            @include respond-to(mobile) {
                gap: 9px;
            }
        }

        & .swiper-pagination-bullet {
            padding-block-start: 27px;
            font-size: 1rem;
            cursor: pointer;
            max-width: 280px;
            border-block-start: 2px solid var(--c-true-transparent);

            @include respond-to(tablet) {
                padding-block-start: 0;
                height: 3px;
                width: auto;
                flex-grow: 1;
                background-color: var(--c-white-transparent-medium);
                border-radius: 5px;
            }

            & .bullet-number {
                display: none;
            }
        }

        & .swiper-pagination-bullet-active {
            cursor: pointer;
            border-block-start: 2px solid var(--c-main-slider-line-color);
            font-weight: 700;

            @include respond-to(tablet) {
                border-block-start: none;
                background-color: var(--c-main-slider-line-color);
            }
        }
    }

    &__slide-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    & .swiper {
        height: 100%;
    }

    & .swiper-slide {
        overflow: hidden;
        background-color: var(--c-primary);
    }

    &__btn-wrapper {
        position: absolute;
        inset-block-end: 178px;
        left: 50%;
        transform: translateX(-50%);
        width: var(--content-size-max);
        padding: 0 40px;
        display: flex;
        justify-content: flex-end;
        z-index: 1;
        height: 0;

        @include respond-to(desktop) {
            width: 100%;
            padding: 0 30px;
        }

        @include respond-to(tablet) {
            display: none;
        }
    }

    &__buttons {
        width: fit-content;
        display: flex;
        gap: 20px;

        & .btn {
            position: static;
        }
    }

    & .header-swiper-button-prev,
    & .header-swiper-button-next {
        margin-block-start: calc(0px - var(--swiper-navigation-size) / 2);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: static;

        &::after {
            content: none;
        }

        &.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            pointer-events: none;
        }
    }

    &_number-controls {
        #{$this}__btn-wrapper {
            display: none;
        }

        #{$this}__pagination {
            & .swiper-pagination {
                justify-content: flex-end;
                border-block-start: none;
                gap: 24px;

                @include respond-to(mobile) {
                    gap: auto;
                    justify-content: space-between;
                }
            }

            & .bullet-number {
                display: block;
                font-size: 1.875rem;

                @include respond-to(mobile) {
                    font-size: 1.25rem;
                }
            }

            & .bullet-text {
                display: none;
            }

            & .swiper-pagination-bullet {
                width: 60px;
                max-width: 60px;
                height: 60px;
                max-height: 60px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-block-start: 0;

                @include respond-to(mobile) {
                    width: 40px;
                    max-width: 40px;
                    height: 40px;
                    max-height: 40px;
                }
            }

            & .swiper-pagination-bullet-active {
                border-block-start: none;
                background-color: var(--c-white-transparent-light);
            }
        }

        #{$this}__slide-content {
            @include respond-to(tablet) {
                flex-direction: column;
                gap: 88px;
                inset-block-start: 140px;
            }

            & .header-slider__button-wrapper {
                @include respond-to(mobile) {
                    margin-block-start: 0;
                }
            }

            & .header-slider__title {
                @include respond-to(tablet) {
                    margin-block-end: 17px;
                }
            }
        }

        #{$this}__slide-add-block-content {
            @include respond-to(tablet) {
                max-width: 200px;
            }
        }

        #{$this}__unit {
            @include respond-to(tablet) {
                font-size: 1rem;
            }
        }
    }
}

.a11y-mode {
    .header-slider {
        padding: 1rem;

        .btn {
            color: var(--c-black);
            border-color: var(--c-black);
        }

        .swiper-slide {
            display: block;
            margin-block-end: 2rem;
            background: var(--c-white);
            color: var(--c-black);

            picture {
                display: none;
            }
        }

        &__title,
        &__subtitle {
            color: var(--c-black);
        }

        &__pagination,
        &__btn-wrapper {
            display: none;
        }

        &__slide-add-block {
            display: none;
        }

        &__slide-content {
            position: static;
        }

        &__text {
            width: 100%;
        }

        h1 {
            font-size: 3em;
        }
    }
}
