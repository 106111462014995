.block-wrapper {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.6;

    .container {
        overflow: visible;
        padding: 0 15px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-block-end: 0.5rem;
        margin-block-start: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-block-end: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    h1,
    .h1 {
        font-size: 2.5rem;
    }

    h2,
    .h2 {
        font-size: 2rem;
    }

    h3,
    .h3 {
        font-size: 1.75rem;
    }

    h4,
    .h4 {
        font-size: 1.5rem;
    }

    h5,
    .h5 {
        font-size: 1.25rem;
    }

    h6,
    .h6 {
        font-size: 1rem;
    }
}
