.tab-plate {
    $this: &;

    display: flex;
    align-items: center;
    gap: 25px;
    background-color: var(--c-white);
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    text-align: start;
    width: 100%;
    transition: background-color 0.33s ease;

    &:hover,
    &.active {
        background-color: var(--c-light-bg);
    }

    &:not(:last-child) {
        margin-block-end: 6px;
    }

    &__icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--c-dark-bg);
    }

    &__point {
        width: 20px;
        height: 20px;
        min-width: 20px;
        border-radius: 50%;
        background-color: var(--c-map-point-color);
        outline: 10px solid var(--c-map-point-outline-color);
        transition: background-color 0.33s ease, outline-color 0.33s ease;
        z-index: 1;
    }

    &__text {
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 500;
    }

    &_alt {
        background-color: var(--c-true-transparent);
        color: var(--c-map-point-color);
        transition: color 0.33s ease;
        gap: 60px;
        position: relative;

        &:not(:last-child) {
            padding-block-end: 80px;
            padding-block-start: 10px;
            margin-block-end: 0;

            #{$this}__point {
                &::after {
                    content: "";
                    position: absolute;
                    inset-block-start: calc(50% - 40px);
                    inset-inline-start: 26px;
                    width: 0;
                    height: calc(100% + 80px);
                    background-color: var(--c-map-point-color);
                    z-index: 0;
                    border-inline-end: 1px solid var(--c-map-point-color);
                }
            }
        }

        &:nth-last-child(2) {
            #{$this}__point {
                &::after {
                    height: calc(100% + 20px);

                    @media screen and (max-width: 1237px) {
                        height: calc(100% + 20px);
                    }

                    @media screen and (max-width: 1047px) {
                        height: calc(100% + 40px);
                    }
                }
            }
        }

        &:hover,
        &.active {
            background-color: var(--c-true-transparent);
            color: var(--c-white);

            #{$this}__point {
                &::after {
                    z-index: -1;
                }
            }
        }

        &.active {
            #{$this}__point {
                outline: 10px solid var(--c-white);
            }
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .tab-plate {
        &__icon {
            display: none;
        }

        &__text {
            color: var(--c-a11y-accent-color);
        }

        &__point {
            display: none;
        }

        &_alt {
            padding-block-end: 0;
            padding-inline-start: 0;
        }
    }
}

.a11y-mode.a11y-mode-letter-spacing-medium {
    .tab-plate {
        letter-spacing: 0.5em;
        word-break: break-all;
    }
}

.a11y-mode.a11y-mode-letter-spacing-big {
    .tab-plate {
        letter-spacing: 0.7em;
        word-break: break-all;
    }
}
