.person-card {
    $this: &;

    background-color: var(--c-light-blue);
    border-radius: 8px;
    display: flex;
    height: 470px;
    position: relative;

    &__info {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        inset-block-end: -1px;
        font-size: 0.875em;
        inset-inline: 0;
        padding: 15px 25px 0;
        position: absolute;
    }

    &__info-name {
        font-size: 1rem;
        font-weight: 600;
        margin-block-end: 5px;

        @include respond-to(mobile) {
            font-weight: 500;
            font-size: 0.875rem;
        }
    }

    &__info-job {
        font-size: 0.875rem;
        font-style: italic;
        margin-block-end: 15px;
        width: 90%;

        @include respond-to(mobile) {
            font-size: 0.75rem;
        }
    }

    &__info-extra {
        align-items: start;
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.6s ease-out;

        > div:last-child {
            margin-block-end: 15px;
        }
    }

    &__info-phone {
        color: var(--c-accent);
        margin: 5px 0;
        display: block;
    }

    &__info-email {
        color: var(--c-accent);
        margin-block-end: 20px;
        display: block;
        word-wrap: break-word;
    }

    &__info-btn {
        margin-block-end: 30px !important;
        margin-block-start: 35px;
    }

    &__no-image {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: var(--c-light-bg);
        border-radius: 8px;

        & svg {
            margin-block-start: 150px;
        }
    }

    & img {
        border-radius: 8px;
        object-fit: cover;
        width: 100%;
    }

    &:hover {
        #{$this}__info-extra {
            max-height: 300px !important; // чтобы клавиатурные настройки не ломали ховер
        }
    }

    &_alt {
        height: 382px;

        @include respond-to(tablet) {
            flex-direction: column;
            height: auto;
        }

        & img {
            min-width: 330px;
            object-position: top;

            @include respond-to(desktop) {
                min-width: 50%;
            }

            @include respond-to(tablet) {
                min-width: 100%;
                min-height: 279px;
            }
        }

        #{$this}__no-image {
            min-width: 330px;
            align-items: center;

            @include respond-to(desktop) {
                min-width: 50%;
            }

            @include respond-to(tablet) {
                min-width: 100%;
                min-height: 279px;
            }

            & svg {
                margin-block-start: 0;
            }
        }

        #{$this}__info {
            position: static;
            padding: 30px;
            display: flex;
            flex-direction: column;

            @include respond-to(tablet) {
                padding-inline: 20px;
                padding-block: 15px 20px;
                height: 100%;
            }
        }

        #{$this}__info-extra {
            max-height: unset !important; // чтобы скриптом не снимало
            justify-content: space-between;
            flex-grow: 1;
            gap: 20px;
        }

        #{$this}__info-btn {
            margin-block: 0 !important; // перекрыть основные стили
        }
    }
}

.a11y-mode {
    .person-card {
        &:hover {
            .person-card__info-extra {
                max-height: unset !important;
            }
        }

        @include respond-to(mobile) {
            flex-direction: column;
        }

        img {
            width: 300px;
        }

        &__info-extra {
            max-height: unset !important;
            width: 100%;
        }

        &__info-contacts {
            width: 100%;
        }

        &__info-header {
            height: auto !important;
        }

        &__info {
            position: static;
            padding: 0;
        }

        &__no-image {
            min-width: 300px;
            max-width: 300px;
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .person-card {
        & img {
            display: none;
        }

        &__no-image {
            display: none;
        }
    }
}
