.text-card {
    display: flex;
    flex-direction: column;
    padding: 36px 40px;
    background-color: var(--c-light-blue);
    border-radius: 8px;
    height: fit-content;

    @include respond-to(tablet) {
        padding: 20px;
        min-height: 80px;
    }

    &_dark {
        background-color: var(--c-light-bg);
    }

    &_light {
        background-color: var(--c-dark-blue);
    }

    &_light-transparent {
        background-color: var(--c-true-transparent);
        border: 1px solid var(--c-white);
    }

    &_smaller-padding {
        padding: 30px 40px;

        @include respond-to(tablet) {
            padding-block: 20px 30px;
            padding-inline: 20px;
        }
    }

    &_s {
        min-height: 100px;
    }

    &_m {
        min-height: 128px;
    }
}

.a11y-mode {
    .text-card {
        border: 2px solid var(--c-black);
        color: var(--c-black);

        &:not(:last-child) {
            margin-block-end: 10px;
        }
    }
}
