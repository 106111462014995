.card-document {
    $this: &;

    align-items: start;
    background-color: var(--c-light-bg);
    border-radius: 8px;
    color: var(--c-primary);
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
    transition: color 0.3s, background-color 0.3s;

    &__head {
        align-items: center;
        display: flex;
        margin-block-end: 50px;
    }

    &__icon {
        margin-inline-end: 15px;

        & svg path {
            transition: fill 0.3s;
        }
    }

    &__subtitle {
        font-size: 1em;
        line-height: 1.4;
        color: var(--c-accent);
        margin-block-end: 20px;
        transition: color 0.3s;
    }

    &__text-wrapper {
        max-height: 140px;
        overflow: hidden;

        @include respond-to(tablet) {
            max-height: 111px;
        }

        @include respond-to(mobile) {
            max-height: 97px;
        }
    }

    &__text {
        &.hidden {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        background-color: var(--c-base-color);
        color: var(--c-white);

        #{$this}__icon svg path {
            fill: var(--c-white);
        }

        #{$this}__subtitle {
            color: var(--c-white);
        }

        .tooltip-hint {
            display: block;
        }
    }
}

.a11y-mode {
    .card-document {
        display: block;

        &__text {
            &.hidden {
                overflow: visible;
                text-overflow: unset;
            }

            &-wrapper {
                max-height: unset;
            }
        }

        &__icon {
            & svg path {
                fill: var(--c-a11y-accent-color);
            }
        }
    }
}
