.page-404 {
    position: relative;
    color: var(--c-white);
    font-weight: 500;
    padding-block-start: 178px;

    @include respond-to(tablet) {
        padding-block-start: 154px;
    }

    @include respond-to(mobile) {
        padding-block-start: 158px;
        text-align: center;
    }

    &__image {
        width: 100vw;
        height: 100vh;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
    }

    &__title {
        font-size: 156px;
        line-height: 1;
        margin-block-end: 82px;

        @include respond-to(tablet) {
            font-size: 117px;
            margin-block-end: 50px;
        }

        @include respond-to(mobile) {
            font-size: 84px;
            margin-block-end: 65px;
        }
    }

    &__subtitle {
        font-size: 20px;
        line-height: 1.4;
        margin-block-end: 64px;
        max-width: 482px;

        @include respond-to(tablet) {
            max-width: 462px;
        }

        @include respond-to(mobile) {
            font-size: 16px;
            max-width: unset;
        }
    }
}
