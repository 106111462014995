.main-indicators-card {
    $this: &;

    border-radius: 8px;
    overflow: hidden;
    color: var(--c-white);
    background-color: var(--c-primary);
    width: 100%;
    padding: 30px 20px;
    position: relative;
    height: 572px;
    display: block;

    @include respond-to(tablet) {
        height: 500px;
    }

    @include respond-to(mobile) {
        height: 400px;
    }

     &:hover {
         #{$this}__front-image {
             display: none;
         }
         #{$this}__hover-image {
             display: block;
         }
         #{$this}__title {
             display: none;
         }
         #{$this}__subtitle {
             display: block;
         }
     }

    &__count {
        position: absolute;
        inset-block-start: 30px;
        inset-inline-start: 20px;
        font-weight: 500;
        font-size: 40px;
        z-index: 1;

        @include respond-to(tablet) {
            font-weight: 600;
            font-size: 30px;
        }

        @include respond-to(mobile) {
            font-size: 22px;
        }
    }

    &__front-image {
        display: block;
        position: absolute;
        inset-block-start: 90px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);

        @media screen and (min-width: 570px) and (max-width: 958px) {
            inset-block-start: 20px;
            width: calc(100% - 100px);
        }

        @media screen and (max-width: 569px) {
            inset-block-start: 40px;
            width: calc(100% - 40px);
        }
    }

    &__hover-image {
        display: none;
        filter: brightness(50%);
        height: 100%;
        object-fit: cover;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
    }

    &__text-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        white-space: normal;
        position: relative;
        z-index: 1; // перекрыть картинку, если текста много
    }

    &__title {
        display: block;
    }

    &__subtitle {
        display: none;
        z-index: 1;

        & a {
            font-weight: 600;
            color: var(--c-white);
        }

        & ul li {
            padding-inline-start: 20px;
            position: relative;

            &::before {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--c-white);
                position: absolute;
                inset-inline-start: 8px;
                inset-block-start: 0.5em;
            }
        }
    }

    &_alt {
        padding: 50px;
        height: 100%;

        @include respond-to(tablet) {
            padding: 30px;
            margin-inline-start: 30px;
            height: 100%;
        }

        @include respond-to(mobile) {
            padding: 20px;
            margin-inline-start: 20px;
            height: 100%;
        }

        &:hover {
            #{$this}__front-image {
                display: block;
            }

            #{$this}__title {
                display: block;
            }
        }

        #{$this}__front-image {
            width: 420px;
            inset-inline: auto 20px;
            inset-block-start: -114px;
            transform: none;

            @include respond-to(tablet) {
                width: 316px;
                inset-block-start: -67px;
                inset-inline-end: -40px;
                transform: none;
            }

            @include respond-to(mobile) {
                width: 176px;
                inset-block-start: -24px;
                inset-inline-end: -38px;
                transform: none;
            }

            @media screen and (min-width: 570px) and (max-width: 958px) {
                width: 176px;
                inset-block-start: -24px;
                inset-inline-end: -38px;
                transform: none;
            }

            @media screen and (max-width: 569px) {
                width: 176px;
                inset-block-start: -24px;
                inset-inline-end: -38px;
                transform: none;
            }
        }

        #{$this}__text-content {
            justify-content: space-between;
            gap: 40px;
        }

        #{$this}__title {
            max-width: 678px;
            font-size: 1.875rem;
            line-height: 1.2;

            @include respond-to(tablet) {
                max-width: 255px;
                font-size: 1.25rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                max-width: 162px;
                font-size: 1rem;
            }
        }

        #{$this}__subtitle {
            max-width: 880px;
            display: block;
            font-size: 1rem;
            line-height: 1.4;

            @include respond-to(tablet) {
                max-width: 100%;
            }

            @include respond-to(mobile) {
                font-size: 0.75rem;
            }

            & ol,
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 20px 143px;

                & li {
                    width: calc((100% - 143px) / 2);
                }

                @include respond-to(tablet) {
                    flex-direction: column;
                    gap: 40px;

                    & li {
                        width: 100%;
                    }
                }

                @include respond-to(mobile) {
                    gap: 20px;
                }
            }
        }
    }
}

.a11y-mode {
    .main-indicators-card {
        $this: &;

        color: var(--c-black);
        background-color: var(--c-white);
        height: auto !important;

        &__count {
            position: static !important;
        }

        &__front-image {
            display: none !important;
        }

        &__hover-image {
            display: none !important;
        }

        &__title {
            display: block !important;
        }

        &__subtitle {
            display: block !important;

            & a {
                color: var(--c-black);
            }

            & ul li::before {
                background-color: var(--c-black);
            }
        }

        &_alt {
            padding: 0;

            .main-indicators-card__title {
                max-width: unset;
            }

            .main-indicators-card__subtitle {
                max-width: unset;

                & ul,
                ol {
                    flex-direction: column;

                    & li {
                        width: 100%;
                    }
                }
            }
        }
    }
}
