$mobile: 639px;
$tablet: 1023px;
$desktop: 1459px;

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: $mobile) {
            @content;
        }
    } @else if $media == tablet {
        @media only screen and (max-width: $tablet) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (max-width: $desktop) {
            @content;
        }
    } @else if $media == big-tablet-only {
        @media only screen and (min-width: $tablet + 1) and (max-width: $desktop) {
            @content;
        }
    } @else if $media == desktop-only {
        @media only screen and (min-width: $tablet + 1) {
            @content;
        }
    } @else if $media == big-desktop-only {
        @media only screen and (min-width: $desktop + 1) {
            @content;
        }
    }
}
