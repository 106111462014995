.tooltip-hint {
    display: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    position: absolute;
    inset-block-start: 90%;
    inset-inline-start: 0;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--c-white);
    color: var(--c-primary);
    box-shadow: 0 4px 10px var(--c-shadow);
    max-width: 100%;
    max-height: fit-content;
    z-index: 2; // перекрыть нижние контейнеры и футер

    @include respond-to(tablet) {
        font-size: 14px;
    }
}
