.stats-item {
    $this: &;

    &__title {
        align-items: baseline;
        display: flex;
        font-size: 1.25em;
        font-weight: 500;
        margin-block-end: 10px;

        & span {
            white-space: nowrap;
        }
    }

    &__number {
        font-size: 3.75em;
        font-weight: 600;
        line-height: 1.1em;
        margin-inline-end: 8px;
    }

    &_small {
        #{$this}__number {
            font-size: 2.5em;
            line-height: 1.23;

            @include respond-to(tablet) {
                font-size: 1.875em;
                line-height: 1.2;
            }

            @include respond-to(mobile) {
                font-size: 1.375em;
            }
        }

        #{$this}__title {
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 1em;
                margin-block-end: 5px;
            }

            @include respond-to(mobile) {
                font-size: 0.875em;
                margin-block-end: 10px;
            }
        }
    }

    &_large {
        #{$this}__number {
            font-size: 5em;
            line-height: 1;

            @include respond-to(tablet) {
                font-size: 50px;
            }

            @include respond-to(mobile) {
                font-size: 35px;
            }
        }

        #{$this}__title {
            @include respond-to(tablet) {
                margin-block-end: 15px;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }
        }

        #{$this}__subtitle {
            font-size: 20px;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 16px;
            }

            @include respond-to(mobile) {
                font-size: 14px;
            }
        }
    }

    &_white {
        #{$this}__number {
            color: var(--c-white);
        }
    }
}
