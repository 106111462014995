@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap";

// TODO: выпилить из хэдэра и футера
$breakpoint-large: 1460px;
$breakpoint-medium: 960px;
$breakpoint-small: 640px;

* {
    box-sizing: border-box;
    margin: 0;
}

html {
    font-size: 16px;

    &:not(.a11y-mode) body {
        @include color-settings;
    }
}

body {
    background-color: var(--c-light-bg);
    font-family: Montserrat, sans-serif;
    color: var(--c-primary);
    margin: 0;
    font-size: 1em;
    line-height: 1.4em;

    &.primary-darkorange {
        --c-primary: darkorange;
    }

    @include respond-to(mobile) {
        font-size: 0.875em;
    }
}

h1,
.h1 {
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;

    @include respond-to(tablet) {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1;
    }

    @include respond-to(mobile) {
        font-size: 1.875rem;
        font-weight: 700;
    }
}

h2,
.h2 {
    font-size: 3.75rem;
    font-weight: 600;
    line-height: 1.1em;

    @include respond-to(tablet) {
        font-size: 2.5rem;
        line-height: 1em;
    }

    @include respond-to(mobile) {
        font-size: 1.375rem;
        line-height: 1.2em;
    }
}

h3,
.h3 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.1;

    @include respond-to(tablet) {
        font-size: 1.875rem;
        line-height: 1.2;
    }

    @include respond-to(mobile) {
        font-size: 1.375rem;
        line-height: 1.4;
    }
}

h4,
.h4 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.4em;

    @include respond-to(tablet) {
        font-weight: 500;
        font-size: 1rem;
    }

    @include respond-to(mobile) {
        font-size: 0.875rem;
    }
}

h5,
.h5 {
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 1.2em;

    @include respond-to(tablet) {
        font-size: 1.25rem;
        line-height: 1.4em;
    }

    @include respond-to(mobile) {
        font-size: 1rem;
    }
}

h6,
.h6 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.4em;

    @include respond-to(tablet) {
        font-size: 1.25rem;
    }

    @include respond-to(mobile) {
        font-weight: 400;
        font-size: 0.875rem;
    }
}

hr {
    background-color: rgb(255 255 255 / 15%);
    border: none;
    height: 1px;
}

a {
    background: transparent;
    text-decoration: none;
    transition: color 0.3s;
    outline: none;
    cursor: pointer;
}

button {
    font-family: Montserrat, sans-serif;
}

pre {
    font-family: inherit;
    white-space: pre-line;
}

b {
    font-weight: 600;
}

picture > img {
    height: 100%;
    object-fit: cover;
}
