:root {
    --c-primary: #213160;
    --c-secondary: #070a38;
    --c-accent: #4786e1;
    --c-light-bg: #e5eaef;
    --c-dark-bg: #bfc7d4;
    --c-blue-bg: #ecf1f7;
    --c-light-blue: #f3f9ff;
    --c-dark-blue: #aecbf3;
    --c-light-text: rgb(255 255 255 / 60%);
    --c-lighter-border: rgb(255 255 255 / 15%);
    --c-white: #fff;
    --c-black: #000;
    --c-error: #df3a4c;
    --c-error-bg: rgba(223 58 76 / 10%);
    --c-shadow: rgb(40 59 106 / 25%);
    --c-shadow-dark: rgba(0 0 0 / 5%);
    --c-shadow-table: rgba(33 49 96 / 5%);
    --c-shadow-table-dark: rgba(33 49 96 / 60%);
    --c-true-transparent: rgb(255 255 255 / 0%);
    --c-transparent-accent: rgb(71 134 225 / 20%);
    --c-transparent-border: rgb(108 111 113 / 30%);
    --c-transparent-blue: rgb(77 146 245 / 20%);
    --c-half-transparent-blue: rgb(77 146 245 / 40%);
    --c-border: #e7e8ea;
    --c-notification: #6c6f71;
    --c-deep-gray: #9098af;
    --c-overlay-bg: rgb(255 255 255 / 50%);
    --c-dark-overlay-bg: rgb(0 0 0 / 30%);
    --c-light-border: rgb(229 234 239 / 70%);
    --c-transparent-bg: rgb(243 249 255 / 30%);
    --c-alt-border: #dbe1e7;
    --c-alt-background: #f8f9fb;
    --c-white-transparent-light: rgb(255 255 255 / 17%);
    --c-white-transparent-medium: rgb(255 255 255 / 30%);
    --c-white-transparent-dark: rgb(255 255 255 / 40%);
    --c-white-border: rgb(255 255 255 / 20%);
    --c-input-range-text: #bfc7d4;
    --c-a11y-accent-color: black;
    --c-a11y-background-color: white;
    --c-half-shadow: rgb(0 0 0 / 50%);
    --c-bg-secondary: #070a38;
    --c-overlay-dark-bg: rgb(3 4 30 / 60%);
    --c-text-on-primary-bg: #fff;
    --c-giant-counter: rgb(33 49 96 / 17%);
}

.a11y-mode {
    --c-primary: var(--c-a11y-accent-color);
    --c-secondary: var(--c-a11y-accent-color);
    --c-accent: var(--c-a11y-accent-color);
    --c-light-bg: var(--c-a11y-background-color);
    --c-dark-bg: var(--c-a11y-background-color);
    --c-blue-bg: var(--c-a11y-background-color);
    --c-light-blue: var(--c-a11y-background-color);
    --c-dark-blue: var(--c-a11y-background-color);
    --c-light-text: var(--c-a11y-background-color);
    --c-white: var(--c-a11y-background-color);
    --c-black: var(--c-a11y-accent-color);
    --c-error: var(--c-a11y-accent-color);
    --c-error-bg: var(--c-a11y-background-color);
    --c-shadow: var(--c-a11y-accent-color);
    --c-shadow-dark: var(--c-a11y-accent-color);
    --c-shadow-table: var(--c-a11y-accent-color);
    --c-true-transparent: var(--c-a11y-background-color);
    --c-transparent-accent: var(--c-a11y-accent-color);
    --c-transparent-border: var(--c-a11y-accent-color);
    --c-transparent-blue: var(--c-a11y-accent-color);
    --c-border: var(--c-a11y-background-color);
    --c-notification: var(--c-a11y-background-color);
    --c-deep-gray: var(--c-a11y-background-color);
    --c-overlay-bg: var(--c-a11y-background-color);
    --c-input-range-text: var(--c-a11y-accent-color);
    --c-bg-secondary: var(--c-a11y-background-color);
    --c-overlay-dark-bg: var(--c-a11y-background-color);
    --c-text-on-primary-bg: var(--c-a11y-background-color);
    --c-giant-counter: var(--c-a11y-background-color);
}

.a11y-mode.a11y-mode-theme-blackOnWhite {
    --c-a11y-accent-color: black;
    --c-a11y-background-color: white;
}

.a11y-mode.a11y-mode-theme-whiteOnBlack {
    --c-a11y-accent-color: white;
    --c-a11y-background-color: black;
}

.a11y-mode.a11y-mode-theme-darkBlueOnBlue {
    --c-a11y-accent-color: #9dd1ff;
    --c-a11y-background-color: #063462;
}

.a11y-mode.a11y-mode-theme-brownOnBeige {
    --c-a11y-accent-color: #4d4b43;
    --c-a11y-background-color: #f7f3d6;
}

.a11y-mode.a11y-mode-theme-greenOnDarkBrown {
    --c-a11y-accent-color: #a9e44d;
    --c-a11y-background-color: #3b2716;
}
