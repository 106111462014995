.review {
    $this: &;

    width: 100%;
    height: 100%;
    padding-block: 35px 53px;
    padding-inline: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--c-light-bg);
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    @include respond-to(tablet) {
        padding-block: 35px 40px;
    }

    @include respond-to(mobile) {
        padding-block: 20px;
        padding-inline: 20px;
    }

    &__image {
        position: absolute;
        inset-block-start: -24px;
        inset-inline-end: -5px;
        max-width: 188px;
        max-height: 139px;
        object-fit: cover;

        .modal-window & {
            inset-inline-end: 0;
        }
    }

    &__user-info {
        margin-block-end: 40px;
        z-index: 1;

        @include respond-to(mobile) {
            margin-block-end: 60px;
        }

        .modal-window & {
            margin-block-end: 50px;

            @include respond-to(tablet) {
                margin-block-end: 40px;
            }

            @include respond-to(mobile) {
                margin-block-end: 30px;
            }
        }
    }

    &__company {
        color: var(--c-accent);
        margin-block-end: 15px;

        @include respond-to(tablet) {
            margin-block-end: 20px;
        }

        @include respond-to(mobile) {
            margin-block-end: 10px;
            font-size: 16px;
            line-height: 1.4;
        }

        .modal-window & {
            margin-block-end: 30px;

            @include respond-to(tablet) {
                font-size: 20px;
                line-height: 1.4;
                margin-block-end: 20px;
            }

            @include respond-to(mobile) {
                font-size: 14px;
                margin-block-end: 15px;
            }
        }
    }

    &__review-text {
        color: var(--c-primary);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        @include respond-to(tablet) {
            max-width: 514px;
        }

        @include respond-to(mobile) {
            max-width: unset;
        }

        .modal-window & {
            -webkit-line-clamp: unset;
            overflow: visible;

            & p:not(:last-child) {
                margin-block-end: 30px;

                @include respond-to(mobile) {
                    margin-block-end: 20px;
                }
            }

            @include respond-to(tablet) {
                max-width: unset;
            }
        }
    }

    .modal-window & {
        background-color: var(--c-true-transparent);
        padding: 0;
    }
}

.a11y-mode {
    .review {
        &__review-text {
            max-width: unset;
        }
    }
}
