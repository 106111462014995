.config-panel-button {
    position: fixed;
    width: 60px;
    height: 60px;
    inset-block-start: 150px; // не по макету, т.к. иначе битриксовая панель перекрывает кнопку
    inset-inline-start: 0;
    background-color: var(--c-white);
    z-index: 101; // перекрыть баннер/слайдер
    box-shadow: 0 4px 8px var(--c-shadow);
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
    cursor: pointer;

    @include respond-to(tablet) {
        display: none;
    }

    &__container {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.config-panel {
    $this: &;

    background-color: var(--c-dark-overlay-bg);
    height: 100vh;
    width: 100vw;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    inset-block-start: 0;
    transition: opacity 0.4s;
    z-index: 101; // перекрыть хэдэр, кнопку открытия панели и пр. элементы
    display: flex;
    gap: 8px;

    @include respond-to(tablet) {
        display: none;
    }

    &.open {
        opacity: 1;
        pointer-events: auto;

        #{$this}__container {
            inset-inline-start: 0;
        }
    }

    &__container {
        position: absolute;
        inset-block-start: 146px; // не по макету, т.к. иначе битриксовая панель перекрывает кнопку
        background-color: var(--c-white);
        box-shadow: 0 4px 10px var(--c-shadow);
        width: 953px;
        flex: 1;

        height: calc(100% - 146px);
        inset-inline-start: -100%;
        transition: inset-inline-start 0.4s;

        display: flex;
        z-index: 102; // перекрыть bg панели

        @media only screen and (max-width: 1008px) {
            width: unset;
            min-width: 54rem;
            max-width: 59.56rem;
        }
    }

    &__close-button {
        position: absolute;
        inset-inline-start: calc(100% + 8px);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--c-white);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-block-start: 8px;
        cursor: pointer;
        z-index: 101; // чтобы перекрыть bg
        flex-shrink: 0;

        & svg {
            width: 14px;
            height: 14px;
        }
    }

    &__background {
        position: absolute;
        height: 100%;
        width: 100%;
        inset-inline-end: 0;
        inset-block-start: 0;
    }

    &__list {
        width: 16.44rem;
        border-inline-end: 1px solid var(--c-light-border);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 14px;
            background-color: var(--c-alt-background);
            border-radius: 8px;
            border: 4px solid var(--c-true-transparent);
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-light-bg);
            border-radius: 8px;
            border: 4px solid var(--c-true-transparent);
            background-clip: content-box;
        }

        & > li {
            padding: 19px 24px;
            background-color: var(--c-transparent-bg);
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.4;
            transition: background-color 0.3s;
            cursor: pointer;

            &:hover {
                background-color: var(--c-light-blue);
            }

            &.active {
                font-weight: 600;
                background-color: var(--c-white);

                &:hover {
                    background-color: var(--c-light-blue);
                }
            }

            &:not(:last-child) {
                border-block-end: 1px solid var(--c-light-border);
            }
        }
    }

    &__content {
        flex: 1;
        padding-inline: 30px;
        padding-block: 30px 106px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 16px;
            background-color: var(--c-light-bg);
            border-radius: 8px;
            border: 4px solid var(--c-true-transparent);
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-dark-bg);
            border-radius: 8px;
            border: 4px solid var(--c-true-transparent);
            background-clip: content-box;
        }

        &-title {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.4;
        }

        &-colors {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-block-end: 30px;
        }

        &-color {
            width: 37px;
            height: 37px;
            border-radius: 50%;
            outline: 2px solid var(--c-alt-border);
            border: 4px solid var(--c-white);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: var(--item-color);

            &:hover,
            &.active {
                outline-color: var(--item-color);
            }
        }

        &-inputs {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &-blocks {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-block-end: 30px;
        }

        &-block {
            background: var(--c-alt-background);
            border: 1px solid var(--c-light-border);
            border-radius: 8px;
            padding-inline: 20px 10px;
            padding-block: 10px;

            &:first-child {
                #{$this}__content-block-arrow-up {
                    display: none;
                }
            }

            &:last-child {
                #{$this}__content-block-arrow-down {
                    display: none;
                }
            }

            &.invisible {
                & #{$this}__content-block-visibility {
                    & svg:first-of-type {
                        display: none;
                    }

                    & svg:last-of-type {
                        display: block;
                    }
                }
            }
        }

        &-block-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        &-block-visibility {
            & svg:first-of-type {
                display: block;
            }

            & svg:last-of-type {
                display: none;
            }
        }

        &-block-templates {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            flex-wrap: nowrap;
            margin-block-end: 15px;
            margin-inline-end: 10px;
        }

        &-block-template {
            padding: 10px;
            border: 2px solid var(--c-light-bg);
            border-radius: 4px;
            transition: background-color 0.3s, border-color 0.3s;
            cursor: pointer;
            width: calc(50% - 5px);
            height: 150px;

            &:hover {
                background-color: var(--c-white);
            }

            &.active {
                background-color: var(--c-white);
                border-color: var(--c-accent);
            }

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
        }

        &-block-arrows {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
        }

        &-controls {
            & .select__choosen span,
            .select__item-checkbox-text {
                font-weight: 500;
            }

            & .btn {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.25;
                width: 209px;
            }
        }

        &-adding {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .select {
                min-width: 310px;
            }

            & .select__choosen {
                overflow: visible;
            }
        }
    }
}
