.link-block-alt {
    $this: &;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--c-light-bg);
    border-radius: 8px;
    padding: 30px;
    height: 100%;

    @include respond-to(tablet) {
        padding-block: 35px 30px;
        padding-inline: 30px;
    }

    @include respond-to(mobile) {
        padding: 20px;
    }

    &__header {
        flex-grow: 1;
        margin-block-end: 50px;

        @include respond-to(tablet) {
            margin-block-end: 34px;
        }

        @include respond-to(mobile) {
            margin-block-end: 30px;
        }
    }

    &__title {
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 500;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__subtitle {
        font-size: 1rem;
        line-height: 1.4;

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &_with-subtitle {
        @include respond-to(tablet) {
            padding: 30px;
        }

        @include respond-to(mobile) {
            padding: 20px;
        }

        #{$this}__title {
            margin-block-end: 20px;

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }
    }
}
