.news-page {
    $this: &;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--grid-gap);

    @include respond-to(tablet) {
        grid-template-columns: repeat(6, 1fr);
    }

    @include respond-to(mobile) {
        grid-template-columns: repeat(4, 1fr);
    }

    &__filters {
        grid-column: span 4;

        @include respond-to(tablet) {
            grid-column: 1/-1;
            margin-block-end: 80px;
        }

        @include respond-to(mobile) {
            margin-block-end: 60px;
        }
    }

    &__list {
        grid-column: 5/-1;

        @include respond-to(tablet) {
            grid-column: 1/-1;
        }

        & > p {
            position: relative;
            inset-block-start: 50%;
            transform: translateY(-50%);
            text-align: center;
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-block-end: 17px;

            @include respond-to(tablet) {
                margin-block-end: 30px;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }
        }

        &-count {
            & span:first-of-type {
                font-weight: 600;
                font-size: 1.25em;
                line-height: 1.4;
                margin-inline-end: 6px;

                @include respond-to(mobile) {
                    font-size: 0.875em;
                    font-weight: 500;
                }
            }

            & span:last-of-type {
                font-weight: 500;

                @include respond-to(mobile) {
                    font-size: 0.875em;
                }
            }
        }

        &-sort {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 1rem;
            padding: 0;
            border: none;

            & span {
                font-weight: 500;
                margin-inline-end: 6px;

                @include respond-to(mobile) {
                    font-size: 0.875em;
                }
            }

            & svg {
                width: 25px;
                height: 14px;
                transition: transform 0.3s;

                @include respond-to(mobile) {
                    width: 20px;
                    height: 11px;
                }

                &.asc {
                    transform: scale(1, -1);
                }
            }
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    &__load-btn {
        margin-block-start: 24px;
        text-align: center;
        width: 100%;

        & .btn {
            @include respond-to(mobile) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &_newslist2 {
        #{$this}__filters {
            display: flex;
            grid-column: span 12;
            margin-block-end: 40px;

            @include respond-to(tablet) {
                flex-direction: column;
                margin-block-end: 80px;
            }

            @include respond-to(mobile) {
                margin-block-end: 60px;
            }
        }

        #{$this}__list {
            grid-column: span 12;
        }

        #{$this}__list-header {
            @include respond-to(mobile) {
                margin-block-end: 70px;
            }
        }

        #{$this}__load-btn {
            @include respond-to(tablet) {
                text-align: left;
            }
        }
    }
}

.a11y-mode {
    .news-page {
        display: block;

        &__filters {
            margin-block-end: 20px;
        }

        &__list-header {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
    }
}
