.reviews {
    $this: &;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    & .review {
        height: 100%;
        justify-content: flex-start;
    }

    &__item {
        width: 100%;

        &.js--hidden-element {
            display: none;
        }
    }

    // второй вариант блока
    &_alt {
        padding-block-start: 30px;

        @include respond-to(tablet) {
            padding-block-start: 13px;
        }

        @include respond-to(mobile) {
            padding-block-start: 0;
        }

        & .review {
            justify-content: space-between;
        }

        & .review__user-info {
            min-height: 82px;

            @include respond-to(mobile) {
                min-height: 81px;
            }
        }

        & .review__review-text {
            -webkit-line-clamp: 8;

            @include respond-to(tablet) {
                -webkit-line-clamp: 6;
            }

            @include respond-to(mobile) {
                -webkit-line-clamp: 5;
            }
        }

        & .modal-review .review__review-text {
            -webkit-line-clamp: unset;

            @include respond-to(tablet) {
                -webkit-line-clamp: unset;
            }

            @include respond-to(mobile) {
                -webkit-line-clamp: unset;
            }
        }

        #{$this}__item {
            width: calc((100% - 40px) / 3);
            height: 520px;

            @include respond-to(tablet) {
                width: calc((100% - 20px) / 2);
                height: 466px;
            }

            @include respond-to(mobile) {
                width: 100%;
                height: 393px;
            }
        }
    }
}

.a11y-mode {
    .reviews {
        &_alt {
            .reviews__item {
                width: 100%;
                height: auto;
            }
        }
    }
}
