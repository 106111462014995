.for-innovators {
    &__text-wrapper {
        display: flex;
        gap: 54px;

        & p {
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }

        @include respond-to(tablet) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__description {
        @include respond-to(mobile) {
            padding-block-start: 80px;
            padding-block-end: 0;
            margin-block: 0 !important; // особый случай, перекрыть обычные значения
            background-color: var(--c-light-blue);
        }

        &:last-child {
            @include respond-to(mobile) {
                padding-block-end: 80px;
            }
        }
    }

    &__tiles {
        padding-block: 100px;
        position: relative;
        background-color: var(--c-light-bg);

        @include respond-to(mobile) {
            background-color: var(--c-light-blue);
        }
    }

    &__background-image {
        position: absolute;
        width: 100%;
        inset-block-start: 0;
    }

    &__tiles-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & .tile:last-child {
            width: calc((100% - 20px) / 2);
        }

        @include respond-to(tablet) {
            & .tile_quarter {
                width: calc(50% - 10px);
            }

            & .tile:last-child {
                width: 100%;
            }
        }

        @include respond-to(mobile) {
            flex-wrap: nowrap;
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            & .tile {
                min-width: 252px;
            }
        }
    }

    &__links-wrapper {
        display: flex;
        gap: 20px;

        @include respond-to(mobile) {
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        & .link-on-primary {
            width: calc(50% - 10px);

            @include respond-to(tablet) {
                width: 100%;
            }
        }

        &_tablet-column {
            @include respond-to(tablet) {
                flex-direction: column;
            }
        }
    }

    &__link {
        width: calc(50% - 10px);

        @include respond-to(mobile) {
            min-width: 252px;
        }
    }

    &__h3 {
        color: var(--c-white);

        @include respond-to(mobile) {
            color: var(--c-primary);
        }
    }

    &__columns {
        columns: 2;
        column-gap: 20px;

        @include respond-to(mobile) {
            columns: 1;
        }

        &_tablet-flex {
            @include respond-to(tablet) {
                display: flex;
                flex-wrap: wrap;
                gap: 60px 20px;
            }

            @include respond-to(mobile) {
                flex-wrap: nowrap;
                gap: 52px;
                overflow-x: scroll;
                scrollbar-width: none; // ff

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            & .text-with-counter {
                @include respond-to(tablet) {
                    width: calc(50% - 10px);
                }

                @include respond-to(mobile) {
                    min-width: 220px;
                }

                &:last-child {
                    @include respond-to(tablet) {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    &__tiles + .section-wrapper.bg-primary {
        margin-block: 0;
    }

    & .section-wrapper.bg-light-blue + .section-wrapper.bg-primary {
        margin-block: 0;
    }

    @include section-indents;
}

.a11y-mode {
    .for-innovators {
        &__text-wrapper {
            flex-direction: column;
        }

        &__links-wrapper {
            flex-direction: column;

            & .link-on-primary {
                width: 100%;
            }
        }

        &__link {
            width: 100%;
        }

        &__h3 {
            color: var(--c-a11y-accent-color);
            max-width: unset;
        }

        &__tiles-wrapper {
            flex-direction: column;

            & .tile {
                min-width: 100%;

                &__count::before {
                    color: var(--c-a11y-accent-color);
                }
            }

            & .tile:last-child {
                width: 100%;
            }
        }

        &__columns {
            columns: 1;

            &_tablet-flex {
                flex-direction: column;

                & .text-with-counter {
                    min-width: 100%;
                }
            }
        }
    }
}
