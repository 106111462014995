.contacts {
    $this: &;

    background: var(--c-primary);
    border-radius: 8px;
    width: 100%;
    padding: 60px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 40px;

    // не влезает в ряд с этого размера
    @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: start;
        padding: 40px;
    }

    @include respond-to(mobile) {
        padding: 40px 20px;
    }

    .btn {
        min-width: fit-content;

        @include respond-to(mobile) {
            min-width: 100%;
        }
    }

    &.active {
        #{$this}__phone,
        #{$this}__email,
        #{$this}__caption {
            display: block;
        }

        #{$this}__btn {
            display: none;
        }

        #{$this}__data {
            @include respond-to(tablet) {
                margin-block-start: 40px;
            }
        }
    }

    &__image {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        opacity: 0.2;
        pointer-events: none;
        height: 100%;
    }

    &__avatar {
        @include respond-to(tablet) {
            margin-inline-end: 0;
        }

        &-info {
            max-width: 262px;

            @include respond-to(tablet) {
                max-width: unset;
            }
        }
    }

    &__no-photo {
        width: 143px;
        min-width: 143px;
        height: 143px;
        min-height: 143px;
        border-radius: 50%;
        background-color: var(--c-light-bg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: 50px;

        @include respond-to(tablet) {
            width: 127px;
            min-width: 127px;
            height: 127px;
            min-height: 127px;
            margin-inline-end: 34px;
        }

        @include respond-to(mobile) {
            width: 90px;
            min-width: 90px;
            height: 90px;
            min-height: 90px;
            margin-inline-end: 21px;
        }

        & svg {
            @include respond-to(tablet) {
                width: 44px;
                height: 39px;
            }

            @include respond-to(mobile) {
                width: 28px;
                height: 25px;
            }
        }

        &_alt {
            width: fit-content;
            height: fit-content;
            background-color: var(--c-true-transparent);
        }
    }

    &__phone,
    &__email {
        color: var(--c-light-bg);
        text-decoration: none;
        display: block;
        transition: 0.3s ease-out;
        white-space: nowrap;

        &:not(:last-of-type) {
            margin-block-end: 4px;
        }

        &:hover {
            color: var(--c-dark-blue);
        }
    }

    &__data {
        display: flex;
        flex-direction: column;

        &-text {
            font-weight: 500;
            font-size: 1.25em;
            line-height: 140%;

            @include respond-to(mobile) {
                font-size: 1em;
            }
        }

        @include respond-to(tablet) {
            flex-direction: column;
            width: 100%;

            & > :last-child {
                margin-inline-start: 0;
                margin-block-start: 16px;
            }
        }

        @include respond-to(mobile) {
            & > :last-child {
                margin-block-start: 10px;
            }
        }
    }

    &__form {
        position: absolute;
    }

    &_column-data {
        #{$this}__avatar {
            &-info {
                max-width: 409px;
            }
        }

        #{$this}__data {
            flex-direction: column;

            & > :last-child {
                margin-inline-start: 0;
                margin-block-start: 8px;
            }
        }
    }

    &_no-avatar {
        #{$this}__avatar {
            &-info {
                max-width: 432px;
            }
        }
    }

    &__caption {
        font-style: italic;
        font-weight: 400;
        font-size: 1em;
        line-height: 140%;
        color: var(--c-dark-bg);
        margin-block-end: 10px;
        display: none;
    }

    &__caption ~ &__phone,
    &__caption ~ &__email {
        color: var(--c-white);
    }

    &_wrap-data.active {
        flex-direction: column;
        align-items: flex-start;

        #{$this}__data {
            margin-block-start: 65px;
            align-items: flex-end;
            justify-content: space-between;
        }

        &#{$this}_column-data {
            #{$this}__data {
                align-items: flex-start;
            }
        }
    }

    &_reverse-data {
        #{$this}__data {
            flex-flow: row-reverse;

            > :last-child {
                margin-inline-start: unset;
                margin-block-start: unset;
            }

            > :first-child {
                margin-block-start: unset;
                margin-inline-start: 16px;
            }
        }

        &#{$this}_column-data {
            #{$this}__data {
                flex-flow: column-reverse;

                > :last-child {
                    margin-block-start: unset;
                    margin-inline-start: unset;
                }

                > :first-child {
                    margin-block-start: 16px;
                    margin-inline-start: unset;
                }
            }
        }
    }

    &_bigger-info {
        #{$this}__avatar {
            &-info {
                max-width: 420px;
            }
        }
    }

    &_light {
        background-color: var(--c-light-bg);
        color: var(--c-primary);

        #{$this}__avatar-info {
            max-width: 584px;

            @include respond-to(tablet) {
                max-width: unset;
            }
        }
    }

    &_alt {
        background-color: var(--c-light-bg);
        padding: 40px;

        #{$this}__avatar-info {
            max-width: unset;

            & .btn {
                display: none;
            }
        }

        #{$this}__phone,
        #{$this}__email {
            color: var(--c-primary);

            &:hover {
                color: var(--c-accent);
            }
        }

        @include respond-to(tablet) {
            padding: 30px;
        }

        @include respond-to(mobile) {
            padding: 40px 20px;
        }
    }

    &_smaller {
        @include respond-to(tablet) {
            padding: 30px;
        }

        @include respond-to(tablet) {
            padding-block: 40px 30px;
            padding-inline: 20px;
        }

        & > .btn {
            display: none;

            @include respond-to(tablet) {
                display: inline-flex;
            }
        }

        #{$this}__avatar-info {
            max-width: 65%;

            @media screen and (max-width: 1175px) {
                max-width: 100%;
            }

            & .btn {
                display: inline-flex;

                @include respond-to(tablet) {
                    display: none;
                }
            }
        }

        & .avatar {
            @media screen and (max-width: 1175px) {
                flex-wrap: wrap;
            }

            @include respond-to(tablet) {
                flex-wrap: nowrap;
            }

            & img {
                width: 158px;
                height: 158px;
                margin-inline-end: 35px;

                @media screen and (max-width: 1175px) {
                    margin-inline-end: 0;
                    margin-block-end: 35px;
                }

                @include respond-to(tablet) {
                    width: 127px;
                    height: 127px;
                    margin-block-end: 0;
                    margin-inline-end: 35px;
                }

                @include respond-to(mobile) {
                    width: 90px;
                    height: 90px;
                    margin-inline-end: 20px;
                }
            }

            &__title {
                margin-block-end: 25px;

                @include respond-to(tablet) {
                    margin-block-end: 20px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }
    }

    &_media {
        align-items: flex-start;
        flex-direction: column;
        gap: 0;

        #{$this}__avatar {
            align-items: flex-start;
            gap: 35px;

            @include respond-to(tablet) {
                margin-block-end: 48px;
            }

            @include respond-to(mobile) {
                gap: 21px;
                margin-block-end: 30px;
            }

            &-info {
                margin-inline-start: 193px;

                @include respond-to(tablet) {
                    margin-inline-start: 0;
                }
            }

            & img {
                position: absolute;

                @include respond-to(tablet) {
                    position: static;
                }
            }
        }

        #{$this}__data-text {
            font-size: 0.875rem;
            line-height: 1.4;
            color: var(--c-alt-base-color);
        }

        #{$this}__links {
            margin-inline-start: 193px;

            @include respond-to(tablet) {
                margin-inline-start: 0;
                display: flex;
                gap: 35px;
            }

            @include respond-to(mobile) {
                display: block;
                margin-inline-start: 0;
            }
        }

        #{$this}__phone {
            margin-block-end: 10px;

            @include respond-to(mobile) {
                font-size: 0.75rem;
                line-height: 1.4;
            }
        }

        #{$this}__email {
            margin-block-end: 30px;

            @include respond-to(tablet) {
                margin-block-end: 38px;
            }

            @include respond-to(mobile) {
                font-size: 0.75rem;
                line-height: 1.4;
            }
        }

        & .avatar_alt img {
            width: 158px;
            height: 158px;
            margin-inline-end: 0;

            @include respond-to(tablet) {
                width: 127px;
                height: 127px;
            }

            @include respond-to(mobile) {
                width: 90px;
                height: 90px;
            }
        }

        & .avatar__title {
            margin-block-end: 20px;
        }

        & .avatar__description {
            margin-block-end: 30px;

            @include respond-to(mobile) {
                margin-block-end: 0;
            }
        }

        & .btn {
            display: block;
            width: fit-content;
            margin-inline-start: 193px;

            @include respond-to(tablet) {
                margin-inline-start: 0;
            }

            @include respond-to(mobile) {
                text-align: center;
            }
        }
    }
}

.a11y-mode {
    .contacts {
        flex-direction: column;
        align-items: flex-start;

        &__avatar {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            width: 100%;
        }

        &__avatar-info {
            max-width: 100%;
        }

        &__phone,
        &__email {
            white-space: normal;
            word-break: break-all;
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .contacts {
        &__no-photo {
            display: none;
        }

        &__data {
            flex-direction: column;
            gap: 10px;

            & > :first-child {
                margin-inline-start: 0;
            }
        }
    }
}
