.municipalities {
    $this: &;

    &__stats {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include respond-to(desktop) {
            flex-direction: column;
        }

        &_dark {
            color: var(--c-white);
            min-height: 1095px;

            @include respond-to(desktop) {
                min-height: unset;
            }
        }

        & ul,
        ol {
            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }

        & ul li,
        ol li {
            list-style: none;
            margin: 0;
            padding-block: 0;
            padding-inline: 16px 0;
            position: relative;
            font-size: 1.5625rem;
            line-height: 2;

            @include respond-to(tablet) {
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }

            & b,
            strong {
                color: var(--c-alt-base-color); // тексты вставляются в админке, поэтому ориентация на эти теги
            }
        }

        & ul li {
            &::before {
                content: "";
                background-color: var(--c-primary);
                border-radius: 50%;
                display: block;
                height: 4px;
                width: 4px;
                inset-inline-start: 0;
                position: absolute;
                inset-block-start: 1.5rem;

                @include respond-to(tablet) {
                    inset-block-start: 0.5rem;
                }
            }
        }

        & ol {
            counter-reset: myCounter;

            li {
                &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter);
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-start: 0;
                    color: var(--c-accent);
                    font-weight: 500;
                }
            }
        }
    }

    &__stats-bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
    }

    &__col {
        width: 50%;
        display: flex;
        flex-direction: column;
        height: 100%;

        @include respond-to(desktop) {
            width: 80%;
            margin-block-end: 50px;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &_tablet-smaller {
            @include respond-to(desktop) {
                width: 60%;
            }

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &_small {
            width: 563px;

            @include respond-to(desktop) {
                width: 100%;
                margin-block-end: 0;
            }
        }
    }

    &__accent {
        font-size: inherit;
        white-space: nowrap;

        @include respond-to(desktop) {
            white-space: normal;
        }
    }

    &__plate {
        padding: 50px 60px 60px;
        background-color: var(--c-white);
        border-radius: 8px;

        @include respond-to(tablet) {
            padding: 40px;
        }

        @include respond-to(mobile) {
            padding: 30px 20px;
        }
    }

    &__plate-content {
        display: flex;
        justify-content: space-between;
        gap: 50px 90px;
        flex-wrap: wrap;
        flex-grow: 1;

        @include respond-to(desktop) {
            gap: 30px 140px;
            justify-content: flex-start;
        }

        &_tablet_column {
            @include respond-to(desktop) {
                flex-direction: column;
            }
        }

        &_gap_small {
            @include respond-to(mobile) {
                gap: 30px 80px;
            }
        }

        & .stats-item:first-child {
            width: 100%;

            & .stats-item__subtitle {
                width: 50%;
            }
        }
    }

    &__plate-item {
        width: calc(50% - 45px);

        @include respond-to(desktop) {
            width: calc(33.3% - 110px);
        }

        @include respond-to(mobile) {
            width: calc(50% - 45px);
        }
    }

    &__text {
        font-size: 1.125rem;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 1rem;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__flex-container {
        display: flex;
        gap: 40px 100px;
        flex-wrap: wrap;

        & > #{$this}__col {
            width: calc((100% - 100px) / 2);
            margin-block-end: 0;

            @include respond-to(tablet) {
                width: 100%;
            }
        }

        &_stats {
            & > #{$this}__col {
                margin-block-end: 0;
            }

            @include respond-to(tablet) {
                & > #{$this}__col {
                    width: calc((100% - 100px) / 2);

                    &:first-child {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__no-data {
        margin-block-start: 100px;
        text-align: center;

        @include respond-to(mobile) {
            margin-block-start: 80px;
        }
    }

    & .container {
        overflow: visible;
    }

    @include section-indents;
}

.a11y-mode {
    .municipalities {
        &__stats {
            flex-direction: column;
            gap: 60px;
        }

        &__col {
            width: 100%;

            &_small {
                width: 100%;
            }
        }

        &__plate {
            padding: 0;
        }

        &__plate-content {
            flex-direction: column;
        }

        &__plate-item {
            width: 100%;
        }
    }
}
