.investment-offers-filters {
    $this: &;

    &__filters {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-block-end: 20px;

        @include respond-to(tablet) {
            gap: 30px;
        }

        @include respond-to(mobile) {
            gap: 20px;
            margin-block-end: 10px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-block-start: 20px;

        @include respond-to(desktop) {
            flex-wrap: wrap;
        }

        @include respond-to(tablet) {
            justify-content: flex-end;
            flex-wrap: nowrap;
        }

        @include respond-to(mobile) {
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            padding-block-start: 10px;
        }

        & .btn {
            @include respond-to(desktop) {
                width: 100%;
                justify-content: center;
            }

            @include respond-to(tablet) {
                width: fit-content;
            }

            @include respond-to(mobile) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &_offers2 {
        #{$this}__filters {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 124px);

            @include respond-to(tablet) {
                grid-auto-flow: column;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(5, min-content);
            }
        }

        // объем инвестиций
        #{$this}__filter_number {
            grid-column: 1/2;
            grid-row: 1/3;

            @include respond-to(tablet) {
                grid-column: unset;
                grid-row: unset;
                order: 3;
            }
        }

        // мо
        #{$this}__filter_checkbox {
            grid-column: 2/3;
            grid-row: 1/2;

            @include respond-to(tablet) {
                grid-column: unset;
                grid-row: unset;
                order: 1;
            }
        }

        // отрасль
        #{$this}__filter_checkbox:first-of-type {
            grid-column: 2/3;
            grid-row: 2/3;

            @include respond-to(tablet) {
                grid-column: unset;
                grid-row: unset;
                order: 2;
            }
        }

        // инвест. площадка
        #{$this}__filter_radio {
            grid-column: 3/-1;
            grid-row: 1/2;

            @include respond-to(tablet) {
                grid-column: unset;
                grid-row: unset;
                order: 4;
            }
        }

        // with button
        & li:last-child {
            grid-column: 3/4;
            grid-row: 2/3;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            @include respond-to(tablet) {
                grid-column: unset;
                grid-row: unset;
                order: 5;
                align-items: center;
                justify-content: flex-start;
                margin-block-start: 10px;
            }

            & .btn {
                width: 100%;

                @include respond-to(tablet) {
                    width: fit-content;
                }

                @include respond-to(mobile) {
                    width: 100%;
                }
            }
        }

        & .filter {
            padding: 40px;

            @include respond-to(tablet) {
                padding: 30px;
            }

            @include respond-to(mobile) {
                padding: 20px;
            }
        }

        & .range-slider {
            justify-content: space-between;

            &__title {
                @include respond-to(tablet) {
                    margin-block-end: 37px;
                }

                @include respond-to(mobile) {
                    margin-block-end: 60px;
                }
            }
        }

        & .checkbox-filter {
            z-index: 2; // перекрыть карточки предложений
            position: relative;
            min-height: 100%;

            &__header {
                min-height: 44px;

                @include respond-to(tablet) {
                    min-height: unset;
                }
            }
        }
    }
}
