// изменяемые через конфиг. панель цвета
:root {
    // по дефолту цвет #213160
    --c-base-color: #213160; // ссылки, цвет шапки в таблицах
    --c-alt-base-color: #4786e1; // карточки при наведении, дропдауны, большие числа; бегунки, чекбоксы в фильтрах, табы; ссылки
    --c-main-slider-line-color: #4d92f5; // цвет полосок слайдера на главной
    --c-list-bullet-color: #4786e1; // цвет буллитов в списках
    --c-map-point-color: #4d92f5; // цвет точки на карте / альт. странице рейтингов
    --c-map-point-outline-color: rgb(77 146 245 / 20%); // цвет обводки точки на карте / альт. странице рейтингов
    --c-alt-link-color: #ced6eb; // цвета ссылок в шапках дропдаунов

    // кнопки-стрелки на темном фоне
    --c-arrow-dark-basic-color: rgb(255 255 255 / 17%);
    --c-arrow-dark-hover-color: rgb(255 255 255 / 30%);
    --c-arrow-dark-active-color: rgb(255 255 255 / 40%);

    // кнопки-стрелки на светлом фоне
    --c-arrow-light-basic-color: #bfc7d4;
    --c-arrow-light-hover-color: #213160;
    --c-arrow-light-active-color: rgb(33 49 96 / 90%);

    // буллиты в слайдере на темном фоне
    --c-slider-bullet-dark-basic-color: #9098af;

    // буллиты в слайдере на светлом фоне
    --c-slider-bullet-light-basic-color: #bfc7d4;
    --c-slider-bullet-light-alt-color: #213160;

    // базовые кнопки: .btn
    --c-button-basic-color: #fff;
    --c-button-hover-color: #213160;
    --c-button-active-text-color: #213160;
    --c-button-active-background-color: #fff;
    --c-button-active-opacity: 0.7;

    // акцентные кнопки: .btn.btn_accent и .btn.btn_background_accent
    --c-button-accent-color: #4786e1;
    --c-button-accent-activeOpacity: 0.7;
}

.a11y-mode {
    --c-base-color: var(--c-a11y-accent-color) !important;
    --c-alt-base-color: var(--c-a11y-accent-color) !important;
    --c-main-slider-line-color: var(--c-a11y-accent-color) !important;
    --c-list-bullet-color: var(--c-a11y-accent-color) !important;
    --c-alt-link-color: var(--c-a11y-accent-color) !important;
    --c-arrow-dark-basic-color: var(--c-a11y-accent-color) !important;
    --c-arrow-dark-hover-color: var(--c-a11y-accent-color) !important;
    --c-arrow-dark-active-color: var(--c-a11y-accent-color) !important;
    --c-arrow-light-basic-color: var(--c-a11y-accent-color) !important;
    --c-arrow-light-hover-color: var(--c-a11y-accent-color) !important;
    --c-arrow-light-active-color: var(--c-a11y-accent-color) !important;
    --c-slider-bullet-dark-basic-color: var(--c-a11y-accent-color) !important;
    --c-slider-bullet-light-basic-color: var(--c-a11y-accent-color) !important;
    --c-slider-bullet-light-alt-color: var(--c-a11y-accent-color) !important;
    --c-button-basic-color: var(--c-a11y-background-color) !important;
    --c-button-hover-color: var(--c-a11y-background-color) !important;
    --c-button-active-text-color: var(--c-a11y-background-color) !important;
    --c-button-active-background-color: var(--c-a11y-accent-color) !important;
    --c-button-accent-color: var(--c-a11y-accent-color) !important;
}
