.input-error {
    background: var(--c-light-bg);
    border-radius: 50px;
    padding: 4px 9px;
    font-size: 0.75rem;
    line-height: 1.4;
    position: relative;
    width: fit-content;
    z-index: 1;

    &::before {
        content: "";
        border-width: 0 10px 10px;
        border-color: var(--c-light-bg) transparent;
        border-style: solid;
        position: absolute;
        inset-block-start: -7px;
        inset-inline-start: 5px;
    }
}
