.dp__instance_calendar,
.dp__calendar {
    position: relative;
}

.dp__calendar_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $dp__font_family;
    flex: 0;

    & .dp__calendar {
        max-width: 100%;
    }
}

.dp__calendar_header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dp-secondary-color);
    white-space: nowrap;
    font-weight: 500;
    max-width: 100%;

    @include respond-to(mobile) {
        display: none;
    }
}

.dp__calendar_header_item {
    text-align: center;
    flex-grow: 1;
    font-weight: 500;
    margin: 0;
    height: 32px;
    width: 52px;

    @include respond-to(tablet) {
        font-size: 1.125rem;
        width: 60px;
    }

    @include respond-to(mobile) {
        font-size: 1rem;
        width: 40px;
    }
}

.dp__calendar_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dp__calendar_item {
    text-align: center;
    flex-grow: 1;
    box-sizing: border-box;
    color: var(--dp-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;

    @include respond-to(tablet) {
        width: 60px;
    }

    @include respond-to(mobile) {
        width: 40px;
    }
}

%__dp_active {
    background: var(--c-accent);
    color: var(--c-white);

    &:hover {
        background: var(--c-accent);
        color: var(--c-white);
    }
}

.dp__cell_inner {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin: 1px auto;
    border: 1px solid transparent;
    position: relative;
    border-radius: 50%;

    @include respond-to(tablet) {
        font-size: 1.125rem;
    }

    @include respond-to(mobile) {
        font-size: 1rem;
    }

    &:hover {
        background: var(--c-accent);
        color: var(--c-white);
    }
}

.dp__cell_offset {
    color: var(--dp-secondary-color);
}

.dp__active_date,
.dp__range_start,
.dp__range_end {
    @extend %__dp_active;
}

.dp__range_between {
    background: var(--c-light-bg);
}
