.dropdown {
    $this: &;

    display: flex;
    flex-direction: column;

    &__block {
        padding: 36px 50px;
        border-radius: 8px;
        background-color: var(--c-light-bg);

        @include respond-to(mobile) {
            padding: 30px 20px;
        }

        &:not(#{$this}__header) {
            margin-block-start: 10px;
        }
    }

    &__header {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        transition: color 0.3s, background-color 0.3s;

        &:not(:only-child) {
            cursor: pointer;
        }

        &.styled-text-links:hover {
            & a:not(.btn) {
                color: var(--c-alt-base-color);

                &:visited {
                    color: var(--c-alt-base-color);
                }
            }
        }
    }

    &__header-icon {
        margin-inline-end: 20px;
        width: 56px;
    }

    &__arrow {
        transition: all 0.4s ease;
        min-width: 16px;
        min-height: 8px;

        path {
            transition: fill 0.3s;
        }

        @include respond-to(mobile) {
            min-width: 12px;
            min-height: 8px;
        }
    }

    &:hover,
    &.active {
        #{$this}__header:not(:only-child) {
            color: var(--c-white);
            background-color: var(--c-base-color);

            & a {
                color: var(--c-alt-link-color) !important;
            }
        }

        #{$this}__arrow {
            path {
                fill: var(--c-white);
            }
        }
    }

    &.active {
        #{$this}__arrow {
            transform: rotate(180deg);
        }
    }
}

.a11y-mode {
    .dropdown {
        &:hover,
        &.active {
            & .dropdown__header:not(:only-child) {
                color: var(--c-a11y-accent-color);
                background-color: var(--c-a11y-background-color);

                & a {
                    color: var(--c-a11y-accent-color);
                }
            }
        }

        &__header {
            border: 1px solid var(--c-black);

            &.styled-text-links:hover {
                & a:not(.btn) {
                    color: var(--c-white);

                    &:visited {
                        color: var(--c-white);
                    }
                }
            }
        }

        &__arrow {
            display: none;
        }

        &__block {
            display: block !important;
        }
    }
}
