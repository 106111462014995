.block-with-indicators {
    $this: &;

    position: relative;
    padding-block: 100px;

    @include respond-to(mobile) {
        padding-block: 80px;
    }

    &__image {
        display: block;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;

        &_smaller {
            inset-inline-start: auto;
            inset-inline-end: 40px;
            width: auto;
        }
    }

    &__header {
        color: var(--c-white);
    }

    &__period {
        color: var(--c-alt-base-color);
    }

    &__wrapper {
        color: var(--c-white);
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        gap: 80px 60px;

        @include respond-to(tablet) {
            gap: 40px;
            justify-content: space-between;
        }
    }

    &__indicator {
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(33.3% - (120px / 3));
        color: var(--c-white);
        order: 4;

        @include respond-to(tablet) {
            width: calc(50% - 100px);
            order: 1;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &:first-child {
            order: 1;
        }

        &:nth-child(2) {
            order: 2;
        }

        &:last-child {
            order: 3;
        }
    }

    &__indicator-value {
        display: inline;
        opacity: 0;
        font-weight: 600;
        font-size: 60px;
        line-height: 1.1;
        margin-inline-end: 4px;

        @include respond-to(tablet) {
            font-size: 40px;
            line-height: 1;
        }

        @include respond-to(mobile) {
            font-weight: 700;
            font-size: 35px;
        }
    }

    &__text {
        font-size: 24px;
        line-height: 1.4;
        font-weight: 500;
        columns: 2;
        column-gap: 150px;

        @include list-with-small-bullets;

        @include respond-to(tablet) {
            font-size: 16px;
            column-gap: 20px;
        }

        @include respond-to(mobile) {
            font-size: 14px;
            columns: unset;
        }
    }

    &__indicators-title {
        font-size: 24px;
        line-height: 1.4;
        font-weight: 500;
        margin-block-end: 40px;

        @include respond-to(tablet) {
            font-size: 22px;
            line-height: 1.2;
        }

        @include respond-to(mobile) {
            font-size: 16px;
            line-height: 1.4;
            margin-block-end: 30px;
        }
    }

    &__value-wrapper-wrapper {
        margin-block-end: 10px;

        @include respond-to(tablet) {
            margin-block-end: 20px;
        }

        @include respond-to(mobile) {
            margin-block-end: 10px;
        }
    }

    &_demography {
        #{$this}__header {
            width: 100%;
        }

        #{$this}__title {
            font-size: 60px;
            line-height: 1.1;
            margin-block-end: 69px;

            @include respond-to(tablet) {
                font-size: 40px;
                line-height: 1;
                margin-block-end: 30px;
            }

            @include respond-to(mobile) {
                font-size: 22px;
                line-height: 1.2;
            }
        }

        #{$this}__body {
            gap: 50px;

            @include respond-to(tablet) {
                gap: 30px 100px;
                justify-content: flex-start;
            }

            @include respond-to(mobile) {
                gap: 30px 80px;
            }
        }

        #{$this}__indicator {
            width: calc((100% - 250px) / 6);

            @include respond-to(tablet) {
                width: calc((100% - 200px) / 3);
            }

            @include respond-to(mobile) {
                width: calc((100% - 80px) / 2);
            }

            & > div {
                display: flex;
                flex-direction: column-reverse;

                @include respond-to(tablet) {
                    flex-direction: column;
                }
            }

            &:first-child {
                order: 1;
            }

            &:nth-child(2) {
                order: 1;
            }

            &:last-child {
                order: 1;
            }
        }

        #{$this}__indicator-value {
            font-size: 40px;
            line-height: 1.2;
            opacity: 1;

            @include respond-to(tablet) {
                font-size: 30px;
            }

            @include respond-to(mobile) {
                font-size: 22px;
            }
        }

        #{$this}__value-wrapper {
            margin-block-end: 5px;

            @include respond-to(mobile) {
                margin-block-end: 10px;
            }
        }
    }

    &__drop-shadow {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background-color: var(--c-black);
        opacity: 0;
    }

    &_alt {
        padding-block: 97px 100px;

        @include respond-to(tablet) {
            padding-block: 100px;
        }

        @include respond-to(mobile) {
            padding-block: 50px;
        }

        #{$this}__drop-shadow {
            opacity: 0.4;
        }

        #{$this}__body {
            gap: 70px 100px;

            @include respond-to(tablet) {
                gap: 60px;
            }

            @include respond-to(mobile) {
                gap: 40px;
            }
        }

        #{$this}__indicator {
            width: calc((100% - 100px) / 2);
            flex-direction: row;
            align-items: flex-start;
            gap: 60px;

            @include respond-to(tablet) {
                width: 100%;
            }

            @include respond-to(mobile) {
                flex-direction: column;
                gap: 20px;
            }

            & h4 {
                @include respond-to(tablet) {
                    font-weight: 400;
                }

                @include respond-to(mobile) {
                    font-weight: 500;
                }
            }
        }

        #{$this}__indicator-value-wrapper {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            min-width: 160px;
            max-width: 160px;
            margin-block-end: 0;

            @include respond-to(tablet) {
                margin-block-end: 0;
                min-width: 36%;
                max-width: 36%;
            }

            @include respond-to(mobile) {
                margin-block-end: 0;
            }

            & .h4 {
                margin-block-end: 5px;

                @include respond-to(tablet) {
                    margin-block-end: 0;
                    font-weight: 400;
                }
            }
        }

        #{$this}__indicator-value {
            margin-inline-end: 10px;
            line-height: 1;

            @include respond-to(tablet) {
                margin-inline-end: 5px;
            }
        }
    }

    &_hr {
        padding-block: 100px;

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        #{$this}__body {
            gap: 80px 100px;

            @include respond-to(tablet) {
                gap: 40px;
            }

            @include respond-to(mobile) {
                gap: 25px;
            }
        }

        #{$this}__indicator {
            width: calc((100% - 100px) / 2);
            gap: 45px;

            @include respond-to(tablet) {
                width: 100%;
                gap: 60px;
            }

            @include respond-to(mobile) {
                gap: 15px;
                flex-direction: row;
            }

            &:first-child {
                order: 1;

                @include respond-to(desktop-only) {
                    order: 1;
                }
            }

            &:nth-child(2) {
                order: 2;

                @include respond-to(desktop-only) {
                    order: 5;
                }
            }

            &:nth-child(3) {
                order: 3;

                @include respond-to(desktop-only) {
                    order: 3;
                }
            }

            &:nth-child(4) {
                order: 4;

                @include respond-to(desktop-only) {
                    order: 2;
                }
            }

            &:nth-child(5) {
                order: 5;

                @include respond-to(desktop-only) {
                    order: 4;
                }
            }

            &:last-child {
                order: 6;

                @include respond-to(desktop-only) {
                    order: 6;
                }
            }

            &:nth-child(n - 3) {
                #{$this}__indicator-value-wrapper {
                    min-width: 100px;
                    max-width: 100px;

                    @include respond-to(tablet) {
                        min-width: 160px;
                        max-width: 160px;
                    }

                    @include respond-to(mobile) {
                        min-width: 90px;
                        max-width: 90px;
                    }
                }
            }

            &:nth-child(n + 4) {
                #{$this}__indicator-value-wrapper {
                    min-width: 230px;
                    max-width: 230px;

                    @include respond-to(tablet) {
                        min-width: 160px;
                        max-width: 160px;
                    }

                    @include respond-to(mobile) {
                        min-width: 90px;
                        max-width: 90px;
                    }
                }
            }
        }

        #{$this}__indicator-value {
            font-size: 3.75rem;

            @include respond-to(tablet) {
                font-size: 2.5rem;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
            }
        }
    }

    &_promising-sector {
        padding-block: 100px;

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        #{$this}__body {
            gap: 60px;

            @include respond-to(tablet) {
                gap: 45px;
            }

            @include respond-to(mobile) {
                gap: 25px;
            }
        }

        #{$this}__drop-shadow {
            opacity: 0.6;
        }

        #{$this}__indicator {
            width: calc((100% - 100px) / 2);
            gap: 30px;

            @include respond-to(tablet) {
                width: 100%;
                gap: 60px;
                flex-direction: row;
            }

            @include respond-to(mobile) {
                gap: 25px;
            }

            &:first-child {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 3;
            }

            &:nth-child(4) {
                order: 4;
            }

            &:nth-child(5) {
                order: 5;
            }

            &:last-child {
                order: 6;
            }

            &:nth-child(2n) {
                #{$this}__indicator-value-wrapper {
                    min-width: 35%;
                    max-width: 35%;

                    @include respond-to(tablet) {
                        min-width: 38%;
                        max-width: 38%;
                    }
                }
            }

            &:nth-child(2n + 1) {
                #{$this}__indicator-value-wrapper {
                    min-width: 55%;
                    max-width: 55%;

                    @include respond-to(tablet) {
                        min-width: 38%;
                        max-width: 38%;
                    }
                }
            }
        }

        #{$this}__indicator-value-wrapper {
            @include respond-to(tablet) {
                flex-wrap: wrap;
            }

            & .h4 {
                white-space: nowrap;
            }
        }

        #{$this}__indicator-value {
            white-space: nowrap;
            font-size: 3.75rem;

            @include respond-to(tablet) {
                font-size: 2.5rem;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                font-weight: 600;
            }
        }
    }

    &_main {
        padding-block: 110px;
        background: radial-gradient(
                circle at calc(50% - 730px) 900px,
                var(--c-half-transparent-blue) 0,
                var(--c-true-transparent) 400px
            ),
            var(--c-secondary);

        @include respond-to(tablet) {
            padding-block: 100px;
        }

        @include respond-to(mobile) {
            padding-block: 80px;
        }

        #{$this}__title {
            font-size: 3.75rem;
            line-height: 1.1;

            @include respond-to(tablet) {
                font-size: 2.5rem;
                line-height: 1;
            }

            @include respond-to(mobile) {
                font-size: 1.375rem;
                line-height: 1.2;
            }
        }

        #{$this}__body {
            gap: 60px 155px;

            @include respond-to(tablet) {
                gap: 57px 116px;
            }

            @include respond-to(mobile) {
                gap: 40px 20px;
            }
        }

        #{$this}__indicator {
            max-width: 312px;

            @include respond-to(tablet) {
                min-width: calc((100% - 116px) / 2);
            }

            @include respond-to(mobile) {
                width: calc((100% - 20px) / 2);
                max-width: 162px;
            }
        }

        #{$this}__indicator-value {
            font-size: 8rem;

            @include respond-to(tablet) {
                font-size: 3.125rem;
            }

            @include respond-to(mobile) {
                font-size: 2.188rem;
            }
        }

        & .link-on-primary {
            z-index: 1; // фоновую картинку перекрываем
        }
    }
}

.a11y-mode {
    .block-with-indicators {
        color: var(--c-black);
        background: var(--c-white);

        &__indicator,
        &__title {
            color: var(--c-black);
        }

        picture {
            display: none;
        }

        &__indicator {
            max-width: unset;
            width: 100%;
            flex-direction: column;

            &:nth-child(n + 4) {
                .block-with-indicators__indicator-value-wrapper {
                    min-width: unset;
                    max-width: unset;
                }
            }

            & h4 {
                width: 100%;
            }
        }

        &__body {
            flex-direction: column;
        }

        &__indicator-value {
            opacity: 1;
        }

        &__header {
            color: var(--c-primary);
        }

        &__text {
            columns: 1;

            & ul li::before {
                background-color: var(--c-primary);
            }
        }
    }
}
