.promising-sectors-detail {
    $this: &;

    &__description {
        @include respond-to(desktop) {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        @include respond-to(mobile) {
            gap: 20px;
        }

        & img {
            max-width: 50%;
            border-radius: 8px;
            float: right;
            margin-block-end: 20px;
            margin-inline-start: 20px;
            object-fit: cover;

            @include respond-to(desktop) {
                max-width: 100%;
                order: 3;
                margin-block-start: 26px;
                margin-block-end: 0;
                margin-inline-start: 0;
            }

            @include respond-to(mobile) {
                margin-block-start: 30px;
            }
        }

        &-images {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            & img {
                float: unset;
                margin: 0;
                width: calc((100% - 40px) / 3);
                height: 328px;

                @include respond-to(tablet) {
                    width: calc((100% - 20px) / 2);
                    height: 280px;
                }

                @include respond-to(mobile) {
                    width: 100%;
                    height: 187px;
                }
            }
        }

        &_alt {
            @include respond-to(desktop) {
                display: block;
            }

            #{$this}__description-text {
                font-size: 1.125rem;

                @include respond-to(tablet) {
                    font-size: 1rem;
                }

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }

                &:not(:last-child) {
                    margin-block-end: 80px;

                    @include respond-to(tablet) {
                        margin-block-end: 55px;
                    }

                    @include respond-to(mobile) {
                        margin-block-end: 50px;
                    }
                }
            }
        }
    }

    &__companies {
        & ul {
            padding-inline-start: 1.5em;
            list-style-position: inside;

            & > li {
                position: relative;

                &::before {
                    content: "\2022";
                    color: var(--c-accent);
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-inline-start: -1em;
                    font-size: 1.5em;
                    position: absolute;
                }
            }
        }
    }

    &__companies-header {
        display: flex;
        justify-content: space-between;

        & > :last-child {
            max-width: 40%;
            margin-inline-start: 32px;
            border-radius: 8px;
        }

        @include respond-to(desktop) {
            flex-direction: column;

            & > :last-child {
                max-width: 100%;
                margin-inline-start: 0;
            }
        }
    }

    &__companies-description {
        display: flex;
        gap: 20px;

        @include respond-to(desktop) {
            flex-direction: column;
        }

        & > img {
            max-width: 40%;
            height: 100%;
            margin-inline-start: 20px;
            border-radius: 8px;

            @include respond-to(desktop) {
                margin-inline-start: 0;
                margin-block-start: 60px;
                max-width: 100%;
                height: auto;
                object-fit: cover;
            }

            @include respond-to(mobile) {
                margin-block-start: 50px;
            }
        }

        & ul {
            & > :not(:last-child) {
                margin-block-end: 32px;

                @include respond-to(mobile) {
                    margin-block-end: 20px;
                }
            }
        }
    }

    &__companies-description-title {
        max-width: 50%;

        @include respond-to(desktop) {
            max-width: 100%;
        }
    }

    &__indicators {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc((100% / 3) - 20px), 1fr));
        gap: 20px;
        clear: both;

        @include respond-to(tablet) {
            display: flex;
            flex-wrap: wrap;
        }

        @include respond-to(mobile) {
            flex-wrap: nowrap;
            overflow: scroll;
            align-items: stretch;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__indicator {
        display: flex;
        flex-direction: column;
        min-height: 354px;
        max-height: fit-content;
        padding: 40px;
        background-color: var(--c-white);
        border-radius: 8px;

        @include respond-to(tablet) {
            min-height: 271px;
            width: calc(50% - 10px);
        }

        @include respond-to(mobile) {
            padding: 30px;
            min-width: 252px;
            min-height: unset;
            max-height: unset;
        }

        & a {
            color: var(--c-accent);
            text-decoration: underline;
            text-underline-offset: 0.5em;

            &:visited {
                color: var(--c-accent);
            }
        }
    }

    &__indicator-value {
        margin-block-end: 52px;

        @include respond-to(tablet) {
            margin-block-end: 63px;
        }

        @include respond-to(mobile) {
            & h2 {
                font-size: 30px;
            }
        }

        & > * {
            display: inline-block;
        }

        & > :last-child {
            margin-inline-start: 10px;

            @include respond-to(tablet) {
                margin-inline-start: 5px;
            }
        }

        &_mb-152 {
            margin-block-end: 152px;

            @include respond-to(tablet) {
                margin-block-end: 63px;
            }
        }

        &_mb-96 {
            margin-block-end: 96px;

            @include respond-to(tablet) {
                margin-block-end: 63px;
            }
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc((100% / 2) - 20px), 1fr));
        gap: 20px;

        @include respond-to(mobile) {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }

        &_alt {
            gap: 40px 20px;
            grid-template-columns: repeat(2, 1fr);

            @include respond-to(tablet) {
                row-gap: 50px;
            }

            @include respond-to(mobile) {
                grid-template-columns: 1fr;
            }

            #{$this}__card {
                min-height: unset;
                max-height: unset;
                padding: 0;

                @include respond-to(mobile) {
                    width: 81%;
                }
            }
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 470px;
        max-height: fit-content;
        padding: 40px;
        background-color: var(--c-light-blue);
        border-radius: 8px;

        @include respond-to(tablet) {
            padding: 20px;
            min-height: 456px;
        }

        @include respond-to(mobile) {
            width: 100%;
            min-height: 379px;
        }

        & a {
            color: var(--c-accent);
            text-decoration: underline;
            text-underline-offset: 0.5em;

            &:visited {
                color: var(--c-accent);
            }
        }
    }

    &__card-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.65;

        @include respond-to(tablet) {
            font-size: 16px;
            line-height: 1.4;
        }

        @include respond-to(mobile) {
            font-size: 14px;
        }
    }

    &__data-block {
        &:not(:last-child) {
            margin-block-end: 100px;
        }
    }

    &__text {
        & ul,
        ol {
            margin-block-start: 20px;
            margin-block-end: 20px;

            @include respond-to(mobile) {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }

        & ul li,
        ol li {
            list-style: none;
            margin: 0;
            padding-block: 0;
            padding-inline: 28px 0;
            position: relative;

            &:not(:last-child) {
                margin-block-end: 20px;

                @include respond-to(mobile) {
                    margin-block-end: 10px;
                }
            }
        }

        & ul li {
            &::before {
                content: "";
                background-color: var(--c-alt-base-color);
                border-radius: 50%;
                display: block;
                height: 8px;
                inset-inline-start: 0;
                position: absolute;
                inset-block-start: 50%;
                transform: translateY(-50%);
                width: 8px;
            }
        }

        & ol {
            counter-reset: myCounter;

            li {
                &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter);
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-start: 50%;
                    transform: translateY(-50%);
                    color: var(--c-accent);
                    font-weight: 500;
                }
            }
        }

        & p:not(:last-child) {
            margin-block-end: 20px;
        }
    }

    &__column-text {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        font-size: 1.125rem;
        line-height: 1.65;

        & > p {
            width: calc(50% - 10px);
        }
    }

    // alt
    &__directions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 30px;

        @include respond-to(mobile) {
            gap: 40px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__direction {
        width: calc((100% - 90px) / 3);

        @include respond-to(tablet) {
            width: calc((100% - 80px) / 2);
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    & .gallery__view {
        margin-block-end: 20px;
    }

    & .gallery__main-photo {
        height: 419px;

        @include respond-to(tablet) {
            height: 470px;
        }

        @include respond-to(mobile) {
            height: 230px;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .promising-sectors-detail {
        &__companies-header {
            flex-direction: column;

            & > :last-child {
                max-width: unset;
                margin-inline-start: 0;
            }
        }

        &__cards {
            grid-template-columns: 1fr;
        }

        &__card {
            min-height: unset;
            padding: 0;
        }

        &__title {
            max-width: unset;
        }
    }
}
