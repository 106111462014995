.table {
    width: fit-content;
    border-collapse: separate;
    border-spacing: 0 5px;

    & thead {
        color: var(--c-white);
        display: table;

        & tr {
            min-height: 102px;
            background-color: var(--c-base-color);

            & th {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.3;
                background-color: var(--c-base-color);
                color: var(--c-white);
                padding: 25px 15px;
                text-align: start;

                &:first-child {
                    border-start-start-radius: 8px;
                    border-end-start-radius: 8px;
                    padding-inline-start: 13px;
                    padding-inline-end: 6px;
                    width: 36px;
                }

                &:nth-child(2) {
                    padding-inline-end: 13px;
                }

                &:last-child {
                    width: 185px;
                }
            }
        }
    }

    & tbody {
        max-height: 1130px;
        overflow-y: auto;
        overflow-x: hidden;
        color: var(--c-secondary);
        display: block;

        @include respond-to(tablet) {
            overflow-x: auto;
            max-height: 726px;
        }

        &::-webkit-scrollbar {
            margin-block-start: 5px;
            width: 8px;
            background-color: var(--c-light-blue);
            border-radius: 8px;

            @include respond-to(tablet) {
                position: absolute;
                inset-block-start: 15px;
                inset-inline-end: 35px;
                display: block;
            }
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-light-bg);
            border-radius: 8px;
            padding-block-start: 5px;

            @include respond-to(tablet) {
                position: absolute;
                inset-block-start: 15px;
                inset-inline-end: 35px;
                display: block;
            }
        }

        & tr {
            display: flex;
            border-inline-end: 2px solid var(--c-white);
            position: relative;

            &:hover {
                td:not(:first-child) {
                    background-color: var(--c-blue-bg) !important;
                }
            }

            &:not(:first-child) {
                margin-block-start: 5px;
            }

            &:nth-child(2n) {
                & td {
                    background-color: var(--c-white);
                    border: 1px solid var(--c-light-blue);
                }
            }

            &:nth-child(2n + 1) {
                & td {
                    background-color: var(--c-light-blue);
                    border: 1px solid var(--c-white);
                }
            }

            & td {
                padding: 25px 15px;
                text-align: start;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.4;
                transition: background-color 0.3s;

                &:first-child {
                    position: absolute;
                    inset-inline: 0;
                    inset-block: 0;
                    padding: 0;
                    background-color: transparent;
                    border-color: transparent;

                    & a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                &:nth-child(2) {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    width: 36px;
                    padding: 25px 5px;
                    text-align: center;
                }

                &:last-child {
                    width: 173px;
                    word-wrap: break-word;
                }
            }
        }
    }

    th:nth-child(2),
    td:nth-child(3) {
        width: 153px;
        word-wrap: break-word;
    }

    th:nth-child(3),
    td:nth-child(4) {
        width: 282px;
    }

    th:nth-child(4),
    td:nth-child(5) {
        width: 161px;
        word-wrap: break-word;
    }

    th:nth-child(5),
    td:nth-child(6) {
        width: 129px;
    }

    th:nth-child(6),
    td:nth-child(7) {
        width: 127px;
    }

    th:nth-child(7),
    td:nth-child(8) {
        width: 152px;
    }

    th:nth-child(8),
    td:nth-child(9) {
        width: 155px;
    }

    th:last-child,
    td:last-child {
        border-start-end-radius: 8px;
        border-end-end-radius: 8px;
    }
}
