@mixin anchor-fix {
    & .section-wrapper.anchor-fix {
        &::before {
            display: block;
            content: "";
            height: 86px;
            margin: -86px 0 0;
        }
    }
}
