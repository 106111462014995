.checkbox {
    $this: &;

    cursor: pointer;
    display: flex;
    position: relative;

    &:hover {
        #{$this}__field {
            background-color: var(--c-white);

            .subscription_footer & {
                background-color: var(--c-true-transparent);
            }
        }
    }

    input {
        &:checked {
            & ~ #{$this}__field {
                svg {
                    display: block;
                }
            }
        }
    }

    &__field {
        border: 1px solid var(--c-alt-base-color);
        border-radius: 2px;
        background-color: transparent;
        height: 12px;
        width: 12px;
        padding: 3px;
        position: relative;
        margin-block-start: 3px;
        flex-shrink: 0;

        & svg {
            display: none;
            height: 6px;
            width: 7px;
            position: absolute;
            inset-inline-start: 50%;
            inset-block-start: 50%;
            transform: translate(-50%, -50%);
        }

        &.show-error {
            border: 1px solid var(--c-error);
        }
    }

    &__text {
        color: rgb(33 49 96 / 48%);
        display: inline-block;
        font-size: 14px;
        line-height: 1.4;
        margin-inline-start: 10px;

        @include respond-to(tablet) {
            font-weight: 400;
        }

        @include respond-to(mobile) {
            font-size: 0.875rem;
        }
    }

    &__error {
        display: none;
        pointer-events: none;
    }

    &.show-error {
        #{$this}__error {
            display: block;
        }

        #{$this}__field {
            border-color: var(--c-error);
        }
    }

    &_filter {
        align-items: center;

        #{$this}__field {
            width: 22px;
            height: 22px;
            margin-block-start: 0;
            background-color: var(--c-white);

            & svg {
                width: 10px;
                height: 8px;
            }

            .radio-filter_column & {
                width: 15px;
                height: 15px;
            }
        }

        #{$this}__text {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.4;
            color: var(--c-primary);

            .radio-filter_column & {
                font-size: 14px;
            }

            @include respond-to(mobile) {
                font-weight: 400;
                font-size: 0.875rem;
            }
        }

        input {
            &:checked {
                & ~ #{$this}__field {
                    background-color: var(--c-alt-base-color);

                    svg {
                        display: block;
                    }
                }

                & ~ #{$this}__text {
                    color: var(--c-alt-base-color);
                }
            }
        }
    }
}

.a11y-mode {
    .checkbox {
        &__field {
            width: 1rem;
            height: 1rem;

            & svg {
                width: 50%;
                height: 50%;
            }
        }

        input {
            &:focus {
                & ~ .checkbox__field {
                    border: 2px solid var(--c-a11y-accent-color);
                }
            }
        }
    }
}
