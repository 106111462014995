.checkbox-filter {
    $this: &;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__hide-btn {
        width: 20px;
        height: 20px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
    }

    &__options {
        padding-block-start: 18px;
        padding-block-end: 12px;
        max-height: 452px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-dark-bg);
            border: 2px solid transparent;
            border-radius: 8px;
            background-clip: content-box;
        }

        @include respond-to(tablet) {
            display: flex;
            row-gap: 20px;
            column-gap: 28px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding-block-start: 30px;
            padding-block-end: 15px;
        }

        @include respond-to(mobile) {
            display: block;
            padding-block-start: 15px;
            padding-block-end: 5px;

            &::-webkit-scrollbar {
                width: 8px;
            }
        }
    }

    &__option {
        max-width: fit-content;

        @include respond-to(tablet) {
            max-width: unset;
            width: calc((100% - 28px) / 2);
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        &:not(:last-child) {
            margin-block-end: 18px;

            @include respond-to(tablet) {
                margin-block-end: 0;
            }

            @include respond-to(mobile) {
                margin-block-end: 20px;
            }
        }
    }

    &.open {
        #{$this}__hide-btn {
            transform: scale(1, -1);
        }
    }
}

.a11y-mode {
    .checkbox-filter {
        &__options {
            flex-flow: column nowrap;
        }

        &__option {
            width: 100%;
        }
    }
}
