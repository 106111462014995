.ratings {
    $this: &;

    background-color: var(--c-light-blue);

    &__wrapper {
        display: flex;
        gap: 20px;

        @include respond-to(tablet) {
            flex-direction: column;
        }

        @include respond-to(mobile) {
            margin-block-start: 80px;
        }
    }

    &__tabs {
        width: calc(40% - 10px);

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__select {
        & .select__choosen {
            background-color: var(--c-light-bg);
            font-weight: 500;
            color: var(--c-primary);
            padding-block: 14px;
            padding-inline: 20px 30px;

            & span {
                font-size: 1.125rem;
                line-height: 1.4;

                @include respond-to(mobile) {
                    font-size: 0.875rem;
                }
            }
        }

        & .select__list {
            max-height: 293px;

            &::-webkit-scrollbar {
                background-color: var(--c-true-transparent);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--c-light-bg);
            }
        }

        & .select__item {
            align-items: center;
        }

        &.select__icon {
            & img {
                height: 100%;
            }
        }
    }

    &__content {
        width: calc(60% - 10px);
        height: fit-content;
        padding: 0 20px;
        border-radius: 8px;
        background-color: var(--c-white);

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    &__item-wrapper {
        display: none;

        &.active {
            display: block;
        }
    }

    & .select.select_simple.open {
        & .select__choosen {
            background-color: var(--c-white);
        }
    }

    & .container {
        overflow: visible;
    }

    @include section-indents;

    &__background {
        background: var(--c-secondary);

        & .content-tabs {
            flex-direction: row-reverse;
            gap: 194px;

            @include respond-to(tablet) {
                flex-direction: column;
                gap: 60px;
            }

            @include respond-to(mobile) {
                margin-block-start: 0;
            }

            & .select.select_simple.open .select__choosen {
                background-color: var(--c-primary);
            }
        }

        & .content-tabs__tabs {
            width: 33.3%;
        }

        & .content-tabs__content {
            color: var(--c-white);
            background-color: var(--c-true-transparent);
            padding: 0;
        }

        & .select__choosen,
        & .select__list {
            background-color: var(--c-primary);
            color: var(--c-white);

            & svg > path {
                stroke: var(--c-white);
            }
        }

        & .checkbox__text {
            color: var(--c-white);
        }

        & .select__item.checked {
            background-color: var(--c-primary);

            & .checkbox__text {
                color: var(--c-accent);
            }
        }
    }
}

.a11y-mode {
    .ratings {
        &__background {
            background-color: var(--c-a11y-background-color);

            & .content-tabs {
                flex-direction: column;
                gap: 40px;

                &__content {
                    color: var(--c-a11y-accent-color);
                }
            }
        }
    }
}
