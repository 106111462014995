.glide {
    $this: &;

    &__track {
        overflow: visible;

        @include respond-to(tablet) {
            margin: 0 -30px;
            clip-path: inset(0 0 0 30px);
        }

        @include respond-to(mobile) {
            margin: 0 -16px;
            clip-path: inset(0 0 0 16px);
        }
    }

    &__bullets {
        position: static;
        transform: none;
        margin-block-start: 40px;
        justify-content: center;
        width: 100%;
        gap: 15px;

        @include respond-to(tablet) {
            margin-block-start: 30px;
            justify-content: flex-start;
        }

        @include respond-to(mobile) {
            margin-block-start: 20px;
            gap: 10px;
        }

        &_tablet-center {
            @include respond-to(tablet) {
                justify-content: center;
            }
        }

        &_mobile-none {
            @include respond-to(mobile) {
                display: none;
            }
        }

        &_arrows {
            position: absolute;
            inset-block: -85px auto;
            inset-inline: auto 0;
            margin-block-start: 0;
            width: fit-content;

            #{$this}__arrow {
                position: static;
                transform: none;
                width: 44px;
                height: 44px;
                background-color: var(--c-dark-bg);

                & > svg {
                    width: 20px;
                }

                &.glide__bullet--active {
                    background-color: var(--c-dark-bg);
                }
            }

            @include respond-to(mobile) {
                inset-inline: 0 auto;
                inset-block-start: -75px;
            }
        }

        &_small-margin {
            margin-block-start: 10px;
        }

        &_sports {
            margin-block-start: 0;

            #{$this}__arrow {
                inset-block-start: 45%;
            }

            @include respond-to(tablet) {
                display: none;
            }
        }
    }

    &__bullet {
        background-color: var(--c-slider-bullet-light-basic-color);
        width: 12px;
        height: 12px;
        border: none;
        margin-inline: 0;
        box-shadow: none;

        &:hover,
        &:focus,
        &--active {
            background-color: var(--c-slider-bullet-light-alt-color);
            border: none;
        }

        @include respond-to(tablet) {
            &:not(.glide__bullet--active) {
                background-color: var(--c-slider-bullet-light-basic-color);
            }
        }

        @include respond-to(mobile) {
            width: 8px;
            height: 8px;
        }

        &_light {
            background-color: var(--c-slider-bullet-dark-basic-color);

            &.glide__bullet--active,
            &:hover,
            &:focus {
                background-color: var(--c-white);
            }
        }
    }

    &--swipeable {
        cursor: default;
    }

    &__arrow {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--c-arrow-light-basic-color);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:hover {
            background-color: var(--c-arrow-light-hover-color);
        }

        &:active {
            background-color: var(--c-arrow-light-active-color);
        }

        &--left {
            inset-inline-start: -10px;
        }

        &--right {
            inset-inline-end: -10px;
        }
    }
}

.a11y-mode {
    .glide {
        &__slides {
            width: 100% !important;
            transform: none !important;
            flex-direction: column;
        }

        &__bullets {
            display: none;
        }

        & li {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}
