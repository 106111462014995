.select {
    $this: &;

    position: relative;

    input {
        height: 1px;
        inset-inline-start: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        pointer-events: none;
        position: absolute;
        inset-block-start: 0;
        width: 1px;
    }

    &__label {
        color: var(--c-primary);
        display: block;
        font-size: 1rem;
        line-height: 1.4;
        margin-block-end: 20px;
        font-weight: 600;

        @include respond-to(tablet) {
            margin-block-end: 10px;
        }
    }

    &__choosen {
        background-color: var(--c-light-blue);
        border-radius: 8px;
        color: var(--c-primary);
        cursor: pointer;
        padding: 20px;
        position: relative;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & span {
            font-size: 0.875rem;
            line-height: 1.4;
            width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__icon {
        min-width: 52px;
        height: 52px;
        margin-inline-end: 20px;
        background-color: var(--c-dark-bg);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to(mobile) {
            min-width: 36px;
            height: 36px;

            & img {
                height: 50%;
            }
        }
    }

    &__list {
        position: absolute;
        inset-block-start: calc(100% + 6px);
        inset-inline-start: 0;
        z-index: 110;
        background-color: var(--c-white);
        box-shadow: 0 4px 10px var(--c-shadow);
        border-radius: 8px;
        width: 100%;
        max-height: 342px;
        overflow-y: auto;
        overflow-x: hidden;
        display: none;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: var(--c-light-bg);
            border-radius: 0 8px 8px 0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--c-alt-base-color);
            border-radius: 8px;
        }
    }

    &__item {
        color: var(--c-primary);
        cursor: pointer;
        padding: 10px 20px;

        &:hover {
            background-color: var(--c-light-blue);
        }

        &.checked {
            background-color: var(--c-light-blue);

            .checkbox__field {
                background-color: var(--c-alt-base-color);
            }
        }
    }

    &__item-checkbox-text {
        font-size: 0.875rem;
        line-height: 1.4;
        color: inherit;
        margin-inline-start: 10px;

        .checked & {
            color: var(--c-alt-base-color);
        }
    }

    &.open {
        #{$this}__choosen {
            svg {
                transform: rotate(180deg);
            }
        }

        #{$this}__list {
            display: block;
        }
    }

    &_bigger {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        #{$this}__label {
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.4;
        }

        #{$this}__choosen {
            padding: 28px 40px 27px;

            & span {
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.4;
            }
        }
    }
}

.a11y-mode {
    .select {
        &__choosen {
            border: 1px solid var(--c-black);
        }

        &__item {
            align-items: center;
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .select {
        &__icon {
            display: none;
        }
    }
}
