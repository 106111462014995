.main {
    &__slider {
        height: 757px;

        @include respond-to(mobile) {
            max-height: 100vh;
        }

        &_alt {
            @include respond-to(tablet) {
                height: 700px;
            }
        }
    }

    &__tiles {
        @include respond-to(tablet) {
            .tiles__container-lower {
                flex-wrap: wrap-reverse;

                .tile {
                    &_half {
                        width: 100%;
                    }

                    &_quarter {
                        width: calc((100% - 20px) / 2);
                    }
                }
            }
        }

        @include respond-to(mobile) {
            .tile {
                width: 100% !important;
            }
        }
    }

    &__headline {
        align-items: center;

        @include respond-to(mobile) {
            .btn {
                display: none;
            }
        }

        &_investors {
            display: none;

            @include respond-to(tablet) {
                display: flex;
            }
        }
    }

    &__news-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    & .bg-secondary + .bg-primary,
    & .bg-primary + .bg-secondary {
        margin-block-start: 0;
    }

    & .glide__slide--clone {
        filter: brightness(0.5);
    }

    & .gallery__photo-block {
        // чтобы кнопка не выезжала в этом размере
        @media screen and (max-width: 1224px) {
            width: 50%;
        }

        @include respond-to(tablet) {
            width: 100%;
        }
    }

    @include section-indents;
}

.a11y-mode {
    .main {
        &__slider {
            height: auto;
            max-height: unset;
        }

        & .gallery__text-block {
            width: 100%;
        }
    }
}
