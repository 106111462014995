.news-card {
    $this: &;

    height: 337px;
    position: relative;

    @include respond-to(desktop) {
        height: 280px;
    }

    &:hover {
        img {
            @include respond-to(desktop-only) {
                transform: scale(1.1);
            }
        }
    }

    &__image-container {
        overflow: hidden;
        border-radius: 6px;
        width: 100%;
        height: 100%;
    }

    & > a {
        background-color: #f4f5f7;
        border-radius: 10px;
        color: var(--c-white);
        display: flex;
        height: 100%;
        padding: 10px;
        position: relative;
        overflow: hidden;

        & img {
            border-radius: 6px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 0.44s ease-out;
        }
    }

    &__text {
        background: linear-gradient(2.29deg, #0b1943 6.65%, rgb(0 0 0 / 3%) 94.15%);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 0 30px 20px;
        position: absolute;
        inset-inline: 10px;
        inset-block: 10px;
    }

    &__date {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-block-end: 20px;

        @include respond-to(desktop) {
            font-size: 0.75rem;
            line-height: 1.25;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        margin-block-end: 10px;

        @include respond-to(desktop) {
            font-size: 0.875rem;
        }
    }

    &__subtitle {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        @include respond-to(desktop) {
            font-size: 0.875rem;
        }

        @include respond-to(tablet) {
            display: none;
        }
    }

    &_giant {
        width: calc((100% - 20px) / 2);

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &_big {
        width: 43%;
    }

    &_middle {
        width: 33%;
    }

    &_small {
        width: 24%;
    }

    &_newslist2 {
        width: 100%;

        @include respond-to(mobile) {
            height: auto;
        }

        & > a {
            background-color: var(--c-light-blue);
            color: var(--c-primary);
            padding: 0;

            @include respond-to(mobile) {
                flex-direction: column;
            }

            & img {
                border-radius: unset;
            }
        }

        #{$this}__image-container {
            border-radius: unset;
            width: 35%;

            @include respond-to(mobile) {
                min-height: 265px;
                width: 100%;
            }
        }

        #{$this}__text {
            background: none;
            flex: 1;
            justify-content: start;
            padding-block: 30px;
            padding-inline: 40px;
            position: unset;

            @include respond-to(tablet) {
                padding: 30px;
            }

            @include respond-to(mobile) {
                padding: 20px;
            }
        }

        #{$this}__date {
            color: var(--c-accent);
            margin-block-end: 60px;

            @include respond-to(tablet) {
                font-size: 0.875rem;
                margin-block-end: 46px;
            }

            @include respond-to(mobile) {
                font-size: 0.85rem;
                margin-block-end: 40px;
            }
        }

        #{$this}__title {
            font-size: 1.5rem;
            margin-block-end: 30px;

            @include respond-to(tablet) {
                font-size: 1.25rem;
                margin-block-end: 20px;
            }

            @include respond-to(mobile) {
                font-size: 1rem;
            }
        }

        #{$this}__subtitle {
            font-size: 1.125rem;
            line-height: 1.65;

            @include respond-to(tablet) {
                display: block;
                font-size: 1rem;
                line-height: 1.4;
            }

            @include respond-to(mobile) {
                font-size: 0.875rem;
            }
        }
    }

    &_alt {
        width: 100%;
        height: 100%;

        &:hover {
            #{$this}__text {
                background-color: var(--c-primary);
                color: var(--c-white);
            }

            #{$this}__date {
                color: var(--c-white);
            }
        }

        #{$this}__text {
            flex-direction: column-reverse;
            justify-content: space-between;
            border-radius: unset;
            padding: 30px;
            gap: 30px;
            transition: background-color 0.3s, color 0.3s;

            @include respond-to(tablet) {
                gap: 20px;
            }

            @include respond-to(mobile) {
                padding: 20px;
                min-height: 148px;
            }
        }

        #{$this}__date {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.2;
            margin-block-end: 0;
            transition: color 0.3s;
        }

        #{$this}__title {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4;
            margin-block-end: 0;
        }

        #{$this}__image-container {
            min-width: 49%;
            max-width: 49%;
            height: auto;
            width: unset;
            max-height: 100%;

            @include respond-to(tablet) {
                min-width: 47%;
                max-width: 47%;
            }

            @include respond-to(mobile) {
                height: 226px;
                min-height: unset;
                min-width: 100%;
                max-width: 100%;
            }

            & img {
                aspect-ratio: 16 / 9;
            }
        }
    }
}

.a11y-mode {
    .news-card {
        height: auto;
        width: 100%;

        & a {
            @include link-sizes-fix;
            background-color: var(--c-a11y-accent-color);
        }

        &__text {
            background: var(--c-true-transparent);
            position: static;
            width: 100%;
            padding-block-start: 20px;
        }

        &__date {
            color: var(--c-a11y-accent-color);
        }

        &__title {
            color: var(--c-a11y-accent-color);
        }
    }
}

.a11y-mode.a11y-mode-images-hide {
    .news-card {
        &__image-container {
            display: none;
        }
    }
}
