.corruption-prevention {
    &__feedback-text {
        width: 600px;
        color: var(--c-white);

        @include respond-to(tablet) {
            width: 503px;
        }

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &__feedback-text-alt {
        width: 715px;
        color: var(--c-primary);

        @include respond-to(tablet) {
            width: 503px;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        & h3 {
            font-size: 20px;
            line-height: 1.4;

            @include respond-to(tablet) {
                font-size: 30px;
                line-height: 1.2;
            }

            @include respond-to(mobile) {
                font-size: 22px;
            }
        }

        & p {
            font-size: 16px;
            line-height: 1.4;

            @include respond-to(mobile) {
                font-size: 14px;
            }
        }
    }

    &__feedback-background {
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 0;
    }

    &__guide-header {
        margin-block-end: 50px;

        @include respond-to(tablet) {
            max-width: 100%;
        }
    }

    &__guide-image {
        width: 100%;
    }

    &__docs-block:not(:last-child) {
        margin-block-end: 100px;

        @include respond-to(mobile) {
            margin-block-end: 80px;
        }
    }

    & .container {
        overflow: visible;
    }

    & p:not(:last-child) {
        margin-block-end: 20px;
    }

    @include section-indents;
}
