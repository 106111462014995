.text-with-icon {
    display: flex;
    gap: 30px;

    @include respond-to(tablet) {
        flex-direction: column;
        gap: 20px;
    }

    @include respond-to(mobile) {
        gap: 10px;
    }

    &__icon {
        width: 50px;
        height: 50px;
        background-color: var(--c-primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @include respond-to(tablet) {
            width: 40px;
            height: 40px;
        }

        @include respond-to(mobile) {
            width: 26px;
            height: 26px;
        }

        & svg {
            width: 20px;
            height: 20px;

            @include respond-to(tablet) {
                width: 16px;
                height: 16px;
            }

            @include respond-to(mobile) {
                width: 12px;
                height: 12px;
            }
        }
    }

    &__name {
        color: var(--c-accent);
        line-height: 1.4;

        @include respond-to(tablet) {
            font-size: 0.875rem;
        }

        &:not(:last-child) {
            margin-block-end: 15px;

            @include respond-to(tablet) {
                margin-block-end: 10px;
            }
        }
    }

    &__value {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.4;

        @include respond-to(tablet) {
            font-weight: 400;
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            font-weight: 500;
            font-size: 0.875rem;
        }
    }
}
