.item-with-counter {
    $this: &;

    &__counter {
        font-size: 1rem;
        line-height: 1.65;
        font-weight: 500;
        text-align: center;
        margin-block-end: 3px;
    }

    &__value {
        border: 2px solid var(--c-alt-base-color);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 600;
        transition: background-color 0.33s ease, color 0.33s ease;

        &:hover {
            background-color: var(--c-alt-base-color);
            color: var(--c-white);
        }
    }

    &_bigger {
        #{$this}__value {
            width: 150px;
            max-width: 233px;
        }
    }

    &_reverse {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;

        #{$this}__counter {
            margin-block-end: 0;
            font-size: 0.875rem;
            text-align: start;
        }
    }

    &_accent {
        #{$this}__value {
            background-color: var(--c-alt-base-color);
            color: var(--c-white);

            &:hover {
                background-color: var(--c-true-transparent);
                color: var(--c-primary);
            }
        }
    }
}

.a11y-mode {
    .item-with-counter {
        &__value {
            width: auto;
            height: auto;
        }
    }
}
