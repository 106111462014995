.image-with-label {
    position: relative;
    width: 100%;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 693px;
        min-height: 693px;
        border-radius: 8px;
        display: block;

        @include respond-to(tablet) {
            max-height: 400px;
            min-height: 400px;
        }

        @include respond-to(mobile) {
            max-height: 230px;
            min-height: 230px;
        }
    }

    &__label {
        position: absolute;
        inset-block-end: 30px;
        inset-inline: 30px;
        padding: 30px 40px;
        border-radius: 8px;
        background-color: var(--c-white);
        font-size: 1.25rem;
        line-height: 1.4;
        max-width: 584px;
        width: fit-content;

        &:empty {
            display: none;
        }

        @include respond-to(tablet) {
            inset-inline: 12px;
            inset-block-end: 12px;
            padding: 12px 18px;
            font-size: 1rem;
        }

        @include respond-to(mobile) {
            inset-inline: 10px;
            inset-block-end: 10px;
            padding: 8px 10px;
            font-size: 0.875rem;
        }
    }
}
